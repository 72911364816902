<template>
  <storage-page class="y-overflow custom-bg">
    <v-col>
      <v-row class="mt-0">
        <v-slide-x-transition>
          <v-col
            cols="6"
            class="pt-0"
          >
            <c-product-groups />
          </v-col>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-col
            cols="6"
            class="pt-0"
          >
            <c-products />
          </v-col>
        </v-slide-x-transition>
      </v-row>
      <v-row class="mt-2">
        <v-slide-y-transition>
          <v-col
            cols="12"
          >
            <c-packages />
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </v-col>
    <router-view />
  </storage-page>
</template>

<script>
import StoragePage from '@/components/StoragePage.vue';
import CProducts from '@/components/product/index/storage.vue';
import CProductGroups from '@/components/product-group/index/storage.vue';
import CPackages from '@/components/package/index/storage.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ViewsStorageProducts',
  components: {
    StoragePage,
    CProducts,
    CProductGroups,
    CPackages,
  },

  computed: {
    ...mapGetters({
      productGroupTemplate: 'productGroup/template',
      productTemplate: 'product/template',
      productGroupList: 'productGroup/list',
      productList: 'product/list',
    }),
  },

};
</script>
