export default (hostname) => {
  switch (hostname) {
    case 'vdl.wms.fitsoft.eu':
    case 'vdl.wms-accp.fitsoft.eu':
    case 'localhost':
      return {
        themes: {
          light: {
            primary: '#004b96',
            secondary: '#444',
            success: '#00AF70',
            toolbar: '#f5f5f5',
            accent: '#444',
            error: '#F44336',
            surface: '#ffffff',
            text: '#555555',
            background: '#f2f3f4',
            border: '#dddddd',
            default: '#e0e0e0',
          },
          dark: {
          },
        },
      };
    case 'fitsoft-slim.wms.fitsoft.eu':
    case 'fitsoft-slim.wms-accp.fitsoft.eu':
      return {
        themes: {
          light: {
            primary: '#009142',
            secondary: '#444444',
            accent: '#444444',
            error: '#e52b38',
            toolbar: '#444444',
            text: '#555555',
            background: '#f2f3f4',
            border: '#dddddd',
            default: '#e0e0e0',
          },
          dark: {
          },
        },
      };
    default:
      throw new Error('Ivalid hostname config');
  }
};
