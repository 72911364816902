import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    SectorId: null,
    ClusterId: null,

    Name: '',

    Level: null,

    Length: 0.00,
    Width: 0.00,
    PositionX: null,
    PositionY: null,
    InnerHeight: 0.00,
    InnerLength: 0.00,
    InnerWidth: 0.00,

    Type: 'Pallet',
    Packages: [],

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
