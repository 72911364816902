import OutboundOrders from '@/components/views/movement/OutboundOrders.vue';

import Index from '@/components/outbound-order/index/movement.vue';

import Form from '@/components/outbound-order/form/movement.vue';

export default [
  {
    path: '/movement/outbound-order/list',
    component: OutboundOrders,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'movement-outbound-order-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Manager'] },
        children: [
          {
            name: 'movement-outbound-order-form',
            path: '/movement/outbound-order/form/:ItemId?',
            component: Form,
            meta: { restriction: ['WMS.Manager'] },
          },
        ],
      },
    ],
  },
];
