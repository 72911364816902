<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="template"
      tile
      :loading="loading"
      height="95vh"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title>
        <span>
          {{ $t("label:docking", null, 1) }}
        </span>
        <v-chip class="ml-4">
          {{ $t(template.Status) }}
        </v-chip>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        style="height: 100%"
        class="pt-2"
      >
        <v-form
          ref="form"
          style="height: inherit"
        >
          <v-row
            style="height: inherit"
            class="ma-0"
          >
            <c-main
              :collapse="collapse"
              @collapse="(v) => (collapse = v)"
            />
            <v-col class="pr-0 d-flex flex-column">
              <h3>{{ $t("label:order_filters") }}</h3>
              <v-row class="flex-grow-0 mx-0 my-n4">
                <v-col cols="5">
                  <v-autocomplete
                    v-model="customerId"
                    :disabled="
                      !template.WarehouseId ||
                        after('Planned') ||
                        inboundOrderLoading
                    "
                    :loading="inboundOrderLoading"
                    :label="$t('label:customer', null, 1)"
                    :items="customers"
                    aria-autocomplete="off"
                    clearable
                    item-text="Name"
                    item-value="ItemId"
                    @input="() => onSearch()"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="itemId"
                    :disabled="
                      !template.WarehouseId ||
                        after('Planned') ||
                        inboundOrderLoading
                    "
                    :loading="inboundOrderLoading"
                    :label="$t('label:id', null, 1)"
                    clearable
                    @input="() => onSearch(750)"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="customerReference"
                    :disabled="
                      !template.WarehouseId ||
                        after('Planned') ||
                        inboundOrderLoading
                    "
                    :loading="inboundOrderLoading"
                    :label="$t('label:customer_reference', null, 1)"
                    clearable
                    @input="() => onSearch(750)"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="mt-0 flex-grow-0"
              >
                <div class="fadeline w-100" />
              </v-row>
              <v-row
                dense
                class="flex-grow-1"
              >
                <v-col
                  cols="9"
                  class="d-flex flex-column"
                >
                  <h4>
                    {{ $t("label:selected_order_lines") }}
                  </h4>
                  <v-card
                    flat
                    outlined
                    class="pa-2 flex-grow-1"
                  >
                    <div
                      v-if="!template.Orderlines.length"
                      class="w-100 text-center"
                    >
                      <span class="caption">
                        {{ $t("label:no_orderlines_assigned") }}
                      </span>
                    </div>
                    <v-col
                      v-else
                      xl="10"
                      md="10"
                      class="px-2 py-0"
                    >
                      <v-row
                        class="flex-nowrap align-center"
                        dense
                      >
                        <v-col class="pa-0">
                          <div class="width-15ch" />
                        </v-col>
                        <v-col
                          xl="3"
                          md="3"
                          class="px-2"
                        >
                          <h5 class="font-weight-thin">
                            {{ $t("label:customer", null, 1) }}
                          </h5>
                        </v-col>
                        <v-col
                          xl="4"
                          md="4"
                          class="px-2"
                        >
                          <h5 class="font-weight-thin">
                            {{ $t("label:product", null, 1) }}
                          </h5>
                        </v-col>
                        <v-col
                          xl="4"
                          md="4"
                          class="px-2"
                        >
                          <h5 class="font-weight-thin">
                            {{ $t("label:customer_product_name", null, 1) }}
                          </h5>
                        </v-col>
                      </v-row>
                    </v-col>
                    <c-orderline-docking
                      v-for="(Orderline, index) in template.Orderlines"
                      :key="`orderline-${index}`"
                      :order-line-index="index"
                      :value="Orderline"
                      :type="template.Type"
                      :disabled="after('Planned')"
                      :loading="inboundOrderLoading || outboundOrderLoading"
                      @open="
                        onOpenOrderline(Orderline[`${template.Type}OrderId`])
                      "
                      @delete="() => onDeleteOrderline(index)"
                    />
                  </v-card>
                </v-col>
                <v-col
                  class="d-flex flex-column"
                  cols="3"
                >
                  <h4>
                    {{ $t("label:filtered_orders") }}
                  </h4>
                  <v-card
                    flat
                    outlined
                    class="pa-2 flex-grow-1"
                  >
                    <div
                      v-if="!orderItems.length"
                      class="w-100 text-center"
                    >
                      <span class="caption">
                        {{ $t("label:no_orders_filtered") }}
                      </span>
                    </div>
                    <v-row
                      v-else
                      justify="end"
                      class="mx-0 my-n1"
                      dense
                    >
                      <v-col
                        cols="auto"
                        class="ml-7 pr-0"
                      >
                        <div class="width-10ch" />
                      </v-col>
                      <v-col class="d-flex flex-column">
                        <h5 class="font-weight-thin">
                          {{ $t("label:customer_reference", null, 1) }}
                        </h5>
                      </v-col>
                    </v-row>
                    <template v-if="template.Type === 'Inbound'">
                      <c-inbound-order-docking
                        v-for="(InboundOrder, index) in orderItems"
                        :key="`inboundorder-${index}`"
                        :value="InboundOrder"
                        :loading="inboundOrderLoading"
                        @open="onOpenOrderline(InboundOrder.ItemId)"
                        @select="onSelectOrder"
                      />
                    </template>
                    <template v-if="template.Type === 'Outbound'">
                      <c-outbound-order-docking
                        v-for="(OutboundOrder, index) in orderItems"
                        :key="`outboundorder-${index}`"
                        :value="OutboundOrder"
                        :loading="outboundOrderLoading"
                        @open="onOpenOrderline(OutboundOrder.ItemId)"
                        @select="onSelectOrder"
                      />
                    </template>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="template.Status === 'Check-in'"
          :loading="loading || docmosisLoading"
          :disabled="loading || docmosisLoading"
          @click="onPrintOrderlines"
        >
          <v-icon> mdi-printer </v-icon>
          {{ $t("btn:print") }}
        </v-btn>
        <v-btn
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="() => onSave()"
        >
          {{ $t("btn:save") }}
        </v-btn>
        <v-btn
          v-if="nextStatus.value"
          color="primary"
          :loading="loading"
          :disabled="loading || !nextStatus.valid"
          @click="onSave(nextStatus.value)"
        >
          {{ nextStatus.name }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CInboundOrderDocking from '@/components/inbound-order/docking.vue';
import COutboundOrderDocking from '@/components/outbound-order/docking.vue';
import COrderlineDocking from '@/components/orderline/docking.vue';
import CMain from '@/components/docking/main.vue';

export default {
  name: 'DockingForm',
  components: {
    CMain,
    CInboundOrderDocking,
    COutboundOrderDocking,
    COrderlineDocking,
  },

  data() {
    return {
      menu: false,
      customerId: null,
      collapse: false,
      orderItems: [],
      searchTimeout: null,
      customerReference: null,
      itemId: null,
      docmosisLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      customerItems: 'customer/items',
      loading: 'docking/loading',
      template: 'docking/template',

      warehouseLoading: 'warehouse/loading',
      warehouses: 'warehouse/items',

      inboundOrderLoading: 'inboundOrder/loading',
      outboundOrderLoading: 'outboundOrder/loading',

      WarehouseId: 'warehouse/WarehouseId',
      dockItems: 'dock/items',
    }),

    customers() {
      const customers = this.customerItems.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.CustomerId,
      );
      // eslint-disable-next-line no-nested-ternary
      customers.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
      return customers;
    },

    nextStatus() {
      let valid = this.template.Orderlines.length > 0
          && [
            this.template.WarehouseId,
            this.template.DockId,
            this.template.PlannedStart,
            this.template.PlannedEnd]
            .filter((v) => !v).length === 0;
      let value;
      let name;

      if (!this.template) {
        return { value, valid, name };
      }

      switch (this.template.Status) {
        case 'New':
          value = 'Planned';
          name = this.$t('btn:to_planned', null, 1);
          break;

        case 'Planned':
          if (this.template.Type === 'Inbound') {
            value = 'Check-in';
            name = this.$t('btn:to_check-in', null, 1);
          } else {
            value = 'Handling';
            name = this.$t('btn:to_handling', null, 1);
          }
          break;
        case 'Check-in':
        case 'Check-out':
          value = 'Done';
          name = this.$t('btn:to_done', null, 1);
          break;
        case 'Handling':
        case 'Done':
          valid = false;
          break;
        default: throw new Error('Invalid Status');
      }
      return { value, valid, name };
    },
  },

  watch: {
    template: {
      deep: true,
      handler(n, o) {
        if (n && n.WarehouseId !== o?.WarehouseId) {
          // this.resetTemplate({ ...this.template, Orderlines: [] });
          this.resetDocks();
          this.onSearch();
        }
      },
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      update: 'docking/update',
      create: 'docking/create',
      resetTemplate: 'docking/resetTemplate',
      getTemplate: 'docking/getItem',

      dockSetItems: 'dock/setItems',
      dockReturnItems: 'dock/returnItems',

      getInboundOrder: 'inboundOrder/getItem',
      inboundOrderReturnItems: 'inboundOrder/returnItems',

      getOutboundOrder: 'outboundOrder/getItem',
      outboundOrderReturnItems: 'outboundOrder/returnItems',
    }),

    async resetDocks() {
      if (this.template.WarehouseId) {
        const items = await this.dockReturnItems({ WarehouseId: this.template.WarehouseId });
        await this.dockSetItems(items);
      } else {
        await this.dockSetItems([]);
      }
    },

    after(status) {
      const weight = {
        Plannable: 0,

        Planned: 1,

        'Check-in': 2,
        Handling: 3,
        'Check-out': 4,
        Done: 5,
      };

      const heaviest = this.template.Orderlines.map(
        ({ Status }) => (weight[Status] || 0),
      ).sort()[0];

      return heaviest > weight[status];
    },

    onClose() {
      this.$router.push({ name: 'movement-docking-list' });
    },

    async initialize() {
      const { ItemId } = this.$route.params;
      let { Type } = this.$route.params;
      switch (Type) {
        case 'inbound':
          Type = 'Inbound';
          break;
        case 'outbound':
          Type = 'Outbound';
          break;
        default:
          throw new Error('Invalid Docking Type route');
      }

      if (this.$route.params.ItemId) {
        const { item } = await this.getTemplate(ItemId);
        if (item.Type !== Type) {
          throw new Error('Docking Type missmatch');
        }
        this.resetTemplate(item);
      } else {
        this.resetTemplate({
          WarehouseId: this.WarehouseId,
          Orderlines: [],
          Type,
          PlannedStart: new Date().toISOString(),
          PlannedEnd: new Date().toISOString(),
        });
      }
    },

    async onSave(Status = this.template.Status, noclose = false) {
      if ([this.$refs.form.validate(),
        ...(this.$refs.packages || []).map(({ $refs }) => $refs.form.validate()),
        ...(this.$refs.uniques || []).map(({ $refs }) => $refs.form.validate()),
      ].includes(false)) {
        this.$toasted.error('Form invalid');
        return;
      }
      if (noclose) {
        return;
      }

      if (this.$route.params.ItemId) {
        if (await this.update({ ...this.template, Status })) {
          this.onClose();
        }
      } else if (await this.create({ ...this.template, Status })) {
        this.onClose();
      }
    },

    onOpenOrderline(ItemId) {
      this.$router.push({
        name: `movement-${this.template.Type.toLowerCase()}-order-form`,
        params: { ItemId },
      });
    },

    async onPrintOrderlines() {
      await this.onSave(this.template.Status, true);
      this.docmosisLoading = true;
      const { data } = await this.axios.get(`https://${window.location.hostname}:3000/dockings/${this.template.ItemId}/docmosis`);
      this.docmosisLoading = false;
      const base64atob = atob(data[0].base64);
      const byteNumbers = new Array(base64atob.length);
      for (let i = 0; i < base64atob.length; i += 1) {
        byteNumbers[i] = base64atob.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });

      const tab = window.open('/');
      tab.URL.createObjectURL(new File([blob], data.filename));
      tab.onload = () => {
        tab.location = URL.createObjectURL(blob);
      };
    },

    onDeleteOrderline(orderlineIndex) {
      const { Orderlines } = this.template;
      Orderlines.splice(orderlineIndex, 1);
      this.resetTemplate({ ...this.template, Orderlines });
    },

    onSearch(timeout = 0) {
      if (!this.template.WarehouseId || (!this.customerId && !this.itemId)) {
        return;
      }
      const method = this[`${this.template.Type.toLowerCase()}OrderReturnItems`];
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        this.orderItems = await method({
          Status: { type: 'select', value: 'Plannable' },
          CustomerId: { type: 'select', value: this.customerId },
          WarehouseId: { type: 'select', value: this.template.WarehouseId },
          CustomerReference: { type: 'text', value: this.customerReference },
          ItemId: { type: 'select', value: this.itemId },
        });
      }, Number(timeout));
    },

    async onSelectOrder({ ItemId }) {
      const { item } = await this[`get${this.template.Type}Order`](ItemId);
      this.resetTemplate({
        ...this.template,
        Orderlines: [
          ...this.template.Orderlines,
          ...item.Orderlines
            .filter(({ DockingId }) => !DockingId)
            .map((v) => ({ ...v, [`${this.template.Type}Order`]: item })),
        ].reduce(
          (t, c) => {
            if (!t.find((v) => v.ItemId === c.ItemId)) {
              t.push(c);
            }
            return t;
          }, [],
        ),
      });
    },
  },
};
</script>
