import MovementPage from '@/layouts/Portal.vue';

import InboundOrder from './inboundOrder';
import OutboundOrder from './outboundOrder';
import Product from './product';

export default [
  {
    path: 'portal',
    component: MovementPage,
    children: [
      ...InboundOrder,
      ...OutboundOrder,
      ...Product,
    ],
  },
];
