import Carriers from '@/components/views/storage/Carriers.vue';

import Index from '@/components/carrier/index.vue';

import CarrierForm from '@/components/carrier/form.vue';

export default [
  {
    path: '/storage/carrier/list',
    component: Carriers,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'storage-carrier-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Manager'] },
        children: [
          {
            name: 'storage-carrier-form',
            path: '/storage/carrier/form/:ItemId?',
            component: CarrierForm,
            meta: { restriction: ['WMS.Manager'] },
          },
        ],
      },
    ],
  },
];
