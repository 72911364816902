export default {
  computed: {
    highComplexityModal() {
      return 'col-12 col-xl-10 col-lg-11 col-md-12 col-sm-12 pa-0';
    },
    midComplexityModal() {
      return 'col-12 col-xl-5 col-lg-7 col-md-9 col-sm-12 pa-0';
    },
    lowComplexityModal() {
      return 'col-12 col-xl-4 col-lg-5 col-md-6 col-sm-8 pa-0';
    },
    cWidthPhoto() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100%';
        case 'sm': return '50%';
        case 'md': return '33%';
        case 'lg': return '25%';
        case 'xl': return '20%';
        default:
          return '25%';
      }
    },
  },
};
