var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"value":true,"persistent":true,"content-class":_vm.highComplexityModal,"scrollable":"","no-click-animation":"","transition":"dialog-bottom-transition"}},[(_vm.template)?_c('v-card',{attrs:{"tile":"","loading":_vm.loadingTransformationOrder,"height":"95vh"}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('label:order_transformation', null, 1))+" ")]),_c('v-chip',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.template.Status))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","light":"","color":"text"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2",staticStyle:{"height":"100%"}},[_c('v-form',{ref:"form",staticStyle:{"height":"inherit"}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"height":"inherit"}},[_c('c-main',{attrs:{"collapse":_vm.collapse,"after":_vm.after},on:{"collapse":function (v) { return _vm.collapse = v; },"on-change-customer":function($event){return _vm.onChangeCustomer()}}}),_c('v-col',{staticClass:"pr-0",staticStyle:{"width":"calc(100% - 25%)"}},[_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.orderlinePanel),callback:function ($$v) {_vm.orderlinePanel=$$v},expression:"orderlinePanel"}},_vm._l((_vm.template.Orderlines),function(Orderline,orderlineIndex){return _c('c-orderline-transformation',{key:("orderline-" + orderlineIndex),ref:"transformation-orderlines",refInFor:true,attrs:{"path":[orderlineIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                  main: _vm.after('Planned'),
                  delete: _vm.after('Planned')
                }},on:{"add-result-package":function($event){return _vm.onAddResultPackage(orderlineIndex)},"remove-orderline":function($event){return _vm.onRemoveOrderline(orderlineIndex)}},scopedSlots:_vm._u([{key:"source-packages",fn:function(){return [_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.sourcePackagesPanel),callback:function ($$v) {_vm.sourcePackagesPanel=$$v},expression:"sourcePackagesPanel"}},_vm._l((_vm.template
                        .Orderlines[orderlineIndex]
                        .SourcePackages),function(Package,packageIndex){return _c('v-expansion-panel',{key:("source-orderline-" + packageIndex),staticClass:"mb-2 rounded"},[_c('v-expansion-panel-header',{staticClass:"py-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var open = ref.open;
return [_c('v-row',{attrs:{"align":"center","dense":""}},[_c('h4',{staticClass:"text-uppercase font-weight-bold",class:{ 'white--text': !open }},[_vm._v(" #"+_vm._s(packageIndex + 1)+" ")]),_c('v-spacer'),_c('v-fade-transition',[(open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-col',{staticClass:"mr-8 pb-0",attrs:{"cols":"auto"}},[_c('v-badge',{attrs:{"color":"info","content":("Σ: " + (_vm.total(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .SourcePackages[packageIndex]
                                    ))),"overlap":"","value":_vm.template.Orderlines[orderlineIndex]
                                      .SourcePackages[packageIndex].Quantity > 1,"offset-x":"45px","offset-y":"12px"}},[_c('c-number',{staticClass:"mt-3 mb-5",staticStyle:{"width":"75px"},attrs:{"value":_vm.template.Orderlines[orderlineIndex]
                                        .SourcePackages[packageIndex].Quantity,"dense":"","disabled":"","label":_vm.$t('label:quantity:package', null, 1)}})],1)],1)],1):_vm._e(),(!open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end","dense":""}},[_c('v-col',{staticClass:"mr-8 pb-0",staticStyle:{"width":"75px"},attrs:{"cols":"auto"}},[(_vm.template.Orderlines[orderlineIndex]
                                      .SourcePackages[packageIndex].Quantity)?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t('label:quantity:package', null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .SourcePackages[packageIndex].Quantity)+" ")])])],1):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('c-package-uniques',{ref:"source-uniques",refInFor:true,attrs:{"path":[orderlineIndex, 'SourcePackages', packageIndex],"reset":_vm.resetTemplate,"template":_vm.template,"open":open}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.after('Planned'),"icon":""},on:{"click":function($event){return _vm.onClonePackage(
                                      orderlineIndex, packageIndex, 'SourcePackages')}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:clone'))+" ")])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"disabled":_vm.after('Planned'),"icon":""},on:{"click":function($event){return _vm.onRemovePackage(
                                      orderlineIndex, packageIndex, 'SourcePackages')}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:delete', null, 1))+" ")])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"content-no-padding",attrs:{"eager":""}},[_c('c-package-main-source',{ref:"packages",refInFor:true,attrs:{"template":_vm.template,"path":[orderlineIndex, 'SourcePackages', packageIndex]},scopedSlots:_vm._u([{key:"product-bundle",fn:function(){return [_c('c-product-bundle-main',{attrs:{"disable":{main: true},"template":_vm.template,"reset":_vm.resetTemplate,"path":[orderlineIndex, 'SourcePackages', packageIndex]}})]},proxy:true},{key:"transaction-inbound-handling",fn:function(){return [_c('c-transaction-inbound-handling',{attrs:{"path":[orderlineIndex,'SourcePackages', packageIndex],"template":_vm.template,"reset":function () {},"disable":{
                                sector: true,
                                main: true
                              }}})]},proxy:true},{key:"transaction-storage-handling",fn:function(){return [_c('c-transaction-storage-handling',{attrs:{"path":[orderlineIndex,'SourcePackages', packageIndex],"template":_vm.template,"reset":function () {},"disable":{
                                sector: true,
                                main: true
                              }}})]},proxy:true},{key:"transaction-transformation-handling",fn:function(){return [_c('c-transaction-transformation-handling',{attrs:{"path":[orderlineIndex, 'SourcePackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate}})]},proxy:true}],null,true)})],1)],1)}),1)]},proxy:true},{key:"result-packages",fn:function(){return [_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.resultPackagesPanel),callback:function ($$v) {_vm.resultPackagesPanel=$$v},expression:"resultPackagesPanel"}},_vm._l((_vm.template
                        .Orderlines[orderlineIndex]
                        .ResultPackages),function(Package,packageIndex){return _c('v-expansion-panel',{key:("orderline-" + packageIndex),staticClass:"mb-2 rounded"},[_c('v-expansion-panel-header',{staticClass:"py-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var open = ref.open;
return [_c('v-row',{attrs:{"align":"center","dense":""}},[_c('h4',{staticClass:"text-uppercase font-weight-bold",class:{ 'white--text': !open }},[_vm._v(" #"+_vm._s(packageIndex + 1)+" ")]),_c('v-spacer'),_c('v-fade-transition',[(open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"width":"75px"},attrs:{"label":_vm.$t('label:batch', null, 1),"disabled":_vm.after('Planned'),"dense":""},model:{value:(_vm.template.Orderlines[orderlineIndex]
                                      .ResultPackages[packageIndex].TransformationBatch),callback:function ($$v) {_vm.$set(_vm.template.Orderlines[orderlineIndex]
                                      .ResultPackages[packageIndex], "TransformationBatch", $$v)},expression:"template.Orderlines[orderlineIndex]\n                                      .ResultPackages[packageIndex].TransformationBatch"}})],1),_c('v-col',{staticClass:"mr-8 pb-0",attrs:{"cols":"auto"}},[_c('v-badge',{attrs:{"color":"info","content":("Σ: " + (_vm.total(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .ResultPackages[packageIndex]
                                    ))),"overlap":"","value":_vm.template.Orderlines[orderlineIndex]
                                      .ResultPackages[packageIndex].Quantity > 1,"offset-x":"45px","offset-y":"12px"}},[_c('c-number',{staticClass:"mt-3",staticStyle:{"width":"75px"},attrs:{"value":_vm.template.Orderlines[orderlineIndex]
                                        .ResultPackages[packageIndex].Quantity,"dense":"","disabled":_vm.after('Planned', Orderline),"label":_vm.$t('label:quantity:package', null, 1)},on:{"input":function (v) { return _vm.onPackageQuantityUpdate(
                                        v,
                                        orderlineIndex,
                                        packageIndex); }}})],1)],1)],1):_vm._e(),(!open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0",staticStyle:{"width":"75px"},attrs:{"cols":"auto"}},[(_vm.template.Orderlines[orderlineIndex]
                                      .ResultPackages[packageIndex].TransformationBatch)?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t('label:transformation_batch', null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .ResultPackages[packageIndex].TransformationBatch)+" ")])]),_c('v-col',{staticClass:"mr-8 pb-0",staticStyle:{"width":"75px"},attrs:{"cols":"auto"}},[(_vm.template.Orderlines[orderlineIndex]
                                      .ResultPackages[packageIndex].Quantity)?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t('label:quantity:package', null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .ResultPackages[packageIndex].Quantity)+" ")])])],1):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('c-package-uniques',{ref:"result-uniques",refInFor:true,attrs:{"path":[orderlineIndex, 'ResultPackages', packageIndex],"reset":_vm.resetTemplate,"template":_vm.template,"open":open}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.after('Planned'),"icon":""},on:{"click":function($event){return _vm.onClonePackage(
                                      orderlineIndex, packageIndex, 'ResultPackages')}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:clone'))+" ")])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"disabled":_vm.after('Planned'),"icon":""},on:{"click":function($event){return _vm.onRemovePackage(
                                      orderlineIndex, packageIndex, 'ResultPackages')}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:delete', null, 1))+" ")])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"content-no-padding",attrs:{"eager":""}},[_c('c-package-main-result',{ref:"packages",refInFor:true,attrs:{"transformation-type":"Result","path":[orderlineIndex, 'ResultPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                            sector: _vm.after('Planned'),
                            main: _vm.after('Planned')
                          }},scopedSlots:_vm._u([{key:"product-bundle",fn:function(){return [_c('c-product-bundle-main',{attrs:{"transformation-type":"Result","disable":{
                                main: _vm.after('Planned')
                              },"path":[orderlineIndex, 'ResultPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate}})]},proxy:true},{key:"transaction-transformation-handling",fn:function(){return [_c('c-transaction-transformation-handling',{attrs:{"disabled":_vm.after('Planned'),"path":[orderlineIndex, 'ResultPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                sector: _vm.after('Planned'),
                                main: _vm.after('Planned')
                              }}})]},proxy:true},{key:"transaction-storage-handling",fn:function(){return [_c('c-transaction-storage-handling',{attrs:{"path":[orderlineIndex, 'ResultPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate}})]},proxy:true},{key:"transaction-outbound-handling",fn:function(){return [_c('c-transaction-outbound-handling',{attrs:{"path":[orderlineIndex, 'ResultPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate}})]},proxy:true}],null,true)})],1)],1)}),1)]},proxy:true}],null,true)})}),1),(_vm.showPackages)?_c('c-package-index',{attrs:{"loading":_vm.loadingTransformationOrder || _vm.loadingPackage,"static-filters":_vm.staticFilters,"disable":{create: true}},scopedSlots:_vm._u([{key:"controls",fn:function(ref){
                              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.creatable(item.ItemId),"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAddSourcePackage(item.ItemId, true)}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" mdi-tag-text-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label:package:transformation:create')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.addable(item.ItemId),"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAddSourcePackage(item.ItemId)}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" mdi-tag-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label:package:transformation:add')))])])],1)]}}],null,false,718353518)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"border darken-1"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("btn:close"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingTransformationOrder,"disabled":_vm.loadingTransformationOrder},on:{"click":function () { return _vm.onSave(); }}},[_vm._v(" "+_vm._s(_vm.$t("btn:save"))+" ")]),(_vm.nextStatus.value)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingTransformationOrder,"disabled":_vm.loadingTransformationOrder || !_vm.nextStatus.valid || _vm.after('Planned')},on:{"click":function($event){return _vm.onSave(_vm.nextStatus.value)}}},[_vm._v(" "+_vm._s(_vm.nextStatus.name)+" ")]):_vm._e()],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }