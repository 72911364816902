import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {

    ProductId: null,

    Length: 0.00,
    Width: 0.00,
    Height: 0.00,
    WeightNetto: 0.00,
    WeightBrutto: 0.00,

    Stackable: false,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
