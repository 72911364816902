<template>
  <v-list
    dense
    class="main-navigation py-0"
  >
    <template v-for="(group, gIndex) in groups">
      <component
        :is="mGroupComponent(group)"
        :key="`${gIndex}-group`"
        :prepend-icon="group.icon || ''"
        class="py-0 icon-margin"
      >
        <template
          v-if="group.label"
          #activator
        >
          <v-list-item-title
            v-if="group.label"
            class="text-uppercase"
          >
            {{ $t(group.label) }}
          </v-list-item-title>
        </template>
        <template v-for="(item, iIndex) in group.items">
          <v-list-item
            v-if="!isSubGroup(item)"
            :key="`${iIndex}-item`"
            :to="item.to || null"
            :href="item.href"
            link
            color="primary"
          >
            <v-list-item-icon
              v-if="item.icon"
              class="mr-4"
            >
              <v-icon
                :small="Boolean(group.label)"
                style="width: 24px"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-uppercase">
              {{ $t(item.label) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :key="`${iIndex}-item`"
            sub-group
            prepend-icon=""
          >
            <template #activator>
              <v-list-item-icon
                v-if="item.icon"
                class="mr-4"
              >
                <v-icon
                  :small="Boolean(group.label)"
                  style="width: 24px"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">
                  {{ $t(item.label) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template #appendIcon>
              <v-icon>
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list-item
              v-for="(subItem, siIndex) in item.items"
              :key="`${siIndex}-s-item`"
              link
              :to="subItem.to || null"
              :href="subItem.href"
            >
              <v-list-item-icon
                v-if="subItem.icon"
                class="mr-4"
              >
                <v-icon
                  :small="Boolean(subItem.label)"
                  style="width: 24px"
                >
                  {{ subItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-uppercase">
                {{ $t(subItem.label) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </component>
      <v-divider :key="`${gIndex}-divider`" />
    </template>
  </v-list>
</template>

<script>

import { VListGroup, VListItem } from 'vuetify/lib';

export default {
  name: 'AppNavigationItems',

  components: { VListGroup, VListItem },

  computed: {
    groups() {
      let groups = [];
      if (this.$tenant
      && this.$tenant.Config
      && this.$tenant.Config.routes) {
        const routesConfig = this.$tenant.Config.routes.find(
          (r) => r.hosts.includes(window.location.hostname),
        );

        if (routesConfig) {
          groups = groups.concat(routesConfig.groups);
        }
      }

      const filteredGroups = groups.filter(this.allow);

      const filteredGroupsWithFilteredSubgroups = filteredGroups.map(
        (group) => ({
          ...group,
          items: group.items
            .filter(this.allow)
            .map((item) => {
              if (!item.items) {
                return item;
              }
              return {
                ...item,
                items: item.items
                  .filter(this.allow),
              };
            }),
        }),
      );
      return filteredGroupsWithFilteredSubgroups;
    },
  },
  methods: {
    mGroupComponent: (group) => (group.label ? 'v-list-group' : 'v-list'),
    isSubGroup: (item) => item.items,
    allow(v) {
      if (!v.restriction || !v.restriction.length) {
        return true;
      }

      const own = (({ items, to }) => {
        if (items) {
          return Boolean(items.find((item) => item.to));
        }
        return Boolean(to);
      })(v);

      let allow = (own && this.$auth.roles.includes('WMS.Admin'));
      this.$auth.roles.forEach((role) => {
        if (v.restriction.includes(role)) {
          allow = true;
        }
      });
      return allow;
    },
  },
};
</script>

<style lang="scss">
.main-navigation {
  .v-list-item:after {
    content: none;
  }
}

.icon-margin {
  .v-list-item__icon {
    margin-right: 16px !important;
  }
  .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 20px !important;
  }
}

</style>
