import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  items: [],
  defaultTemplate: {
    SpecialtyId: null,
    LanguageId: null,

    Name: '',
    Phone: '',
    Email: '',
    Web: '',
    Note: '',
    DebtorNumber: '',
    CreditorNumber: '',
    VATNR: '',

    IsPrivate: true,
    IsCustomer: true,
    IsSupplier: false,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
};
