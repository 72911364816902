import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    CustomerId: null,
    ContactId: null,

    Description: '',

    Status: 'New',

    SentDate: null,

    Sync: null,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
