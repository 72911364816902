<template>
  <v-row class="ma-0">
    <app-navigation v-model="menuWide" />
    <v-slide-x-transition>
      <v-main
        style="padding-top: 60px;"
        class="management"
        :class="menuWide ? 'menuWide' : ''"
      >
        <v-container fluid>
          <v-row no-gutters>
            <slot />
          </v-row>
        </v-container>
      </v-main>
    </v-slide-x-transition>
  </v-row>
</template>

<script>

import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'FinancePage',
  components: {
    AppNavigation,
  },
  data: () => ({
    menuWide: false,
  }),
};
</script>
<style lang="scss">
.menuWide {
  .v-main__wrap {
    max-width: calc(100vw - 270px);
    margin-left: auto;
  }
}
</style>
