import Dockings from '@/components/views/movement/Dockings.vue';

import Index from '@/components/docking/index.vue';

import Form from '@/components/docking/form.vue';

export default [
  {
    path: '/movement/docking/list',
    component: Dockings,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'movement-docking-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Manager'] },
        children: [
          {
            name: 'movement-docking-form',
            path: '/movement/docking/form/:Type/:ItemId?',
            component: Form,
            meta: { restriction: ['WMS.Manager'] },
          },
        ],
      },
    ],
  },
];
