<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-card
      v-if="template"
      tile
      :loading="loading"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title class="card-title">
        <v-row align="center">
          <v-col
            cols="auto"
            class="mr-8"
          >
            <span
              class="card-title-caption text-truncate"
            >
              {{ $t('label:package', null, 1) }}
            </span>
          </v-col>
          <v-spacer />
          <v-btn
            icon
            light
            color="text"
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-col class="px-0 pb-0">
          <c-orderline-package-parenting v-if="template.InboundOrderline" />
          <v-row
            no-gutters
            class="mt-3"
          >
            <div class="fadeline w-100" />
          </v-row>
          <v-row
            class="mt-0"
          >
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="template.InboundBatch"
                :label="$t('label:batch', null, 1)"
              />
            </v-col>
            <v-col
              v-if="Boolean(template.ProductBundle)"
              cols="auto"
            >
              <v-badge
                color="info"
                :content="`Σ: ${total}`"
                class="w-100"
                overlap
                :value="template.Quantity > 1"
                offset-x="25px"
                offset-y="15px"
              >
                <c-number
                  v-model="template.Quantity"
                  :label="$t('label:quantity', null, 1)"
                  class="width-15ch"
                  :min="0"
                  :fixed="0"
                  :disabled="Boolean(template.ItemId)"
                />
              </v-badge>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :label="$t('label:status', null, 1)"
                hide-details="auto"
                aria-autocomplete="off"
                disabled
                :items="cStatuses"
                :value="template.Status"
              />
            </v-col>
          </v-row>
          <v-expansion-panels
            class="panels-styled"
            focusable
          >
            <c-package-main-default
              :template="template"
              :reset="resetTemplate"
              @move="onMove"
            >
              <template #product-bundle>
                <c-product-bundle-main
                  :reset="resetTemplate"
                  :template="template"
                  :path="[]"
                  :disable="{
                    product: true,
                    quantity: template.InboundOrderline && Boolean(template.InboundOrderline.ItemId)
                  }"
                />
              </template>
              <template #transaction-movement-handling>
                <c-transaction-movement-handling
                  :reset="resetTemplate"
                  :disable="{main: !isNewSector}"
                  :template="template"
                  :path="[]"
                />
              </template>
              <template #transaction-transformation-handling>
                <c-transaction-transformation-handling
                  :template="template"
                  :path="[]"
                  :reset="() =>{}"
                  :disable="{ main: true}"
                />
              </template>
              <template #transaction-storage-handling>
                <c-transaction-storage-handling
                  :template="template"
                  :path="[]"
                  :reset="() =>{}"
                  :disable="{ main: true}"
                />
              </template>
              <template #transaction-inbound-handling>
                <c-transaction-inbound-handling
                  :template="template"
                  :path="[]"
                  :reset="resetTemplate"
                  :disable="{ main: Boolean(template.ItemId)}"
                />
              </template>
              <template #transaction-outbound-handling>
                <c-transaction-outbound-handling
                  :template="template"
                  :path="[]"
                  :reset="() =>{}"
                  :disable="{ main: true}"
                />
              </template>
            </c-package-main-default>
          </v-expansion-panels>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onClose">
          {{ $t('btn:close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          @click="onSave"
        >
          {{ $t('btn:save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CPackageMainDefault from '@/components/package/main/default.vue';
import CProductBundleMain from '@/components/product-bundle/main.vue';

import COrderlinePackageParenting from '@/components/orderline/package-parenting.vue';

import CTransactionMovementHandling from '@/components/transaction/movement-handling.vue';
import CTransactionInboundHandling from '@/components/transaction/inbound-handling.vue';
import CTransactionOutboundHandling from '@/components/transaction/outbound-handling.vue';
import CTransactionStorageHandling from '@/components/transaction/storage-handling.vue';
import CTransactionTransformationHandling from '@/components/transaction/transformation-handling.vue';

export default {
  name: 'PackageForm',

  components: {
    CPackageMainDefault,
    CProductBundleMain,

    COrderlinePackageParenting,

    CTransactionMovementHandling,
    CTransactionInboundHandling,
    CTransactionOutboundHandling,
    CTransactionStorageHandling,
    CTransactionTransformationHandling,
  },

  data: () => ({
    sectors: [],
    oldSector: null,
    isNewSector: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'package/loadingList',
      template: 'package/template',

      WarehouseId: 'warehouse/WarehouseId',
    }),

    total() {
      return this.template.ProductBundle.Bundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), this.template.ProductBundle.Quantity,
      ) * this.template.Quantity;
    },

    cStatuses() {
      return [
        {
          value: 'Planned',
          text: this.$t('label:status:planned', null, 1),
        },
        {
          value: 'Stored',
          text: this.$t('label:status:stored', null, 1),
        },
        {
          value: 'Dissolved',
          text: this.$t('label:status:dissolved', null, 1),
        },
        {
          value: 'Departed',
          text: this.$t('label:status:departed', null, 1),
        },
      ];
    },
  },

  async beforeMount() {
    await this.resetTemplate({
      WarehouseId: this.WarehouseId,
      InboundOrderline: null,
      ProductBundle: { Bundles: [] },
    });
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    ...mapActions({
      resetTemplate: 'package/resetTemplate',

      getPackage: 'package/getItem',
      packageUpdate: 'package/update',
      packageCreate: 'package/create',
    }),

    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getPackage(this.$route.params.ItemId);
        this.resetTemplate(item);
        this.oldSector = item.SectorId;
      } else {
        this.resetTemplate({
          WarehouseId: this.WarehouseId,
          InboundOrderline: { Quantity: 1 },
        });
      }
    },

    onMove(SectorId) {
      if (!this.oldSector) {
        const { StorageHandling } = this.template;
        if (StorageHandling?.SectorUsages && StorageHandling?.SectorUsages[0]) {
          StorageHandling.SectorUsages[0].SectorId = SectorId;
        }
        return this.resetTemplate({
          ...this.template,
          SectorId,
          StorageHandling,
        });
      }
      this.isNewSector = this.oldSector !== SectorId;
      const MovementHandling = this.template.MovementHandling || {
        Type: 'MovementHandling',
        ConsumableUsages: [],
        ResourceUsages: [],
        ExecutorUsages: [],
      };
      if (!this.isNewSector) {
        ['ConsumableUsages', 'ResourceUsages', 'ExecutorUsages'].forEach((type) => {
          MovementHandling[type] = MovementHandling[type].filter(({ ItemId }) => ItemId);
        });
      }
      return this.resetTemplate({ ...this.template, SectorId, MovementHandling });
    },

    baseTotal(BasePackage) {
      if (!BasePackage.BaseProductBundle?.BaseBundles) {
        return 1;
      }
      return BasePackage.BaseProductBundle.BaseBundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0),
        BasePackage.BaseProductBundle.Quantity,
      );
    },

    async onSave() {
      if (this.$route.params.ItemId) {
        if (await this.packageUpdate(this.template)) {
          this.onClose();
        }
      } else if (await this.packageCreate(this.template)) {
        this.onClose();
      }
    },

    onClose() {
      this.$router.push({ name: 'storage-package-list' });
    },
  },
};
</script>
