<template>
  <v-autocomplete
    :key="`select-autocomplete-key-${key}`"
    :value="cValue"
    :items="cSectors"
    :loading="sectorLoading"

    :label="$t('label:sector', null, 1)"
    :placeholder="$t('label:sector')"

    :item-text="(s) => `${s.Type}: ${s.Name}`"
    item-value="ItemId"

    v-bind="{ ...$attrs, ...$props }"
    :hide-details="$attrs.disabled"
    aria-autocomplete="off"
    :menu-props="{ bottom: true, offsetY: true }"
    :tabindex="1"
    class="sector-select sector-disabled mt-2"
    :rules="$attrs.disabled ? undefined : mnValidation.required"
    @input="(v) => $attrs.disabled ? null : onInput(v)"
  >
    <template #selection="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-sheet
            v-if="sector"
            v-bind="item.attrs"
            :input-value="item.selected"
            color="transparent"
            class="pt-0 pb-1 d-flex align-center w-100"
            v-on="on"
          >
            <v-badge
              :content="`${cTotal.packageLinked ? '' : '+'} ${cTotal.packageShare >= 1
                ? cTotal.packageShare : '<1' }%`"
              offset-x="18px"
              offset-y="15px"
              :color="cTotal.packageLinked ? 'grey' : 'blue'"
            >
              <div
                class="circle"
              >
                <h6
                  class="font-weight-regular d-flex align-center justify-center"
                  style="height: 100%;"
                >
                  {{ cTotal.occupancy >= 1 ? cTotal.occupancy
                    : cTotal.occupancy > 100 ? '>100' : '⋖1' }}%
                </h6>
                <div
                  class="fill"
                  :style="`height: ${cTotal.occupancy >= 100 ? '100' : cTotal.occupancy}%;
            background-color: ${cTotal.bgColor}`"
                />
              </div>
            </v-badge>
            <v-list-item-content class="pa-0 truncated align-self-end mb-1">
              <v-list-item-title class="mb-0">
                {{ item.Name }}
              </v-list-item-title>
              <small
                v-if="item.deletedAt"
                class="red--text text-lowercase caption"
              >
                {{ $t('label:deleted', null, 1) }}
              </small>
              <v-list-item-subtitle v-if="cTotal.occupancy > 100">
                <h5
                  class="font-weight-regular truncated red--text"
                  :style="{opacity: $attrs.disabled ? '0.7' : ''}"
                >
                  Sector capacity exceeded
                </h5>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-sheet>
        </template>
        <div
          v-if="sector"
          class="d-flex flex-column"
        >
          <span>{{ cTotal.occupancy > 100 ? 'The sector is over occupied'
            : `Sector occupied on ${ cTotal.occupancy }%` }}
          </span>
          <span>Current package occupy {{ cTotal.packageShare }}% of sector volume</span>
        </div>
      </v-tooltip>
    </template>
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.ItemId }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.Name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SectorSelect',

  props: {
    pPackage: {
      type: Object,
      required: true,
    },
    pPackages: {
      type: Array,
      required: true,
    },
    sPackages: {
      type: Array,
      required: true,
      description: 'source packages of the same sector',
    },
    rPackages: {
      type: Array,
      required: true,
      description: 'result packages of the same sector',
    },
  },

  data: () => ({
    key: 0,
    sector: null,
    sectors: [],
  }),

  computed: {
    ...mapGetters({
      sectorLoading: 'sector/loading',
    }),

    cFilter() {
      return { WarehouseId: this.pPackage.WarehouseId };
    },

    cValue() {
      return this.pPackage.SectorId;
    },

    cTotal() {
      if (!this.sector) {
        throw new Error('Sector not (yet) set');
      }

      let packagesVolume;
      // uses in transformation order only
      const sPackagesVolume = (this.sPackages || [])
        .reduce((t, i) => this.mPackageVolume(i) + t, 0);
      const rPackagesVolume = (this.rPackages || [])
        .reduce((t, i) => this.mPackageVolume(i) + t, 0);

      // calculating volume of already existing packages
      packagesVolume = ((this.sector?.Packages || [])
        .reduce((t, i) => this.mPackageVolume(i) + t, 0) - sPackagesVolume + rPackagesVolume);

      // check if current package exists in DB
      const exists = (this.sector?.Packages || [])
        .find(({ ItemId }) => ItemId === this.pPackage.ItemId);

      const currentpackageVolume = this.mPackageVolume(this.pPackage);

      const newPackagesVolume = (this.pPackages || [])
        .reduce((t, i) => this.mPackageVolume(i) + t, 0);

      packagesVolume += newPackagesVolume || 0;

      const currentPackageShare = (currentpackageVolume
      / (this.sector?.InnerLength * this.sector?.InnerWidth * this.sector?.InnerHeight)) * 100;

      if (!exists) {
        // do nothing
      } else if ((
        exists.Length !== this.pPackage.Length
      || exists.Width !== this.pPackage.Width
      || exists.Height !== this.pPackage.Height
      || exists.Quantity !== this.pPackage.Quantity
      )) {
        packagesVolume += (currentpackageVolume - this.mPackageVolume(exists));
      }

      const sectorOccupancy = (packagesVolume
      / (this.sector?.InnerLength * this.sector?.InnerWidth * this.sector?.InnerHeight)) * 100;
      let color = 'green';
      if (sectorOccupancy > 50 && sectorOccupancy < 100) {
        color = 'darkgreen';
      }
      if (sectorOccupancy > 100) {
        color = 'red';
      }

      return {
        packageLinked: !!exists,
        occupancy: sectorOccupancy.toFixed(0),
        bgColor: color,
        packageShare: currentPackageShare.toFixed(0),
      };
    },
    cSectors() {
      return this.sectors.filter((v) => !v.deletedAt || v.ItemId === this.pPackage.SectorId);
    },
  },

  watch: {
    pPackage: {
      immediate: true,
      deep: true,
      handler(n, o) {
        if (o && n?.WarehouseId && n.WarehouseId !== o?.WarehouseId) {
          this.onInput(null);
          this.getSectors();
        }
        if (n.SectorId !== o?.SectorId) {
          this.getSector();
        }
      },
    },
  },

  mounted() {
    this.getSectors();
    this.getSector();
  },

  methods: {
    ...mapActions({
      sectorReturnItems: 'sector/returnItems',
      sectorGetItem: 'sector/getItem',
    }),
    async getSectors() {
      this.sectors = (await this.sectorReturnItems(this.cFilter));
    },
    async getSector() {
      if (!this.cValue) {
        return;
      }
      const { item } = await this.sectorGetItem(this.cValue);
      this.sector = item;
      this.$set(this, 'key', this.key + 1);
    },
    async onInput(v) {
      await this.$emit('input', v);
    },
    mPackageVolume(Package) {
      return Number(Package.Length * Package.Width * Package.Height * Package.Quantity);
    },
  },
};
</script>

<style lang="scss">
.circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid white;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  padding: 3px;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px gray;
  margin: 4px;
  margin-right: 10px;
  margin-bottom: 2px;
}
.fill {
  // background-color: teal;
  opacity: 0.5;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.sector-select {
  .v-select__selections {
    flex-wrap: nowrap;
    input {
      min-width: 0px !important;
    }
  }
  .v-label--active {
    transform: translateY(-25px) scale(0.75);
  }
}
.sector-disabled.v-input--is-disabled {
  .v-input__control {
    opacity: 0.75;
  }
}
</style>
