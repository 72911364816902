import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    ProductId: null,
    BasePackageId: null,
    BaseBundleId: null,

    Quantity: null,

    BaseBundles: [],

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
