<template>
  <v-form
    ref="form"
    class="px-0 pb-2 pt-5 w-100"
    rounded="lg"
    color="#dddddd"
  >
    <v-row
      class="ma-0"
      dense
    >
      <v-col
        md="3"
        xl="3"
        class="pb-0"
      >
        <v-autocomplete
          :label="$t('label:warehouse', null, 1)"
          item-text="Name"
          item-value="ItemId"
          :items="warehouseItems"
          :value="value.WarehouseId"
          aria-autocomplete="off"
          hide-details
          disabled
          @input="v => onInput('WarehouseId', v)"
        />
        <c-sector-select
          v-if="value.WarehouseId"
          :disabled="value.Status !== 'Stored'"
          :p-package="value"
          :p-packages="[pPackages]"
          :s-packages="[]"
          :r-packages="[]"
          @input="v => $emit('move', v)"
        />
        <v-checkbox
          :input-value="value.Stackable"
          :label="$t('label:stackable')"
          hide-details
        />
      </v-col>
      <v-col class="pb-0">
        <slot name="product-bundle" />
      </v-col>
      <v-col
        cols="auto"
        class="pb-0"
      >
        <v-row class="d-xl-flex flex-xl-column mr-0">
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pb-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_netto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightNetto"
              :disabled="Boolean(value.ItemId)"
              @input="v => onInput('WeightNetto', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_brutto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightBrutto"
              :disabled="Boolean(value.ItemId)"
              @input="v => onInput('WeightBrutto', v)"
            />
          </v-col>
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pt-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:length', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Length"
              :disabled="Boolean(value.ItemId)"
              @input="v => onInput('Length', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:width', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Width"
              :disabled="Boolean(value.ItemId)"
              @input="v => onInput('Width', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:height', null, 1)"
              class="width-10ch"
              :value="value.Height"
              :disabled="Boolean(value.ItemId)"
              @input="v => onInput('Height', v)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="cSlots.length"
      no-gutters
      class="mt-1"
    >
      <div class="fadeline w-100" />
    </v-row>
    <v-row
      v-if="cSlots.length"
      class="ma-0"
    >
      <v-col class="text-center">
        <v-btn-toggle
          v-model="activeSlot"
          mandatory
          class="mx-auto"
          style="z-index: 10"
        >
          <v-btn
            v-for="(v, i) in cSlots"
            :key="`slot-${i}`"
            width="auto"
            small
            :value="v.value"
          >
            {{ v.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row
      v-if="cSlots.length"
      class="ma-0 mb-n3"
      justify="center"
      style="transform: translateY(-12px)"
    >
      <v-tabs-items
        v-model="activeSlot"
        class="w-100"
      >
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-inbound-handling"
        >
          <slot name="transaction-inbound-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-transformation-handling"
        >
          <slot name="transaction-transformation-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-storage-handling"
        >
          <slot name="transaction-storage-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-movement-handling"
        >
          <slot name="transaction-movement-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-outbound-handling"
        >
          <slot name="transaction-outbound-handling" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

import CSectorSelect from '@/components/sector/select.vue';

export default {
  name: 'PackageMainInbound',

  components: {
    CSectorSelect,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },

    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of package',
    },
  },

  data: () => ({
    sectors: [],
    activeSlot: null,
  }),

  computed: {
    ...mapGetters({
      warehouseItems: 'warehouse/items',
    }),
    pPackages() {
      return this.template.ItemId ? [] : this.template;
    },

    cSlots() {
      return [
        {
          text: this.$t('label:inbound_handling'),
          value: 'transaction-inbound-handling',
          type: 'InboundHandling',
        },
        {
          text: this.$t('label:transformation_handling'),
          value: 'transaction-transformation-handling',
          type: 'TransformationHandling',
        },
        {
          text: this.$t('label:storage_handling'),
          value: 'transaction-storage-handling',
          type: 'StorageHandling',
        },
        {
          text: this.$t('label:movement_handling'),
          value: 'transaction-movement-handling',
          type: 'MovementHandling',
        },
        {
          text: this.$t('label:outbound_handling'),
          value: 'transaction-outbound-handling',
          type: 'OutboundHandling',
        },
      ].filter(
        ({ type }) => this.value[type]?.length || Boolean(this.value[type]),
      );
    },

    value() {
      return this.template;
    },
  },

  methods: {
    onInput(p, v) {
      this.reset({
        ...this.template,
        [p]: v,
      });
    },

  },
};
</script>
