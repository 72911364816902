import Vue from 'vue';
import { AuthReady } from '@/auth';

let instance;

const assetTenant = (data) => {
  document.title = data.Name;

  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `/favicons/${data.Config.tenant}.png`;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const defineTenant = (axios) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        Config: null,
        Name: null,
      };
    },
    async created() {
      await AuthReady();
      const { data } = await axios.get(`https://${window.location.hostname}:3000/tenants/tenant`);
      assetTenant(data);
      this.$set(this, 'Config', data.Config);
      this.$set(this, 'Name', data.Name);
    },
  });

  return instance;
};

export default {
  async install(vue, axios) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$tenant = defineTenant(axios);
  },
};
