import OutboundOrders from '@/components/views/portal/OutboundOrders.vue';

import Index from '@/components/outbound-order/index/portal.vue';

// import Form from '@/components/outbound-order/form/portal.vue';

export default [
  {
    path: '/portal/outbound-order/list',
    component: OutboundOrders,
    meta: {
      restriction: ['WMS.Portaluser'],
    },

    children: [
      {
        name: 'portal-outbound-order-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Portaluser'] },
        // children: [
        //   {
        //     name: 'portal-outbound-order-form',
        //     path: '/portal/outbound-order/form/:ItemId?',
        //     component: Form,
        //     meta: { restriction: ['WMS.Portaluser'] },
        //   },
        // ],
      },
    ],
  },
];
