<template>
  <v-text-field
    ref="textField"
    v-currency="cOptions"
    :label="label"
    :value="formattedValue"
    :readonly="readonly"
    :disabled="disabled"
    :auto-decimal-mode="autoDecimalMode"
    :allow-negative="allowNegative"
    :rules="allowZero ? [] : currencyRules"
    @focus="onFocus"
    @input="handleInput"
  >
    <template
      v-if="append"
      #append
    >
      <span
        class="pa-1"
        style="font-size: 14px"
      >
        {{ append }}
      </span>
    </template>
  </v-text-field>
</template>

<script>

export default {
  name: 'CCurrencyField',
  props: {
    value: { type: [Number, String], default: 0 },
    label: { type: String, default: null },
    append: { type: String, default: null },
    required: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },

    autoDecimalMode: { type: Boolean, default: false },
    allowNegative: { type: Boolean, default: false },
    valueRange: { type: Object, default: null },
    currency: { type: String, default: 'EUR' },

    allowZero: { type: Boolean, default: true },
  },
  data() {
    return {
      formattedValue: '',
    };
  },
  computed: {
    cOptions() {
      const options = {
        autoDecimalMode: this.autoDecimalMode,
        allowNegative: this.allowNegative,
        valueRange: this.valueRange,
        currency: this.currency,
      };
      return options;
    },
  },
  watch: {
    // WATCHER USED INSTEAD MOUNTED HOOK (should be triggered once, if value is not yet set)
    value: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          if (!this.$refs.textField) {
            return;
          }
          this.$ci.setValue(this.$refs.textField, value);
        });
      },
    },
  },
  methods: {
    onFocus({ target }) {
      target.select();
    },
    handleInput(value) {
      if (value === '') {
        // eslint-disable-next-line no-param-reassign
        value = 0;
      }

      if (!this.$refs.textField) {
        // valid case for OrderLines component, when related DOM controls being destroyed by
        // setItems([...i, k]) actions
        return;
      }
      // updates formatted output
      this.$ci.setValue(this.$refs.textField, this.$ci.parse(value));
      this.formattedValue = value;

      if (this.$ci.parse(value) !== parseFloat(this.value)) {
        this.$emit('input', this.$ci.parse(value));
      }
    },
  },
};
</script>
