import Vue from 'vue';

import defaultMutations from '@/store/defaultMutations';

export default {
  ...defaultMutations,

  SET_PER_PAGE(state, v) {
    Vue.set(state, 'per_page', v);
  },

  SET_PAGE(state, v) {
    Vue.set(state, 'page', v);
  },
};
