<template>
  <v-autocomplete
    :value="overseer || value"
    :items="pItems ? pItems : users"
    :label="$t(label)"
    :placeholder="$t(placeholder)"
    :loading="vuexLoading || authLoading"
    :readonly="disabled"
    :disabled="disabled"
    :hide-details="disabled"
    aria-autocomplete="off"
    item-text="name"
    item-value="user_id"
    class="auth-select"
    :menu-props="{ bottom: true, offsetY: true }"
    required
    :tabindex="1"
    :rules="disabled ? undefined : mnValidation.required"
    @input="(v) => (disabled ? null : $emit('input', v))"
    @change="disabled ? null : onChange"
  >
    <template #selection="{ item }">
      <v-sheet
        v-bind="item.attrs"
        :input-value="item.selected"
        color="transparent"
        class="pt-0 pb-1 d-flex align-center w-100"
      >
        <v-list-item-avatar
          size="28"
          class="my-0 d-flex align-self-end"
          :style="{opacity: disabled ? '0.7' : ''}"
        >
          <img :src="item.picture">
        </v-list-item-avatar>
        <v-list-item-content class="pa-0 truncated">
          <v-list-item-title>
            <h5
              class="font-weight-regular truncated"
              :style="{opacity: disabled ? '0.7' : ''}"
            >
              {{ item.name }}
            </h5>
          </v-list-item-title>
          <v-list-item-subtitle>
            <h5
              class="font-weight-regular truncated"
              :style="{opacity: disabled ? '0.7' : ''}"
            >
              {{ item.email }}
            </h5>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-sheet>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar>
        <img :src="item.picture">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import { getInstance } from '@/auth/index';

export default {
  name: 'CAuthSelect',
  props: {
    pItems: {
      type: Array,
      default: null,
      description: 'If set - used instead Vuex module',
    },
    value: {
      type: String,
      default: null,
    },
    overseer: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      users: 'user/items',
      vuexLoading: 'user/loading',
    }),
    authLoading() {
      return getInstance().loadingUsers;
    },
  },

  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="scss">
.auth-select {
  .v-select__selections {
    flex-wrap: nowrap;
    input {
      min-width: 0px !important;
    }
  }
  .v-label--active {
    transform: translateY(-25px) scale(0.75);
  }
}
</style>
