import { render, staticRenderFns } from "./AppNavigation.vue?vue&type=template&id=5ef11973&"
import script from "./AppNavigation.vue?vue&type=script&lang=js&"
export * from "./AppNavigation.vue?vue&type=script&lang=js&"
import style0 from "./AppNavigation.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VAppBarNavIcon,VFadeTransition,VHover,VNavigationDrawer,VSpacer})
