<template>
  <v-menu
    v-if="unique"
    v-model="menu"
    eager
    :close-on-content-click="false"
    transition="slide-y-transition"
    :offset-y="true"
    :bottom="true"
    left
    min-width="400px"
  >
    <template #activator="{ on: on1 }">
      <v-tooltip top>
        <template #activator="{ on: on2 }">
          <v-btn
            class="mr-2"
            icon
            color="red"
            v-on="{...on1, ...on2}"
            @click:append="menu = true"
          >
            <v-icon :class="{ 'white--text': !open }">
              mdi-view-list
            </v-icon>
          </v-btn>
        </template>
        {{ $t('label:unique', null, 2) }}
      </v-tooltip>
    </template>
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <v-col>
            <v-row>
              {{ $t('label:unique', null, 2) }}: {{ uniques.length }} / {{ total }}
              <v-spacer />
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="uniques.length >= total"
                    icon
                    v-on="on"
                    @click="onSync(true)"
                  >
                    <v-icon>
                      mdi-playlist-plus
                    </v-icon>
                  </v-btn>
                </template>
                {{ $t('label:sync:up') }}
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="total >= uniques.length"
                    icon
                    v-on="on"
                    @click="onSync(false)"
                  >
                    <v-icon>
                      mdi-playlist-minus
                    </v-icon>
                  </v-btn>
                </template>
                {{ $t('label:sync:down') }}
              </v-tooltip>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text style="overflow-y: auto; max-height:250px;">
          <v-col>
            <v-row
              v-for="(string, index) in uniques"
              :key="`unique-${index}`"
            >
              <v-text-field
                :value="string"
                :rules="validation"
                dense
                append-outer-icon="mdi-delete"
                @input="v => onInput(index, v)"
                @click:append-outer="onDelete(index)"
              />
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script>
export default {
  name: 'PackageUniques',

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    uniques() {
      return this.value.Uniques || [];
    },

    unique() {
      return this.template.Orderlines[this.path[0]].Unique;
    },

    value() {
      if (!this.path.length) {
        return this.template;
      }
      return this.template.Orderlines[this.path[0]][this.path[1]][this.path[2]];
    },

    total() {
      return this.value.ProductBundle.Bundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), (this.value.ProductBundle.Quantity || 0),
      ) * (this.value.Quantity || 0);
    },

    validation() {
      return [
        () => this.uniques.length === this.total || this.$t('validation:uniques:count'),
      ];
    },
  },

  methods: {
    onSync(add = false) {
      const { Orderlines } = this.template;
      const Uniques = Orderlines[this.path[0]][this.path[1]][this.path[2]].Uniques || [];

      if (add) {
        Uniques.push(...(new Array(this.total - Uniques.length)).fill('', Uniques.length));
      } else {
        Uniques.splice(this.total, Uniques.length - this.total);
      }
      Orderlines[this.path[0]][this.path[1]][this.path[2]] = ({ ...this.value, Uniques });
      this.reset({
        ...this.template,
        Orderlines,
      });
    },

    onDelete(index) {
      const { Orderlines } = this.template;
      const { Uniques } = Orderlines[this.path[0]][this.path[1]][this.path[2]];
      Uniques.splice(index, 1);
      Orderlines[this.path[0]][this.path[1]][this.path[2]] = ({ ...this.value, Uniques });
      this.reset({
        ...this.template,
        Orderlines,
      });
    },

    onInput(index, v) {
      const { Orderlines } = this.template;
      const Uniques = Orderlines[this.path[0]][this.path[1]][this.path[2]].Uniques || [];
      Uniques[index] = v;
      Orderlines[this.path[0]][this.path[1]][this.path[2]] = ({ ...this.value, Uniques });
      this.reset({
        ...this.template,
        Orderlines,
      });
    },
  },
};
</script>
