<template>
  <transition name="slide-fade">
    <v-row>
      <v-col>
        <v-card
          outlined
          :disabled="loading"
          :loading="loading"
          :class="{'pt-2': !loading}"
        >
          <template slot="progress">
            <progress-bar />
          </template>
          <c-vuex-list
            :headers="headers"
            :get-list="getList"
            :list="list"
            :loading="loading"
          >
            <template #footer-prepend>
              <v-btn
                small
                color="primary darken-1"
                class="width-auto"
                @click.stop="onForm()"
              >
                {{ $t('label:cluster', null, 1) }}
                <v-icon
                  small
                  right
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  <v-btn
                    icon
                    @click.stop="onForm(item.ItemId)"
                  >
                    <v-icon>
                      mdi-open-in-app
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ mWarehouseName(item) }}
                </td>
                <td>
                  {{ item.Name }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        class="mr-2 float-right"
                        v-on="on"
                        @click="onDelete(item)"
                      >
                        <v-icon
                          class="delete-icon"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('label:delete') }}
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </c-vuex-list>
        </v-card>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClusterIndex',

  data: () => ({
    warehouseItems: [],
  }),

  computed: {
    ...mapGetters({
      loading: 'cluster/loadingList',
      warehouseLoading: 'warehouse/loading',
      list: 'cluster/list',
    }),
    headers() {
      return [
        {
          text: '', value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:warehouse', null, 1), value: 'WarehouseId', searchable: false,
        },
        {
          text: this.$t('label:name', null, 1), value: 'Name', type: 'text',
        },
        {
          text: '', value: '', searchable: false, sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      warehouseReturnItems: 'warehouse/returnItems',
      getList: 'cluster/getList',
      deleteItem: 'cluster/destroy',
    }),

    mWarehouseName(item) {
      return item?.Warehouse?.Name || '-';
    },

    async warehouseAction(filter) {
      this.warehouseItems = await this.warehouseReturnItems(filter);
    },

    onForm(ItemId) {
      this.$router.push({ name: 'storage-cluster-form', params: { ItemId } });
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
