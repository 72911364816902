var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pl-0 pr-2",class:{ collapsed: _vm.collapse,
            expanded: !_vm.collapse },attrs:{"md":"3"}},[_c('v-card',{staticClass:"pa-2",class:{ 'd-flex': _vm.collapse },attrs:{"color":"#f5f5f5","elevation":"0","outlined":"","min-height":"100%","height":"inherit"}},[(!_vm.collapse)?_c('div',{class:{ 'd-none': _vm.collapse }},[_c('v-autocomplete',{attrs:{"disabled":Boolean(_vm.template.ItemId) || _vm.template.Invoicelines &&
          _vm.template.Invoicelines.length > 0,"label":_vm.$t('label:customer', null, 1),"items":_vm.customerItems,"aria-autocomplete":"off","item-text":"Name","item-value":"ItemId","required":"","rules":_vm.mnValidation.required},on:{"input":function () { return _vm.$emit('on-change-customer'); }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":"","icon":"","small":""}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,1855039890)},[_vm._v(" "+_vm._s(_vm.$t("label:customer_add"))+" ")])]},proxy:true}],null,false,22081077),model:{value:(_vm.template.CustomerId),callback:function ($$v) {_vm.$set(_vm.template, "CustomerId", $$v)},expression:"template.CustomerId"}}),_c('v-autocomplete',{attrs:{"label":_vm.$t('label:contact', null, 1),"items":_vm.contactItems,"item-text":function (c) { return [c.FirstName, c.MiddleName, c.LastName].join(' '); },"loading":_vm.contactLoading,"aria-autocomplete":"off","item-value":"ItemId","rules":_vm.mnValidation.required},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":""}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,3675742034)},[_vm._v(" "+_vm._s(_vm.$t("label:contact_add"))+" ")])]},proxy:true}],null,false,3540850194),model:{value:(_vm.template.ContactId),callback:function ($$v) {_vm.$set(_vm.template, "ContactId", $$v)},expression:"template.ContactId"}}),_c('v-textarea',{attrs:{"filled":"","label":_vm.$t('label:description', null, 1),"rows":"3","hide-details":"auto"},model:{value:(_vm.template.Description),callback:function ($$v) {_vm.$set(_vm.template, "Description", $$v)},expression:"template.Description"}})],1):_vm._e(),_c('div',{staticClass:"ma-auto"},[(_vm.collapse && _vm.$vuetify.breakpoint.lgAndDown)?_c('h4',{staticClass:"text-uppercase font-weight-bold black--text customer-info"},[_vm._v(" "+_vm._s(_vm.$t("text:customer_info"))+" ")]):_vm._e(),(_vm.collapse && _vm.$vuetify.breakpoint.xlOnly)?_c('h1',{staticClass:"text-uppercase font-weight-bold black--text customer-info"},[_vm._v(" "+_vm._s(_vm.$t("text:customer_info"))+" ")]):_vm._e()]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"info-color-hover pointer-on-hover",staticStyle:{"top":"-12px","right":"-9px","position":"absolute"},attrs:{"size":"24px"},on:{"click":function($event){return _vm.$emit('collapse', !_vm.collapse)}}},'v-avatar',attrs,false),on),[_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(!_vm.collapse ? 'mdi-chevron-left' : 'mdi-chevron-right')+" ")])],1)]}}])},[(!_vm.collapse)?_c('span',[_vm._v(_vm._s(_vm.$t("text:hide_customer_data")))]):_c('span',[_vm._v(_vm._s(_vm.$t("text:show_customer_data")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }