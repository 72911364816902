<template>
  <movement-page class="y-overflow custom-bg">
    <v-col sm="12">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-col>
  </movement-page>
</template>

<script>

import MovementPage from '@/components/MovementPage.vue';

export default {
  name: 'ViewsMovementOrders',
  components: {
    MovementPage,
  },
};
</script>
