/* eslint-disable no-param-reassign */
import keys from './keys';

export default {
  // eslint-disable-next-line no-shadow
  install(Vue) {
    // eslint-disable-next-line max-len
    Vue.prototype.$tt = (vue, key, ...params) => {
      const translation = keys[vue.$auth.tenant]
        ? keys[vue.$auth.tenant][key] : key;
      return vue.$t(translation, ...params);
    };
  },
};
