<template>
  <v-row>
    <v-col>
      <v-autocomplete
        :label="$t('label:warehouse', null, 1)"
        item-text="Name"
        item-value="ItemId"
        :items="warehouseItems"
        :value="template.WarehouseId"
        aria-autocomplete="off"
        :disabled="Boolean(template.ItemId)"
        @input="onWarehouseInput"
      />
      <v-autocomplete
        :value="template.CustomerId"
        :disabled="Boolean(template.ItemId)"
        :label="$t('label:customer', null, 1)"
        :items="customers"
        aria-autocomplete="off"
        item-text="Name"
        item-value="ItemId"
        required
        :rules="mnValidation.required"
        @input="onCustomerInput"
      />
    </v-col>
    <v-col>
      <v-autocomplete
        :label="$t('label:order_inbound', null, 1)"
        aria-autocomplete="off"
        item-value="ItemId"
        :item-text="v =>
          `order# ${v.ItemId} | ref: ${v.CustomerReference || '-'} (${v.Status})`"
        :items="orders"
        :value="template.InboundOrderline.InboundOrderId"
        return-object
        :loading="inboundOrderLoading"
        :disabled="!template.CustomerId || Boolean(template.ItemId) || inboundOrderLoading"
        @input="onInboundOrderInput"
      />
      <v-autocomplete
        :value="template.InboundOrderline.DockingId"
        :label="$t('label:docking_inbound', null, 1)"
        aria-autocomplete="off"
        item-value="ItemId"
        :item-text="v =>
          `order# ${v.ItemId} | serial# ${v.Serial || '-'} (${v.Status})`"
        :items="dockings"
        :loading="dockingLoading"
        :disabled="!template.CustomerId ||
          Boolean(template.ItemId) ||
          !template.InboundOrderline.InboundOrderId || dockingLoading"
        return-object
        @input="onDockingInput"
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="template.InboundOrderline.Batch"
        :label="$t('label:batch', null, 1)"
        :disabled="Boolean(template.ItemId)"
      />
    </v-col>
    <v-col
      cols="3"
      class="pb-0"
    >
      <v-autocomplete
        :value="template.InboundOrderline.ProductId"
        :label="$t('label:product', null, 1)"
        :items="products"
        :loading="productLoading"
        :search-input.sync="search.product"
        item-value="ItemId"
        item-text="Name"
        flat
        hide-no-data
        clearable
        aria-autocomplete="off"
        :disabled="Boolean(template.InboundOrderline.ItemId) || !template.CustomerId"
        @update:search-input="onProductSearch"
        @input="onProductInput"
      />
      <c-number
        v-model="template.InboundOrderline.Quantity"
        class="float-right"
        :label="$t('label:batch:quantity', null, 1)"
        :disabled="Boolean(template.InboundOrderline.ItemId)"
      >
        <template #prepend>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :disabled="!template.InboundOrderline.ProductId || Boolean(template.ProductBundle)"
                fab
                color="secondary"
                x-small
                class="mr-2"
                v-on="on"
                @click="onProductBundleCreate"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            {{ $t("label:package:create") }}
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :disabled="disableShred"
                fab
                color="secondary"
                x-small
                v-on="on"
                @click="onShred"
              >
                <v-icon>mdi-auto-fix</v-icon>
              </v-btn>
            </template>
            {{ $t("label:shard_process") }}
          </v-tooltip>
        </template>
      </c-number>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="template.InboundOrderline.Name"
        :label="$t('label:customer_product_name', null, 1)"
        :disabled="Boolean(template.ItemId)"
      />
      <v-textarea
        v-model="template.InboundOrderline.Description"
        style="margin-top: 10px"
        rows="3"
        filled
        :label="$t('label:description', null, 1)"
        :disabled="Boolean(template.ItemId)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrderlinePackageParenting',

  data: () => ({
    search: {
      product: null,
      docking: null,
      order: null,
    },
    timeout: {
      product: null,
      docking: null,
      order: null,
    },
    products: [],
    dockings: [],
    orders: [],
  }),

  computed: {
    ...mapGetters({
      warehouseItems: 'warehouse/items',

      loading: 'package/loadingList',
      template: 'package/template',

      inboundOrderLoading: 'inboundOrder/loading',
      dockingLoading: 'docking/loading',

      customerItems: 'customer/items',
      inboundOrderItems: 'inboundOrder/items',
      dockingItems: 'docking/items',
      productLoading: 'product/loading',

      WarehouseId: 'warehouse/WarehouseId',
    }),

    customers() {
      const customers = this.customerItems.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.CustomerId,
      );
      // eslint-disable-next-line no-nested-ternary
      customers.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
      return customers;
    },

    disableShred() {
      return (
        !this.template.InboundOrderline.Quantity
        || Number(this.template.InboundOrderline.Quantity) === 1
        || Boolean(this.template.ProductBundle)
      );
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      productGetItem: 'product/getItem',
      resetTemplate: 'package/resetTemplate',

      dockingReturnItems: 'docking/returnItems',
      productReturnItems: 'product/returnItems',
      orderReturnItems: 'inboundOrder/returnItems',
    }),
    async initialize() {
      this.onProductSearch();
      this.onInboundOrderSearch();
      this.onDockingSearch();
    },
    baseTotal(BasePackage) {
      if (!BasePackage.BaseProductBundle?.BaseBundles) {
        return 1;
      }
      return BasePackage.BaseProductBundle.BaseBundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), BasePackage.BaseProductBundle.Quantity,
      );
    },
    async onShred() {
      const { item } = await this.productGetItem(
        this.template.InboundOrderline.ProductId,
      );

      if (!item.ShardSize) {
        this.$toasted.error(this.$t('validation:shard:empty'));
        return;
      }

      const BasePackage = item.BasePackage || {};

      const unshredQuantity = this.template.InboundOrderline.Quantity;
      const unitTotalQuantity = unshredQuantity / item.ShardSize || 1;

      if (unitTotalQuantity % 1 !== 0) {
        this.$toasted.error(this.$t('validation:shard:multiple'));
        return;
      }

      const basePackageUnitQuantity = this.baseTotal(BasePackage);
      const fullPackagesQuantity = Math.floor(
        unitTotalQuantity / basePackageUnitQuantity,
      );

      const leftoveerQuantity = unitTotalQuantity % basePackageUnitQuantity;

      if (leftoveerQuantity > 0) {
        this.$toasted.error(this.$t('validation:shard:leftover'));
        return;
      }

      if (fullPackagesQuantity >= 1) {
        this.resetTemplate({
          ...this.packageDefaultTemplate,
          ...BasePackage,
          ...this.template,
          InboundBatch: this.template.InboundOrderline.Batch,
          Quantity: fullPackagesQuantity,
          ItemId: undefined,
          ProductBundle: {
            ...JSON.parse(
              JSON.stringify({
                ...this.productBundleDefaultTemplate,
                ...(BasePackage?.BaseProductBundle || {
                  ...this.productBundleDefaultTemplate,
                  Quantity: 1,
                }),
                ItemId: null,
                ProductId: this.template.InboundOrderline.ProductId,
                Bundles: (
                  BasePackage?.BaseProductBundle?.BaseBundles || []
                ).map((v) => ({
                  ...this.bundleDefaultTemplate,
                  ...v,
                  ItemId: undefined,
                })),
              }),
            ),
          },
        });
      }
    },

    onWarehouseInput(WarehouseId) {
      this.resetTemplate({ ...this.template, WarehouseId });
      this.onCustomerInput(null);
    },

    onCustomerInput(CustomerId) {
      this.resetTemplate({
        ...this.template,
        CustomerId,
        InboundOrderline: {
          ...this.template.InboundOrderline,
          CustomerId,
          InboundOrderId: null,
          DockingId: null,
          ProductId: null,
        },
        ProductBundle: this.template.ProductBundle ? {
          ...this.template.ProductBundle,
          CustomerId,
          ProductId: null,
        } : null,
      });
      this.onProductSearch();
      this.onInboundOrderSearch();
      this.onDockingSearch();
    },

    onProductInput(ProductId) {
      this.resetTemplate({
        ...this.template,
        InboundOrderline: {
          ...this.template.InboundOrderline,
          ProductId,
        },
        ProductBundle: this.template.ProductBundle ? {
          ...this.template.ProductBundle,
          ProductId,
        } : null,
      });
    },

    onInboundOrderInput({ ItemId, Status }) {
      let lineStatus;
      switch (Status) {
        case 'New':
        case 'Plannable':
          lineStatus = 'Plannable';
          break;
        case 'Done':
          lineStatus = 'Done';
          break;
        default:
      }
      this.resetTemplate({
        ...this.template,
        Status: ['New', 'Plannable'].includes(Status) ? 'Planned' : 'Stored',
        InboundOrderline: {
          ...this.template.InboundOrderline,
          InboundOrderId: ItemId,
          Status: lineStatus,
          DockingId: null,
        },
      });
      this.onDockingSearch();
    },

    onDockingInput({ ItemId, Status }) {
      let packageStatus;
      switch (Status) {
        case 'New':
        case 'Planned':
          packageStatus = 'Planned';
          break;
        case 'Check-in':
        case 'Done':
          packageStatus = 'Stored';
          break;
        default:
      }
      const destruct = {
        InboundHandling: null,
        StorageHandling: null,
        Status: packageStatus,
      };

      if (Status === 'Done') {
        destruct.StorageHandling = {
          Type: 'StorageHandling',
          Start: (new Date()).toISOString(),
          SectorUsages: [
            {
              SectorId: this.template.SectorId,
              Start: (new Date()).toISOString(),
            },
          ],
        };

        destruct.InboundHandling = {
          Type: 'InboundHandling',
          Start: (new Date()).toISOString(),
          ResourceUsages: [],
          ConsumableUsages: [],
          ExecutorUsages: [],
        };
      }

      this.resetTemplate({
        ...this.template,
        ...destruct,
        InboundOrderline: {
          ...this.template.InboundOrderline,
          DockingId: ItemId,
        },
      });
    },

    async onProductBundleCreate() {
      const { item } = await this.productGetItem(
        this.template.InboundOrderline.ProductId,
      );
      const BasePackage = item.BasePackage || {};

      this.resetTemplate({
        ...this.template,
        ProductBundle: {
          ...JSON.parse(
            JSON.stringify({
              ...this.productBundleDefaultTemplate,
              ...(BasePackage?.BaseProductBundle || {}),
              ItemId: null,
              ProductId: this.template.InboundOrderline.ProductId,
              Bundles: (BasePackage?.BaseProductBundle?.BaseBundles || []).map(
                (v) => ({
                  ...this.bundleDefaultTemplate,
                  ...v,
                  ItemId: undefined,
                }),
              ),
            }),
          ),
        },
      });
    },

    onProductSearch() {
      clearTimeout(this.timeout.product);
      this.timeout.product = setTimeout(async () => {
        const filter = {
          CustomerId: { value: this.template.CustomerId, type: 'select' },
          ItemId: { value: this.template.InboundOrderline.ProductId, type: 'select' },
        };

        if (!this.template.InboundOrderline) {
          return;
        }

        if (this.search.product?.length) {
          filter.Name = { value: this.search.product, type: 'text' };
        }

        this.products = (await this.productReturnItems(filter)).filter(
          ({ deletedAt, ItemId }) => !deletedAt
          || ItemId === this.template.InboundOrderline.ProductId,
        );
      }, 500);
    },

    onInboundOrderSearch() {
      clearTimeout(this.timeout.order);
      this.timeout.order = setTimeout(async () => {
        if (!this.template.InboundOrderline) {
          return;
        }

        const filter = {
          WarehouseId: { value: this.template.WarehouseId, type: 'select' },
          CustomerId: { value: this.template.CustomerId, type: 'select' },
        };

        if (this.template.InboundOrderline.InboundOrderId) {
          filter.ItemId = { value: this.template.InboundOrderline.InboundOrderId, type: 'select' };
        }

        if (this.search.order?.length) {
          filter.ItemId = { value: this.search.order, type: 'text' };
          filter.Status = ['Plannable', 'Done'];
        }

        this.orders = (await this.orderReturnItems(filter)).filter(
          ({ deletedAt, ItemId }) => !deletedAt
          || ItemId === this.template.InboundOrderline.InboundOrderId,
        );
      }, 500);
    },

    onDockingSearch() {
      clearTimeout(this.timeout.docking);
      this.timeout.docking = setTimeout(async () => {
        if (!this.template.InboundOrderline) {
          return;
        }
        const filter = {
          Type: { value: 'Inbound', type: 'select' },
          ItemId: { value: this.template.InboundOrderline.DockingId, type: 'select' },
          WarehouseId: { value: this.template.WarehouseId, type: 'select' },
        };

        if (this.search.docking?.length) {
          filter.ItemId = { value: this.search.order, type: 'select' };

          switch (this.template.InboundOrderline.Status) {
            case 'Plannable':
            case 'Planned':
            case 'Check-in':
              filter.Status = ['New', 'Planned', 'Check-in', 'Done'];
              break;
            case 'Done':
              filter.Status = ['Done'];
              break;
            default:
              throw new Error('Missing status');
          }
        }
        this.dockings = (await this.dockingReturnItems(filter)).filter(
          ({ deletedAt, ItemId }) => !deletedAt
          || ItemId === this.template.InboundOrderline.DockingId,
        );
      }, 500);
    },
  },
};
</script>
