var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._b({key:("select-autocomplete-key-" + _vm.key),staticClass:"sector-select sector-disabled mt-2",attrs:{"value":_vm.cValue,"items":_vm.cSectors,"loading":_vm.sectorLoading,"label":_vm.$t('label:sector', null, 1),"placeholder":_vm.$t('label:sector'),"item-text":function (s) { return ((s.Type) + ": " + (s.Name)); },"item-value":"ItemId","hide-details":_vm.$attrs.disabled,"aria-autocomplete":"off","menu-props":{ bottom: true, offsetY: true },"tabindex":1,"rules":_vm.$attrs.disabled ? undefined : _vm.mnValidation.required},on:{"input":function (v) { return _vm.$attrs.disabled ? null : _vm.onInput(v); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.sector)?_c('v-sheet',_vm._g(_vm._b({staticClass:"pt-0 pb-1 d-flex align-center w-100",attrs:{"input-value":item.selected,"color":"transparent"}},'v-sheet',item.attrs,false),on),[_c('v-badge',{attrs:{"content":((_vm.cTotal.packageLinked ? '' : '+') + " " + (_vm.cTotal.packageShare >= 1
              ? _vm.cTotal.packageShare : '<1') + "%"),"offset-x":"18px","offset-y":"15px","color":_vm.cTotal.packageLinked ? 'grey' : 'blue'}},[_c('div',{staticClass:"circle"},[_c('h6',{staticClass:"font-weight-regular d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(_vm.cTotal.occupancy >= 1 ? _vm.cTotal.occupancy : _vm.cTotal.occupancy > 100 ? '>100' : '⋖1')+"% ")]),_c('div',{staticClass:"fill",style:(("height: " + (_vm.cTotal.occupancy >= 100 ? '100' : _vm.cTotal.occupancy) + "%;\n          background-color: " + (_vm.cTotal.bgColor)))})])]),_c('v-list-item-content',{staticClass:"pa-0 truncated align-self-end mb-1"},[_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.Name)+" ")]),(item.deletedAt)?_c('small',{staticClass:"red--text text-lowercase caption"},[_vm._v(" "+_vm._s(_vm.$t('label:deleted', null, 1))+" ")]):_vm._e(),(_vm.cTotal.occupancy > 100)?_c('v-list-item-subtitle',[_c('h5',{staticClass:"font-weight-regular truncated red--text",style:({opacity: _vm.$attrs.disabled ? '0.7' : ''})},[_vm._v(" Sector capacity exceeded ")])]):_vm._e()],1)],1):_vm._e()]}}],null,true)},[(_vm.sector)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.cTotal.occupancy > 100 ? 'The sector is over occupied' : ("Sector occupied on " + (_vm.cTotal.occupancy) + "%"))+" ")]),_c('span',[_vm._v("Current package occupy "+_vm._s(_vm.cTotal.packageShare)+"% of sector volume")])]):_vm._e()])]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.ItemId)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}}])},'v-autocomplete',Object.assign({}, _vm.$attrs, _vm.$props),false))}
var staticRenderFns = []

export { render, staticRenderFns }