const modules = [
  'cluster',
  'inboundOrder',
  'outboundOrder',
  'package',
  'resource',
  'sector',
  'consumable',
  'resource',
  'carrier',
  'dock',
  'docking',
];

export default (store) => {
  store.watch(({ warehouse }) => warehouse.WarehouseId, (n, o) => {
    if (n !== o) {
      modules.forEach((module) => {
        store.dispatch(`${module}/getItems`);
        store.dispatch(`${module}/getList`, {});
      });
    }
  });
};
