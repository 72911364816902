<template>
  <v-form
    ref="form"
    class="pa-2 pt-5 w-100"
    rounded="lg"
    color="#dddddd"
  >
    <v-row
      class="ma-0"
      dense
    >
      <v-col
        md="3"
        xl="3"
        class="pb-0"
      >
        <v-autocomplete
          :label="$t('label:warehouse', null, 1)"
          item-text="Name"
          item-value="ItemId"
          :items="warehouseItems"
          :value="value.WarehouseId"
          aria-autocomplete="off"
          hide-details
          disabled
          readonly
        />
        <c-sector-select
          v-if="value.WarehouseId"
          :p-package="value"
          :p-packages="[]"
          :s-packages="[]"
          :r-packages="[]"
          :rules="mnValidation.required"
          @input="(v) => onInput('SectorId', v)"
        />
        <v-checkbox
          :input-value="value.Stackable"
          :label="$t('label:stackable')"
          hide-details
          :disabled="disable.main"
          @change="(v) => onInput('Stackable', v)"
        />
      </v-col>
      <v-col class="pb-0">
        <slot name="product-bundle" />
      </v-col>
      <v-col
        cols="auto"
        class="pb-0"
      >
        <v-row class="d-xl-flex flex-xl-column mr-0">
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pb-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_netto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightNetto"
              :disabled="disable.main"
              @input="(v) => onInput('WeightNetto', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_brutto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightBrutto"
              :disabled="disable.main"
              @input="(v) => onInput('WeightBrutto', v)"
            />
          </v-col>
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pt-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:length', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Length"
              :disabled="disable.main"
              @input="(v) => onInput('Length', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:width', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Width"
              :disabled="disable.main"
              @input="(v) => onInput('Width', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:height', null, 1)"
              class="width-10ch"
              :value="value.Height"
              :disabled="disable.main"
              @input="(v) => onInput('Height', v)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="cSlots.length"
      no-gutters
      class="mt-0"
    >
      <div class="fadeline w-100" />
    </v-row>
    <v-row
      v-if="cSlots.length"
      class="ma-0"
    >
      <v-col class="text-center">
        <v-btn-toggle
          v-model="activeSlot"
          mandatory
          class="mx-auto"
          style="z-index: 10"
        >
          <v-btn
            v-for="(v, i) in cSlots"
            :key="`slot-${i}`"
            width="auto"
            small
            :value="v.value"
          >
            {{ v.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row
      v-if="cSlots.length"
      class="ma-0 mb-n3"
      justify="center"
      style="transform: translateY(-12px)"
    >
      <v-tabs-items
        v-model="activeSlot"
        class="w-100"
      >
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-inbound-handling"
        >
          <slot name="transaction-inbound-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-transformation-handling"
        >
          <slot name="transaction-transformation-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-storage-handling"
        >
          <slot name="transaction-storage-handling" />
        </v-tab-item>
        <v-tab-item
          class="mx-n3"
          eager
          value="transaction-outbound-handling"
        >
          <slot name="transaction-outbound-handling" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CSectorSelect from '@/components/sector/select.vue';

export default {
  name: 'PackageMainOutbound',

  components: {
    CSectorSelect,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
  },

  data: () => ({
    sectors: [],
    activeSlot: null,
  }),

  computed: {
    ...mapGetters({
      warehouseItems: 'warehouse/items',
    }),

    cSlots() {
      return [
        {
          text: this.$t('label:inbound_handling'),
          value: 'transaction-inbound-handling',
          type: 'InboundHandling',
        },
        {
          text: this.$t('label:transformation_handling'),
          value: 'transaction-transformation-handling',
          type: 'TransformationHandling',
        },
        {
          text: this.$t('label:storage_handling'),
          value: 'transaction-storage-handling',
          type: 'StorageHandling',
        },
        {
          text: this.$t('label:outbound_handling'),
          value: 'transaction-outbound-handling',
          type: 'OutboundHandling',
        },
      ].filter(
        ({ type }) => this.value[type]?.length || Boolean(this.value[type]),
      );
    },

    value() {
      if (!this.path.length) {
        return this.template;
      }
      return this.template.Orderlines[this.path[0]][this.path[1]][this.path[2]];
    },
  },

  mounted() {
    this.onChangeWarehouse(this.value.WarehouseId);
  },

  methods: {
    ...mapActions({
      sectorReturnItems: 'sector/returnItems',
    }),

    onInput(p, v) {
      const { Orderlines } = this.template;
      Orderlines[this.path[0]][this.path[1]][this.path[2]] = {
        ...this.value,
        [p]: v,
      };
      this.reset({
        ...this.template,
        Orderlines,
      });
    },

    async onChangeWarehouse(WarehouseId) {
      if (!WarehouseId) {
        this.onInput('SectorId', null);
      }
      this.onInput('WarehouseId', WarehouseId);
      const filter = {
        WarehouseId: this.value.WarehouseId,
      };
      this.sectors = await this.sectorReturnItems(filter);
    },
  },
};
</script>
