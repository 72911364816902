export default {
  methods: {
    mAuthPass(allowedRoles, allowedTenants) {
      const { roles, tenant } = this.$auth;

      if (allowedTenants
            && allowedTenants.length
            && !allowedTenants.includes(tenant)) {
        return false;
      }

      if (this.strict) {
        return roles.filter((r) => allowedRoles.includes(r)).length > 0;
      }

      return roles.find((r) => r === 'WMS.Admin') || roles.filter((r) => allowedRoles.includes(r)).length > 0;
    },
  },
};
