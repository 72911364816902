<template>
  <transition name="slide-fade">
    <v-row>
      <v-col>
        <v-card
          outlined
          :disabled="loading"
          :loading="loading"
          :class="{'pt-2': !loading}"
        >
          <template slot="progress">
            <progress-bar />
          </template>
          <c-vuex-list
            :headers="headers"
            :get-list="getList"
            :list="list"
            :loading="loading"
          >
            <template #item="{ item }">
              <tr
                :class="{ itemSelected: mSelected(item.ItemId),
                          primary: mSelected(item.ItemId) }"
                @click.stop="onSelect(item)"
              >
                <td>
                  {{ item.Name }}
                </td>
              </tr>
            </template>
          </c-vuex-list>
        </v-card>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductGroupIndex',

  computed: {
    ...mapGetters({
      loading: 'productGroup/loadingList',
      list: 'productGroup/list',

      productGroupTemplate: 'productGroup/template',
      productTemplate: 'product/template',
    }),
    headers() {
      return [
        {
          text: this.$t('label:name', null, 1),
          value: 'Name',
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getList: 'productGroup/getPortalList',

      resetProductGroupTemplate: 'productGroup/resetTemplate',
      resetProductTemplate: 'product/resetTemplate',
    }),

    mSelected(ItemId) {
      return this.productGroupTemplate?.ItemId === ItemId;
    },

    onSelect(item) {
      if (item.ItemId !== this.productGroupTemplate?.ItemId) {
        this.resetProductGroupTemplate(item);
        this.resetProductTemplate(null);
      }
    },
  },
};
</script>
