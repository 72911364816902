<template>
  <v-col
    md="3"
    class="pl-0 pr-2"
    :class="{ collapsed: collapse,
              expanded: !collapse }"
  >
    <v-card
      class="pa-2"
      :class="{ 'd-flex': collapse }"
      color="#f5f5f5"
      elevation="0"
      outlined
      min-height="100%"
      height="inherit"
    >
      <div
        v-if="!collapse"
        :class="{ 'd-none': collapse }"
      >
        <v-autocomplete
          v-model="template.CustomerId"
          :disabled="Boolean(template.Orderlines.length) || after('Planned') || customerLoading"
          :label="$t('label:customer', null, 1)"
          :items="customers"
          :loading="customerLoading"
          aria-autocomplete="off"
          item-text="Name"
          item-value="ItemId"
          required
          :rules="mnValidation.required"
          @input="() => $emit('on-change-customer')"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  :disabled="Boolean(template.Orderlines.length) || after('Planned')"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:customer_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="template.ContactId"
          :label="$t('label:contact', null, 1)"
          :items="contactItems"
          :item-text="
            (c) => [c.FirstName, c.MiddleName, c.LastName].join(' ')
          "
          :loading="contactLoading"
          aria-autocomplete="off"
          item-value="ItemId"
          :rules="mnValidation.required"
          :disabled="after('Planned')"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:contact_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="template.WarehouseId"
          :label="$t('label:warehouse', null, 1)"
          :items="warehouses"
          :rules="mnValidation.required"
          item-text="Name"
          item-value="ItemId"
          :loading="warehouseLoading"
          aria-autocomplete="off"
          :disabled="Boolean(template.Orderlines.length)"
        />

        <v-text-field
          v-model="template.CustomerReference"
          :label="$t('label:customer_reference', null, 1)"
          aria-autocomplete="off"
        />
        <v-row no-gutters>
          <v-col>
            <v-text-field
              disabled
              :value="executionDate"
              :label="$t('label:execution_date', null, 1)"
            />
          </v-col>
        </v-row>
        <v-textarea
          v-model="template.Description"
          filled
          :label="$t('label:description', null, 1)"
          rows="3"
          hide-details="auto"
          :disabled="after('Planned')"
        />
      </div>
      <div class="ma-auto">
        <h4
          v-if="collapse && $vuetify.breakpoint.lgAndDown"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h4>
        <h1
          v-if="collapse && $vuetify.breakpoint.xlOnly"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h1>
      </div>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-avatar
            size="24px"
            v-bind="attrs"
            style="top: -12px; right: -9px; position: absolute;"
            class="info-color-hover pointer-on-hover"
            v-on="on"
            @click="$emit('collapse', !collapse)"
          >
            <v-icon
              small
              class="white--text"
            >
              {{ !collapse ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
          </v-avatar>
        </template>
        <span v-if="!collapse">{{ $t("text:hide_customer_data") }}</span>
        <span v-else>{{ $t("text:show_customer_data") }}</span>
      </v-tooltip>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'TransformationOrderMain',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
    after: {
      type: Function,
      default: () => { },
      description: '',
    },
  },

  computed: {
    ...mapGetters({
      template: 'transformationOrder/template',

      contactLoading: 'contact/loading',

      customerItems: 'customer/items',
      contacts: 'contact/items',

      customerLoading: 'customer/loading',

      warehouseLoading: 'warehouse/loading',
      warehouses: 'warehouse/items',

    }),

    executionDate() {
      return this.template?.updatedAt ? this.$moment(this.template.updatedAt).format('DD-MM-YYYY') : '';
    },

    customers() {
      const customers = this.customerItems.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.CustomerId,
      );
      // eslint-disable-next-line no-nested-ternary
      customers.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
      return customers;
    },

    contactItems() {
      return this.contacts.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.ContactId,
      );
    },
  },
};
</script>
