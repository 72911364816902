<template>
  <v-card
    class="mb-1 w-100"
  >
    <v-row
      class="ma-0"
      align="center"
      dense
    >
      <v-col>
        <div class="d-flex align-center">
          <small class="d-flex flex-column">
            <span class="clipped-line">
              {{ $isodate(invoiceline.Name) }}
            </span>
            <small>
              <span class="clipped-line">
                {{ $isodate(invoiceline.Description) }}
              </span>
            </small>
          </small>
          <v-spacer />
          <v-menu
            :close-on-content-click="false"
            :offset-y="true"
            :bottom="true"
            left
            transition="slide-y-transition"
          >
            <template #activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template #activator="{ on: tooltip }">
                  <v-btn
                    fab
                    depressed
                    x-small
                    class="ml-1"
                    v-bind="attrs"
                    v-on.stop="{ ...tooltip, ...menu }"
                    @click.stop
                  >
                    <v-icon>
                      {{ invoiceline.Description2 ? 'mdi-information-outline' : 'mdi-link' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $isodate(invoiceline.Description2) ?
                  $isodate(invoiceline.Description2) : 'Linked entities' }}</span>
              </v-tooltip>
            </template>
            <v-card class="pa-1 d-flex flex-column">
              <v-row
                class="ma-0"
                dense
              >
                <v-col cols="auto">
                  <h4 style="width: 100px">
                    Item Id
                  </h4>
                </v-col>
                <v-col>
                  {{ invoiceline.ItemId }}
                </v-col>
              </v-row>
              <v-row
                class="ma-0"
                dense
              >
                <v-col cols="auto">
                  <h4 style="width: 100px">
                    Invoice Id
                  </h4>
                </v-col>
                <v-col>
                  {{ invoiceline.InvoiceId }}
                </v-col>
              </v-row>
              <v-row
                class="ma-0"
                dense
              >
                <v-col cols="auto">
                  <h4 style="width: 100px">
                    Matrix Id
                  </h4>
                </v-col>
                <v-col>
                  {{ invoiceline.MatrixId }}
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </div>
      </v-col>
      <v-col
        xl="2"
        md="2"
      >
        <span class="clipped-line">
          {{ CTransaction.type }}
        </span>
      </v-col>
      <v-col
        xl="1"
        md="1"
      >
        <span class="clipped-line">
          {{ CTransaction.carrier ? CTransaction.carrier : '' }}
        </span>
      </v-col>
      <v-col
        xl="1"
        md="1"
        class="text-right"
      >
        <span>
          {{ CTransaction.quantity }}{{ CTransaction.unit }}
        </span>
      </v-col>
      <v-col
        cols="auto"
        class="text-right"
      >
        <span class="clipped-line width-15ch">
          {{ invoiceline.PriceUnit && !loading ? CPrice(invoiceline.PriceUnit) : '-' }}
        </span>
      </v-col>
      <v-col cols="auto">
        <div
          class="d-flex justify-end width-15ch"
        >
          {{ CPrice(linePrice) }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Invoiceline',

  props: {
    invoiceline: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    linePrice: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      carrierItems: 'carrier/items',
    }),

    CTransaction() {
      let type;
      let carrier;
      let quantity;
      let unit;
      switch (this.invoiceline.Type) {
        case 'SectorUsage':
          type = this.$t('label:sector_usage');
          carrier = `${this.CSectorUsage.type ? this.CSectorUsage.type : ''}`;
          quantity = this.CSectorUsage.quantity;
          unit = this.CSectorUsage.unit ? this.CSectorUsage.unit : '';
          break;
        case 'ExecutorUsage':
          type = this.$t('label:usage_executor');
          quantity = this.invoiceline.Quantity;
          unit = this.$t('label:minute', null, 2);
          break;
        case 'ConsumableUsage':
          type = this.$t('label:usage_consumable');
          quantity = this.invoiceline.Quantity;
          break;
        case 'ResourceUsage':
          type = this.$t('label:usage_resource');
          quantity = this.invoiceline.Quantity;
          break;
        case 'Custom':
          type = this.$t('label:custom');
          quantity = this.invoiceline.Quantity;
          break;

        default: throw new Error('Invalid Status');
      }
      return {
        type, carrier, quantity, unit,
      };
    },

    CSectorUsage() {
      let type;
      let quantity;
      let unit;
      switch (this.invoiceline.Unit) {
        case 'CarrierId':
          type = this.CCarrier;
          quantity = this.invoiceline.Multiplier;
          break;
        case 'SquareMeters':
          quantity = this.invoiceline.Quantity;
          unit = 'm²';
          break;
        case 'CubicMeters':
          quantity = this.invoiceline.Quantity;
          unit = 'm³';
          break;
        default:
          quantity = '-';
          unit = '-';
      }
      return { type, quantity, unit };
    },

    CCarrier() {
      return this.carrierItems.filter(
        (c) => c.ItemId === Number(this.invoiceline.Quantity),
      )[0].Name;
    },

  },

  methods: {
    CPrice(price) {
      return Number(price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
    },
  },

};
</script>
