<template>
  <div>
    <vue-dropzone
      :id="id"
      ref="ref"
      :options="options"
      :include-styling="true"
      :use-custom-slot="true"
      :duplicate-check="true"
      class="remove-oversize-file dropzone-height w-100"
      :class="classes"
      @vdropzone-success="onSuccess"
    >
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title success-color">
          {{ $t('label:add_file') }}
        </h3>
        <div>
          {{ $t('label:select_file') }}
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>

export default {
  name: 'CDropzone',
  props: {
    id: {
      type: String,
      default: null,
      description: '',
    },
    type: {
      type: String,
      default: null,
      description: 'Define type of uploaded file',
    },
    params: {
      type: Object,
      default: null,
      description: 'If set - used as dropZone.options.params',
    },

    onSuccess: {
      type: Function,
      default: () => { },
      description: '',
    },
    autoProcessQueue: {
      type: Boolean,
      default: false,
      description: '',
    },
    classes: {
      type: String,
      default: null,
      description: 'String with CSS classes',
    },
    url: {
      type: String,
      default: null,
      description: '',
    },
    acceptedFiles: {
      type: String,
      default: null,
      description: '',
    },
  },
  computed: {
    documentDropzoneOptions() {
      return {
        url: this.url ? `https://${window.location.hostname}:3000/${this.url}/` : 'https://',
        thumbnailWidth: 150,
        maxFilesize: 5,
        addRemoveLinks: true,
        autoProcessQueue: this.autoProcessQueue,
        acceptedFiles: this.acceptedFiles,
        dictCancelUpload: this.$t('label:cancel_upload'),
        dictRemoveFile: this.$t('label:remove_file'),
        headers: { Authorization: `Bearer ${this.$auth.token}` },
        params: this.params,
      };
    },
    photoDropzoneOptions() {
      return {
        url: this.url ? `https://${window.location.hostname}:3000/${this.url}/` : 'https://',
        thumbnailWidth: 150,
        maxFilesize: 5,
        addRemoveLinks: true,
        autoProcessQueue: this.autoProcessQueue,
        acceptedFiles: 'image/*',
        dictCancelUpload: this.$t('label:cancel_upload'),
        dictRemoveFile: this.$t('label:remove_file'),
        headers: { Authorization: `Bearer ${this.$auth.token}` },
        params: this.params,
      };
    },
    options() {
      return this.type === 'photo' ? this.photoDropzoneOptions
        : this.documentDropzoneOptions;
    },
  },
};
</script>
<style lang="scss">
.vue-dropzone.dropzone {
  font-family: unset;
  position: relative;
  letter-spacing: unset;
  border: #ddd 4px dashed;
  border-radius: 2px;
  .dz-preview .dz-image {
    border-radius: 4px;
  }
}

.remove-oversize-file {
  .dz-preview, .dz-preview.dz-error {
    .dz-details {
      padding-top: 2.5em;
      .dz-size {
        margin-bottom: 0.1em;
      }
    }
    .dz-remove {
      top: 5px;
      right: 5px;
      height: 24px;
      border: none;
      border-radius: 12px;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      margin: 0 auto;
      padding: 2px;
      margin-left: none !important;
      background-color: #f5f5f5;
      color: red;
      width: auto;
      opacity: 1;
      font-size: 0.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      &:hover {
        text-decoration: unset;
        background-color: #e5e5e5;
        transition: 0.3s;
      }
    }
  }
  .dz-preview.dz-error {
    z-index: 1000 !important;
    .dz-error-message {
      border-radius: 0px;
      opacity: 1;
    }
  }
}

  .dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
}

</style>
