/* eslint-disable no-useless-escape */
import moment from 'moment';

export default {
  async install(vue) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$isodate = (string = '', format = null) => {
      if (!string) {
        return '';
      }
      return string.replaceAll(
        new RegExp('ISODATE\<(.*?)\>', 'g'),
        (isodate) => {
          const r = isodate.replaceAll(new RegExp('ISODATE\<|\>', 'g'), '');
          if (!r.length) {
            return '';
          }
          if (format) {
            return moment(r).format(format);
          }
          if (format === false) {
            return `${moment(r).toISOString()} (UTC)`;
          }
          return `${moment(r).toLocaleString()} (local)`;
        },
      );
    };
  },
};
