<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="lowComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-form ref="form">
      <v-card
        v-if="productGroup"
        tile
        :loading="loading"
      >
        <template slot="progress">
          <progress-bar />
        </template>
        <v-card-title class="card-title">
          <span
            class="card-title-caption text-truncate"
          >
            {{ $t('label:product_serie', null, 1) }}
          </span>
          <v-spacer />
          <v-btn
            icon
            light
            color="text"
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-2">
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="productGroup.CustomerId"
                :label="$t('label:customer', null, 1)"
                :items="customerItems"
                :loading="customerLoading"
                item-value="ItemId"
                item-text="Name"
                aria-autocomplete="off"
                flat
                hide-no-data
                clearable
                :rules="mnValidation.required"
              />
              <v-text-field
                v-model="productGroup.Name"
                :label="$t('label:name', null, 1)"
                :rules="mnValidation.required"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            fixed
            color="border darken-1"
            @click="onClose"
          >
            {{ $t('btn:close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onSave"
          >
            {{ $t('btn:save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductGroupForm',

  computed: {
    ...mapGetters({
      loading: 'productGroup/loading',
      customerItems: 'customer/items',
      customerLoading: 'customer/loading',
      productGroup: 'productGroup/template',
    }),
  },

  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      update: 'productGroup/update',
      create: 'productGroup/create',
      resetTemplate: 'productGroup/resetTemplate',
      getProductGroup: 'productGroup/getItem',
    }),
    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getProductGroup(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate();
      }
    },

    async onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.$route.params.ItemId) {
        if (await this.update(this.productGroup)) {
          this.onClose();
        }
      } else if (await this.create(this.productGroup)) {
        this.onClose();
      }
    },

    onClose() {
      this.$router.push({ name: 'storage-items-list' });
    },
  },
};
</script>
