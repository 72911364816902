<template>
  <th class="pt-2 pb-1 px-0">
    <p
      v-if="!header.text || !header.text.length"
      style="min-width: 50px"
    />
    <template v-else>
      <template
        v-if="header.searchable === undefined || header.searchable === true"
      >
        <v-badge
          :value="Boolean(sort.order)"
          :content="sort.order"
          offset-x="22px"
          offset-y="14px"
        >
          <v-text-field
            v-if="!header.type || header.type === 'text'"
            type="text"
            :value="filter.value"
            :label="header.text"
            :append-outer-icon="sort.icon"
            dense
            :clearable="![null, false].includes(header.clearable)"
            hide-details
            class="header-sort px-4"
            @click:append-outer="onToggleSort"
            @input="v => onFilter(v)"
          />
          <c-number
            v-if="header.type === 'number'"
            :fixed="header.fixed"
            :value="filter.value"
            :label="header.text"
            :append-outer-icon="sort.icon"
            dense
            :clearable="![null, false].includes(header.clearable)"
            :nullable="![null, false].includes(header.clearable)"
            hide-details
            class="header-sort px-4"
            @click:append-outer="onToggleSort"
            @input="v => onFilter(v)"
          />
          <template v-if="header.type === 'select'">
            <v-autocomplete
              v-if="!options.action"
              :value="filter.value"
              :items="options.items"
              :item-text="options.itemText"
              :item-value="options.itemValue"
              :label="header.text"
              :append-outer-icon="sort.icon"
              aria-autocomplete="off"
              dense
              :clearable="![null, false].includes(header.clearable)"
              hide-details
              class="header-sort px-4"
              @click:append-outer="onToggleSort"
              @input="v => onFilter(v, true)"
            />
            <v-autocomplete
              v-if="options.action"
              :value="filter.value"
              :items="options.items"
              :item-text="options.itemText"
              :item-value="options.itemValue"
              :label="header.text"
              :append-outer-icon="sort.icon"
              :search-input.sync="filterItemsSearch"
              :loading="options.loading"
              cache-items
              aria-autocomplete="off"
              dense
              :clearable="![null, false].includes(header.clearable)"
              hide-no-data
              hide-details
              class="header-sort px-4"
              @update:search-input="onFilterItemsSearch"
              @click:append-outer="onToggleSort"
              @input="v => onFilter(v, true)"
            />
          </template>
          <v-menu
            v-if="header.type === 'date'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template #activator="{ on }">
              <v-text-field
                :value.sync="dateRangeText"
                :label="header.text"
                dense
                readonly
                hide-details
                class="px-4"
                :clearable="![null, false].includes(header.clearable)"
                append-icon="mdi-calendar"
                v-on="on"
                @click:append="toggleMenu()"
                @click:clear="onClearDate"
              />
            </template>
            <v-date-picker
              v-model="dates"
              range
              class="elevation-0"
              no-title
              color="blue"
              locale="nl"
              :first-day-of-week="1"
              :min="options.start.min"
              :max="options.start.max"
              @change="onFilter(formatDate, true); toggleMenu(dates)"
            />
          </v-menu>
        </v-badge>
      </template>

      <v-badge
        v-else
        :value="Boolean(sort.order)"
        :content="sort.order"
        offset-x="22px"
        offset-y="14px"
      >
        <v-text-field
          :value="header.text"
          dense
          class="header-sort px-4"
          readonly
          hide-details
          :append-outer-icon="sort.icon"
          @click:append-outer="onToggleSort"
        />
      </v-badge>
    </template>
  </th>
</template>

<script>
export default {
  name: 'CVuexListHedaer',

  props: {
    header: {
      type: Object,
      required: true,
      description:
        '{text: String, value: requiredUniqueString, sortable: Boolean, clearable: Boolean}',
    },

    options: {
      type: [Array, Object],
      default: null,
      description:
        '{start: {min: Date, max: Date}, end: {min: Date, max: Date}} for header.type === date. autocomplete-options for header.type === select',
    },

    list: {
      type: Object,
      required: true,
      description: 'Vuex Module`s list getter',
    },
  },

  data: () => ({
    dates: [],

    menu: false,

    filterItemsSearch: null,
    filterItems: [],

    filterTimeout: null,
  }),

  computed: {
    formatDate() {
      const { dates } = this;
      dates.sort();
      return {
        start: this.$moment(dates[0]).startOf('day'),
        end: this.$moment(dates[1]).endOf('day'),
      };
    },
    maxOrder() {
      return Math.max(Object.values(this.list.filters)
        .filter(({ sort }) => sort)
        .map(({ sort }) => sort.order)) + 1;
    },

    dateRangeText() {
      return this.dates.join(' ~ ');
    },

    filter() {
      return this.list.filters[this.header.value] || { value: null };
    },

    sort() {
      if (this.header.sortable === false) {
        return {};
      }

      const { sort } = this.filter;

      if (!sort) {
        return { order: null, icon: 'mdi-circle-small' };
      }

      if (sort.asc) {
        return { order: this.mOrder(sort.order), icon: 'mdi-menu-down' };
      }

      if (!sort.asc) {
        return { order: this.mOrder(sort.order), icon: 'mdi-menu-up' };
      }

      throw new Error('Invalid sorted.value');
    },
  },

  methods: {
    onFilterItemsSearch() {
      clearTimeout(this.filterItemsTimeout);
      this.filterItemsTimeout = setTimeout(
        () => {
          const filter = {};
          if (this.filterItemsSearch && this.filterItemsSearch.length) {
            filter.Name = { value: this.filterItemsSearch, type: 'text' };
            this.options.action(filter);
          }
        }, 2000,
      );
    },
    onClearDate() {
      this.$set(this, 'dates', []);
      this.onFilter(null, true);
    },
    mOrder(order) {
      return Object.values(this.list.filters)
        .filter(({ sort }) => sort && sort.order)
        .map(({ sort }) => sort.order)
        .sort()
        .findIndex((i) => i === order) + 1;
    },

    toggleMenu(dates = [this.filter?.value?.start, this.filter?.value?.end]) {
      this.menu = !this.menu;
      this.dates = dates;
    },
    onToggleSort() {
      let { sort } = this.filter;
      if (!sort) {
        sort = {
          asc: true,
          order: this.maxOrder,
        };
      } else {
        sort = sort.asc
          ? {
            asc: false,
            order: sort.order,
          }
          : undefined;
      }

      clearTimeout(this.filterTimeout);
      this.$emit('input', { ...this.filter, sort });
    },

    onFilter(filterValue, immediate) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(
        () => this.$emit('input', { ...this.filter, value: filterValue, type: this.header.type }), immediate ? 1 : 2000,
      );
    },
  },
};
</script>
<style lang="scss">
.header-sort {
  .v-input__append-outer {
    margin-bottom: 0px;
  }
}
</style>
