import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    InvoiceId: null,
    MatrixId: null,

    Type: null,

    Name: '',
    Description: '',
    Description2: '',

    PriceUnit: 0.00,

    Quantity: 0.00,
    Multiplier: 0,
    Unit: null,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
