var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{class:{ 'pt-2': !_vm.loading },attrs:{"outlined":"","disabled":_vm.loading,"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('c-vuex-list',{ref:"list",staticClass:"complex-footer",attrs:{"headers":_vm.headers,"get-list":_vm.getList,"list":_vm.list,"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer-prepend",fn:function(){return [_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.onUpdateStatusFilter},model:{value:(_vm.dStatus),callback:function ($$v) {_vm.dStatus=$$v},expression:"dStatus"}},[_c('v-btn',{attrs:{"small":"","value":null}},[_vm._v(" "+_vm._s(_vm.$t("text:all"))+" ")]),_vm._l((_vm.statuses),function(ref,index){
var text = ref.text;
var value = ref.value;
return _c('v-btn',{key:("status-" + index),attrs:{"width":"auto","small":"","value":value}},[_vm._v(" "+_vm._s(text)+" ")])})],2)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.ItemId)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.CustomerReference)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Debtor ? item.Debtor.Name : '-')+" ")]),_c('td',[_vm._v(_vm._s(_vm.mQuantity(item)))]),_c('td',_vm._l((_vm.mPlannedDockings(item)),function(ref,index){
var ItemId = ref.ItemId;
var PlannedStart = ref.PlannedStart;
return _c('v-btn',{key:(index + "-planned-docking-time"),staticClass:"ma-1",attrs:{"x-small":"","color":"blue","outlined":"","disabled":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-map-marker-distance ")]),_vm._v(" #"+_vm._s(ItemId)+" | "+_vm._s(PlannedStart)+" ")],1)}),1)])]}}])})],2)],1)],1),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }