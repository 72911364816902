import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    Name: '',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
  WarehouseId: null,
};
