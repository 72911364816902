<template>
  <div
    v-if="file"
    :style="{width: img ? cWidthPhoto : 'inherit'}"
  >
    <v-btn-toggle
      v-if="!img"
      rounded
      borderless
      background-color="default"
      class="d-flex align-center"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            v-if="download"
            small
            icon
            link
            :href="s3(file.Key, true)"
            v-on="on"
          >
            <v-icon class="ml-2">
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        {{ $t('label:download') }}
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            v-if="open"
            icon
            link
            small
            target="_blank"
            :href="s3(file.Key, false)"
            v-on="on"
          >
            <v-icon class="ml-2">
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </template>
        {{ $t('label:open_in_tab') }}
      </v-tooltip>
      <span
        class="text-truncate px-2"
        style="max-width:200px"
      >
        {{ cName }}
      </span>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            v-if="deletable"
            icon
            small
            v-on="on"
            @click="onDelete()"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        {{ $t('label:delete') }}
      </v-tooltip>
    </v-btn-toggle>

    <v-card
      v-if="img"
      class="ma-1"
    >
      <v-img
        :src="s3(file.Key)"
        aspect-ratio="1"
      >
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
        >
          <v-spacer />

          <v-btn-toggle
            rounded
            borderless
            background-color="default"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-if="download"
                  :small="$vuetify.breakpoint.mdAndUp"
                  icon
                  link
                  :href="s3(file.Key, true)"
                  v-on="on"
                >
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              {{ $t('label:download') }}
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-if="open"
                  icon
                  link
                  :small="$vuetify.breakpoint.mdAndUp"
                  target="_blank"
                  :href="s3(file.Key, false)"
                  v-on="on"
                >
                  <v-icon>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </template>
              {{ $t('label:open_in_tab') }}
            </v-tooltip>

            <v-menu
              v-if="expandable"
              :close-on-content-click="false"
              origin="center center"
              transition="scale-transition"
              left
            >
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      icon
                      :small="$vuetify.breakpoint.mdAndUp"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>
                        mdi-arrow-expand-all
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('label:expand') }} </span>
                </v-tooltip>
              </template>
              <v-card>
                <v-img
                  :src="s3(file.Key)"
                  min-width="500px"
                  max-width="1000px"
                  max-height="1000px"
                  contain
                >
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-menu>

            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-if="deletable"
                  icon
                  :small="$vuetify.breakpoint.mdAndUp"
                  v-on="on"
                  @click="onDelete()"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('label:delete') }}
            </v-tooltip>
          </v-btn-toggle>
        </v-app-bar>
      </v-img>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'CFile',
  props: {
    file: {
      type: Object,
      required: true,
      description: 'AWS SDK: S3 upload-result-inherited object like Document or Attachment',
    },
    download: {
      type: Boolean,
      default: false,
      description: 'If true - enables download.',
    },
    open: {
      type: Boolean,
      default: false,
      description: 'If true - enables open in new tab.',
    },
    deletable: {
      type: Boolean,
      default: false,
      description: 'If true - allows file deletion.',
    },
    classes: {
      type: String,
      default: null,
      description: 'String with CSS classes',
    },
    name: {
      type: String,
      default: null,
      description: 'If set - used as FileName',
    },
    img: {
      type: Boolean,
      default: false,
      description: '',
    },
    expandable: {
      type: Boolean,
      default: false,
      description: '',
    },
    preview: {
      type: Boolean,
      default: false,
      description: '',
    },
  },
  computed: {
    cName() {
      return this.name || this.file.FileName || this.file.Key.substring(this.file.Key.lastIndexOf('/') + 1);
    },

  },
  methods: {
    onDelete() {
      if (this.deletable) {
        this.$emit('delete', this.file);
      }
    },
  },
};
</script>
