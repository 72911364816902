var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{class:{'pt-2': !_vm.loading},attrs:{"outlined":"","disabled":_vm.loading,"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('c-vuex-list',{attrs:{"headers":_vm.headers,"get-list":_vm.getList,"list":_vm.list,"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer-prepend",fn:function(){return [_c('v-btn',{staticClass:"width-auto",attrs:{"small":"","color":"primary darken-1","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onForm('Inbound')}}},[_vm._v(" "+_vm._s(_vm.$t('label:inbound', null, 1))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{staticClass:"width-auto ml-2",attrs:{"small":"","color":"primary darken-1","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onForm('Outbound')}}},[_vm._v(" "+_vm._s(_vm.$t('label:outbound', null, 1))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-plus ")])],1),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.onUpdateStatusFilter},model:{value:(_vm.dStatus),callback:function ($$v) {_vm.dStatus=$$v},expression:"dStatus"}},[_c('v-btn',{attrs:{"small":"","value":null}},[_vm._v(" "+_vm._s(_vm.$t('text:all'))+" ")]),_vm._l((_vm.statuses),function(ref,index){
var text = ref.text;
var value = ref.value;
return _c('v-btn',{key:("status-" + index),attrs:{"width":"auto","small":"","value":value}},[_vm._v(" "+_vm._s(text)+" ")])})],2)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onForm(item.Type, item.ItemId)}}},[_c('v-icon',[_vm._v(" mdi-open-in-app ")])],1)],1),_c('td',[_vm._v(" "+_vm._s(item.ItemId)+" ")]),_c('td',[_vm._v(_vm._s(item.TmsId))]),_c('td',[_vm._v(_vm._s(_vm.$t(item.Type)))]),_c('td',[_vm._v(_vm._s(item.Dock.Name))]),_c('td',[_vm._v(_vm._s(_vm.mQuantity(item)))]),_c('td',[_vm._v(_vm._s(item.Serial))]),_c('td',[_vm._v(_vm._s(item.DriverName))]),_c('td',[_vm._v(" "+_vm._s(item.PlannedStart ? _vm.$moment(item.PlannedStart).format("DD-MM-YYYY") : '-')+" ")]),_c('td',[(['New', 'Planned'].includes(item.Status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 float-right",attrs:{"icon":""},on:{"click":function($event){return _vm.onDelete(item)}}},on),[_c('v-icon',{staticClass:"delete-icon"},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:delete'))+" ")]):_vm._e()],1)])]}}])})],2)],1)],1),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }