import SystemPage from '@/layouts/Application.vue';

import Dock from './dock';
import Consumable from './consumable';
import Resource from './resource';

export default [
  {
    path: '/system',
    component: SystemPage,
    children: [
      ...Consumable,
      ...Resource,
      ...Dock,
    ],
  },
];
