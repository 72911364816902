import Vue from 'vue';
import { getInstance } from '@/auth';
import defaultActions, { api } from '@/store/defaultActions';

export default {
  ...defaultActions,

  /**
   * Get items list from DB and return them w/o vuex store involvement
   */
  async returnItems({ getters, commit }, params) {
    try {
      commit('SET_LOADING', true);
      const { data } = await Vue.axios.get(`${api}${getters.namespace}/users`, { params });
      commit('SET_LOADING', false);
      return data.items;
    } catch (e) {
      commit('SET_LOADING', false);
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
      return [];
    }
  },

  wsEvent: (vuex, data) => {
    if (data.action !== 'LOGOUT') {
      return;
    }
    const auth = getInstance();
    if (auth.user.sub !== data.payload.data.user_id) {
      return;
    }
    auth.logout();
  },
};
