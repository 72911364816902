var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"align-self":"center","width":"100vw","position":"absolute"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-select',{staticClass:"mx-auto",staticStyle:{"max-width":"300px"},attrs:{"value":_vm.WarehouseId,"placeholder":_vm.$t('label:warehouse_select'),"loading":_vm.warehouseLoading || _vm.loading,"disabled":_vm.warehouseLoading || _vm.loading,"dense":"","solo":"","single-line":"","light":"","rounded":"","background-color":hover ? '#cccccc' : '#dddddd',"hide-details":"","item-text":"Name","item-value":"ItemId","items":_vm.warehouses,"menu-props":{ bottom: true,
                     offsetY: true,
                     rounded: 'lg b-xl',
                     transition: 'slide-y-transition' },"clearable":""},on:{"input":_vm.onChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                     var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }