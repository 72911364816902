<template>
  <v-col
    md="3"
    class="pl-0 pr-2"
    :class="{ collapsed: collapse,
              expanded: !collapse }"
  >
    <v-card
      class="pa-2"
      :class="{ 'd-flex': collapse }"
      color="#f5f5f5"
      elevation="0"
      outlined
      min-height="100%"
      height="inherit"
    >
      <div
        v-if="!collapse"
        :class="{ 'd-none': collapse }"
      >
        <v-autocomplete
          :value="template.Type"
          :label="$t('label:type', null, 1)"
          :items="types"
          :rules="mnValidation.required"
          disabled
          aria-autocomplete="off"
        />
        <v-autocomplete
          :value="template.WarehouseId"
          :disabled="Boolean(template.Orderlines.length)"
          :label="$t('label:warehouse', null, 1)"
          :items="warehouses"
          :rules="mnValidation.required"
          item-text="Name"
          item-value="ItemId"
          :loading="warehouseLoading"
          aria-autocomplete="off"
          @input="onChangeWarehouseId"
        />
        <v-autocomplete
          v-model="template.DockId"
          :label="$t('label:dock', null, 1)"
          :items="docks"
          :rules="mnValidation.required"
          item-text="Name"
          item-value="ItemId"
          :loading="dockLoading"
          aria-autocomplete="off"
          :disabled="dockLoading"
        />

        <v-text-field
          v-model="template.TmsId"
          :label="$t('label:slim_id', null, 1)"
          aria-autocomplete="off"
        />
        <v-text-field
          v-model="template.Serial"
          :label="$t('label:serial', null, 1)"
          aria-autocomplete="off"
        />
        <v-text-field
          v-model="template.DriverName"
          :label="$t('label:driver', null, 1)"
          aria-autocomplete="off"
        />
        <v-row dense>
          <v-col>
            <v-menu
              v-model="menu.PlannedStart"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="mDate('PlannedStart')"
                  :label="$t('label:planned_start', null, 1)"
                  append-icon="mdi-calendar"
                  readonly
                  :tabindex="4"
                  :disabled="after('Planned')"
                  v-bind="attrs"
                  v-on="on"
                  @click:append="() => menu.PlannedStart = true"
                />
              </template>
              <v-date-picker
                v-model="template.PlannedStart"
                no-title
                locale="nl"
                :min="$moment().format('YYYY-MM-DD')"
                @input="onChangeStart"
              />
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="menu.PlannedEnd"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="mDate('PlannedEnd')"
                  :label="$t('label:planned_end', null, 1)"
                  append-icon="mdi-calendar"
                  readonly
                  :disabled="after('Planned')"
                  :tabindex="4"
                  v-bind="attrs"
                  @click:append="() => menu.PlannedEnd = true"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="template.PlannedEnd"
                no-title
                locale="nl"
                :min="$moment(template.PlannedStart || new Date()).format('YYYY-MM-DD')"
                @input="menu.PlannedEnd = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              :value="mDate('Start')"
              :label="$t('label:start', null, 1)"
              readonly
              disabled
              :tabindex="4"
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="mDate('End')"
              :label="$t('label:end', null, 1)"
              readonly
              :tabindex="4"
              disabled
            />
          </v-col>
        </v-row>
      </div>
      <div class="ma-auto">
        <h4
          v-if="collapse && $vuetify.breakpoint.lgAndDown"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t('btn:basic_data') }}
        </h4>
        <h1
          v-if="collapse && $vuetify.breakpoint.xlOnly"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:main") }}
        </h1>
      </div>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-avatar
            size="24px"
            v-bind="attrs"
            style="top: -12px; right: -9px; position: absolute;"
            class="info-color-hover pointer-on-hover"
            v-on="on"
            @click="$emit('collapse', !collapse)"
          >
            <v-icon
              small
              class="white--text"
            >
              {{ !collapse ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
          </v-avatar>
        </template>
        <span v-if="!collapse">{{ $t("text:hide_customer_data") }}</span>
        <span v-else>{{ $t("text:show_customer_data") }}</span>
      </v-tooltip>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DockingMain',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    menu: {
      PlannedStart: false,
      PlannedEnd: false,
      Start: false,
      End: false,
    },
  }),

  computed: {
    ...mapGetters({
      dockLoading: 'docking/loading',
      template: 'docking/template',

      warehouseLoading: 'warehouse/loading',
      warehouses: 'warehouse/items',

      dockItems: 'dock/items',
    }),

    types() {
      return [
        {
          value: 'Inbound',
          text: this.$t('label:inbound'),
        },
        {
          value: 'Outbound',
          text: this.$t('label:outbound'),
        },
      ];
    },

    docks() {
      return this.dockItems.filter(
        (a) => (!a.deletedAt && a.Active) || a.ItemId === this.template.DockId,
      );
    },
  },

  methods: {
    ...mapActions({
      resetTemplate: 'docking/resetTemplate',
    }),
    onChangeStart(PlannedStart) {
      this.resetTemplate({
        ...this.template,
        PlannedStart,
        PlannedEnd: PlannedStart,
      });
      this.menu.PlannedStart = false;
    },
    mDate(prop) {
      if (!this.template[prop]) {
        return 'DD-MM-YYYY';
      }
      return this.$moment(this.template[prop]).format('DD-MM-YYYY');
    },
    onChangeWarehouseId(WarehouseId) {
      this.resetTemplate({ ...this.template, WarehouseId });
    },
    after(status) {
      const weight = {
        Planned: 1,
        'Check-in': 2,
        Handling: 3,
        Done: 4,
      };

      return weight[this.template.Status] > weight[status];
    },
  },
};
</script>
