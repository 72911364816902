import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    ProducgtBundleId: null,

    CarrierId: null,
    PackageId: null,

    Quantity: null,
    Sequence: null,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
