/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import io from 'socket.io-client';
import { AuthReady } from '../auth';

export default {
  async install(Vue, options) {
    await AuthReady();

    const crmWs = io(getHost(), {
      extraHeaders: {
        authorization: Vue.axios.defaults.headers.common.Authorization,
      },
      secure: true,
      rejectUnauthorized: false,
    });

    crmWs.on('connect', () => { console.log('connected to crm ws'); });
    crmWs.on('disconnect', () => { console.log('disconnected from crm ws'); });
    // crmWs.on('connect_error', (data) => { console.error('crm ws err:', data); });

    crmWs.onevent = (packet) => {
      const event = packet.data[0].split('_');

      // eslint-disable-next-line no-underscore-dangle
      if (Object.keys(options.store._actions).findIndex((key) => key === `${event[1].toLowerCase()}/wsEvent`) === -1) {
        return;
      }

      options.store.dispatch(`${event[1].toLowerCase()}/wsEvent`, {
        action: event[0],
        payload: packet.data[1],
      });
    };

    const wmsWs = io(getHost(true), {
      extraHeaders: {
        authorization: Vue.axios.defaults.headers.common.Authorization,
      },
      secure: true,
      rejectUnauthorized: false,
    });

    wmsWs.on('connect', () => { console.log('connected to vdl ws'); });
    wmsWs.on('disconnect', () => { console.log('disconnected from vdl ws'); });
    wmsWs.on('connect_error', (data) => { console.error('vdl ws err:', data); });

    wmsWs.oldOnevent = wmsWs.onevent;

    wmsWs.onevent = (packet) => {
      if (!packet.data[1].noVuex) {
        const event = packet.data[0].split('_');
        options.store.dispatch(`${packet.data[1].cName ? packet.data[1].cName : event[1].toLowerCase()}/wsEvent`, {
          action: event[0],
          payload: packet.data[1],
        });
      } else {
        wmsWs.oldOnevent(packet);
      }
    };

    Vue.liveUpdates = {
      CRM: crmWs,
      WMS: wmsWs,
    };
  },
};

function getHost(own = false) {
  if (!window.location.hostname) {
    return false;
  }
  if (own) {
    return `https://${window.location.hostname}:3000/ws/live-update`;
  }

  const host = window.location.hostname.split('.');
  if (host[0] !== 'localhost') {
    return `https://${host[0]}.${host[1].split('-')[2] ? 'crm-accp' : 'crm'}.fitsoft.eu:3000/ws/live-update`;
  }
  // return 'https://localhost:3001/ws/live-update';
  return 'https://vdl.crm-accp.fitsoft.eu:3000/ws/live-update';
}
