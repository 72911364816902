<template>
  <v-form
    ref="form"
    color="#dddddd"
  >
    <v-row
      class="ma-0 flex-nowrap"
      dense
      align="center"
    >
      <v-col style="width: calc(100% - 13ch - 44px);">
        <c-auth-select
          :disabled="disable.main"
          :value="value.ExecutorAuthSub"
          :label="$t('label:executor', null, 1)"
          :rules="mnValidation.required"
          :p-items="movers"
          @input="v => onInput('ExecutorAuthSub', v)"
        />
      </v-col>
      <v-col cols="auto">
        <c-number
          :disabled="disable.main"
          :value="value.Duration"
          :rules="mnValidation.required"
          :label="$t('label:duration', null, 1)"
          class="width-10ch"
          :suffix="$t('label:minute', null, 2)"
          @input="v => onInput('Duration', Math.abs(v))"
        />
      </v-col>
      <v-col cols="auto">
        <v-tooltip
          v-if="!disable.main"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="onDelete"
            >
              <v-icon
                class="delete-icon"
              >
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </template>
          {{ $t('label:delete') }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { getInstance } from '@/auth/index';
import { mapActions } from 'vuex';

export default {
  name: 'UsageExecutor',

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex][TransactionType][UsageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
  },

  data: () => ({
    movers: [],
  }),

  computed: {
    value() {
      if (this.path.length === 2) {
        return this.template[this.path[0]].ExecutorUsages[this.path[1]];
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
        .ExecutorUsages[this.path[4]];
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      userReturnItems: 'user/returnItems',
    }),

    async initialize() {
      const { items } = await getInstance().getUsers('WMS.Mover');
      this.movers = items;
    },

    onInput(p, v) {
      if (this.path.length === 2) {
        const { ExecutorUsages } = this.template[this.path[0]];
        ExecutorUsages[this.path[1]][p] = v;
        this.reset({
          ...this.template,
          ExecutorUsages,
        });
      } else {
        const { Orderlines } = this.template;

        Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
          .ExecutorUsages[this.path[4]] = ({ ...this.value, [p]: v });

        this.reset({
          ...this.template,
          Orderlines,
        });
      }
    },

    onDelete() {
      if (this.path.length === 2) {
        const { ExecutorUsages } = this.template[this.path[0]];
        ExecutorUsages.splice(this.path[1], 1);
        this.reset({
          ...this.template,
          ExecutorUsages,
        });
      } else {
        const { Orderlines } = this.template;

        Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
          .ExecutorUsages.splice(this.path[4], 1);

        this.reset({
          ...this.template,
          Orderlines,
        });
      }
    },
  },
};
</script>
