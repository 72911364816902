import foreach from 'lodash/forEach';
import Vue from 'vue';

export default {
  SET_LOADING(state, v) {
    state.loading = v;
  },

  SET_LOADING_LIST(state, v) {
    state.loadingList = v;
  },

  RESET_TEMPLATE(state, template) {
    Vue.set(state, 'template', template);
  },

  SET_ITEMS(state, v) {
    Vue.set(state, 'items', v);
  },

  SET_LIST(state, v) {
    Vue.set(state, 'list', v);
  },

  /**
   * Update resource in the store. Values to update to be passed as a object
   */
  UPDATE_ITEM(state, data) {
    // Get the item to update
    const index = state.items.findIndex((i) => i.ItemId === data.ItemId);
    if (index < 0) {
      // insert if not found (case when item pre-retreived directly, like giveaway Tasks)
      state.items.push(data);
    } else {
      foreach(data, (value, key) => {
        if (key !== 'ItemId') {
          Vue.set(state.items[index], key, value);
        }
      });
    }
  },

  /**
   * Store a new resource in the store
   */
  STORE(state, item) {
    if (item.ItemId && state.items.findIndex((i) => i.ItemId === item.ItemId) === -1) {
      state.total += 1;
      state.items.push(item);
    }
  },

  /**
   * Remove resource from store
   */
  DESTROY(state, ItemId) {
    Vue.delete(state.items, state.items.findIndex((i) => i.ItemId === ItemId));
    state.total -= 1;
  },

  /**
   * Websocket events
   */
  WS_UPDATE(state, data) {
    const index = state.items.findIndex((i) => i.ItemId === data.ItemId);

    if (index !== -1) {
      Vue.set(state.items, index, { ...state.items[index], ...data });
    }
  },

  WS_CREATE(state, data) {
    const item = state.items.findIndex((i) => i.ItemId === data.ItemId);

    if (item === -1) {
      state.total += 1;
      state.items.push(data);
    }
  },

  WS_DELETE(state, data) {
    const item = state.items.findIndex((i) => i.ItemId === data.ItemId);
    if (item === -1) {
      return;
    }
    if (data.deleted !== undefined) {
      Vue.set(state.items, item, data);
    } else {
      Vue.delete(state.items, item);
    }
  },
};
