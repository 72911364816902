<template>
  <transition name="slide-fade">
    <v-row>
      <v-col>
        <v-card
          outlined
          :disabled="loading"
          :loading="loading"
          :class="{'pt-2': !loading}"
        >
          <template slot="progress">
            <progress-bar />
          </template>
          <c-vuex-list
            :headers="headers"
            :get-list="getList"
            :list="list"
            :loading="loading"
          >
            <template #footer-prepend>
              <v-btn
                small
                color="primary darken-1"
                class="width-auto"
                @click.stop="onForm()"
              >
                {{ $t("label:product_serie", null, 1) }}
                <v-icon
                  small
                  right
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <template #item="{ item }">
              <tr
                :class="{ itemSelected: mSelected(item.ItemId),
                          primary: mSelected(item.ItemId) }"
                @click.stop="onSelect(item)"
              >
                <td>
                  <v-btn
                    icon
                    @click.stop="onForm(item.ItemId)"
                  >
                    <v-icon
                      :disabled="loading"
                    >
                      mdi-open-in-app
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ mCustomer(item.CustomerId) }}
                </td>
                <td>
                  {{ item.Name }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        class="mr-2 float-right"
                        v-on="on"
                        @click="onDelete(item)"
                      >
                        <v-icon
                          class="delete-icon"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('label:delete') }}
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </c-vuex-list>
        </v-card>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductGroupIndex',

  computed: {
    ...mapGetters({
      loading: 'productGroup/loadingList',
      list: 'productGroup/list',
      customers: 'customer/items',

      productGroupTemplate: 'productGroup/template',
      productTemplate: 'product/template',
    }),
    headers() {
      return [
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
        {
          text: this.$t('label:customer', null, 1),
          value: 'CustomerId',
          clearable: true,
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:name', null, 1),
          value: 'Name',
        },
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getList: 'productGroup/getList',

      resetProductGroupTemplate: 'productGroup/resetTemplate',
      resetProductTemplate: 'product/resetTemplate',

      deleteItem: 'productGroup/destroy',
    }),

    mSelected(ItemId) {
      return this.productGroupTemplate?.ItemId === ItemId;
    },

    onSelect(item) {
      if (item.ItemId !== this.productGroupTemplate?.ItemId) {
        this.resetProductGroupTemplate(item);
        this.resetProductTemplate(null);
      }
    },

    onForm(ItemId) {
      this.$router.push({
        name: 'storage-items-product-group-form',
        params: { ItemId },
      });
    },

    mCustomer(CustomerId) {
      const customer = this.customers.find(
        ({ ItemId }) => ItemId === CustomerId,
      );
      return customer ? customer.Name : '-';
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
