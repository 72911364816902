<template>
  <v-card
    class="mb-1 w-100"
  >
    <v-row
      v-if="invoiceline"
      class="ma-0"
      align="center"
      dense
    >
      <v-col>
        <div class="d-flex align-center">
          <v-text-field
            v-model="invoiceline.Name"
            hide-details
          />
        </div>
      </v-col>
      <v-col
        xl="2"
        md="2"
        align-self="end"
      >
        <span class="clipped-line">
          {{ invoiceline.Type }}
        </span>
      </v-col>
      <v-col
        xl="1"
        md="1"
      >
        <c-number
          v-model="invoiceline.Quantity"
          :fixed="0"
          :min="1"
        />
      </v-col>
      <v-col
        cols="auto"
      >
        <c-number
          v-model="invoiceline.PriceUnit"
          :fixed="2"
          :min="0"
          currency
          class="currency-input"
          style="width: 140px"
        />
      </v-col>
      <v-col cols="auto">
        <div
          class="d-flex justify-end"
        >
          <c-number
            :value="invoiceline.PriceUnit * invoiceline.Quantity"
            :fixed="2"
            currency
            class="currency-input"
            style="width: 140px"
            disabled
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col>
        <v-btn
          v-if="invoice.Status === 'New'"
          :disabled="cDisabled"
          @click="onAdd"
        >
          {{ $t('btn:add') }}
          <v-icon right>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'InvoicelineMain',

  computed: {
    ...mapGetters({
      invoice: 'invoice/template',
      invoiceline: 'invoiceline/template',
      carrierItems: 'carrier/items',
    }),
    cDisabled() {
      return !this.invoice.CustomerId
       || !this.invoiceline.Quantity || this.invoiceline.Name.length < 3;
    },
  },

  beforeMount() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      resetTemplate: 'invoiceline/resetTemplate',
    }),
    initialize() {
      this.resetTemplate({
        Quantity: 1,
        Multiplier: 1,
        Type: 'Custom',
        CustomerId: this.invoice.CustomerId,
      });
    },
    onAdd() {
      this.$emit('add', { ...this.invoiceline });
      this.initialize();
    },
  },
};
</script>
