import Resources from '@/components/views/system/Resources.vue';

import Index from '@/components/resource/index.vue';

import resourceForm from '@/components/resource/form.vue';

export default [
  {
    path: '/system/resource/list',
    component: Resources,
    meta: {
      restriction: ['WMS.Admin'],
    },

    children: [
      {
        name: 'system-resource-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Admin'] },
        children: [
          {
            name: 'system-resource-form',
            path: '/system/resource/form/:ItemId?',
            component: resourceForm,
            meta: { restriction: ['WMS.Admin'] },
          },
        ],
      },
    ],
  },
];
