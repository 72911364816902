<template>
  <v-chip
    :color="color"
    pill
    :label="label"
    :class="label ? '' : 'px-0'"
    class="w-100 pointer-on-hover"
  >
    <div class="d-flex align-center">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-avatar v-on="cTooltip ? on : false">
            <img
              :src="s3(user.picture)"
              :alt="user.email"
            >
          </v-avatar>
        </template>
        {{ user.email }}
      </v-tooltip>
      <span
        class="mx-2"
      >{{ cName }}</span>
    </div>
  </v-chip>
</template>

<script>

export default {
  name: 'UserPreview',
  props: {
    user: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: 'brand',
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cName() {
      return this.user.name && this.user.name.length ? this.user.name : this.user.email;
    },
    cClass() {
      return this.pill ? 'pl-0' : '';
    },
    cTooltip() {
      return this.user.name && this.user.name.length && this.user.name !== this.user.email;
    },
  },
};
</script>
