var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"headers":_vm.headers,"no-data-text":_vm.$t('label:no_entries'),"no-results-text":_vm.$t('label:no_entries_found'),"items":_vm.list.items,"options":_vm.cOptions,"server-items-length":_vm.list.total,"footer-props":{
    itemsPerPageText: '',
    itemsPerPageOptions: [10, 50, 100],
    pageText: '{0}-{1} of {2}',
    showFirstLastPage: true,
    firstIcon: 'mdi-chevron-double-left',
    lastIcon: 'mdi-chevron-double-right',
    prevIcon: 'mdi-chevron-left',
    nextIcon: 'mdi-chevron-right',
    itemsPerPageText: _vm.$t('label:rows_per_page'),
  },"hide-default-header":""},on:{"update:options":_vm.onUpdateOptions},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.cHeaders),function(header,index){return _c('c-header',{key:("th-" + index),attrs:{"header":header,"options":header.options,"list":_vm.list},on:{"input":function (filter) { return _vm.onUpdateFilter(header.value, filter); }}})}),1)])]},proxy:true},{key:"footer.prepend",fn:function(){return [_vm._t("footer-prepend"),_c('v-spacer'),(_vm.onExport)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.onExport}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-export ")])],1)]}}],null,false,2045468015)},[_vm._v(" "+_vm._s(_vm.$t('text:export:page'))+" ")]):_vm._e(),(_vm.refreshable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.onRefresh}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1)]}}],null,false,2766880783)},[_vm._v(" "+_vm._s(_vm.$t('text:force_update'))+" ")]):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }