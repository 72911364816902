import Vue from 'vue';
import defaultActions, { api } from '@/store/defaultActions';

export default {
  ...defaultActions,

  async invoice(
    { commit, getters },
    {
      from,
      to,
    },
  ) {
    commit('SET_LOADING', true);
    try {
      await Vue.axios.post(`${api}${getters.namespace}/invoice`, { from, to });
    } catch (e) {
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
    }
    commit('SET_LOADING', false);
  },
};
