import MovementPage from '@/layouts/Application.vue';

import InboundOrder from './inboundOrder';
import OutboundOrder from './outboundOrder';
import TransformationOrder from './transformationOrder';
import Docking from './docking';

export default [
  {
    path: 'Movement',
    component: MovementPage,
    children: [
      ...InboundOrder,
      ...OutboundOrder,
      ...TransformationOrder,
      ...Docking,
    ],
  },
];
