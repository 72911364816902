import Clusters from '@/components/views/storage/Clusters.vue';

import ClusterForm from '@/components/cluster/form.vue';

export default [
  {
    name: 'storage-cluster-list',
    path: '/storage/cluster/list',
    component: Clusters,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'storage-cluster-form',
        path: '/storage/cluster/form/:ItemId?',
        component: ClusterForm,
        meta: { restriction: ['WMS.Manager'] },
      },
    ],
  },
];
