import Consumables from '@/components/views/system/Consumables.vue';

import Index from '@/components/consumable/index.vue';

import consumableForm from '@/components/consumable/form.vue';

export default [
  {
    path: '/system/consumable/list',
    component: Consumables,
    meta: {
      restriction: ['WMS.Admin'],
    },

    children: [
      {
        name: 'system-consumable-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Admin'] },
        children: [
          {
            name: 'system-consumable-form',
            path: '/system/consumable/form/:ItemId?',
            component: consumableForm,
            meta: { restriction: ['WMS.Admin'] },
          },
        ],
      },
    ],
  },
];
