<template>
  <v-col
    md="3"
    class="pl-0 pr-2"
    :class="{ collapsed: collapse,
              expanded: !collapse }"
  >
    <v-card
      class="pa-2"
      :class="{ 'd-flex': collapse }"
      color="#f5f5f5"
      elevation="0"
      outlined
      min-height="100%"
      height="inherit"
    >
      <div
        v-if="!collapse"
        :class="{ 'd-none': collapse }"
      >
        <v-autocomplete
          v-model="template.CustomerId"
          :disabled="Boolean(template.Orderlines.length) || after('New')"
          :label="$t('label:customer', null, 1)"
          :items="customers"
          aria-autocomplete="off"
          item-text="Name"
          item-value="ItemId"
          required
          :rules="mnValidation.required"
          @input="onInputCustomer"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  :disabled="Boolean(template.Orderlines.length) || after('New')"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:customer_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="template.ContactId"
          :label="$t('label:contact', null, 1)"
          :items="contactItems"
          :item-text="
            (c) => [c.FirstName, c.MiddleName, c.LastName].join(' ')
          "
          :loading="contactLoading"
          aria-autocomplete="off"
          item-value="ItemId"
          :rules="mnValidation.required"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:contact_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="template.WarehouseId"
          :label="$t('label:warehouse', null, 1)"
          :items="warehouses"
          :rules="mnValidation.required"
          item-text="Name"
          item-value="ItemId"
          :loading="warehouseLoading"
          aria-autocomplete="off"
          :disabled="Boolean(template.Orderlines.length) || after('New')"
        />
        <v-autocomplete
          v-model="template.DefaultAddressBlock"
          :label="$t('label:default_delivery_address', null, 1)"
          :items="tmsAddresses"
          item-text="code"
          item-value="code"
          :loading="warehouseLoading"
          aria-autocomplete="off"
          :disabled="!template.CustomerId"
        />
        <v-text-field
          v-model="template.CustomerReference"
          :label="$t('label:customer_reference', null, 1)"
          aria-autocomplete="off"
        />
        <v-row>
          <v-col>
            <v-text-field
              disabled
              :value="mDate('PlannedStart')"
              :label="$t('label:planned_start', null, 1)"
            />
          </v-col>
          <v-col v-if="template.Start">
            <v-text-field
              disabled
              :value="mDate('Start')"
              :label="$t('label:start_date', null, 1)"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              disabled
              :value="mDate('PlannedEnd')"
              :label="$t('label:planned_end', null, 1)"
            />
          </v-col>
          <v-col v-if="template.Start">
            <v-text-field
              disabled
              :value="mDate('End')"
              :label="$t('label:end_date', null, 1)"
            />
          </v-col>
        </v-row>
        <v-textarea
          v-model="template.Description"
          filled
          :label="$t('label:description', null, 1)"
          rows="3"
          hide-details="auto"
          :disabled="after('Handling')"
        />
      </div>
      <div class="ma-auto">
        <h4
          v-if="collapse && $vuetify.breakpoint.lgAndDown"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h4>
        <h1
          v-if="collapse && $vuetify.breakpoint.xlOnly"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h1>
      </div>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-avatar
            size="24px"
            v-bind="attrs"
            style="top: -12px; right: -9px; position: absolute;"
            class="info-color-hover pointer-on-hover"
            v-on="on"
            @click="$emit('collapse', !collapse)"
          >
            <v-icon
              small
              class="white--text"
            >
              {{ !collapse ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
          </v-avatar>
        </template>
        <span v-if="!collapse">{{ $t("text:hide_customer_data") }}</span>
        <span v-else>{{ $t("text:show_customer_data") }}</span>
      </v-tooltip>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'OutboundOrderMain',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
    after: {
      type: Function,
      default: () => { },
      description: '',
    },
  },

  data: () => ({
    tmsAddresses: [],
  }),

  computed: {
    ...mapGetters({
      template: 'outboundOrder/template',

      contactLoading: 'contact/loading',

      customerItems: 'customer/items',
      contacts: 'contact/items',

      warehouseLoading: 'warehouse/loading',
      warehouses: 'warehouse/items',

    }),

    customers() {
      const customers = this.customerItems.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.CustomerId,
      );
      // eslint-disable-next-line no-nested-ternary
      customers.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
      return customers;
    },

    contactItems() {
      return this.contacts.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.ContactId,
      );
    },
  },
  watch: {
    template: {
      immediate: false,
      deep: false,
      handler(v, o) {
        if (v.CustomerId !== o.CustomerId) {
          this.getAddresses();
        }
      },
    },
  },

  methods: {
    onInputCustomer() {
      this.$emit('on-change-customer');
      this.getAddresses();
    },

    async getAddresses() {
      if (!this.template.CustomerId) {
        this.template.DefaultAddressBlock = null;
        return;
      }

      const { data } = await this.axios.get(
        `https://${window.location.hostname}:3000/tms/addresses/${this.template.CustomerId}`,
      );

      this.tmsAddresses = data;
    },

    mDate(prop) {
      const values = this.template.Orderlines.map(
        ({ Docking }) => (Docking ? Docking[prop] : null),
      ).filter((v) => v);

      if (!values.length) {
        return 'DD-MM-YYYY';
      }

      switch (prop) {
        case 'PlannedStart':
        case 'Start':
          values.sort((a, b) => a - b);
          break;
        case 'PlannedEnd':
        case 'End':
          values.sort((a, b) => b - a);
          break;
        default:
          throw new Error('Invalid mDate prmop');
      }

      return this.$moment(values[0]).format('DD-MM-YYYY');
    },
  },
};
</script>
