<template>
  <transition name="slide-fade">
    <v-row>
      <v-col>
        <v-card
          outlined
          :disabled="loading"
          :loading="loading"
          :class="{ 'pt-2': !loading }"
        >
          <template slot="progress">
            <progress-bar />
          </template>
          <c-vuex-list
            :headers="headers"
            :get-list="getList"
            :list="list"
            :loading="loading"
          >
            <template #footer-prepend>
              <v-btn
                small
                color="primary darken-1"
                class="width-auto"
                @click.stop="onForm()"
              >
                {{ $t("label:sector", null, 1) }}
                <v-icon
                  small
                  right
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  <div class="d-flex">
                    <v-btn
                      icon
                      @click.stop="onForm(item.ItemId)"
                    >
                      <v-icon> mdi-open-in-app </v-icon>
                    </v-btn>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          @click="onForm(item.ItemId, true)"
                          v-on="on"
                        >
                          <v-icon>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("label:clone") }}
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  {{ mWarehouseName(item) }}
                </td>
                <td>
                  {{ mnClusterName(item) }}
                </td>
                <td>
                  {{ mnParentsectorName(item) }}
                </td>
                <td>
                  {{ item.Name }}
                </td>
                <td>
                  {{ item.Type }}
                </td>
                <!-- <td>
                  {{ item.Level }}
                </td>
                <td>
                  {{ item.PositionX }}
                </td>
                <td>
                  {{ item.PositionY }}
                </td>
                <td>
                  {{ item.Width }}
                </td>
                <td>
                  {{ item.Length }}
                </td>
                <td>
                  {{ item.InnerWidth }}
                </td>
                <td>
                  {{ item.InnerLength }}
                </td>
                <td>
                  {{ item.InnerHeight }}
                </td>
                <td>
                  {{ item.CreatedAt ? $moment(item.CreatedAt ).format("DD-MM-YYYY") : '-' }}
                </td>
                <td>
                  {{ item.UpdatedAt ? $moment(item.UpdatedAt ).format("DD-MM-YYYY") : '-' }}
                </td> -->
                <td>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        class="mr-2 float-right"
                        v-on="on"
                        @click="onDelete(item)"
                      >
                        <v-icon class="delete-icon">
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t("label:delete") }}
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </c-vuex-list>
        </v-card>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SectorIndex',

  data: () => ({
    warehouseItems: [],
  }),

  computed: {
    ...mapGetters({
      loading: 'sector/loadingList',
      warehouseLoading: 'warehouse/loading',
      list: 'sector/list',
      clusterItems: 'cluster/items',
      clusterLoading: 'cluster/loading',
    }),
    clusters() {
      return this.clusterItems.filter((a) => !a.deletedAt);
    },
    headers() {
      return [
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
        {
          text: this.$t('label:warehouse', null, 1),
          value: 'WarehouseId',
          searchable: false,
        },
        {
          text: this.$t('label:cluster', null, 1),
          value: 'ClusterId',
          type: 'select',
          options: {
            action: this.getClusters,
            items: this.clusters,
            itemValue: 'ItemId',
            itemText: 'Name',
            loading: this.clusterLoading,
          },
        },
        {
          text: this.$t('label:parent_sector', null, 1),
          value: 'SectorId',
          type: 'number',
        },
        {
          text: this.$t('label:name', null, 1),
          value: 'Name',
          type: 'text',
        },
        {
          text: this.$t('label:type', null, 1),
          value: 'Type',
          type: 'select',
          options: {
            items: [
              { text: this.$t('label:rack', null, 1), value: 'Rack' },
              { text: this.$t('label:pallet', null, 1), value: 'Pallet' },
              { text: this.$t('label:ground', null, 1), value: 'Ground' },
            ],
          },
          clearable: true,
        },
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      warehouseReturnItems: 'warehouse/returnItems',
      getList: 'sector/getList',
      deleteItem: 'sector/destroy',

      getClusters: 'cluster/getItems',
    }),

    mWarehouseName(item) {
      return item?.Warehouse?.Name || '-';
    },

    mnClusterName(item) {
      return item.Cluster?.Name || '-';
    },

    mnParentsectorName(item) {
      return item.ParentSector?.Name || '-';
    },

    async warehouseAction(filter) {
      this.warehouseItems = await this.warehouseReturnItems(filter);
    },

    onForm(ItemId, clone = false) {
      if (clone) {
        this.$router.push({ name: 'storage-sector-clone', params: { ItemId } });
      } else {
        this.$router.push({ name: 'storage-sector-form', params: { ItemId } });
      }
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
