import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  items: [],
  defaultTemplate: {
    ItemId: '',
    user_id: '',
    email: '',
    name: '',
    picture: '',
    app_metadata: {},
    roles: [],
  },
  template: null,
};
