<template>
  <v-badge
    color="info"
    :content="`Σ: ${value.Quantity}`"
    outlined
    class="w-100"
    overlap
    offset-x="100px"
    offset-y="20px"
  >
    <v-card class="my-1">
      <v-row
        class="ma-0"
        align="center"
      >
        <v-col
          xl="10"
          md="10"
          class="pa-2"
        >
          <v-row
            class="flex-nowrap align-center"
            dense
          >
            <v-col
              cols="auto"
              class="pa-0"
            >
              <v-row no-gutters>
                <v-sheet
                  color="border"
                  max-height="16"
                  class="d-flex align-center mr-2 pa-1 rounded-pill"
                >
                  <h6>
                    # {{ orderLineIndex + 1 }}
                  </h6>
                </v-sheet>
                <v-spacer />
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-chip
                      x-small
                      color="info"
                      :loading="loading"
                      :disabled="loading"
                      v-on="on"
                      @click="$emit('open')"
                    >
                      {{ $t('label:order', null, 1) }}: # {{ value[`${type}OrderId`] }}
                    </v-chip>
                  </template>
                  {{ $t('label:go_to_order') }} #{{ value[`${type}OrderId`] }}
                </v-tooltip>
              </v-row>
              <v-sheet
                rounded
                :color="value.Batch ? 'border' : ''"
                max-height="16"
                class="d-flex align-center mt-1 pa-1 rounded-pill width-15ch"
              >
                <div
                  v-if="value.Batch"
                  class="d-flex align-center"
                >
                  <h6>
                    {{ $t('label:batch') }}: #
                  </h6>
                  <h6 class="width-10ch truncated">
                    {{ value.Batch }}
                  </h6>
                </div>
              </v-sheet>
            </v-col>
            <v-col
              xl="3"
              md="3"
              class="px-2"
            >
              <span class="clipped-line">
                {{ customer[0] && !loading ? customer[0].Name : '-' }}
              </span>
            </v-col>
            <v-col
              xl="4"
              md="4"
              class="px-2"
            >
              <span class="clipped-line">
                {{ value.Product.Name && !loading ? value.Product.Name : '-' }}
              </span>
            </v-col>
            <v-col
              xl="4"
              md="4"
              class="px-2"
            >
              <span class="clipped-line">
                {{ value.Name && !loading ? value.Name : '-' }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
        <v-btn
          v-if="!$attrs.disabled"
          color="error"
          height="46px"
          min-width="24px"
          class="px-1 rounded-tl-0 rounded-bl-0 rounded-tr-sm rounded-br-sm"
          :loading="loading"
          :disabled="loading"
          @click="$emit('delete', value)"
        >
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </v-row>
    </v-card>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderlineDocking',

  props: {
    type: {
      type: String,
      required: true,
      description: 'Outbound or Inbound',
    },
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    orderLineIndex: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      customerItems: 'customer/items',
    }),

    customer() {
      return this.customerItems.filter(
        (c) => c.ItemId === this.value[`${this.type}Order`].CustomerId,
      );
    },
  },
};
</script>
<style lang="scss">
.clipped-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
