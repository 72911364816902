var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"value":true,"persistent":true,"content-class":_vm.highComplexityModal,"scrollable":"","no-click-animation":"","transition":"dialog-bottom-transition"}},[(_vm.template)?_c('v-card',{attrs:{"tile":"","loading":_vm.loadingOutboundOrder,"height":"95vh"}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("label:order_outbound", null, 1))+" ")]),_c('v-chip',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.template.Status))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","light":"","color":"text"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2",staticStyle:{"height":"100%"}},[_c('v-form',{ref:"form",staticStyle:{"height":"inherit"}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"height":"inherit"}},[_c('c-main',{attrs:{"collapse":_vm.collapse,"after":_vm.after},on:{"collapse":function (v) { return _vm.collapse = v; },"on-change-customer":function($event){return _vm.onChangeCustomer()}}}),_c('v-col',{staticClass:"pr-0",staticStyle:{"width":"calc(100% - 25%)"}},[_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.template.Orderlines),function(Orderline,orderlineIndex){return _c('c-orderline-outbound',{key:("orderline-" + orderlineIndex),ref:"outbound-orderlines",refInFor:true,attrs:{"path":[orderlineIndex],"disable":{
                  main: _vm.after('Planned', Orderline),
                  unique: _vm.after('Planned', Orderline),
                  delete: _vm.after('Plannable', Orderline)
                }},on:{"remove-package":function($event){return _vm.onRemovePackage(orderlineIndex)},"remove-orderline":function($event){return _vm.onRemoveOrderline(orderlineIndex)}},scopedSlots:_vm._u([{key:"packages",fn:function(){return [_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.packagesPanel),callback:function ($$v) {_vm.packagesPanel=$$v},expression:"packagesPanel"}},_vm._l((_vm.template.Orderlines[orderlineIndex]
                        .OutboundPackages),function(Package,packageIndex){return _c('v-expansion-panel',{key:("orderline-" + packageIndex),staticClass:"mb-2 rounded"},[_c('v-expansion-panel-header',{staticClass:"py-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var open = ref.open;
return [_c('v-row',{attrs:{"align":"center","dense":""}},[_c('h4',{staticClass:"text-uppercase font-weight-bold",class:{ 'white--text': !open }},[_vm._v(" "+_vm._s(_vm.$t("label:package", null, 1))+" #"+_vm._s(packageIndex + 1)+" ")]),_c('v-spacer'),_c('v-fade-transition',[(open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('label:outbound_batch', null, 1),"disabled":_vm.after('Planned', Orderline),"dense":""},model:{value:(_vm.template.Orderlines[orderlineIndex]
                                      .OutboundPackages[packageIndex].OutboundBatch),callback:function ($$v) {_vm.$set(_vm.template.Orderlines[orderlineIndex]
                                      .OutboundPackages[packageIndex], "OutboundBatch", $$v)},expression:"template.Orderlines[orderlineIndex]\n                                      .OutboundPackages[packageIndex].OutboundBatch"}})],1),_c('v-col',{staticClass:"mr-8 pb-0",attrs:{"cols":"auto"}},[_c('v-badge',{attrs:{"color":"info","content":("Σ: " + (_vm.total(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex]
                                    ))),"overlap":"","value":_vm.template.Orderlines[orderlineIndex]
                                      .OutboundPackages[packageIndex].Quantity > 1,"offset-x":"45px","offset-y":"12px"}},[_c('c-number',{staticClass:"mt-3",staticStyle:{"width":"150px"},attrs:{"value":_vm.template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].Quantity,"dense":"","disabled":"","label":_vm.$t('label:quantity:package', null, 1)}})],1)],1)],1):_vm._e(),(!open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0",staticStyle:{"width":"150px"},attrs:{"cols":"auto"}},[(_vm.template.Orderlines[orderlineIndex]
                                      .OutboundPackages[packageIndex].OutboundBatch)?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t('label:outbound_batch', null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .OutboundPackages[packageIndex].OutboundBatch)+" ")])]),_c('v-col',{staticClass:"mr-8 pb-0",staticStyle:{"width":"150px"},attrs:{"cols":"auto"}},[(_vm.template.Orderlines[orderlineIndex]
                                      .OutboundPackages[packageIndex].Quantity)?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t('label:quantity:package', null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .OutboundPackages[packageIndex].Quantity)+" ")])])],1):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('c-package-uniques',{ref:"uniques",refInFor:true,attrs:{"path":[orderlineIndex, 'OutboundPackages', packageIndex],"reset":_vm.resetTemplate,"template":_vm.template,"open":open}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"disabled":_vm.after('Check-in', Orderline),"icon":""},on:{"click":function($event){return _vm.onRemovePackage(orderlineIndex, packageIndex)}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:delete', null, 1))+" ")])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"content-no-padding",attrs:{"eager":""}},[_c('c-package-main-outbound',{ref:"packages",refInFor:true,attrs:{"type":"outbound","path":[orderlineIndex,'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                            main: true,
                            sector: true,
                          }},scopedSlots:_vm._u([{key:"product-bundle",fn:function(){return [_c('c-product-bundle-main',{attrs:{"path":[orderlineIndex,'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                main: true,
                                sector: true,
                              }}})]},proxy:true},{key:"transaction-inbound-handling",fn:function(){return [_c('c-transaction-inbound-handling',{attrs:{"path":[orderlineIndex,'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                main: true,
                                sector: true,
                              }}})]},proxy:true},{key:"transaction-transformation-handling",fn:function(){return [_c('c-transaction-transformation-handling',{attrs:{"path":[orderlineIndex,'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                main: true,
                                sector: true,
                              }}})]},proxy:true},{key:"transaction-storage-handling",fn:function(){return [_c('c-transaction-storage-handling',{attrs:{"path":[orderlineIndex,'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                main: true,
                                sector: true,
                              }}})]},proxy:true},(_vm.after('Planned', Orderline))?{key:"transaction-outbound-handling",fn:function(){return [_c('c-transaction-outbound-handling',{attrs:{"path":[orderlineIndex, 'OutboundPackages', packageIndex],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                                main: _vm.after('Check-out', Orderline),
                              }}})]},proxy:true}:null],null,true)})],1)],1)}),1)]},proxy:true}],null,true)})}),1),_c('v-btn',{staticClass:"mt-1 mb-3",attrs:{"disabled":!_vm.template.CustomerId || !_vm.template.WarehouseId || _vm.after('Planned'),"color":"primary"},on:{"click":_vm.onAddOrderline}},[_vm._v(" "+_vm._s(_vm.$t('label:order_line', null, 1))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fal fa-plus ")])],1),(_vm.showPackages)?_c('c-package-index',{attrs:{"loading":_vm.loadingOutboundOrder || _vm.loadingPackage,"static-filters":_vm.staticFilters,"disable":{create: true}},scopedSlots:_vm._u([{key:"controls",fn:function(ref){
                              var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.addable(item.ItemId),"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAddPackage(item.ItemId)}}},[_c('v-icon',{attrs:{"disabled":_vm.loadingOutboundOrder || _vm.loadingPackage,"color":"accent"}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3295463071)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"border darken-1"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("btn:close"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loadingOutboundOrder},on:{"click":function($event){$event.stopPropagation();return _vm.onPrint(_vm.template.ItemId)}}},[_vm._v(" "+_vm._s(_vm.$t('label:print_order'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-printer ")])],1),(true)?_c('v-btn',{attrs:{"color":"warning","loading":_vm.loadingOutboundOrder},on:{"click":_vm.onSync}},[_vm._v(" "+_vm._s(_vm.$t("btn:send_to_tms"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"info","loading":_vm.loadingOutboundOrder,"href":"https://vdl.slim.fitsoft.eu/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('label:tms'))+" | "+_vm._s(_vm.template.TmsId)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-open-in-new ")])],1),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingOutboundOrder,"disabled":_vm.loadingOutboundOrder},on:{"click":function () { return _vm.onSave(); }}},[_vm._v(" "+_vm._s(_vm.$t("btn:save"))+" ")]),(_vm.nextStatus.value)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingOutboundOrder,"disabled":_vm.loadingOutboundOrder || !_vm.nextStatus.valid || _vm.after('Handling')},on:{"click":function($event){return _vm.onSave(_vm.nextStatus.value)}}},[_vm._v(" "+_vm._s(_vm.nextStatus.name)+" ")]):_vm._e()],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }