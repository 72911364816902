import Sectors from '@/components/views/storage/Sectors.vue';

import SectorForm from '@/components/sector/form.vue';

export default [
  {
    name: 'storage-sector-list',
    path: '/storage/sector/list',
    component: Sectors,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'storage-sector-form',
        path: '/storage/sector/form/:ItemId?',
        component: SectorForm,
        meta: { restriction: ['WMS.Manager'] },
      },
      {
        name: 'storage-sector-clone',
        path: '/storage/sector/clone/:ItemId?',
        component: SectorForm,
        meta: { restriction: ['WMS.Manager'] },
      },
    ],
  },
];
