export default {
  computed: {
    mnValidation() {
      return {
        required: [
          (v) => (v !== undefined && v !== null && (typeof v === 'string' ? v.length > 0 : true)) || this.$t('validation:required'),
        ],
        selectedOne: [
          (v) => (v !== undefined && v !== null) || this.$t('validation:selected:required'),
        ],
        selectedMultiple: [
          (v) => (v !== undefined && v !== null && Boolean(v.length)) || this.$t('validation:selected:required'),
        ],
        gtNull: [
          (v) => !!v || this.$t('validation:quantity:required'),
          (v) => v > 0 || this.$t('validation:quantity:required'),
        ],
        filled: [
          (v) => !!v || this.$t('validation:filled:required'),
        ],
        isURL: [
          (v) => {
            if (v && v.length) {
              const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
                  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
                  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
                  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
                  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
                  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
              return pattern.test(v) || this.$t('validation:web:invalid');
            }
            return true;
          },
        ],
        isVCurrency: [
          (v) => !!v || this.$t('validation:amount:required'),
          (v) => this.$ci.parse(v) > 0 || this.$t('validation:amount:required'),
        ],
        isEmailAddress: [
          (v) => {
            if (v && v.length) {
              // eslint-disable-next-line no-useless-escape
              return (v && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)) || this.$t('validation:email:invalid');
            } return true;
          },
        ],
        isEmailTo: [
          (v) => !!v || this.$t('validation:to:required'),
          (v) => {
            if (v && v.length) {
              const emails = v.replace(/\s+/g, '').split(',');
              let valid = true;
              emails.forEach((e) => {
                // eslint-disable-next-line no-useless-escape
                if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e)) {
                  valid = this.$t('validation:to:invalid');
                }
              });
              return valid;
            }
            return true;
          },
        ],
      };
    },
  },
};
