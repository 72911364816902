import Vue from 'vue';
import axios from 'axios';
import createAuth0Client from '@auth0/auth0-spa-js';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState(
  {}, document.title, window.location.pathname,
);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const AuthReady = () => new Promise((resolve) => {
  const auth = getInstance();
  const interval = setInterval(() => {
    if (!auth.loading) {
      clearInterval(interval);
      resolve(true);
    }
  }, 50);
});

/**
 * Creates an instance of the Auth0 SDK.
 * If one has already been created, it returns that instance
 */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = `${window.location.origin}/authenticate`,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loadingUsers: false,
        loading: true,
        isAuthenticated: false,
        token: null,
        auth0Client: null,
        error: null,

        user: {}, // Auth0
        roles: [], // app_metadata
        tenant: null,
        language: null, // app_metadata
        WarehouseId: null, // app_metadata
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') && window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          this.token = await this.getTokenSilently();
          axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          axios.defaults.withCredentials = true;
          const params = {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
            withCredentials: true,
          };
          await axios.post(`https://${window.location.hostname}:3000/auth`, params);
          const result = await axios.get(`https://${window.location.hostname}:3000/auth/config`, params);
          this.$set(this, 'roles', result.data.roles);
          this.$set(this, 'tenant', result.data.tenant);
          this.$set(this, 'language', result.data.language);
          this.$set(this, 'WarehouseId', result.data.wms?.WarehouseId);
          Vue.i18n.set(result.data.language);
        }
        this.user = await this.auth0Client.getUser();
        this.loading = false;
      }
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o = { returnTo: window.location.origin }) {
        return this.auth0Client.logout(o);
      },
      hasRole(role) {
        return this.roles.includes(role);
      },
      async updateLanguage(ISO2) {
        this.$set(this, 'language', ISO2);
        Vue.i18n.set(ISO2);
        try {
          await axios.post(`https://${window.location.hostname}:3000/auth/language`, { language: ISO2 });
          this.$toasted.success(this.$t('action:save:success:language'), { icon: 'mdi-check-outline' });
        } catch (e) {
          this.$toasted.error(this.$t('action:save:error:language', { error: e.message }));
        }
      },
      async updateWarehouse(WarehouseId) {
        this.$set(this, 'WarehouseId', WarehouseId);
        try {
          await axios.post(`https://${window.location.hostname}:3000/auth/warehouse`, { WarehouseId });
          this.$toasted.success(this.$t('action:save:success:warehouse'), { icon: 'mdi-check-outline' });
        } catch (e) {
          this.$toasted.error(this.$t('action:save:error:warehouse', { error: e.message }));
        }
      },
      async getUsers(roleName = null) {
        try {
          this.loadingUsers = true;

          let role = '';
          if (roleName) {
            role = `role=${roleName}`;
          }
          const { data } = await this.axios.get(`https://${window.location.hostname}:3000/auth/users?${role}`);
          this.loadingUsers = false;

          return data;
        } catch (e) {
          this.$toasted.error(this.$t('action:get:error:users', { error: e.response.data }));
          this.loadingUsers = true;
          return [];
        }
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useAuth0(options);
  },
};
