<template>
  <v-form
    ref="form"
    color="#dddddd"
  >
    <v-row>
      <v-col
        md="3"
        xl="3"
      >
        <v-checkbox
          :input-value="value.Stackable"
          :label="$t('label:stackable')"
          hide-details
          class="mt-0"
          @change="(v) => onInput('Stackable', v)"
        />
      </v-col>
      <v-col
        class="pb-0"
      >
        <slot name="base-product-bundle" />
      </v-col>
      <v-col cols="auto">
        <v-row class="d-xl-flex flex-xl-column mr-0">
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pb-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_netto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightNetto"
              :disabled="disabled"
              @input="(v) => onInput('WeightNetto', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:weight_brutto', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.WeightBrutto"
              :disabled="disabled"
              @input="(v) => onInput('WeightBrutto', v)"
            />
          </v-col>
          <v-col
            cols="auto"
            class="d-xl-flex flex-xl-row pt-xl-0"
          >
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:length', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Length"
              :disabled="disabled"
              @input="(v) => onInput('Length', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:width', null, 1)"
              class="width-10ch pr-xl-4"
              :value="value.Width"
              :disabled="disabled"
              @input="(v) => onInput('Width', v)"
            />
            <c-number
              :fixed="2"
              hide-details
              :label="$t('label:height', null, 1)"
              class="width-10ch"
              :value="value.Height"
              :disabled="disabled"
              @input="(v) => onInput('Height', v)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  name: 'BasePackageMain',

  props: {
    value: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(p, v) {
      if (this.disabled) {
        return;
      }
      this.$emit('input', { ...this.value, [p]: v });
    },
  },
};
</script>
