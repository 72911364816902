<template>
  <v-col>
    <v-form
      v-if="value"
      ref="form"
      color="#dddddd"
      class="w-100"
    >
      <div class="d-flex">
        <v-col
          v-if="value.Start"
          class="pb-0"
          cols="auto"
        >
          <h5
            style="position: absolute; transform: translateY(-14px)"
            class="font-weight-thin"
          >
            {{ $t("label:start", null, 1) }}
          </h5>
          <span>
            {{ value.Start ? $moment(value.Start).format("DD-MM-YYYY") : "" }}
          </span>
        </v-col>
        <span style="transform: translateY(10px)">
          {{ value.Start && value.End ? "-" : "" }}
        </span>
        <v-col
          v-if="value.End"
          class="pb-0"
          cols="auto"
        >
          <h5
            style="position: absolute; transform: translateY(-14px)"
            class="font-weight-thin"
          >
            {{ $t("label:end", null, 1) }}
          </h5>
          <span>
            {{ value.End ? $moment(value.End).format("DD-MM-YYYY") : "" }}
          </span>
        </v-col>
      </div>
      <v-row class="ma-0">
        <v-col>
          <c-sector-usage-main
            v-for="(v, i) in value.SectorUsages"
            :key="`storage-sector-usage-${i}`"
            :path="[...path, i]"
            :template="template"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>

import CSectorUsageMain from '@/components/usage/sector.vue';

export default {
  name: 'TransactionStorageHandling',

  components: {
    CSectorUsageMain,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
  },

  computed: {
    value() {
      if (!this.path.length) {
        return this.template.StorageHandling;
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]].StorageHandling;
    },
  },
};
</script>
