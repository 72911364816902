import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    TmsId: null,
    WarehouseId: null,
    CustomerId: null,
    ContactId: null,
    DebtorId: null,

    PlannedStart: null,
    PlannedEnd: null,
    Start: null,
    End: null,

    Description: null,

    Orderlines: [],

    Status: 'New',
    DefaultAddressBlock: null,

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
