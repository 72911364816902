<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="cLoading"
            :loading="cLoading"
            :class="{ 'pt-2': !cLoading }"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              :headers="headers"
              :get-list="getList"
              :list="list"
              :static-filters="staticFilters"
              :loading="cLoading"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>
                    {{ mProductName(item) }}
                  </td>
                  <td>
                    {{ $t(item.Status) }}
                  </td>
                  <td>
                    <strong :class="{ 'red--text': item.Quantity > 1 }">
                      {{ item.Quantity }}
                    </strong>
                  </td>
                  <td>
                    {{ item.InboundBatch }}
                  </td>
                  <td>
                    {{ item.OutboundBatch }}
                  </td>
                  <td>
                    {{
                      item.UpdatedAt
                        ? $moment(item.UpdatedAt).format("DD-MM-YYYY")
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      item.deletedAt
                        ? $moment(item.deletedAt).format("DD-MM-YYYY")
                        : "-"
                    }}
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PackageIndex',

  props: {
    loading: {
      type: Boolean,
      default: null,
    },
    staticFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      list: 'package/list',
      loadingPackage: 'package/loadingList',

      productLoading: 'product/loading',

      customers: 'customer/items',
      productItems: 'product/items',

      productTemplate: 'product/template',
    }),

    cLoading() {
      return this.loading || this.loadingPackage;
    },

    headers() {
      return [
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:product', null, 1),
          value: 'ProductId',
          type: 'select',
          sortable: false,
          clearable: true,
          options: {
            action: this.getProducts,
            items: this.productItems,
            itemValue: 'ItemId',
            itemText: 'Name',
            loading: this.productLoading,
          },
        },
        {
          text: this.$t('label:status', null, 1),
          value: 'Status',
          type: 'select',
          options: {
            items: [
              {
                text: this.$t('label:status:planned', null, 1),
                value: 'Planned',
              },
              {
                text: this.$t('label:status:stored', null, 1),
                value: 'Stored',
              },
              {
                text: this.$t('label:status:dissolved', null, 1),
                value: 'Dissolved',
              },
              {
                text: this.$t('label:status:departed', null, 1),
                value: 'Departed',
              },
            ],
          },
          clearable: true,
        },

        {
          text: this.$t('label:quantity:package', null, 1),
          value: 'Quantity',
          type: 'number',
        },
        {
          text: this.$t('label:inbound_batch', null, 1),
          value: 'InboundBatch',
        },
        {
          text: this.$t('label:outbound_batch', null, 1),
          value: 'OutboundBatch',
        },
        {
          text: this.$t('label:updated_at', null, 1),
          value: 'updatedAt',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        {
          text: this.$t('label:deleted_at', null, 1),
          value: 'deletedAt',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
      ];
    },
  },

  watch: {
    productTemplate: {
      deep: true,
      async handler(n, o) {
        const value = n?.ItemId;
        const oValue = o?.ItemId;
        if (value !== oValue) {
          this.getProducts({ ItemId: value });
          this.getList({
            ...this.list,
            offset: 0,
            filters: {
              ...this.list.filters,
              ProductId: { type: 'select', value: Number(value) },
            },
          });
        }
      },
    },
  },

  methods: {
    ...mapActions({
      getList: 'package/getPortalList',

      getProducts: 'product/getItems',
    }),

    mProductName(item) {
      return item.ProductBundle?.Product?.Name || '-';
    },
  },
};
</script>
