<template>
  <v-form
    ref="form"
    class="pa-2 w-100"
    rounded="lg"
    color="#dddddd"
  >
    <v-row
      class="ma-0"
      dense
    >
      <v-col
        md="12"
        class="pb-0"
      >
        <v-autocomplete
          :label="$t('label:warehouse', null, 1)"
          item-text="Name"
          item-value="ItemId"
          :items="warehouseItems"
          :value="value.WarehouseId"
          aria-autocomplete="off"
          hide-details
          disabled
          readonly
        />
        <c-sector-select
          v-if="value.WarehouseId"
          :p-package="value"
          :p-packages="[]"
          :s-packages="[]"
          :r-packages="[]"
          disabled
          @input="(v) => onInput('SectorId', v)"
        />
        <v-checkbox
          :input-value="value.Stackable"
          :label="$t('label:stackable')"
          hide-details
          disabled
        />
      </v-col>
      <v-col
        md="12"
        class="pb-0 mt-4"
      >
        <slot
          name="product-bundle"
        />
      </v-col>
      <v-col
        cols="auto"
        class="mx-auto"
      >
        <v-row no-gutters>
          <c-number
            :fixed="2"
            hide-details
            :label="$t('label:weight_netto', null, 1)"
            class="width-10ch pr-4"
            :value="value.WeightNetto"
            disabled
          />
          <c-number
            :fixed="2"
            hide-details
            :label="$t('label:weight_brutto', null, 1)"
            class="width-10ch pr-4"
            :value="value.WeightBrutto"
            disabled
          />
        </v-row>
        <v-row no-gutters>
          <c-number
            :fixed="2"
            hide-details
            :label="$t('label:length', null, 1)"
            class="width-10ch pr-4"
            :value="value.Length"
            disabled
          />
          <c-number
            :fixed="2"
            hide-details
            :label="$t('label:width', null, 1)"
            class="width-10ch pr-4"
            :value="value.Width"
            disabled
          />
          <c-number
            :fixed="2"
            hide-details
            :label="$t('label:height', null, 1)"
            class="width-10ch"
            :value="value.Height"
            disabled
          />
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CSectorSelect from '@/components/sector/select.vue';

export default {
  name: 'PackageMainSource',

  components: {
    CSectorSelect,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
  },

  data: () => ({
    sectors: [],
    activeSlot: null,
  }),

  computed: {
    ...mapGetters({
      warehouseItems: 'warehouse/items',
    }),

    cSlots() {
      return [
        {
          text: this.$t('label:inbound_handling'), value: 'transaction-inbound-handling', type: 'InboundHandling',
        },
        {
          text: this.$t('label:transformation_handling'), value: 'transaction-transformation-handling', type: 'TransformationHandling',
        },
        {
          text: this.$t('label:storage_handling'), value: 'transaction-storage-handling', type: 'StorageHandling',
        },
      ].filter(({ type }) => (this.value[type]?.length || Boolean(this.value[type])));
    },

    value() {
      if (!this.path.length) {
        return this.template;
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]];
    },
  },

  async mounted() {
    this.sectors = await this.sectorReturnItems({
      WarehouseId: this.value.WarehouseId,
    });
  },

  methods: {
    ...mapActions({
      sectorReturnItems: 'sector/returnItems',
    }),
  },
};
</script>
