var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":"","disabled":_vm.loading,"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('c-vuex-list',{attrs:{"headers":_vm.headers,"get-list":_vm.getList,"list":_vm.list,"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer-prepend",fn:function(){return [_c('v-col',[_c('v-btn',{staticClass:"width-auto",attrs:{"small":"","color":"primary darken-1","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onForm()}}},[_vm._v(" "+_vm._s(_vm.$t('label:invoice', null, 1))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"from",attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.mDate(_vm.from),"label":_vm.$t('label:from', null, 1),"append-icon":"mdi-calendar","readonly":"","tabindex":4,"disabled":_vm.invoiceLoading,"loading":_vm.invoiceLoading},on:{"click:append":function () { return _vm.menu.from = true; }}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.from),callback:function ($$v) {_vm.$set(_vm.menu, "from", $$v)},expression:"menu.from"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"blue","locale":"nl","first-day-of-week":1,"max":_vm.$moment().format('YYYY-MM-DD')},on:{"input":function (v) { return _vm.onChange('from', v); }},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"to",attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.invoiceLoading,"loading":_vm.invoiceLoading,"value":_vm.mDate(_vm.to),"label":_vm.$t('label:to', null, 1),"append-icon":"mdi-calendar","readonly":"","tabindex":4},on:{"click:append":function () { return _vm.menu.to = true; }}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.to),callback:function ($$v) {_vm.$set(_vm.menu, "to", $$v)},expression:"menu.to"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"blue","locale":"nl","first-day-of-week":1,"max":_vm.$moment().format('YYYY-MM-DD')},on:{"input":function (v) { return _vm.onChange('to', v); }},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-col',[_c('v-btn',{staticClass:"width-auto mt-3",attrs:{"disabled":!_vm.from || !_vm.to || _vm.invoiceLoading,"loading":_vm.invoiceLoading},on:{"click":function($event){return _vm.onInvoice()}}},[_vm._v(" "+_vm._s(_vm.$t('btn:invoice:generate'))+" ")])],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onForm(item.ItemId)}}},[_c('v-icon',[_vm._v(" mdi-open-in-app ")])],1)],1),_c('td',[_vm._v(_vm._s(item.ItemId))]),_c('td',[_vm._v(_vm._s(_vm.mCustomer(item.CustomerId)))]),_c('td',[_vm._v(_vm._s(_vm.$t(item.Status)))]),_c('td',[_vm._v(_vm._s(item.SentDate ? _vm.$moment(item.SentDate).format("DD-MM-YYYY") : '-'))]),_c('td',[_vm._v(_vm._s(item.Description))]),_c('td',[(['New'].includes(item.Status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 float-right",attrs:{"icon":""},on:{"click":function($event){return _vm.onDelete(item)}}},on),[_c('v-icon',{staticClass:"delete-icon"},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:delete'))+" ")]):_vm._e()],1)])]}}])})],2)],1)],1),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }