<template>
  <v-form
    ref="form"
    color="#dddddd"
  >
    <v-row
      class="ma-0"
      dense
      align="center"
    >
      <v-col style="width: calc(100% - 13ch - 44px);">
        <v-autocomplete
          :disabled="disable.main"
          :value="value.ResourceId"
          :label="$t('label:resource', null, 1)"
          :items="resources"
          :loading="resourceLoading"
          :search-input.sync="resourceSearch"
          item-value="ItemId"
          item-text="Name"
          flat
          hide-no-data
          cache-items
          clearable
          aria-autocomplete="off"
          :hide-details="disable.main"
          @update:search-input="onResourceSearch"
          @input="v => onInput('ResourceId', v)"
        />
      </v-col>
      <v-col cols="auto">
        <c-number
          :disabled="disable.main"
          :value="value.Duration"
          :rules="mnValidation.required"
          :label="$t('label:duration', null, 1)"
          class="width-10ch"
          @input="v => onInput('Duration', Math.abs(v))"
        />
      </v-col>
      <v-col cols="auto">
        <v-tooltip
          v-if="!disable.main"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="onDelete"
            >
              <v-icon
                class="delete-icon"
              >
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </template>
          {{ $t('label:delete') }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UsageResource',

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex][TransactionType][UsageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
  },

  data: () => ({
    resourceSearch: null,
    resources: [],
  }),

  computed: {
    ...mapGetters({
      resourceLoading: 'resource/loading',
    }),

    value() {
      if (this.path.length === 2) {
        return this.template[this.path[0]].ResourceUsages[this.path[1]];
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
        .ResourceUsages[this.path[4]];
    },
  },

  mounted() {
    this.onResourceSearch();
  },

  methods: {
    ...mapActions({
      resourceReturnItems: 'resource/returnItems',
    }),

    onInput(p, v) {
      if (this.path.length === 2) {
        const { ResourceUsages } = this.template[this.path[0]];
        ResourceUsages[this.path[1]][p] = v;
        this.reset({
          ...this.template,
          ResourceUsages,
        });
      } else {
        const { Orderlines } = this.template;

        Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
          .ResourceUsages[this.path[4]] = ({ ...this.value, [p]: v });

        this.reset({
          ...this.template,
          Orderlines,
        });
      }
    },

    onDelete() {
      if (this.path.length === 2) {
        const { ResourceUsages } = this.template[this.path[0]];
        ResourceUsages.splice(this.path[1], 1);
        this.reset({
          ...this.template,
          ResourceUsages,
        });
      } else {
        const { Orderlines } = this.template;

        Orderlines[this.path[0]][this.path[1]][this.path[2]][this.path[3]]
          .ResourceUsages.splice(this.path[4], 1);

        this.reset({
          ...this.template,
          Orderlines,
        });
      }
    },

    onResourceSearch() {
      clearTimeout(this.resourceTimeout);
      this.resourceTimeout = setTimeout(async () => {
        const filter = {
          WarehouseId: this.template.WarehouseId,
        };
        if (!this.value) {
          return;
        }
        if (this.resourceSearch?.length) {
          filter.Name = { value: this.resourceSearch, type: 'text' };
        } else if (this.value.ResourceId) {
          filter.ItemId = { value: this.value.ResourceId, type: 'select' };
        }
        this.resources = (await this.resourceReturnItems(filter))
          .filter(({ ItemId, deletedAt }) => !deletedAt || ItemId === this.value.ResourceId);
      }, 500);
    },
  },
};
</script>
