<template>
  <div>
    <template v-if="!$auth.loading">
      <router-view />
    </template>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { AuthReady } from '@/auth';

export default {
  name: 'LayoutsPortal',
  mounted() {
    this.initWidgets();
    this.initWarehouses();
    this.initCarriers();
  },
  methods: {
    ...mapActions({
      getWarehouses: 'warehouse/getItems',
      getCarriers: 'carrier/getItems',
    }),
    async initWarehouses() {
      await AuthReady();
      this.getWarehouses();
    },
    async initCarriers() {
      await AuthReady();
      this.getCarriers();
    },
    async initWidgets() {
      await AuthReady();

      if (this.$auth.tenant !== 'vdl') {
        return;
      }

      const feedbackInitJs = document.createElement('script');
      feedbackInitJs.src = 'https://desk.zoho.eu/portal/api/feedbackwidget/69653000000212001?orgId=20077406276&displayType=popout';
      feedbackInitJs.type = 'text/javascript';
      document.head.appendChild(feedbackInitJs);
    },
  },
};
</script>
