import Vue from 'vue';
import VueRouter from 'vue-router';

import { getInstance, AuthReady } from '../auth/index';
import AuthRoutes from '../auth/routes';
import FinanceRoutes from './finance/index';
import MovementRoutes from './movement/index';
import StorageRoutes from './storage/index';
import SystemRoutes from './system/index';
import PortalRoutes from './portal/index';

Vue.use(VueRouter);

const routes = [
  ...AuthRoutes,
  ...FinanceRoutes,
  ...MovementRoutes,
  ...StorageRoutes,
  ...SystemRoutes,
  ...PortalRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const rolesGuard = (to) => {
  if (to.name !== 'login' && getInstance().isAuthenticated === false) {
    return false;
  }

  if (!to.meta.restriction || !to.meta.restriction.length) {
    return true;
  }

  let allow = getInstance().roles.includes('WMS.Admin');

  getInstance().roles.forEach((role) => {
    if (to.meta.restriction.includes(role)) {
      allow = true;
    }
  });

  return allow;
};

router.beforeEach(async (to, from, next) => {
  await AuthReady();

  if (!rolesGuard(to)) {
    return next({ name: 'login' });
  }
  return next();
});

export default router;
