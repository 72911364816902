<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="cLoading"
            :loading="cLoading"
            :class="{ 'pt-2': !cLoading }"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              :headers="headers"
              :get-list="getList"
              :list="list"
              :static-filters="staticFilters"
              :loading="cLoading"
              :on-export="onExport"
            >
              <template #footer-prepend>
                <v-btn
                  v-if="!disable.create"
                  small
                  color="primary darken-1"
                  class="width-auto"
                  @click.stop="onForm()"
                >
                  {{ $t("label:package:manual", null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <template #item="{ item }">
                <tr>
                  <td v-if="!disable.open || $slots.controls">
                    <slot
                      name="controls"
                      :item="item"
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          v-if="!disable.open"
                          icon
                          @click.stop="onForm(item.ItemId)"
                        >
                          <v-icon
                            :disabled="cLoading"
                            color="accent"
                          >
                            mdi-open-in-app
                          </v-icon>
                        </v-btn>
                      </div>
                    </slot>
                  </td>
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>
                    {{ mProductName(item) }}
                  </td>
                  <td>
                    {{ mCustomer(item.CustomerId) }}
                  </td>
                  <td>
                    {{ $t(item.Status) }}
                  </td>
                  <td>
                    {{ mSectorName(item) }}
                  </td>
                  <td>
                    <strong :class="{ 'red--text': item.Quantity > 1 }">
                      {{ item.Quantity }}
                    </strong>
                  </td>
                  <td>
                    {{ item.InboundBatch }}
                  </td>
                  <td>
                    {{ item.OutboundBatch }}
                  </td>
                  <td>
                    {{
                      item.UpdatedAt
                        ? $moment(item.UpdatedAt).format("DD-MM-YYYY")
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      item.deletedAt
                        ? $moment(item.deletedAt).format("DD-MM-YYYY")
                        : "-"
                    }}
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { stringify } from 'csv-stringify/lib/sync';

export default {
  name: 'PackageIndex',

  props: {
    loading: {
      type: Boolean,
      default: null,
    },
    staticFilters: {
      type: Object,
      default() {
        return this.sectorTemplate
          ? { SectorId: { type: 'select', value: this.sectorTemplate.ItemId } }
          : {};
      },
    },
    disable: {
      type: Object,
      default: () => ({
        create: false,
      }),
    },
  },

  computed: {
    ...mapGetters({
      list: 'package/list',
      loadingPackage: 'package/loadingList',

      productLoading: 'product/loading',
      sectorLoading: 'sector/loading',

      customers: 'customer/items',
      productItems: 'product/items',
      sectorItems: 'sector/items',

      productTemplate: 'product/template',

      sectorTemplate: 'sector/template',
    }),

    cLoading() {
      return this.loading || this.loadingPackage;
    },

    sectors() {
      return this.sectorItems.filter((a) => !a.deletedAt);
    },

    headers() {
      const headers = [];
      if (!this.disable.open || this.$slots.controls) {
        headers.push({
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        });
      }
      headers.push(
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:product', null, 1),
          value: 'ProductId',
          type: 'select',
          sortable: false,
          clearable: true,
          options: {
            action: this.getProducts,
            items: this.productItems,
            itemValue: 'ItemId',
            itemText: 'Name',
            loading: this.productLoading,
          },
        },
        {
          text: this.$t('label:customer', null, 1),
          value: 'CustomerId',
          clearable: true,
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:status', null, 1),
          value: 'Status',
          type: 'select',
          options: {
            items: [
              {
                text: this.$t('label:status:planned', null, 1),
                value: 'Planned',
              },
              {
                text: this.$t('label:status:stored', null, 1),
                value: 'Stored',
              },
              {
                text: this.$t('label:status:dissolved', null, 1),
                value: 'Dissolved',
              },
              {
                text: this.$t('label:status:departed', null, 1),
                value: 'Departed',
              },
            ],
          },
          clearable: true,
        },
        {
          text: this.$t('label:sector', null, 1),
          value: 'SectorId',
          type: 'select',
          searchable: !this.sectorTemplate,
          options: {
            action: this.getSectors,
            items: this.sectors,
            itemValue: 'ItemId',
            itemText: 'Name',
            loading: this.sectorLoading,
          },
        },
        {
          text: this.$t('label:quantity:package', null, 1),
          value: 'Quantity',
          type: 'number',
        },
        {
          text: this.$t('label:inbound_batch', null, 1),
          value: 'InboundBatch',
        },
        {
          text: this.$t('label:outbound_batch', null, 1),
          value: 'OutboundBatch',
        },
        {
          text: this.$t('label:updated_at', null, 1),
          value: 'updatedAt',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        {
          text: this.$t('label:deleted_at', null, 1),
          value: 'deletedAt',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
      );
      return headers;
    },
  },

  watch: {
    productTemplate: {
      deep: true,
      async handler(n, o) {
        const value = n?.ItemId;
        const oValue = o?.ItemId;
        if (value !== oValue) {
          this.getProducts({ ItemId: value });
          this.getList({
            ...this.list,
            offset: 0,
            filters: {
              ...this.list.filters,
              ProductId: { type: 'select', value: Number(value) },
            },
          });
        }
      },
    },
  },

  methods: {
    ...mapActions({
      getList: 'package/getList',

      getProducts: 'product/getItems',
      getSectors: 'sector/getItems',
    }),

    onExport() {
      const csvData = [
        [
          '#',
          'ID',
          'Product',
          'Customer',
          'Status',
          'Sector',
          'Package Quantity',
          'Inbound Batch',
          'Outbound Batch',
          'Updated At',
          'Deleted At',
        ],
        ...this.list.items.map((v, i) => [
          this.list.offset + i + 1,
          v.ItemId,
          this.mProductName(v),
          this.mCustomer(v.CustomerId),
          this.$t(v.Status),
          this.mSectorName(v),
          v.Quantity,
          v.InboundBatch,
          v.OutboundBatch,
          v.updatedAt,
          v.deletedAt,
        ]),
      ];

      const link = document.createElement('a');

      const blob = new Blob([stringify(csvData, { delimiter: ';' })], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'packages.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    mProductName(item) {
      return item.ProductBundle?.Product?.Name || '-';
    },

    mSectorName(item) {
      return item.Sector?.Name || '-';
    },

    mCustomer(CustomerId) {
      const customer = this.customers.find(
        ({ ItemId }) => ItemId === CustomerId,
      );
      return customer ? customer.Name : '-';
    },

    onForm(ItemId) {
      switch (this.$route.name) {
        case 'storage-items-list':
          this.$router.push({
            name: 'storage-items-package-form',
            params: { ItemId },
          });
          break;
        case 'storage-package-list':
          this.$router.push({
            name: 'storage-package-form',
            params: { ItemId },
          });
          break;
        default:
          throw new Error(
            'Package parent route.name redirect config not defined',
          );
      }
    },
  },
};
</script>
