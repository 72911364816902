<template>
  <storage-page class="y-overflow custom-bg">
    <v-col sm="12">
      <c-package-index />
    </v-col>
    <router-view />
  </storage-page>
</template>

<script>

import StoragePage from '@/components/StoragePage.vue';
import CPackageIndex from '@/components/package/index/storage.vue';

export default {
  name: 'ViewsStoragePackage',
  components: {
    StoragePage,
    CPackageIndex,
  },
};
</script>
