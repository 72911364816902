import Vue from 'vue';

export const api = `https://${window.location.hostname}:3000/`;

export default {
  async getPortalList(
    { commit, getters },
    {
      limit = getters.list?.limit,
      offset = getters.list?.offset,
      filters = getters.list?.filters,
    },
  ) {
    commit('SET_LOADING_LIST', true);
    try {
      const { data } = await Vue.axios.post(`${api}${getters.namespace}/portal-list`,
        {
          ...getters.list, limit, offset, filters, items: undefined, total: undefined,
        });
      commit('SET_LIST', {
        ...getters.list, offset, filters, limit, ...data,
      });
    } catch (e) {
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
    }
    commit('SET_LOADING_LIST', false);
  },
};
