export default (roles) => {
  const [
    admin,
    manager,
    portalUser,
    // engineer,
    // driver,
  ] = [
    roles.includes('WMS.Admin'),
    roles.includes('WMS.Manager'),
    roles.includes('WMS.Portaluser'),
    // roles.includes('XTRA.Manager'),
    // roles.includes('XTRA.ChiefEngineer'),
    // roles.includes('XTRA.Engineer'),
    // roles.includes('XTRA.Driver'),
  ];
  if (admin || manager) {
    return { name: 'movement-inbound-order-list' };
  }
  if (portalUser) {
    return { name: 'portal-inbound-order-list' };
  }
  // if (chiefEngineer) {
  //   return { name: 'movement-service-list' };
  // }
  // if (engineer) {
  //   return { name: 'service-task-list' };
  // }
  // if (driver) {
  //   return { name: 'delivery-task-list' };
  // }
  throw new Error('User has no WMS roles');
};
