import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {

    BundleId: null,
    ProductId: null,
    PackageId: null,

    Quantity: null,

    BestBeforeDate: null,

    Bundles: [],

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
