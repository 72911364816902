<template>
  <div
    :class="{'pl-4': $vuetify.breakpoint.mdAndUp,
             logoSmAndDown: $vuetify.breakpoint.smAndDown }"
    class="logo-wrapper"
  >
    <img
      :src="`/${$auth.tenant}.png`"
      class="logo"
      @click="onLogo"
    >
    <v-toolbar-title
      style="cursor:pointer"
      @click="onLogo"
    >
      <span class="d-none d-sm-flex">{{ title }}</span>
    </v-toolbar-title>
  </div>
</template>

<script>

import defaultRoute from '../auth/defaultRoute';
import { getInstance } from '../auth/index';

export default {
  name: 'AppLogo',
  computed: {
    title() {
      return this.$tenant ? this.$tenant.Name : '';
    },
  },
  methods: {
    onLogo() {
      this.$router.push(defaultRoute(getInstance().roles));
    },
  },
};
</script>

<style lang="scss" scoped>

.logoSmAndDown {
  justify-content: center;
}
.logo-wrapper {
  display: flex;
  // flex-grow: 1;
  // flex-wrap: nowrap;
  // flex-direction: row;
  // align-content: center;
  align-items: center;
  .logo {
    height: 26px;
    margin-right: 20px;
    cursor: pointer;
  }
  .title {
    height: 100%;
    line-height: 200%;
    display: block;
  }
}
</style>
