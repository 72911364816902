<template>
  <div
    style="align-self: center; width: 100vw; position: absolute;"
  >
    <v-hover
      v-slot="{ hover }"
    >
      <v-select
        :value="WarehouseId"
        :placeholder="$t('label:warehouse_select')"
        :loading="warehouseLoading || loading"
        :disabled="warehouseLoading || loading"
        dense
        solo
        single-line
        light
        rounded
        :background-color="hover ? '#cccccc' : '#dddddd'"
        hide-details
        style="max-width:300px"
        class="mx-auto"
        item-text="Name"
        item-value="ItemId"
        :items="warehouses"
        :menu-props="{ bottom: true,
                       offsetY: true,
                       rounded: 'lg b-xl',
                       transition: 'slide-y-transition' }"
        clearable
        @input="onChange"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.Name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
    </v-hover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getInstance } from '../auth/index';

export default {
  name: 'AppWarehouse',

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters({
      warehouses: 'warehouse/items',
      WarehouseId: 'warehouse/WarehouseId',
      warehouseLoading: 'warehouse/loading',
    }),
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setWarehouseId: 'warehouse/setWarehouseId',
    }),
    initialize() {
      this.setWarehouseId(this.$auth.WarehouseId);
    },
    async onChange(v) {
      this.loading = true;
      await getInstance().updateWarehouse(v);
      this.setWarehouseId(this.$auth.WarehouseId);

      this.loading = false;
    },
  },
};
</script>
