<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="template"
      tile
      :loading="loadingOutboundOrder"
      height="95vh"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title>
        <span>
          {{ $t("label:order_outbound", null, 1) }}
        </span>
        <v-chip class="ml-4">
          {{ $t(template.Status) }}
        </v-chip>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        style="height: 100%"
        class="pt-2"
      >
        <v-form
          ref="form"
          style="height: inherit"
        >
          <v-row
            style="height: inherit"
            class="ma-0"
          >
            <c-main
              :collapse="collapse"
              :after="after"
              @collapse="(v) => collapse = v"
              @on-change-customer="onChangeCustomer()"
            />
            <v-col
              class="pr-0"
              style="width: calc(100% - 25%)"
            >
              <v-expansion-panels
                v-model="panel"
                class="panels-styled"
                focusable
              >
                <c-orderline-outbound
                  v-for="(Orderline, orderlineIndex) in template.Orderlines"
                  ref="outbound-orderlines"

                  :key="`orderline-${orderlineIndex}`"

                  :path="[orderlineIndex]"

                  :disable="{
                    main: after('Planned', Orderline),
                    unique: after('Planned', Orderline),
                    delete: after('Plannable', Orderline)
                  }"

                  @remove-package="onRemovePackage(orderlineIndex)"
                  @remove-orderline="onRemoveOrderline(orderlineIndex)"
                >
                  <template #packages>
                    <v-expansion-panels
                      v-model="packagesPanel"
                      class="panels-styled"
                      focusable
                    >
                      <v-expansion-panel
                        v-for="(Package, packageIndex) in template.Orderlines[orderlineIndex]
                          .OutboundPackages"
                        :key="`orderline-${packageIndex}`"
                        class="mb-2 rounded"
                      >
                        <v-expansion-panel-header class="py-2">
                          <template #default="{ open }">
                            <v-row
                              align="center"
                              dense
                            >
                              <h4
                                class="text-uppercase font-weight-bold"
                                :class="{ 'white--text': !open }"
                              >
                                {{ $t("label:package", null, 1) }} #{{ packageIndex + 1 }}
                              </h4>
                              <v-spacer />
                              <v-fade-transition>
                                <v-row
                                  v-if="open"
                                  justify="end"
                                  class="ma-0"
                                  @click.stop
                                >
                                  <v-col
                                    cols="auto"
                                    class="pb-0"
                                  >
                                    <v-text-field
                                      v-model="template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].OutboundBatch"
                                      :label="$t('label:outbound_batch', null, 1)"
                                      :disabled="after('Planned', Orderline)"
                                      class="mt-3"
                                      style="width: 150px"
                                      dense
                                    />
                                  </v-col>
                                  <v-col
                                    cols="auto"
                                    class="mr-8 pb-0"
                                  >
                                    <v-badge
                                      color="info"
                                      :content="`Σ: ${total(
                                        template.Orderlines[orderlineIndex]
                                          .OutboundPackages[packageIndex]
                                      )}`"
                                      overlap
                                      :value="template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].Quantity > 1"
                                      offset-x="45px"
                                      offset-y="12px"
                                    >
                                      <c-number
                                        :value="template.Orderlines[orderlineIndex]
                                          .OutboundPackages[packageIndex].Quantity"
                                        style="width: 150px"
                                        class="mt-3"
                                        dense
                                        disabled
                                        :label="$t('label:quantity:package', null, 1)"
                                      />
                                    </v-badge>
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-if="!open"
                                  justify="end"
                                  class="ma-0"
                                >
                                  <v-col
                                    cols="auto"
                                    style="width: 150px"
                                    class="pb-0"
                                  >
                                    <h5
                                      v-if="template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].OutboundBatch"
                                      style="position: absolute; transform: translateY(-14px)"
                                      class="font-weight-thin white--text"
                                    >
                                      {{ $t('label:outbound_batch', null, 1) }}
                                    </h5>
                                    <span class="white--text">
                                      {{ template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].OutboundBatch }}
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="auto"
                                    style="width: 150px"
                                    class="mr-8 pb-0"
                                  >
                                    <h5
                                      v-if="template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].Quantity"
                                      style="position: absolute; transform: translateY(-14px)"
                                      class="font-weight-thin white--text"
                                    >
                                      {{ $t('label:quantity:package', null, 1) }}
                                    </h5>
                                    <span class="white--text">
                                      {{ template.Orderlines[orderlineIndex]
                                        .OutboundPackages[packageIndex].Quantity }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-fade-transition>
                              <v-col
                                cols="auto"
                                class="pb-0"
                                @click.stop
                              >
                                <c-package-uniques
                                  ref="uniques"
                                  :path="[orderlineIndex, 'OutboundPackages', packageIndex]"
                                  :reset="resetTemplate"
                                  :template="template"
                                  :open="open"
                                />
                              </v-col>
                              <v-col
                                cols="auto"
                                class="pb-0"
                                @click.stop
                              >
                                <v-tooltip top>
                                  <template #activator="{ on }">
                                    <v-btn
                                      :disabled="after('Check-in', Orderline)"
                                      icon
                                      class="mr-4"
                                      v-on="on"
                                      @click="onRemovePackage(orderlineIndex, packageIndex)"
                                    >
                                      <v-icon :class="{ 'white--text': !open }">
                                        mdi-delete-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  {{ $t('label:delete', null, 1) }}
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="content-no-padding"
                          eager
                        >
                          <c-package-main-outbound
                            ref="packages"
                            type="outbound"

                            :path="[orderlineIndex,'OutboundPackages', packageIndex]"
                            :template="template"
                            :reset="resetTemplate"
                            :disable="{
                              main: true,
                              sector: true,
                            }"
                          >
                            <template #product-bundle>
                              <c-product-bundle-main
                                :path="[orderlineIndex,'OutboundPackages', packageIndex]"
                                :template="template"
                                :reset="resetTemplate"
                                :disable="{
                                  main: true,
                                  sector: true,
                                }"
                              />
                            </template>
                            <template #transaction-inbound-handling>
                              <c-transaction-inbound-handling
                                :path="[orderlineIndex,'OutboundPackages', packageIndex]"
                                :template="template"
                                :reset="resetTemplate"
                                :disable="{
                                  main: true,
                                  sector: true,
                                }"
                              />
                            </template>
                            <template #transaction-transformation-handling>
                              <c-transaction-transformation-handling
                                :path="[orderlineIndex,'OutboundPackages', packageIndex]"
                                :template="template"
                                :reset="resetTemplate"
                                :disable="{
                                  main: true,
                                  sector: true,
                                }"
                              />
                            </template>
                            <template #transaction-storage-handling>
                              <c-transaction-storage-handling
                                :path="[orderlineIndex,'OutboundPackages', packageIndex]"
                                :template="template"
                                :reset="resetTemplate"
                                :disable="{
                                  main: true,
                                  sector: true,
                                }"
                              />
                            </template>
                            <template
                              v-if="after('Planned', Orderline)"
                              #transaction-outbound-handling
                            >
                              <c-transaction-outbound-handling
                                :path="[orderlineIndex, 'OutboundPackages', packageIndex]"
                                :template="template"
                                :reset="resetTemplate"
                                :disable="{
                                  main: after('Check-out', Orderline),
                                }"
                              />
                            </template>
                          </c-package-main-outbound>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </c-orderline-outbound>
              </v-expansion-panels>
              <v-btn
                :disabled="!template.CustomerId || !template.WarehouseId || after('Planned')"
                color="primary"
                class="mt-1 mb-3"
                @click="onAddOrderline"
              >
                {{ $t('label:order_line', null, 1) }}
                <v-icon right>
                  fal fa-plus
                </v-icon>
              </v-btn>
              <c-package-index
                v-if="showPackages"
                :loading="loadingOutboundOrder || loadingPackage"
                :static-filters="staticFilters"
                :disable="{create: true}"
              >
                <template #controls="{item}">
                  <v-btn
                    :disabled="!addable(item.ItemId)"
                    icon
                    @click.stop="onAddPackage(item.ItemId)"
                  >
                    <v-icon
                      :disabled="loadingOutboundOrder || loadingPackage"
                      color="accent"
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
              </c-package-index>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loadingOutboundOrder"
          @click.stop="onPrint(template.ItemId)"
        >
          {{ $t('label:print_order') }}
          <v-icon right>
            mdi-printer
          </v-icon>
        </v-btn>
        <v-btn
          v-if="true"
          color="warning"
          :loading="loadingOutboundOrder"
          @click="onSync"
        >
          {{ $t("btn:send_to_tms") }}
        </v-btn>
        <v-btn
          color="info"
          :loading="loadingOutboundOrder"
          href="https://vdl.slim.fitsoft.eu/"
          target="_blank"
        >
          {{ $t('label:tms') }} | {{ template.TmsId }}
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-btn
          color="success"
          :loading="loadingOutboundOrder"
          :disabled="loadingOutboundOrder"
          @click="() => onSave()"
        >
          {{ $t("btn:save") }}
        </v-btn>
        <v-btn
          v-if="nextStatus.value"
          color="primary"
          :loading="loadingOutboundOrder"
          :disabled="loadingOutboundOrder || !nextStatus.valid || after('Handling')"
          @click="onSave(nextStatus.value)"
        >
          {{ nextStatus.name }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import COrderlineOutbound from '@/components/orderline/outbound.vue';
import CPackageMainOutbound from '@/components/package/main/outbound.vue';
import CPackageUniques from '@/components/package/uniques.vue';
import CProductBundleMain from '@/components/product-bundle/main.vue';
import CTransactionInboundHandling from '@/components/transaction/inbound-handling.vue';
import CTransactionTransformationHandling from '@/components/transaction/transformation-handling.vue';
import CTransactionOutboundHandling from '@/components/transaction/outbound-handling.vue';
import CTransactionStorageHandling from '@/components/transaction/storage-handling.vue';
import CMain from '@/components/outbound-order/main.vue';
import CPackageIndex from '@/components/package/index/storage.vue';

export default {
  name: 'OutboundOrderFormMovement',
  components: {
    COrderlineOutbound,

    CPackageMainOutbound,
    CPackageUniques,
    CProductBundleMain,

    CTransactionInboundHandling,
    CTransactionOutboundHandling,
    CTransactionTransformationHandling,
    CTransactionStorageHandling,
    CMain,
    CPackageIndex,
  },

  data: () => ({
    collapse: false,
    panel: null,
    packagesPanel: null,
  }),

  computed: {
    ...mapGetters({
      loadingOutboundOrder: 'outboundOrder/loading',
      loadingPackage: 'package/loading',
      template: 'outboundOrder/template',

      orderlineDefaultTemplate: 'orderline/defaultTemplate',
      packageDefaultTemplate: 'package/defaultTemplate',
      productBundleDefaultTemplate: 'productBundle/defaultTemplate',
      bundleDefaultTemplate: 'bundle/defaultTemplate',

      WarehouseId: 'warehouse/WarehouseId',
    }),

    disabledSync() {
      return this.template.TmsId || !this.template.ItemId;
    },

    showPackages() {
      if (this.after('Planned')) {
        return false;
      }
      return this.panel !== null && this.panel !== undefined;
    },

    nextStatus() {
      let valid;
      let value;
      let name;

      if (!this.template) {
        return { value, valid, name };
      }

      switch (this.template.Status) {
        case 'New':
          valid = this.template.Orderlines.length > 0;
          value = 'Plannable';
          name = this.$t('btn:to_plannable', null, 1);
          break;
        case 'Planned':
          // valid = this.template.Orderlines
          //   .flatMap(({ OutboundPackages }) => OutboundPackages)
          //   .filter((v) => Boolean(v?.ProductBundle))
          //   .length > 0;
          // value = 'Check-in';
          // name = this.$t('btn:to_check-in', null, 1);
          // break;
          // eslint-disable-next-line no-fallthrough
        case 'Check-in':
          // valid = true;
          // value = 'Handling';
          // name = this.$t('btn:to_handling', null, 1);
          // break;
          // eslint-disable-next-line no-fallthrough
        case 'Handling':
          // valid = true;
          // value = 'Done';
          // name = this.$t('btn:to_done', null, 1);
          // break;
          // eslint-disable-next-line no-fallthrough
        case 'Done':
        default:
          valid = false;
          break;
        // default: throw new Error('Invalid Status');
      }
      return { value, valid, name };
    },

    staticFilters() {
      const filters = {
        WarehouseId: { type: 'select', value: this.template.WarehouseId },
        CustomerId: { type: 'select', value: this.template.CustomerId },
        OutboundOrderlineId: { type: 'equal', value: null },
        Status: { type: 'select', value: 'Stored' },
      };

      if (this.panel !== null && this.panel !== undefined) {
        const value = this.template.Orderlines[this.panel].ProductId;
        if (!value) {
          return filters;
        }
        filters.ProductId = { type: 'select', value };
      }
      return filters;
    },
  },

  async beforeMount() {
    await this.resetTemplate({ Orderlines: [] });
  },
  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      export: 'outboundOrder/export',

      update: 'outboundOrder/update',
      create: 'outboundOrder/create',
      resetTemplate: 'outboundOrder/resetTemplate',
      getTemplate: 'outboundOrder/getItem',
      getPackage: 'package/getItem',

      getContactItems: 'contact/getItems',
      setContactItems: 'contact/setItems',

      productGetItem: 'product/getItem',
    }),

    async onSync() {
      await this.export(this.template);
    },

    addable(PackageId) {
      return !this.template.Orderlines.flatMap(
        ({ OutboundPackages }) => OutboundPackages.map(
          ({ ItemId }) => ItemId,
        ),
      ).includes(PackageId);
    },

    total(Package) {
      return Package.ProductBundle.Bundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), Package.ProductBundle.Quantity,
      ) * Package.Quantity;
    },

    baseTotal(BasePackage) {
      if (!BasePackage.BaseProductBundle?.BaseBundles) {
        return 1;
      }
      return BasePackage.BaseProductBundle.BaseBundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), BasePackage.BaseProductBundle.Quantity,
      );
    },

    validateQuantity({ Quantity, OutboundPackages }) {
      return [
        (v) => (v !== undefined && v !== null) || this.$t('validation:required'),
        (v) => (v <= Quantity) || this.$t('validation:orderline:exceeds:solus'),
        () => (OutboundPackages.reduce(
          (t, v) => Number(v.Quantity) + Number(t), 0,
        ) <= Quantity) || this.$t('validation:orderline:exceeds:along'),
      ];
    },

    after(status, orderline) {
      const weight = {
        Plannable: 0,
        Planned: 1,
        'Check-in': 2,
        Handling: 3,
        'Check-out': 4,
        Done: 5,
      };

      const heaviest = orderline
        ? weight[orderline.Status]
        : (this.template.Orderlines.map(
          ({ Status }) => (weight[Status] || 0),
        ).sort()[0]);

      return heaviest > weight[status];
    },

    async onPrint(ItemId) {
      this.docmosisLoading = true;
      const { data } = await this.axios.get(
        `https://${window.location.hostname}:3000/outbound-orders/${ItemId}/handling/docmosis`,
      );
      this.docmosisLoading = false;
      const base64atob = atob(data[0].base64);
      const byteNumbers = new Array(base64atob.length);
      for (let i = 0; i < base64atob.length; i += 1) {
        byteNumbers[i] = base64atob.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(byteNumbers)], {
        type: 'application/pdf',
      });

      const tab = window.open('/');
      tab.URL.createObjectURL(new File([blob], data.filename));
      tab.onload = () => {
        tab.location = URL.createObjectURL(blob);
      };
    },

    onChangeCustomer(keep = false) {
      if (!keep) {
        this.template.ContactId = null;
      }
      this.setContactItems([]);
      this.getContactItems({ CustomerId: this.template.CustomerId });
    },

    onClose() {
      this.$router.push({ name: 'movement-outbound-order-list' });
    },

    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getTemplate(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({
          WarehouseId: this.WarehouseId,
        });
      }
      if (this.template.CustomerId) {
        this.onChangeCustomer(true);
      }
      this.resetValidation();
    },

    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      (this.$refs.packages || []).forEach(({ $refs }) => $refs.form.resetValidation());
    },

    async onSave(Status = this.template.Status) {
      if ([this.$refs.form.validate(),
        ...(this.$refs.packages || []).map(({ $refs }) => $refs.form.validate()),
        ...(this.$refs?.uniques || []).map(
          ({ $refs }) => ($refs?.form ? $refs?.form.validate() : true),
        ),
      ].includes(false)) {
        this.$toasted.error('Form invalid');
        return;
      }

      if (this.$route.params.ItemId) {
        if (await this.update({ ...this.template, Status })) {
          this.onClose();
        }
      } else if (await this.create({ ...this.template, Status })) {
        this.onClose();
      }
    },

    async onAddOrderline() {
      this.panel = this.template.Orderlines.length;
      this.collapse = true;
      this.resetTemplate({
        ...this.template,
        Orderlines: [
          ...this.template.Orderlines,
          {
            ...JSON.parse(JSON.stringify(this.orderlineDefaultTemplate)),
          },
        ],
      });
    },

    async onAddPackage(ItemId) {
      const { Orderlines } = this.template;

      const { item } = await this.getPackage(ItemId);

      Orderlines[this.panel] = {
        ...Orderlines[this.panel],
        ProductId: item.ProductBundle.ProductId,
        OutboundPackages: [
          ...Orderlines[this.panel].OutboundPackages,
          item,
        ],
      };

      this.resetTemplate({ ...this.template, Orderlines });

      this.$refs['outbound-orderlines'][this.panel].onProductSearch();
    },

    onPackageQuantityUpdate(Quantity, orderlineIndex, packageIndex) {
      const { Orderlines } = this.template;
      Orderlines[orderlineIndex].OutboundPackages[packageIndex].Quantity = Quantity;
      this.resetTemplate({ ...this.template, Orderlines });
    },

    onRemoveOrderline(orderlineIndex) {
      const { Orderlines } = this.template;
      this.panel = null;
      Orderlines.splice(orderlineIndex, 1);

      this.resetTemplate({ ...this.template, Orderlines });
    },

    onRemovePackage(orderlineIndex, packageIndex) {
      const { template } = this;

      template.Orderlines[orderlineIndex].OutboundPackages
        .splice(packageIndex, 1);

      this.resetTemplate(template);
      this.packagesPanel = packageIndex - 1;
    },
  },
};
</script>
