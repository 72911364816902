<template>
  <v-col
    md="3"
    class="pl-0 pr-2"
    :class="{ collapsed: collapse,
              expanded: !collapse }"
  >
    <v-card
      class="pa-2"
      :class="{ 'd-flex': collapse }"
      color="#f5f5f5"
      elevation="0"
      outlined
      min-height="100%"
      height="inherit"
    >
      <div
        v-if="!collapse"
        :class="{ 'd-none': collapse }"
      >
        <v-autocomplete
          v-model="template.CustomerId"
          :disabled="Boolean(template.ItemId) || template.Invoicelines &&
            template.Invoicelines.length > 0"
          :label="$t('label:customer', null, 1)"
          :items="customerItems"
          aria-autocomplete="off"
          item-text="Name"
          item-value="ItemId"
          required
          :rules="mnValidation.required"
          @input="() => $emit('on-change-customer')"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  disabled
                  icon
                  small
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:customer_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="template.ContactId"
          :label="$t('label:contact', null, 1)"
          :items="contactItems"
          :item-text="
            (c) => [c.FirstName, c.MiddleName, c.LastName].join(' ')
          "
          :loading="contactLoading"
          aria-autocomplete="off"
          item-value="ItemId"
          :rules="mnValidation.required"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  disabled
                  v-on="on"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              {{ $t("label:contact_add") }}
            </v-tooltip>
          </template>
        </v-autocomplete>

        <v-textarea
          v-model="template.Description"
          filled
          :label="$t('label:description', null, 1)"
          rows="3"
          hide-details="auto"
        />
      </div>
      <div class="ma-auto">
        <h4
          v-if="collapse && $vuetify.breakpoint.lgAndDown"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h4>
        <h1
          v-if="collapse && $vuetify.breakpoint.xlOnly"
          class="text-uppercase font-weight-bold black--text customer-info"
        >
          {{ $t("text:customer_info") }}
        </h1>
      </div>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-avatar
            size="24px"
            v-bind="attrs"
            style="top: -12px; right: -9px; position: absolute;"
            class="info-color-hover pointer-on-hover"
            v-on="on"
            @click="$emit('collapse', !collapse)"
          >
            <v-icon
              small
              class="white--text"
            >
              {{ !collapse ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
          </v-avatar>
        </template>
        <span v-if="!collapse">{{ $t("text:hide_customer_data") }}</span>
        <span v-else>{{ $t("text:show_customer_data") }}</span>
      </v-tooltip>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'InvoiceMain',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      template: 'invoice/template',

      contactLoading: 'contact/loading',

      customerItems: 'customer/items',
      contacts: 'contact/items',
    }),

    contactItems() {
      return this.contacts.filter(
        ({ ItemId, deleted }) => !deleted || ItemId === this.template.ContactId,
      );
    },
  },
};
</script>
