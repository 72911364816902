<template>
  <v-row
    align="center"
    justify="center"
  >
    {{ $t('label:signing_in') }}
  </v-row>
</template>

<script>

import defaultRoute from '../defaultRoute';
import { getInstance, AuthReady } from '../index';

export default {
  name: 'AuthPage',
  mounted() {
    this.redirect();
  },
  methods: {
    async redirect() {
      await AuthReady();
      this.$router.push(defaultRoute(getInstance().roles));
    },
  },
};
</script>

<style scoped>
</style>
