var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.file)?_c('div',{style:({width: _vm.img ? _vm.cWidthPhoto : 'inherit'})},[(!_vm.img)?_c('v-btn-toggle',{staticClass:"d-flex align-center",attrs:{"rounded":"","borderless":"","background-color":"default"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.download)?_c('v-btn',_vm._g({attrs:{"small":"","icon":"","link":"","href":_vm.s3(_vm.file.Key, true)}},on),[_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-download ")])],1):_vm._e()]}}],null,false,1839270580)},[_vm._v(" "+_vm._s(_vm.$t('label:download'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.open)?_c('v-btn',_vm._g({attrs:{"icon":"","link":"","small":"","target":"_blank","href":_vm.s3(_vm.file.Key, false)}},on),[_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-open-in-new ")])],1):_vm._e()]}}],null,false,2543935894)},[_vm._v(" "+_vm._s(_vm.$t('label:open_in_tab'))+" ")]),_c('span',{staticClass:"text-truncate px-2",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.cName)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.deletable)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.onDelete()}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,false,2928840872)},[_vm._v(" "+_vm._s(_vm.$t('label:delete'))+" ")])],1):_vm._e(),(_vm.img)?_c('v-card',{staticClass:"ma-1"},[_c('v-img',{attrs:{"src":_vm.s3(_vm.file.Key),"aspect-ratio":"1"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-spacer'),_c('v-btn-toggle',{attrs:{"rounded":"","borderless":"","background-color":"default"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.download)?_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.mdAndUp,"icon":"","link":"","href":_vm.s3(_vm.file.Key, true)}},on),[_c('v-icon',[_vm._v(" mdi-download ")])],1):_vm._e()]}}],null,false,3809453496)},[_vm._v(" "+_vm._s(_vm.$t('label:download'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.open)?_c('v-btn',_vm._g({attrs:{"icon":"","link":"","small":_vm.$vuetify.breakpoint.mdAndUp,"target":"_blank","href":_vm.s3(_vm.file.Key, false)}},on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1):_vm._e()]}}],null,false,1099695642)},[_vm._v(" "+_vm._s(_vm.$t('label:open_in_tab'))+" ")]),(_vm.expandable)?_c('v-menu',{attrs:{"close-on-content-click":false,"origin":"center center","transition":"scale-transition","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":_vm.$vuetify.breakpoint.mdAndUp}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-arrow-expand-all ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('label:expand'))+" ")])])]}}],null,false,3532910003)},[_c('v-card',[_c('v-img',{attrs:{"src":_vm.s3(_vm.file.Key),"min-width":"500px","max-width":"1000px","max-height":"1000px","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)})],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.deletable)?_c('v-btn',_vm._g({attrs:{"icon":"","small":_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){return _vm.onDelete()}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,false,1504707004)},[_vm._v(" "+_vm._s(_vm.$t('label:delete'))+" ")])],1)],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }