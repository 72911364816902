import Vue from 'vue';

const EventBus = new Vue();

export default {
  install(vue) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$eventBus = EventBus;
  },
};
