<template>
  <finance-page class="y-overflow custom-bg">
    <v-col sm="12">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-col>
  </finance-page>
</template>

<script>

import FinancePage from '@/components/FinancePage.vue';

export default {
  name: 'ViewsFinanceInvoices',
  components: {
    FinancePage,
  },
};
</script>
