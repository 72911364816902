import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    TmsId: null,
    WarehouseId: null,
    Name: '',
    Description: '',
    Date: null,
    Billable: true,
    InitiatorType: 'Customer',
    InitiatorId: null,
    Status: 'Planned',

    Orderlines: [],

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
