<template>
  <v-form
    ref="form"
    color="#dddddd"
  >
    <v-row
      class="ma-0"
      dense
    >
      <v-col>
        <v-autocomplete
          disabled
          :value="value.SectorId"
          :label="$t('label:sector', null, 1)"
          :items="sectors"
          :loading="sectorLoading"
          readonly
          item-value="ItemId"
          item-text="Name"
          flat
          hide-no-data
          clearable
          aria-autocomplete="off"
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field
          disabled
          :value="value.Start ? $moment(value.Start).format('DD-MM-YYYY') : 'DD-MM-YYYY'"
          :label="$t('label:start', null, 1)"
          readonly
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field
          disabled
          :value="value.End ? $moment(value.End).format('DD-MM-YYYY') : 'DD-MM-YYYY'"
          :label="$t('label:end', null, 1)"
          readonly
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UsageSector',

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex][UsageIndex]',
    },
  },

  data: () => ({
    sectors: [],
  }),

  computed: {
    ...mapGetters({
      sectorLoading: 'sector/loading',
    }),

    value() {
      if (this.path.length === 1) {
        return this.template.StorageHandling.SectorUsages[this.path[0]];
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]].StorageHandling
        .SectorUsages[this.path[3]];
    },
  },

  mounted() {
    this.onSectorSearch();
  },

  methods: {
    ...mapActions({
      sectorReturnItems: 'sector/returnItems',
    }),

    onSectorSearch() {
      clearTimeout(this.sectorTimeout);
      this.sectorTimeout = setTimeout(async () => {
        const filter = {};
        if (this.value?.SectorId) {
          filter.ItemId = { value: this.value.SectorId, type: 'select' };
        }
        this.sectors = await this.sectorReturnItems(filter);
      }, 500);
    },
  },
};
</script>
