<template>
  <storage-page class="y-overflow custom-bg">
    <v-col sm="12">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-col>
  </storage-page>
</template>

<script>

import StoragePage from '@/components/StoragePage.vue';

export default {
  name: 'ViewsStorageCarriers',
  components: {
    StoragePage,
  },
};
</script>
