import Index from '@/auth/views/Index.vue';
import { getInstance } from './index';
import defaultRoute from './defaultRoute';

export default [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      if (getInstance().isAuthenticated) {
        return next(defaultRoute(getInstance().roles));
      }
      return getInstance().loginWithRedirect();
    },
  },
  {
    name: 'login',
    path: '',
    beforeEnter: (to, from, next) => {
      if (getInstance().isAuthenticated) {
        return next(defaultRoute(getInstance().roles));
      }
      return getInstance().loginWithRedirect();
    },
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      if (getInstance().isAuthenticated) {
        return next(defaultRoute(getInstance().roles));
      }
      return getInstance().loginWithRedirect();
    },
  },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: (to, from, next) => {
      getInstance().logout({ returnTo: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}` });
      return next({ name: 'login' });
    },
  },
  {
    name: 'authenticate',
    path: '/authenticate',
    component: Index,
  },
];
