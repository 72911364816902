<template>
  <transition name="slide-fade">
    <v-row>
      <v-col>
        <v-card
          outlined
          :disabled="loading"
          :loading="loading"
          :class="{'pt-2': !loading}"
        >
          <template slot="progress">
            <progress-bar />
          </template>
          <c-vuex-list
            :headers="headers"
            :get-list="getList"
            :list="list"
            :loading="loading"
          >
            <template #footer-prepend>
              <v-btn
                small
                color="primary darken-1"
                class="width-auto"
                :disabled="!productGroupTemplate || !productGroupTemplate.ItemId"
                @click.stop="onForm()"
              >
                {{ $t('label:product', null, 1) }}
                <v-icon
                  small
                  right
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <template #item="{ item }">
              <tr
                :class="{ itemSelected: mSelected(item.ItemId),
                          primary: mSelected(item.ItemId) }"
                @click.stop="onSelect(item)"
              >
                <td>
                  <v-btn
                    icon
                    @click.stop="onForm(item.ItemId)"
                  >
                    <v-icon
                      :disabled="loading"
                    >
                      mdi-open-in-app
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ mCustomer(item.CustomerId) }}
                </td>
                <td>
                  {{ item.ProductGroup.Name }}
                </td>
                <td>
                  {{ item.Name }}
                </td>
                <td>
                  {{ $moment(item.updatedAt).format('DD-MM-YYYY') }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        class="mr-2 float-right"
                        v-on="on"
                      >
                        <v-icon
                          class="delete-icon"
                          @click="() => onDelete(item)"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('label:delete') }}
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </c-vuex-list>
        </v-card>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductIndex',

  computed: {
    ...mapGetters({
      customers: 'customer/items',
      loading: 'product/loadingList',
      productGroupLoading: 'productGroup/loading',
      productGroupItems: 'productGroup/items',
      list: 'product/list',

      productGroupTemplate: 'productGroup/template',
      productTemplate: 'product/template',
    }),
    headers() {
      return [
        {
          text: '', value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:customer', null, 1),
          value: 'CustomerId',
          clearable: true,
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:product_group', null, 1),
          value: 'ProductGroupId',
          type: 'select',
          options: {
            action: this.getProductGroups,
            items: this.productGroupItems,
            itemValue: 'ItemId',
            itemText: 'Name',
            loading: this.productGroupLoading,
          },
        },
        {
          text: this.$t('label:name', null, 1), value: 'Name',
        },
        {
          text: this.$t('label:updated_at', null, 1), value: 'updatedAt', searchable: false,
        },
        {
          text: '', value: '', searchable: false, sortable: false,
        },
      ];
    },
  },

  watch: {
    productGroupTemplate: {
      deep: true,
      async handler(n, o) {
        const value = n?.ItemId;
        const oValue = o?.ItemId;
        if (value !== oValue) {
          this.getProductGroups({ ItemId: value });
          this.getList({
            ...this.list,
            offset: 0,
            filters: { ...this.list.filters, ProductGroupId: { type: 'select', value: Number(value) } },
          });
        }
      },
    },
  },

  methods: {
    ...mapActions({
      getList: 'product/getList',
      getProductGroups: 'productGroup/getItems',

      resetProductGroupTemplate: 'productGroup/resetTemplate',
      resetProductTemplate: 'product/resetTemplate',
      deleteItem: 'product/destroy',
    }),

    mCustomer(CustomerId) {
      const customer = this.customers.find(
        ({ ItemId }) => ItemId === CustomerId,
      );
      return customer ? customer.Name : '-';
    },

    mSelected(ItemId) {
      return this.productTemplate?.ItemId === ItemId;
    },

    onSelect(item) {
      if (item.ItemId !== this.productTemplate?.ItemId) {
        this.resetProductTemplate(item);
        if (item.ProductGroupId !== this.productGroupTemplate?.ItemId) {
          this.resetProductGroupTemplate(null);
        }
      }
    },

    onForm(ItemId) {
      this.$router.push({ name: 'storage-items-product-form', params: { ItemId } });
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
