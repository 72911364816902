import Vue from 'vue';
import Vuex from 'vuex';

import user from './user/index';

import bundle from './bundle/index';
import carrier from './carrier/index';
import cluster from './cluster/index';
import contact from './contact/index';
import customer from './customer/index';
import baseBundle from './base-bundle/index';
import basePackage from './base-package/index';
import baseProductBundle from './base-product-bundle/index';
import handling from './handling/index';
import inboundOrder from './inbound-order/index';
import outboundOrder from './outbound-order/index';
import orderline from './orderline/index';
import packageStore from './package/index';
import product from './product/index';
import productBundle from './product-bundle/index';
import productGroup from './product-group/index';
import resource from './resource/index';
import sector from './sector/index';
import transaction from './transaction/index';
import transformationOrder from './transformation-order/index';
import transformationOrderline from './transformation-orderline/index';
import consumable from './consumable/index';
import consumableUsage from './consumable-usage/index';
import resourceUsage from './resource-usage/index';
import sectorUsage from './sector-usage/index';
import executorUsage from './executor-usage/index';
import docking from './docking/index';
import dock from './dock/index';
import warehouse from './warehouse/index';
import invoice from './invoice/index';
import invoiceline from './invoice-line/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    bundle,
    carrier,
    cluster,
    contact,
    customer,
    baseBundle,
    basePackage,
    baseProductBundle,
    handling,
    inboundOrder,
    outboundOrder,
    orderline,
    package: packageStore,
    product,
    productBundle,
    productGroup,
    resource,
    sector,
    transaction,
    transformationOrder,
    transformationOrderline,
    docking,
    dock,
    warehouse,
    consumable,
    consumableUsage,
    resourceUsage,
    sectorUsage,
    executorUsage,
    invoice,
    invoiceline,
  },
});
