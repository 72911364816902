<template>
  <v-col class="px-0 pt-0">
    <v-form
      ref="form"
      color="#dddddd"
      class="w-100"
    >
      <div class="d-flex">
        <v-col
          v-if="value.Start"
          class="pb-0"
          cols="auto"
        >
          <h5
            style="position: absolute; transform: translateY(-14px)"
            class="font-weight-thin"
          >
            {{ $t("label:start", null, 1) }}
          </h5>
          <span>
            {{ value.Start ? $moment(value.Start).format("DD-MM-YYYY") : "" }}
          </span>
        </v-col>
        <span style="transform: translateY(10px)">
          {{ value.Start && value.End ? "-" : "" }}
        </span>
        <v-col
          v-if="value.End"
          class="pb-0"
          cols="auto"
        >
          <h5
            style="position: absolute; transform: translateY(-14px)"
            class="font-weight-thin"
          >
            {{ $t("label:end", null, 1) }}
          </h5>
          <span>
            {{ value.End ? $moment(value.End).format("DD-MM-YYYY") : "" }}
          </span>
        </v-col>
      </div>
      <v-row class="ma-0">
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-card
            flat
            tile
          >
            <div class="header-gradient pa-0 pl-2 d-flex align-center rounded">
              <span class="white--text">
                {{ $t("label:executor", null, 1) }}
              </span>
              <v-spacer />
              <v-btn
                v-if="!disable.main"
                small
                icon
                @click="onAdd('ExecutorUsages')"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div v-if="value.ExecutorUsages && value.ExecutorUsages.length">
              <c-executor-usage-main
                v-for="(v, index) in value.ExecutorUsages"
                :key="`executor-usage-${index}`"
                :path="[...path, 'OutboundHandling', index]"
                :disable="disable"
                :template="template"
                :reset="reset"
              />
            </div>
            <div
              v-else
              class="w-100 text-center pa-2"
            >
              <span>{{ $t("label:no_executor_involved") }}</span>
            </div>
          </v-card>
        </v-col>
        <v-divider vertical />
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-card
            flat
            tile
          >
            <div class="header-gradient pa-0 pl-2 d-flex align-center rounded">
              <span class="white--text">
                {{ $t("label:usage_resource") }}
              </span>
              <v-spacer />
              <v-btn
                v-if="!disable.main"
                small
                icon
                @click="onAdd('ResourceUsages')"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div v-if="value.ResourceUsages && value.ResourceUsages.length">
              <c-resource-usage-main
                v-for="(v, index) in value.ResourceUsages"
                :key="`resource-usage-${index}`"
                :path="[...path, 'OutboundHandling', index]"
                :disable="disable"
                :template="template"
                :reset="reset"
              />
            </div>
            <div
              v-else
              class="w-100 text-center pa-2"
            >
              <span>
                {{ $t("label:no_resource_used") }}
              </span>
            </div>
          </v-card>
        </v-col>
        <v-divider vertical />
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-card
            flat
            tile
          >
            <div class="header-gradient pa-0 pl-2 d-flex align-center rounded">
              <span class="white--text">
                {{ $t("label:usage_consumable") }}
              </span>
              <v-spacer />
              <v-btn
                v-if="!disable.main"
                small
                icon
                @click="onAdd('ConsumableUsages')"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div v-if="value.ConsumableUsages && value.ConsumableUsages.length">
              <c-consumable-usage-main
                v-for="(v, index) in value.ConsumableUsages"
                :key="`executor-usage-${index}`"
                :path="[...path, 'OutboundHandling', index]"
                :disable="disable"
                :template="template"
                :reset="reset"
              />
            </div>
            <div
              v-else
              class="w-100 text-center pa-2"
            >
              <span>
                {{ $t("label:no_consumables_spent") }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

import CConsumableUsageMain from '@/components/usage/consumable.vue';
import CResourceUsageMain from '@/components/usage/resource.vue';
import CExecutorUsageMain from '@/components/usage/executor.vue';

export default {
  name: 'TransactionOutboundHandling',

  components: {
    CConsumableUsageMain,
    CResourceUsageMain,
    CExecutorUsageMain,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
      }),
    },
  },

  computed: {
    ...mapGetters({
      executorUsageDefaultTemplate: 'executorUsage/defaultTemplate',
      consumableUsageDefaultTemplate: 'consumableUsage/defaultTemplate',
      resourceUsageDefaultTemplate: 'resourceUsage/defaultTemplate',
    }),

    value() {
      if (!this.path.length) {
        return this.template.OutboundHandling || {};
      }
      return this.template.Orderlines[this.path[0]][this.path[1]][this.path[2]]
        .OutboundHandling || {};
    },

    defaultTemplate() {
      return {
        ExecutorUsages: this.executorUsageDefaultTemplate,
        ConsumableUsages: this.consumableUsageDefaultTemplate,
        ResourceUsages: this.resourceUsageDefaultTemplate,
      };
    },
  },

  methods: {
    onAdd(type) {
      const { Orderlines } = this.template;
      Orderlines[this.path[0]][this.path[1]][this.path[2]].OutboundHandling[
        type
      ].push({
        ...this.defaultTemplate[type],
      });
      this.reset({ ...this.template, Orderlines });
    },
  },
};
</script>
<style lang="scss">
.header-gradient {
  background-image: linear-gradient(
    to right,
    #a0a0a0,
    #b7b7b7,
    #cecece,
    #e6e7e6,
    #ffffff
  );
}
</style>
