import StoragePage from '@/layouts/Application.vue';

import Sector from './sector';
import Cluster from './cluster';
import Package from './package';
import Carrier from './carrier';
import Product from './product';

export default [
  {
    path: 'storage',
    component: StoragePage,
    children: [
      ...Sector,
      ...Cluster,
      ...Package,
      ...Carrier,
      ...Product,
    ],
  },
];
