<template>
  <v-app
    id="app"
    :class="wrapper"
  >
    <template v-if="!$auth.loading && $tenant">
      <router-view />
    </template>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  computed: {
    wrapper() {
      return this.$auth !== undefined ? `tenant-${this.$auth.tenant}` : '';
    },
  },
};
</script>
<style lang="scss">
@import './assets/sass/app.scss';
.toaster {
  font-family: "Roboto", sans-serif;
}

.unscrolled-number-input input[type="number"] {
  -moz-appearance: textfield;
}
.unscrolled-number-input input::-webkit-outer-spin-button,
.unscrolled-number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

</style>

<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
