<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="loading"
            :loading="loading"
            :class="{ 'pt-2': !loading }"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              ref="list"
              :headers="headers"
              :get-list="getList"
              :list="list"
              class="complex-footer"
              :loading="loading"
            >
              <template #footer-prepend>
                <!-- <v-btn
                  small
                  color="primary darken-1"
                  class="width-auto"
                  :disabled="loading"
                  :loading="loading"
                  @click.stop="onForm()"
                >
                  {{ $t("label:order_outbound", null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer /> -->
                <v-btn-toggle
                  v-model="dStatus"
                  mandatory
                  @change="onUpdateStatusFilter"
                >
                  <v-btn
                    small
                    :value="null"
                  >
                    {{ $t("text:all") }}
                  </v-btn>
                  <v-btn
                    v-for="({ text, value }, index) in statuses"
                    :key="`status-${index}`"
                    width="auto"
                    small
                    :value="value"
                  >
                    {{ text }}
                  </v-btn>
                </v-btn-toggle>
              </template>
              <template #item="{ item }">
                <tr>
                  <!-- <td>
                    <div class="d-flex">
                      <v-icon
                        :disabled="loading"
                        class="float-left"
                        color="accent"
                        left
                        @click.stop="onForm(item.ItemId)"
                      >
                        mdi-open-in-app
                      </v-icon>
                      <template v-if="item.Status === 'Handling'">
                        <v-icon
                          :disabled="loading"
                          class="float-left"
                          color="accent"
                          left
                          @click.stop="onPrint(item.ItemId)"
                        >
                          mdi-printer
                        </v-icon>
                      </template>
                    </div>
                  </td> -->
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>
                    {{ item.CustomerReference }}
                  </td>
                  <td>
                    {{ item.Debtor ? item.Debtor.Name : '-' }}
                  </td>
                  <td>{{ mQuantity(item) }}</td>
                  <td>
                    <v-btn
                      v-for="(
                        { ItemId, PlannedStart }, index
                      ) in mPlannedDockings(item)"
                      :key="`${index}-planned-docking-time`"
                      x-small
                      color="blue"
                      class="ma-1"
                      outlined
                      disabled
                    >
                      <v-icon left>
                        mdi-map-marker-distance
                      </v-icon>
                      #{{ ItemId }} | {{ PlannedStart }}
                    </v-btn>
                  </td>
                  <!-- <td>
                    <v-tooltip
                      v-if="['New'].includes(item.Status)"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          class="mr-2 float-right"
                          v-on="on"
                          @click="onDelete(item)"
                        >
                          <v-icon class="delete-icon">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("label:delete") }}
                    </v-tooltip>
                  </td> -->
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'OutboundOrderIndexPortal',

  data: () => ({
    dStatus: null,
    debtors: [],
  }),

  computed: {
    ...mapGetters({
      loading: 'outboundOrder/loadingList',
      list: 'outboundOrder/list',
    }),

    statuses() {
      return [
        {
          value: 'New',
          text: this.$t('label:status:new', null, 1),
        },
        {
          value: 'Plannable',
          text: this.$t('label:status:plannable:order', null, 1),
        },
        {
          value: 'Planned',
          text: this.$t('label:status:planned:docking', null, 1),
        },
        {
          value: 'Handling',
          text: this.$t('label:status:handling:orderline', null, 1),
        },
        {
          value: 'Check-out',
          text: this.$t('label:status:check-out:orderline', null, 1),
        },
        {
          value: 'Done',
          text: this.$t('label:status:done', null, 1),
        },
      ];
    },

    headers() {
      return [
        // {
        //   text: '',
        //   value: '',
        //   searchable: false,
        //   sortable: false,
        // },
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:customer_reference', null, 1),
          value: 'CustomerReference',
          type: 'text',
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:debtor', null, 1),
          value: '',
          sortable: false,
          searchable: false,
        },
        {
          text: this.$t('label:package:quantity', null, 1),
          value: '',
          searchable: false,
          sortable: false,
        },
        {
          text: this.$t('label:planned_dockings', null, 1),
          value: 'PlannedStart',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        // {
        //   text: '',
        //   value: '',
        //   searchable: false,
        //   sortable: false,
        // },
      ];
    },
  },

  methods: {
    ...mapActions({
      getList: 'outboundOrder/getPortalList',
      create: 'outboundOrder/create',
      deleteItem: 'outboundOrder/destroy',
    }),
    mPlannedDockings({ Orderlines }) {
      const dockings = Orderlines.flatMap(({ Docking }) => Docking).filter(
        (v) => v,
      );

      dockings.sort(
        // eslint-disable-next-line no-nested-ternary
        (a, b) => (a.PlannedStart < b.PlannedStart
          ? -1
          : a.PlannedStart > b.PlannedStart
            ? 1
            : 0),
      );
      return dockings.map((v) => ({
        ...v,
        PlannedStart: v.PlannedStart
          ? this.$moment(v.PlannedStart).format('DD-MM-YYYY')
          : '-',
      }));
    },
    mQuantity({ Orderlines }) {
      return Orderlines.flatMap((v) => v.OutboundPackages || []).reduce(
        (total, { Quantity }) => total + Quantity,
        0,
      );
    },
    onUpdateStatusFilter(value) {
      const { filters } = this.list;
      delete filters.Status;
      if (value) {
        filters.Status = { type: 'select', value };
      }
      this.getList({
        ...this.list,
        offset: 0,
        filters,
      });
    },
    async onForm(ItemId) {
      this.$router.push({
        name: 'movement-outbound-order-form',
        params: { ItemId },
      });
    },
    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
<style lang="scss">
.complex-footer {
  .v-data-footer__select {
    margin-left: 0px;
  }
}
</style>
