import Packages from '@/components/views/storage/Packages.vue';

import PackageForm from '@/components/package/form.vue';

export default [
  {
    name: 'storage-package-list',
    path: '/storage/package/list',
    component: Packages,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'storage-package-form',
        path: '/storage/package/form/:ItemId?',
        component: PackageForm,
        meta: { restriction: ['WMS.Manager'] },
      },
    ],
  },
];
