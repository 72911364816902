import Products from '@/components/views/portal/Products.vue';

// import ProductGroupForm from '@/components/product-group/form.vue';
// import ProductForm from '@/components/product/form.vue';
// import PackageForm from '@/components/package/form.vue';

export default [
  {
    name: 'portal-items-list',
    path: '/portal/item/list',
    component: Products,
    meta: {
      restriction: ['WMS.Portaluser'],
    },

    // children: [
    //   {
    //     name: 'portal-items-product-group-form',
    //     path: '/portal/items/product-group/form/:ItemId?',
    //     component: ProductGroupForm,
    //     meta: { restriction: ['WMS.Portaluser'] },
    //   },
    //   {
    //     name: 'portal-items-product-form',
    //     path: '/portal/items/product/form/:ItemId?',
    //     component: ProductForm,
    //     meta: { restriction: ['WMS.Portaluser'] },
    //   },
    //   {
    //     name: 'portal-items-package-form',
    //     path: '/portal/items/package/form/:ItemId',
    //     component: PackageForm,
    //     meta: { restriction: ['WMS.Portaluser'] },
    //   },
    // ],
  },
];
