<template>
  <v-text-field
    :key="`key-${key}`"
    :value="currency ? cValue(value) : value"
    v-bind="{ ...$attrs, ...$props }"
    :min="null"
    :max="null"
    :hide-details="$attrs.disabled"
    :type="!focused && currency ? 'text' : 'number'"
    @click:append-outer="$emit('click:append-outer')"
    @click:clear="() => onInput(null)"
    @change="onInput"
    @focus="focused = true"
    @blur="focused = false"
  >
    <slot
      v-for="(_, name) in $slots"
      :slot="name"
      :name="name"
    />
  </v-text-field>
</template>

<script>
export default {
  name: 'CNumber',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
      description: 'Used as min value',
      validator: (v) => Math.abs(v) <= 2147483648,
    },
    max: {
      type: Number,
      default: 2147483648,
      description: 'Used as max value',
      validator: (v) => Math.abs(v) <= 2147483648,
    },
    fixed: {
      type: Number,
      default: 0,
      description: 'Force digits after float',
      validator: (v) => !v || (v && v >= 0 && v <= 10),
    },
    nullable: {
      type: Boolean,
      default: false,
      description: 'If true - @clear event sets value to null instead of 0',
    },
  },

  data: () => ({
    key: 1,
    focused: false,
  }),
  computed: {

  },

  mounted() {
    if (this.min >= this.max) {
      throw new Error('CNumber: min prop is greater or equal max prop.');
    }
  },

  methods: {
    cValue(value) {
      let stringValue = '';

      stringValue = Number(value).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
      return this.focused ? value : stringValue;
    },

    onInput(value) {
      const clearable = (this.$attrs.clearable || this.$attrs.clearable === '') && this.nullable;
      let v = (clearable && !value) ? null : Number(value || 0);
      if (v === null) {
        if (this.value === v) {
          this.key += 1;
        }
        this.$emit('input', v);
        return;
      }

      if (v < this.min) {
        v = this.min;
      }

      if (v > this.max) {
        v = this.max;
      }
      if (!this.currency) {
        v = v.toFixed(this.fixed);
      }

      if (Number(this.value) === Number(v)) {
        /**
         * re-render text-field to reset it's value
         * cause vuetify won't trigger textfield value update on it's own in equal case
         */
        this.key += 1;
        if (this.currency) {
          this.focused = false;
          this.cValue(v);
        }
      }
      this.$emit('input', v);
    },
  },
};
</script>
