import Vue from 'vue';
import warehouseActions from '@/store/warehouseActions';
import portalActions from '@/store/portalActions';
import { api } from '@/store/defaultActions';

export default {
  ...warehouseActions,
  ...portalActions,

  async export({ commit, getters }, data) {
    commit('SET_LOADING', true);
    try {
      const response = await Vue.axios.post(`${api}${getters.namespace}/export`, data);
      commit('UPDATE_ITEM', { ...data, ...response.data?.item || {} });
      commit('RESET_TEMPLATE', { ...data, ...response.data?.item || {} });
      commit('SET_LOADING', false);
      return true;
    } catch (e) {
      Vue.toasted.error(e.response.data);
      commit('SET_LOADING', false);
      return false;
    }
  },
};
