import Products from '@/components/views/storage/Products.vue';

import ProductGroupForm from '@/components/product-group/form.vue';
import ProductForm from '@/components/product/form.vue';
import PackageForm from '@/components/package/form.vue';

export default [
  {
    name: 'storage-items-list',
    path: '/storage/items/list',
    component: Products,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'storage-items-product-group-form',
        path: '/storage/items/product-group/form/:ItemId?',
        component: ProductGroupForm,
        meta: { restriction: ['WMS.Manager'] },
      },
      {
        name: 'storage-items-product-form',
        path: '/storage/items/product/form/:ItemId?',
        component: ProductForm,
        meta: { restriction: ['WMS.Manager'] },
      },
      {
        name: 'storage-items-package-form',
        path: '/storage/items/package/form/:ItemId',
        component: PackageForm,
        meta: { restriction: ['WMS.Manager'] },
      },
    ],
  },
];
