var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"value":true,"persistent":true,"content-class":_vm.highComplexityModal,"scrollable":"","no-click-animation":"","transition":"dialog-bottom-transition"}},[(_vm.template)?_c('v-card',{attrs:{"tile":"","loading":_vm.loading,"disabled":_vm.loading,"height":"95vh"}},[_c('template',{slot:"progress"},[_c('progress-bar')],1),_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("label:order_inbound", null, 1))+" ")]),_c('v-chip',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.template.Status))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","light":"","color":"text"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2",staticStyle:{"height":"100%"}},[_c('v-form',{ref:"form",staticStyle:{"height":"inherit"}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"height":"inherit"}},[_c('c-main',{attrs:{"collapse":_vm.collapse,"after":_vm.after},on:{"collapse":function (v) { return (_vm.collapse = v); },"on-change-customer":function($event){return _vm.onChangeCustomer()}}}),_c('v-col',{staticClass:"pr-0"},[_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.template.Orderlines),function(Orderline,orderlineIndex){return _c('c-orderline-inbound',{key:("orderline-" + orderlineIndex),attrs:{"path":[orderlineIndex],"reset":_vm.resetTemplate,"disable":{
                  main: _vm.after('Planned', Orderline),
                  unique: _vm.after('Planned', Orderline),
                  status: _vm.disabledStatus(Orderline),
                  delete: _vm.after('Plannable', Orderline),
                }},on:{"shred":function($event){return _vm.onShred(orderlineIndex)},"add-package":function($event){return _vm.onAddPackage(orderlineIndex)},"remove-orderline":function($event){return _vm.onRemoveOrderline(orderlineIndex)}},scopedSlots:_vm._u([{key:"packages",fn:function(){return [_c('v-expansion-panels',{staticClass:"panels-styled",attrs:{"focusable":""},model:{value:(_vm.packagesPanel),callback:function ($$v) {_vm.packagesPanel=$$v},expression:"packagesPanel"}},_vm._l((_vm.template.Orderlines[
                        orderlineIndex
                      ].InboundPackages),function(Package,packageIndex){return _c('v-expansion-panel',{key:("orderline-" + packageIndex),staticClass:"mb-2 rounded"},[_c('v-expansion-panel-header',{staticClass:"py-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var open = ref.open;
return [_c('v-row',{attrs:{"align":"center","dense":""}},[_c('h4',{staticClass:"text-uppercase font-weight-bold",class:{ 'white--text': !open }},[_vm._v(" "+_vm._s(_vm.$t("label:package", null, 1))+" #"+_vm._s(packageIndex + 1)+" ")]),_c('v-spacer'),_c('v-fade-transition',[(open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('label:inbound_batch', null, 1),"disabled":_vm.after('Planned', Orderline),"dense":""},model:{value:(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                        .InboundBatch
                                    ),callback:function ($$v) {_vm.$set(_vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                        , "InboundBatch", $$v)},expression:"\n                                      template.Orderlines[orderlineIndex]\n                                        .InboundPackages[packageIndex]\n                                        .InboundBatch\n                                    "}})],1),_c('v-col',{staticClass:"mr-8 pb-0",attrs:{"cols":"auto"}},[_c('v-badge',{attrs:{"color":"info","content":("Σ: " + (_vm.total(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                    ))),"overlap":"","value":_vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                        .Quantity > 1,"offset-x":"45px","offset-y":"12px"}},[_c('c-number',{staticClass:"mt-3",staticStyle:{"width":"150px"},attrs:{"value":_vm.template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .Quantity,"dense":"","disabled":_vm.after('Check-in', Orderline),"rules":_vm.validateQuantity(
                                          _vm.template.Orderlines[orderlineIndex]
                                        ),"label":_vm.$t('label:quantity:package', null, 1)},on:{"input":function (v) { return _vm.onPackageQuantityUpdate(
                                            v,
                                            orderlineIndex,
                                            packageIndex
                                          ); }}})],1)],1)],1):_vm._e(),(!open)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0",staticStyle:{"width":"150px"},attrs:{"cols":"auto"}},[(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                        .InboundBatch
                                    )?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t("label:inbound_batch", null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .InboundPackages[packageIndex] .InboundBatch)+" ")])]),_c('v-col',{staticClass:"mr-8 pb-0",staticStyle:{"width":"150px"},attrs:{"cols":"auto"}},[(
                                      _vm.template.Orderlines[orderlineIndex]
                                        .InboundPackages[packageIndex]
                                        .Quantity
                                    )?_c('h5',{staticClass:"font-weight-thin white--text",staticStyle:{"position":"absolute","transform":"translateY(-14px)"}},[_vm._v(" "+_vm._s(_vm.$t("label:quantity:package", null, 1))+" ")]):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.template.Orderlines[orderlineIndex] .InboundPackages[packageIndex] .Quantity)+" ")])])],1):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('c-package-uniques',{ref:"uniques",refInFor:true,attrs:{"path":[
                                  orderlineIndex,
                                  'InboundPackages',
                                  packageIndex ],"reset":_vm.resetTemplate,"template":_vm.template,"open":open}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.after('Check-in', Orderline),"icon":""},on:{"click":function($event){return _vm.onClonePackage(
                                        orderlineIndex,
                                        packageIndex
                                      )}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("label:clone"))+" ")])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"disabled":_vm.after('Check-in', Orderline),"icon":""},on:{"click":function($event){return _vm.onRemovePackage(
                                        orderlineIndex,
                                        packageIndex
                                      )}}},on),[_c('v-icon',{class:{ 'white--text': !open }},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("label:delete", null, 1))+" ")])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"content-no-padding",attrs:{"eager":""}},[_c('c-package-main-inbound',{ref:"packages",refInFor:true,attrs:{"path":[
                            orderlineIndex,
                            'InboundPackages',
                            packageIndex ],"template":_vm.template,"reset":_vm.resetTemplate,"disable":{
                            main: _vm.after('Planned', Orderline),
                            sector: _vm.after('Handling', Orderline),
                          }},scopedSlots:_vm._u([{key:"product-bundle",fn:function(){return [_c('c-product-bundle-main',{attrs:{"disable":{
                                main: _vm.after('Check-in', Orderline),
                              },"template":_vm.template,"reset":_vm.resetTemplate,"path":[
                                orderlineIndex,
                                'InboundPackages',
                                packageIndex ]}})]},proxy:true},(_vm.after('Check-in', Orderline))?{key:"transaction-inbound-handling",fn:function(){return [_c('c-transaction-inbound-handling',{attrs:{"disable":{
                                main: _vm.after('Handling', Orderline),
                              },"template":_vm.template,"reset":_vm.resetTemplate,"path":[
                                orderlineIndex,
                                'InboundPackages',
                                packageIndex ]}})]},proxy:true}:null,(
                              _vm.disabledStatus(Orderline) &&
                                _vm.after('Handling', Orderline)
                            )?{key:"transaction-storage-handling",fn:function(){return [_c('c-transaction-storage-handling',{attrs:{"template":_vm.template,"reset":_vm.resetTemplate,"path":[
                                orderlineIndex,
                                'InboundPackages',
                                packageIndex ]}})]},proxy:true}:null,(_vm.after('Planned', Orderline))?{key:"transaction-outbound-handling",fn:function(){return [_c('c-transaction-outbound-handling',{attrs:{"disable":{
                                main: _vm.after('Check-out', Orderline),
                              },"template":_vm.template,"reset":_vm.resetTemplate,"path":[
                                orderlineIndex,
                                'InboundPackages',
                                packageIndex ]}})]},proxy:true}:null],null,true)})],1)],1)}),1)]},proxy:true}],null,true)})}),1),_c('v-btn',{staticClass:"float-left",attrs:{"disabled":!_vm.template.CustomerId || _vm.after('Planned'),"color":"primary"},on:{"click":_vm.onAddOrderline}},[_vm._v(" "+_vm._s(_vm.$t("label:order_line", null, 1))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fal fa-plus ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"border darken-1"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("btn:close"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onPrint(_vm.template.ItemId)}}},[_vm._v(" "+_vm._s(_vm.$t('label:print_order'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-printer ")])],1),(true)?_c('v-btn',{attrs:{"color":"warning","loading":_vm.loading},on:{"click":_vm.onSync}},[_vm._v(" "+_vm._s(_vm.$t("btn:send_to_tms"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"info","loading":_vm.loading,"href":"https://vdl.slim.fitsoft.eu/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('label:tms'))+" | "+_vm._s(_vm.template.TmsId)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-open-in-new ")])],1),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function () { return _vm.onSave(); }}},[_vm._v(" "+_vm._s(_vm.$t("btn:save"))+" ")]),(_vm.nextStatus.value)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading || !_vm.nextStatus.valid || _vm.after('Handling')},on:{"click":function($event){return _vm.onSave(_vm.nextStatus.value)}}},[_vm._v(" "+_vm._s(_vm.nextStatus.name)+" ")]):_vm._e()],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }