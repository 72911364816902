<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-form ref="form">
      <v-card
        v-if="template"
        tile
        :loading="loading"
      >
        <template slot="progress">
          <progress-bar />
        </template>
        <v-card-title class="card-title">
          <span
            class="card-title-caption text-truncate"
          >
            {{ $t('label:product', null, 1) }}
          </span>
          <v-spacer />
          <v-btn
            icon
            light
            color="text"
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-2">
          <v-row justify="space-between">
            <v-col>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="template.CustomerId"
                    :label="$t('label:customer', null, 1)"
                    :items="customerItems"
                    :loading="customerLoading"
                    aria-autocomplete="off"
                    item-text="Name"
                    item-value="ItemId"
                    required
                    :rules="mnValidation.required"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="template.ProductGroupId"
                    :label="$t('label:product_group', null, 1)"
                    :items="productGroupItems"
                    :loading="productGroupLoading"
                    :search-input.sync="productGroupSearch"
                    item-value="ItemId"
                    item-text="Name"
                    aria-autocomplete="off"
                    cache-items
                    flat
                    hide-no-data
                    :rules="mnValidation.required"
                    :disabled="!!template.ItemId"
                    @update:search-input="onProductGroupSearch"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="template.Name"
                    :label="$t('label:name', null, 1)"
                    aria-autocomplete="off"
                    :rules="mnValidation.required"
                  />
                  <v-checkbox
                    v-model="template.Stackable"
                    :label="$t('label:stackable')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <c-number
                v-model="template.ShardSize"
                :label="$t('label:shard_size')"
              />
            </v-col>
            <v-col cols="auto">
              <c-number
                v-model="template.Weight"
                :fixed="2"
                :label="$t('label:weight')"
                class="width-10ch"
              />
              <c-number
                v-model="template.Loadmeters"
                :fixed="2"
                :label="$t('label:loadmeters')"
                class="width-10ch"
              />
            </v-col>
            <v-col cols="auto">
              <c-number
                v-model="template.Length"
                :fixed="2"
                :label="$t('label:length')"
                class="width-10ch"
              />
              <c-number
                v-model="template.Width"
                :fixed="2"
                :label="$t('label:width')"
                class="width-10ch"
              />
              <c-number
                v-model="template.Height"
                :fixed="2"
                :label="$t('label:height')"
                class="width-10ch"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="!template.BasePackage"
                @click="onAddBasePackage"
              >
                {{ $t('label:base-package', null, 1) }}
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
              <template v-else>
                <h2>
                  {{ $t('label:default_packaging') }}
                </h2>
                <div class="fadeline w-100 mt-2 mb-6" />
                <c-base-package-main

                  ref="packages"

                  v-model="template.BasePackage"
                  :disabled="loading || productGroupLoading"
                >
                  <template #base-product-bundle>
                    <c-base-product-bundle-main v-model="template.BasePackage.BaseProductBundle" />
                  </template>
                </c-base-package-main>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            fixed
            color="border darken-1"
            @click="onClose"
          >
            {{ $t('btn:close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onSave"
          >
            {{ $t('btn:save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import CBasePackageMain from '@/components/base-package/main.vue';
import CBaseProductBundleMain from '@/components/base-product-bundle/main.vue';

export default {
  name: 'ProductForm',

  components: {
    CBasePackageMain,
    CBaseProductBundleMain,
  },

  data: () => ({
    productGroupSearch: null,
    productGroupItems: [],
  }),

  computed: {
    ...mapGetters({
      customerItems: 'customer/items',
      loading: 'product/loading',
      customerLoading: 'customer/loading',

      productGroupLoading: 'productGroup/loading',
      productGroupTemplate: 'productGroup/template',
      template: 'product/template',

      basePackageDefaultTemplate: 'basePackage/defaultTemplate',
      baseProductBundleDefaultTemplate: 'baseProductBundle/defaultTemplate',
    }),
  },

  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      update: 'product/update',
      create: 'product/create',
      resetTemplate: 'product/resetTemplate',
      getProduct: 'product/getItem',

      returnProductGroupItems: 'productGroup/returnItems',
    }),

    async initialize() {
      await this.resetTemplate();
      if (this.$route.params.ItemId) {
        const { item } = await this.getProduct(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({
          ...this.productGroupTemplate,
          ItemId: undefined,
          ProductGroupId: this.productGroupTemplate?.ItemId,
        });
      }
      this.onProductGroupSearch(true);
    },

    onAddBasePackage() {
      this.resetTemplate({
        ...this.template,
        BasePackage: {
          ...this.basePackageDefaultTemplate,
          BaseProductBundle: this.baseProductBundleDefaultTemplate,
        },
      });
    },

    onProductGroupSearch(immediate) {
      clearTimeout(this.productGroupTimeout);
      this.productGroupTimeout = setTimeout(
        async () => {
          const filter = {};
          if (this.productGroupSearch?.length) {
            filter.Name = { value: this.productGroupSearch, type: 'text' };
          } else if (this.template.ProductGroupId) {
            filter.ItemId = { value: this.template.ProductGroupId, type: 'select' };
          }
          if (!Object.keys(filter).length) {
            return;
          }
          this.productGroupItems = await this.returnProductGroupItems(filter);
        }, immediate ? 0 : 2000,
      );
    },

    async onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.$route.params.ItemId) {
        if (await this.update(this.template)) {
          this.onClose();
        }
      } else if (await this.create(this.template)) {
        this.onClose();
      }
    },

    onClose() {
      this.$router.push({ name: 'storage-items-list' });
    },
  },
};
</script>
