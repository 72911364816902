import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    PackageId: null,
    OrderlineId: null,
    SectorId: null,

    WarehouseId: null,

    Quantity: null,

    InboundBatch: '',
    TransformationBatch: '',
    OutboundBatch: '',

    Length: 0.00,
    Width: 0.00,
    Height: 0.00,
    WeightNetto: 0.00,
    WeightBrutto: 0.00,

    Stackable: false,

    ProductBundle: null,

    Uniques: null,
    Status: 'Planned',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
