<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="midComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-card
      v-if="template"
      tile
      :loading="loading"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title class="card-title">
        <span
          class="card-title-caption text-truncate"
        >
          {{ $t('label:resource', null, 1) }}
        </span>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-2">
        <v-row justify="space-between">
          <v-col>
            <v-text-field
              v-model="template.Name"
              :label="$t('label:name', null, 1)"
            />
            <v-autocomplete
              v-model="template.WarehouseId"
              :label="$t('label:warehouse', null, 1)"
              item-text="Name"
              item-value="ItemId"
              :items="warehouseItems"

              aria-autocomplete="off"
              hide-details
              :disabled="Boolean(template.WarehouseId && template.ItemId)"
              :rules="mnValidation.required"
              style="width: 50%"
            />
          </v-col>
          <v-col cols="2" />
          <v-col cols="auto">
            <c-number
              v-model="template.Stock"
              :label="$t('label:stock', null, 1)"
              class="width-15ch"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          fixed
          color="border darken-1"
          @click="onClose"
        >
          {{ $t('btn:close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="onSave"
        >
          {{ $t('btn:save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResourceForm',

  computed: {
    ...mapGetters({
      loading: 'resource/loading',
      template: 'resource/template',
      warehouseItems: 'warehouse/items',
      WarehouseId: 'warehouse/WarehouseId',
    }),

  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      update: 'resource/update',
      create: 'resource/create',
      resetTemplate: 'resource/resetTemplate',
      getResource: 'resource/getItem',
    }),
    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getResource(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({ WarehouseId: this.WarehouseId });
      }
    },

    onClose() {
      this.$router.push({ name: 'system-resource-list' });
    },

    async onSave() {
      if (this.$route.params.ItemId) {
        if (await this.update(this.template)) {
          this.onClose();
        }
      } else if (await this.create(this.template)) {
        this.onClose();
      }
    },
  },
};
</script>
