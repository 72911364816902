var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.unique)?_c('v-menu',{attrs:{"eager":"","close-on-content-click":false,"transition":"slide-y-transition","offset-y":true,"bottom":true,"left":"","min-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on1 = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"red"},on:{"click:append":function($event){_vm.menu = true}}},Object.assign({}, on1, on2)),[_c('v-icon',{class:{ 'white--text': !_vm.open }},[_vm._v(" mdi-view-list ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('label:unique', null, 2))+" ")])]}}],null,false,3126345990),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('v-col',[_c('v-row',[_vm._v(" "+_vm._s(_vm.$t('label:unique', null, 2))+": "+_vm._s(_vm.uniques.length)+" / "+_vm._s(_vm.total)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uniques.length >= _vm.total,"icon":""},on:{"click":function($event){return _vm.onSync(true)}}},on),[_c('v-icon',[_vm._v(" mdi-playlist-plus ")])],1)]}}],null,false,867247376)},[_vm._v(" "+_vm._s(_vm.$t('label:sync:up'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.total >= _vm.uniques.length,"icon":""},on:{"click":function($event){return _vm.onSync(false)}}},on),[_c('v-icon',[_vm._v(" mdi-playlist-minus ")])],1)]}}],null,false,3351887629)},[_vm._v(" "+_vm._s(_vm.$t('label:sync:down'))+" ")])],1)],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"250px"}},[_c('v-col',_vm._l((_vm.uniques),function(string,index){return _c('v-row',{key:("unique-" + index)},[_c('v-text-field',{attrs:{"value":string,"rules":_vm.validation,"dense":"","append-outer-icon":"mdi-delete"},on:{"input":function (v) { return _vm.onInput(index, v); },"click:append-outer":function($event){return _vm.onDelete(index)}}})],1)}),1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }