<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="template"
      tile
      :loading="loading"
      :disabled="loading"
      height="95vh"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title>
        <span>
          {{ $t("label:order_inbound", null, 1) }}
        </span>
        <v-chip class="ml-4">
          {{ $t(template.Status) }}
        </v-chip>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        style="height: 100%"
        class="pt-2"
      >
        <v-form
          ref="form"
          style="height: inherit"
        >
          <v-row
            style="height: inherit"
            class="ma-0"
          >
            <c-main
              :collapse="collapse"
              :after="after"
              @collapse="(v) => (collapse = v)"
              @on-change-customer="onChangeCustomer()"
            />
            <v-col class="pr-0">
              <v-expansion-panels
                v-model="panel"
                class="panels-styled"
                focusable
              >
                <c-orderline-inbound
                  v-for="(Orderline, orderlineIndex) in template.Orderlines"
                  :key="`orderline-${orderlineIndex}`"
                  :path="[orderlineIndex]"
                  :reset="resetTemplate"
                  :disable="{
                    main: after('Planned', Orderline),
                    unique: after('Planned', Orderline),
                    status: disabledStatus(Orderline),
                    delete: after('Plannable', Orderline),
                  }"
                  @shred="onShred(orderlineIndex)"
                  @add-package="onAddPackage(orderlineIndex)"
                  @remove-orderline="onRemoveOrderline(orderlineIndex)"
                >
                  <template #packages>
                    <v-expansion-panels
                      v-model="packagesPanel"
                      class="panels-styled"
                      focusable
                    >
                      <v-expansion-panel
                        v-for="(Package, packageIndex) in template.Orderlines[
                          orderlineIndex
                        ].InboundPackages"
                        :key="`orderline-${packageIndex}`"
                        class="mb-2 rounded"
                      >
                        <v-expansion-panel-header class="py-2">
                          <template #default="{ open }">
                            <v-row
                              align="center"
                              dense
                            >
                              <h4
                                class="text-uppercase font-weight-bold"
                                :class="{ 'white--text': !open }"
                              >
                                {{ $t("label:package", null, 1) }} #{{
                                  packageIndex + 1
                                }}
                              </h4>
                              <v-spacer />
                              <v-fade-transition>
                                <v-row
                                  v-if="open"
                                  justify="end"
                                  class="ma-0"
                                  @click.stop
                                >
                                  <v-col
                                    cols="auto"
                                    class="pb-0"
                                  >
                                    <v-text-field
                                      v-model="
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .InboundBatch
                                      "
                                      :label="
                                        $t('label:inbound_batch', null, 1)
                                      "
                                      :disabled="after('Planned', Orderline)"
                                      class="mt-3"
                                      style="width: 150px"
                                      dense
                                    />
                                  </v-col>
                                  <v-col
                                    cols="auto"
                                    class="mr-8 pb-0"
                                  >
                                    <v-badge
                                      color="info"
                                      :content="`Σ: ${total(
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                      )}`"
                                      overlap
                                      :value="
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .Quantity > 1
                                      "
                                      offset-x="45px"
                                      offset-y="12px"
                                    >
                                      <c-number
                                        :value="
                                          template.Orderlines[orderlineIndex]
                                            .InboundPackages[packageIndex]
                                            .Quantity
                                        "
                                        style="width: 150px"
                                        class="mt-3"
                                        dense
                                        :disabled="after('Check-in', Orderline)"
                                        :rules="
                                          validateQuantity(
                                            template.Orderlines[orderlineIndex]
                                          )
                                        "
                                        :label="
                                          $t('label:quantity:package', null, 1)
                                        "
                                        @input="
                                          (v) =>
                                            onPackageQuantityUpdate(
                                              v,
                                              orderlineIndex,
                                              packageIndex
                                            )
                                        "
                                      />
                                    </v-badge>
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-if="!open"
                                  justify="end"
                                  class="ma-0"
                                >
                                  <v-col
                                    cols="auto"
                                    style="width: 150px"
                                    class="pb-0"
                                  >
                                    <h5
                                      v-if="
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .InboundBatch
                                      "
                                      style="
                                        position: absolute;
                                        transform: translateY(-14px);
                                      "
                                      class="font-weight-thin white--text"
                                    >
                                      {{ $t("label:inbound_batch", null, 1) }}
                                    </h5>
                                    <span class="white--text">
                                      {{
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .InboundBatch
                                      }}
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="auto"
                                    style="width: 150px"
                                    class="mr-8 pb-0"
                                  >
                                    <h5
                                      v-if="
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .Quantity
                                      "
                                      style="
                                        position: absolute;
                                        transform: translateY(-14px);
                                      "
                                      class="font-weight-thin white--text"
                                    >
                                      {{
                                        $t("label:quantity:package", null, 1)
                                      }}
                                    </h5>
                                    <span class="white--text">
                                      {{
                                        template.Orderlines[orderlineIndex]
                                          .InboundPackages[packageIndex]
                                          .Quantity
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-fade-transition>
                              <v-col
                                cols="auto"
                                class="pb-0"
                                @click.stop
                              >
                                <c-package-uniques
                                  ref="uniques"
                                  :path="[
                                    orderlineIndex,
                                    'InboundPackages',
                                    packageIndex,
                                  ]"
                                  :reset="resetTemplate"
                                  :template="template"
                                  :open="open"
                                />
                              </v-col>
                              <v-col
                                cols="auto"
                                class="pb-0"
                                @click.stop
                              >
                                <v-tooltip top>
                                  <template #activator="{ on }">
                                    <v-btn
                                      :disabled="after('Check-in', Orderline)"
                                      icon
                                      @click="
                                        onClonePackage(
                                          orderlineIndex,
                                          packageIndex
                                        )
                                      "
                                      v-on="on"
                                    >
                                      <v-icon :class="{ 'white--text': !open }">
                                        mdi-content-copy
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  {{ $t("label:clone") }}
                                </v-tooltip>
                              </v-col>
                              <v-col
                                cols="auto"
                                class="pb-0"
                                @click.stop
                              >
                                <v-tooltip top>
                                  <template #activator="{ on }">
                                    <v-btn
                                      :disabled="after('Check-in', Orderline)"
                                      icon
                                      class="mr-4"
                                      v-on="on"
                                      @click="
                                        onRemovePackage(
                                          orderlineIndex,
                                          packageIndex
                                        )
                                      "
                                    >
                                      <v-icon :class="{ 'white--text': !open }">
                                        mdi-delete-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  {{ $t("label:delete", null, 1) }}
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="content-no-padding"
                          eager
                        >
                          <c-package-main-inbound
                            ref="packages"
                            :path="[
                              orderlineIndex,
                              'InboundPackages',
                              packageIndex,
                            ]"
                            :template="template"
                            :reset="resetTemplate"
                            :disable="{
                              main: after('Planned', Orderline),
                              sector: after('Handling', Orderline),
                            }"
                          >
                            <template #product-bundle>
                              <c-product-bundle-main
                                :disable="{
                                  main: after('Check-in', Orderline),
                                }"
                                :template="template"
                                :reset="resetTemplate"
                                :path="[
                                  orderlineIndex,
                                  'InboundPackages',
                                  packageIndex,
                                ]"
                              />
                            </template>
                            <template
                              v-if="after('Check-in', Orderline)"
                              #transaction-inbound-handling
                            >
                              <c-transaction-inbound-handling
                                :disable="{
                                  main: after('Handling', Orderline),
                                }"
                                :template="template"
                                :reset="resetTemplate"
                                :path="[
                                  orderlineIndex,
                                  'InboundPackages',
                                  packageIndex,
                                ]"
                              />
                            </template>
                            <template
                              v-if="
                                disabledStatus(Orderline) &&
                                  after('Handling', Orderline)
                              "
                              #transaction-storage-handling
                            >
                              <c-transaction-storage-handling
                                :template="template"
                                :reset="resetTemplate"
                                :path="[
                                  orderlineIndex,
                                  'InboundPackages',
                                  packageIndex,
                                ]"
                              />
                            </template>
                            <template
                              v-if="after('Planned', Orderline)"
                              #transaction-outbound-handling
                            >
                              <c-transaction-outbound-handling
                                :disable="{
                                  main: after('Check-out', Orderline),
                                }"
                                :template="template"
                                :reset="resetTemplate"
                                :path="[
                                  orderlineIndex,
                                  'InboundPackages',
                                  packageIndex,
                                ]"
                              />
                            </template>
                          </c-package-main-inbound>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </c-orderline-inbound>
              </v-expansion-panels>
              <v-btn
                :disabled="!template.CustomerId || after('Planned')"
                color="primary"
                class="float-left"
                @click="onAddOrderline"
              >
                {{ $t("label:order_line", null, 1) }}
                <v-icon right>
                  fal fa-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading"
          @click.stop="onPrint(template.ItemId)"
        >
          {{ $t('label:print_order') }}
          <v-icon right>
            mdi-printer
          </v-icon>
        </v-btn>
        <!-- :disabled="disabledSync" -->
        <v-btn
          v-if="true"
          color="warning"
          :loading="loading"
          @click="onSync"
        >
          {{ $t("btn:send_to_tms") }}
        </v-btn>
        <v-btn
          color="info"
          :loading="loading"
          href="https://vdl.slim.fitsoft.eu/"
          target="_blank"
        >
          {{ $t('label:tms') }} | {{ template.TmsId }}
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-btn
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="() => onSave()"
        >
          {{ $t("btn:save") }}
        </v-btn>
        <v-btn
          v-if="nextStatus.value"
          color="primary"
          :loading="loading"
          :disabled="loading || !nextStatus.valid || after('Handling')"
          @click="onSave(nextStatus.value)"
        >
          {{ nextStatus.name }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import COrderlineInbound from '@/components/orderline/inbound.vue';
import CPackageMainInbound from '@/components/package/main/inbound.vue';
import CPackageUniques from '@/components/package/uniques.vue';
import CProductBundleMain from '@/components/product-bundle/main.vue';
import CTransactionInboundHandling from '@/components/transaction/inbound-handling.vue';
import CTransactionStorageHandling from '@/components/transaction/storage-handling.vue';
import CTransactionOutboundHandling from '@/components/transaction/outbound-handling.vue';
import CMain from '@/components/inbound-order/main.vue';

export default {
  name: 'InboundOrderFormMovement',
  components: {
    COrderlineInbound,

    CPackageMainInbound,
    CPackageUniques,
    CProductBundleMain,

    CTransactionInboundHandling,
    CTransactionStorageHandling,
    CTransactionOutboundHandling,
    CMain,
  },

  data: () => ({
    collapse: false,
    panel: 0,
    packagesPanel: 0,
  }),

  computed: {
    ...mapGetters({
      loading: 'inboundOrder/loading',
      template: 'inboundOrder/template',

      orderlineDefaultTemplate: 'orderline/defaultTemplate',
      packageDefaultTemplate: 'package/defaultTemplate',
      productBundleDefaultTemplate: 'productBundle/defaultTemplate',
      bundleDefaultTemplate: 'bundle/defaultTemplate',

      WarehouseId: 'warehouse/WarehouseId',
    }),

    disabledSync() {
      return Boolean(this.template.TmsId) || !this.template.ItemId;
    },

    nextStatus() {
      let valid;
      let value;
      let name;

      if (!this.template) {
        return { value, valid, name };
      }

      switch (this.template.Status) {
        case 'New':
          valid = this.template.Orderlines.length > 0;
          value = 'Plannable';
          name = this.$t('btn:to_plannable', null, 1);
          break;
        case 'Planned':
        // valid = this.template.Orderlines
        //   .flatMap(({ InboundPackages }) => InboundPackages)
        //   .filter((v) => Boolean(v?.ProductBundle))
        //   .length > 0;
        // value = 'Check-in';
        // name = this.$t('btn:to_check-in', null, 1);
        // break;
        // eslint-disable-next-line no-fallthrough
        case 'Check-in':
        // valid = true;
        // value = 'Handling';
        // name = this.$t('btn:to_handling', null, 1);
        // break;
        // eslint-disable-next-line no-fallthrough
        case 'Handling':
        // valid = true;
        // value = 'Done';
        // name = this.$t('btn:to_done', null, 1);
        // break;
        // eslint-disable-next-line no-fallthrough
        case 'Done':
        default:
          valid = false;
          break;
        // default: throw new Error('Invalid Status');
      }
      return { value, valid, name };
    },
  },

  async beforeMount() {
    await this.resetTemplate({ Orderlines: [] });
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      export: 'inboundOrder/export',

      update: 'inboundOrder/update',
      create: 'inboundOrder/create',
      resetTemplate: 'inboundOrder/resetTemplate',
      getTemplate: 'inboundOrder/getItem',

      getContactItems: 'contact/getItems',
      setContactItems: 'contact/setItems',

      productGetItem: 'product/getItem',
    }),

    async onPrint(ItemId) {
      this.docmosisLoading = true;
      const { data } = await this.axios.get(
        `https://${window.location.hostname}:3000/inbound-orders/${ItemId}/handling/docmosis`,
      );
      this.docmosisLoading = false;
      const base64atob = atob(data[0].base64);
      const byteNumbers = new Array(base64atob.length);
      for (let i = 0; i < base64atob.length; i += 1) {
        byteNumbers[i] = base64atob.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(byteNumbers)], {
        type: 'application/pdf',
      });

      const tab = window.open('/');
      tab.URL.createObjectURL(new File([blob], data.filename));
      tab.onload = () => {
        tab.location = URL.createObjectURL(blob);
      };
    },

    disabledStatus(Orderline) {
      if (['Handling', 'Done'].includes(Orderline.Status)) {
        return (
          Orderline.InboundPackages.filter(
            ({ StorageHandling }) => !StorageHandling,
          ).length === 0
        );
      }

      return true;
    },

    total(Package) {
      return (
        Package.ProductBundle.Bundles.reduce(
          (total, { Quantity }) => total * (Quantity || 0),
          Package.ProductBundle.Quantity,
        ) * Package.Quantity
      );
    },

    baseTotal(BasePackage) {
      if (!BasePackage.BaseProductBundle?.BaseBundles) {
        return 1;
      }
      return BasePackage.BaseProductBundle.BaseBundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0),
        BasePackage.BaseProductBundle.Quantity,
      );
    },

    validateQuantity({ Quantity, InboundPackages }) {
      return [
        (v) => (v !== undefined && v !== null) || this.$t('validation:required'),
        (v) => v <= Quantity || this.$t('validation:orderline:exceeds:solus'),
        () => InboundPackages.reduce((t, v) => Number(v.Quantity) + Number(t), 0)
            <= Quantity || this.$t('validation:orderline:exceeds:along'),
      ];
    },

    after(status, orderline) {
      const weight = {
        Plannable: 0,
        Planned: 1,
        'Check-in': 2,
        Handling: 3,
        'Check-out': 4,
        Done: 5,
      };

      const heaviest = orderline
        ? weight[orderline.Status]
        : this.template.Orderlines.map(
          ({ Status }) => weight[Status] || 0,
        ).sort()[0];

      return heaviest > weight[status];
    },

    onChangeCustomer(keep = false) {
      if (!keep) {
        this.template.ContactId = null;
      }
      this.setContactItems([]);
      this.getContactItems({ CustomerId: this.template.CustomerId });
    },

    onClose() {
      this.$router.push({ name: 'movement-inbound-order-list' });
    },

    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getTemplate(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({
          WarehouseId: this.WarehouseId,
        });
      }
      if (this.template.CustomerId) {
        this.onChangeCustomer(true);
      }
      this.resetValidation();
    },

    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      (this.$refs.packages || []).forEach(({ $refs }) => $refs.form.resetValidation());
    },

    async onSync() {
      await this.export(this.template);
    },

    async onSave(Status = this.template.Status) {
      if (
        [
          this.$refs.form.validate(),
          ...(this.$refs.packages || []).map(({ $refs }) => $refs.form.validate()),
          ...(this.$refs?.uniques || []).map(({ $refs }) => (
            $refs?.form ? $refs?.form.validate() : true)),
        ].includes(false)
      ) {
        this.$toasted.error('Form invalid');
        return;
      }

      if (this.$route.params.ItemId) {
        if (await this.update({ ...this.template, Status })) {
          this.onClose();
        }
      } else if (await this.create({ ...this.template, Status })) {
        this.onClose();
      }
    },

    async onAddOrderline() {
      this.panel = this.template.Orderlines.length;
      this.collapse = true;
      this.resetTemplate({
        ...this.template,
        Orderlines: [
          ...this.template.Orderlines,
          {
            ...JSON.parse(JSON.stringify(this.orderlineDefaultTemplate)),
          },
        ],
      });
    },

    async onShred(orderlineIndex) {
      const { Orderlines } = this.template;

      if (Orderlines[orderlineIndex].InboundPackages.length) {
        throw new Error('Inbound Packages already set');
      }

      const { item } = await this.productGetItem(
        Orderlines[orderlineIndex].ProductId,
      );
      if (!item.ShardSize) {
        this.$toasted.error(this.$t('validation:shard:empty'));
        return;
      }
      const BasePackage = item.BasePackage || {};
      const InboundPackages = [];

      const unshredQuantity = Orderlines[orderlineIndex].Quantity;
      const unitTotalQuantity = unshredQuantity / item.ShardSize || 1;
      if (unitTotalQuantity % 1 !== 0) {
        this.$toasted.error(this.$t('validation:shard:multiple'));
        return;
      }
      const basePackageUnitQuantity = this.baseTotal(BasePackage);
      const fullPackagesQuantity = Math.floor(
        unitTotalQuantity / basePackageUnitQuantity,
      );
      const leftoveerQuantity = unitTotalQuantity % basePackageUnitQuantity;

      if (fullPackagesQuantity >= 1) {
        InboundPackages.push({
          ...this.packageDefaultTemplate,
          ...BasePackage,
          CustomerId: this.template.CustomerId,
          WarehouseId: this.template.WarehouseId,
          InboundBatch: Orderlines[orderlineIndex].Batch,
          Quantity: fullPackagesQuantity,
          ItemId: undefined,
          ProductBundle: {
            ...JSON.parse(
              JSON.stringify({
                ...this.productBundleDefaultTemplate,
                ...(BasePackage?.BaseProductBundle || {
                  ...this.productBundleDefaultTemplate,
                  Quantity: 1,
                }),
                ItemId: null,
                ProductId: Orderlines[orderlineIndex].ProductId,
                Bundles: (
                  BasePackage?.BaseProductBundle?.BaseBundles || []
                ).map((v) => ({
                  ...this.bundleDefaultTemplate,
                  ...v,
                  ItemId: undefined,
                })),
              }),
            ),
          },
        });
      }
      if (leftoveerQuantity > 0) {
        InboundPackages.push({
          ...this.packageDefaultTemplate,
          ...BasePackage,
          CustomerId: this.template.CustomerId,
          WarehouseId: this.template.WarehouseId,
          InboundBatch: Orderlines[orderlineIndex].Batch,
          Quantity: 1,
          ItemId: undefined,
          ProductBundle: {
            ...JSON.parse(
              JSON.stringify({
                ...this.productBundleDefaultTemplate,
                ItemId: null,
                ProductId: Orderlines[orderlineIndex].ProductId,
                Quantity: leftoveerQuantity,
                Bundles: [],
              }),
            ),
          },
        });
      }

      Orderlines[orderlineIndex].Quantity = InboundPackages.reduce(
        (total, Package) => total + Package.Quantity,
        0,
      );
      Orderlines[orderlineIndex].InboundPackages = JSON.parse(
        JSON.stringify(InboundPackages),
      );

      this.resetTemplate({ ...this.template, Orderlines });
    },

    async onAddPackage(orderlineIndex) {
      const { Orderlines } = this.template;
      this.packagesPanel = Orderlines[orderlineIndex].InboundPackages.length;

      const { item } = await this.productGetItem(
        Orderlines[orderlineIndex].ProductId,
      );
      const BasePackage = item.BasePackage || {};

      const InboundPackage = {
        ...this.packageDefaultTemplate,
        ...BasePackage,
        CustomerId: this.template.CustomerId,
        WarehouseId: this.template.WarehouseId,
        InboundBatch: Orderlines[orderlineIndex].Batch,
        Quantity: Orderlines[orderlineIndex].InboundPackages.reduce(
          (t, { Quantity }) => Number(t) - Number(Quantity),
          Orderlines[orderlineIndex].Quantity,
        ),
        ItemId: undefined,
        ProductBundle: {
          ...JSON.parse(
            JSON.stringify({
              ...this.productBundleDefaultTemplate,
              ...(BasePackage?.BaseProductBundle || {}),
              ItemId: null,
              ProductId: Orderlines[orderlineIndex].ProductId,
              Bundles: (BasePackage?.BaseProductBundle?.BaseBundles || []).map(
                (v) => ({
                  ...this.bundleDefaultTemplate,
                  ...v,
                  ItemId: undefined,
                }),
              ),
            }),
          ),
        },
      };

      Orderlines[orderlineIndex].InboundPackages.push(
        JSON.parse(JSON.stringify(InboundPackage)),
      );

      this.resetTemplate({ ...this.template, Orderlines });
    },

    onPackageQuantityUpdate(Quantity, orderlineIndex, packageIndex) {
      const { Orderlines } = this.template;
      Orderlines[orderlineIndex].InboundPackages[packageIndex].Quantity = Quantity;
      this.resetTemplate({ ...this.template, Orderlines });
    },

    onRemoveOrderline(orderlineIndex) {
      const { Orderlines } = this.template;
      this.panel = orderlineIndex;
      Orderlines.splice(orderlineIndex, 1);

      this.resetTemplate({ ...this.template, Orderlines });
    },

    onRemovePackage(orderlineIndex, packageIndex) {
      const { template } = this;

      template.Orderlines[orderlineIndex].InboundPackages.splice(
        packageIndex,
        1,
      );

      this.resetTemplate(template);
      this.packagesPanel = packageIndex - 1;
    },

    async onClonePackage(orderlineIndex, packageIndex) {
      const { Orderlines } = this.template;

      const Quantity = Orderlines[orderlineIndex].InboundPackages[packageIndex].Quantity - 1;

      Orderlines[orderlineIndex].InboundPackages[packageIndex]
        .Quantity = Quantity > 1 ? Quantity : 1;

      Orderlines[orderlineIndex].InboundPackages.push(
        this.clone(Orderlines[orderlineIndex].InboundPackages[packageIndex]),
      );

      this.resetTemplate({ ...this.template, Orderlines });
      this.packagesPanel = Orderlines[orderlineIndex].InboundPackages.length - 1;
    },

    clone(Package) {
      return JSON.parse(
        JSON.stringify({
          ...Package,
          ItemId: undefined,
          Quantity: 1,
          ProductBundle: {
            ...Package.ProductBundle,
            ItemId: undefined,
            Bundles: Package.ProductBundle.Bundles.map((i) => ({
              ...i,
              ItemId: undefined,
            })),
          },
        }),
      );
    },
  },
};
</script>
