import localhost from './localhost';

export default (hostname) => {
  switch (hostname === 'localhost' ? localhost : hostname) {
    case 'vdl.wms.fitsoft.eu':
    case 'vdl.wms-accp.fitsoft.eu':
      return {
        domain: 'vdl.eu.auth0.com',
        audience: 'https://vdl.fitsoft.nl',

        clientId: 'RCPUvcTdHpcWU1amW8BV0miL71tRpixs',
      };
    case 'fitsoft-slim.wms.fitsoft.eu':
    case 'fitsoft-slim.wms-accp.fitsoft.eu':
      return {
        domain: 'fitsoft-slim.eu.auth0.com',
        audience: 'https://slim.fitsoft.nl',

        clientId: 'LiGlWUq3fjaJZtDN6z14cXLjLuhlSHG7',
      };
    default:
      throw new Error('Ivalid hostname config');
  }
};
