<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="loading"
            :loading="loading"
            :class="{ 'pt-2': !loading }"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              ref="list"
              :headers="headers"
              :get-list="getList"
              :list="list"
              class="complex-footer"
              :loading="loading"
            >
              <template #footer-prepend>
                <v-btn
                  small
                  color="primary darken-1"
                  class="width-auto"
                  :disabled="loading"
                  :loading="loading"
                  @click.stop="onForm()"
                >
                  {{ $t("label:order_inbound", null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn-toggle
                  v-model="dStatus"
                  mandatory
                  @change="onUpdateStatusFilter"
                >
                  <v-btn
                    small
                    :value="null"
                  >
                    {{ $t("text:all") }}
                  </v-btn>
                  <v-btn
                    v-for="({ text, value }, index) in statuses"
                    :key="`status-${index}`"
                    width="auto"
                    small
                    :value="value"
                  >
                    {{ text }}
                  </v-btn>
                </v-btn-toggle>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <div class="d-flex">
                      <v-icon
                        :disabled="loading"
                        class="float-left"
                        color="accent"
                        left
                        @click.stop="onForm(item.ItemId)"
                      >
                        mdi-open-in-app
                      </v-icon>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon
                            :disabled="loading"
                            class="float-left"
                            color="accent"
                            left
                            v-on="on"
                            @click.stop="onPrint(item.ItemId)"
                          >
                            mdi-printer
                          </v-icon>
                        </template>
                        <span>{{ $t('label:print_order') }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>
                    {{ item.CustomerReference }}
                  </td>
                  <td>
                    {{ mCustomer(item.CustomerId) }}
                  </td>
                  <td>
                    {{ item.DebtorId }}
                  </td>
                  <td>{{ mQuantity(item) }}</td>
                  <td>
                    <v-btn
                      v-for="(
                        { ItemId, PlannedStart }, index
                      ) in mPlannedDockings(item)"
                      :key="`${index}-planned-docking-time`"
                      x-small
                      color="blue"
                      class="ma-1"
                      outlined
                      :to="{
                        name: 'movement-docking-form',
                        params: { ItemId, Type: 'inbound' },
                      }"
                    >
                      <v-icon left>
                        mdi-map-marker-distance
                      </v-icon>
                      #{{ ItemId }} | {{ PlannedStart }}
                    </v-btn>
                  </td>
                  <td>
                    <v-tooltip
                      v-if="['New'].includes(item.Status)"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          class="mr-2 float-right"
                          v-on="on"
                          @click="onDelete(item)"
                        >
                          <v-icon class="delete-icon">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("label:delete") }}
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'InboundOrderIndexMovement',

  data: () => ({
    dStatus: null,
  }),

  computed: {
    ...mapGetters({
      loading: 'inboundOrder/loadingList',
      list: 'inboundOrder/list',
      customers: 'customer/items',
    }),

    statuses() {
      return [
        {
          value: 'New',
          text: this.$t('label:status:new', null, 1),
        },
        {
          value: 'Plannable',
          text: this.$t('label:status:plannable', null, 1),
        },
        {
          value: 'Planned',
          text: this.$t('label:status:planned', null, 1),
        },
        {
          value: 'Check-in',
          text: this.$t('label:status:check-in', null, 1),
        },
        {
          value: 'Handling',
          text: this.$t('label:status:handling', null, 1),
        },
        {
          value: 'Done',
          text: this.$t('label:status:done', null, 1),
        },
      ];
    },

    headers() {
      return [
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:customer_reference', null, 1),
          value: 'CustomerReference',
          type: 'text',
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:customer', null, 1),
          value: 'CustomerId',
          clearable: true,
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:debtor', null, 1),
          value: 'DebtorId',
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:package:quantity', null, 1),
          value: '',
          searchable: false,
          sortable: false,
        },
        {
          text: this.$t('label:planned_dockings', null, 1),
          value: 'PlannedStart',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        {
          text: '',
          value: '',
          searchable: false,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getList: 'inboundOrder/getList',
      create: 'inboundOrder/create',
      deleteItem: 'inboundOrder/destroy',
    }),
    mPlannedDockings({ Orderlines }) {
      const dockings = Orderlines.flatMap(({ Docking }) => Docking).filter(
        (v) => v,
      );

      dockings.sort(
        // eslint-disable-next-line no-nested-ternary
        (a, b) => (a.PlannedStart < b.PlannedStart
          ? -1
          : a.PlannedStart > b.PlannedStart
            ? 1
            : 0),
      );
      return dockings.map((v) => ({
        ...v,
        PlannedStart: v.PlannedStart
          ? this.$moment(v.PlannedStart).format('DD-MM-YYYY')
          : '-',
      }));
    },
    mQuantity({ Orderlines }) {
      return Orderlines.flatMap((v) => v.InboundPackages || []).reduce(
        (total, { Quantity }) => total + Quantity,
        0,
      );
    },
    onUpdateStatusFilter(value) {
      const { filters } = this.list;
      delete filters.Status;
      if (value) {
        filters.Status = { type: 'select', value };
      }
      this.getList({
        ...this.list,
        offset: 0,
        filters,
      });
    },
    async onForm(ItemId) {
      this.$router.push({
        name: 'movement-inbound-order-form',
        params: { ItemId },
      });
    },
    async onPrint(ItemId) {
      this.docmosisLoading = true;
      const { data } = await this.axios.get(
        `https://${window.location.hostname}:3000/inbound-orders/${ItemId}/handling/docmosis`,
      );
      this.docmosisLoading = false;
      const base64atob = atob(data[0].base64);
      const byteNumbers = new Array(base64atob.length);
      for (let i = 0; i < base64atob.length; i += 1) {
        byteNumbers[i] = base64atob.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(byteNumbers)], {
        type: 'application/pdf',
      });

      const tab = window.open('/');
      tab.URL.createObjectURL(new File([blob], data.filename));
      tab.onload = () => {
        tab.location = URL.createObjectURL(blob);
      };
    },
    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
    mCustomer(CustomerId) {
      const customer = this.customers.find(
        ({ ItemId }) => ItemId === CustomerId,
      );
      return customer ? customer.Name : '-';
    },
  },
};
</script>
<style lang="scss">
.complex-footer {
  .v-data-footer__select {
    margin-left: 0px;
  }
}
</style>
