import defaultActions from '@/store/defaultActions';

export default {
  ...defaultActions,

  getItems(module, p = {}) {
    const params = p;
    if (module.getters.WarehouseId) {
      params.WarehouseId = module.getters.WarehouseId;
    }
    return defaultActions.getItems(module, params);
  },

  getList(
    module,
    {
      limit = module.getters.list?.limit,
      offset = module.getters.list?.offset,
      filters = module.getters.list?.filters,
    },
  ) {
    const f = filters;
    if (module.getters.WarehouseId) {
      f.WarehouseId = { value: module.getters.WarehouseId, type: 'select' };
    } else if (f.WarehouseId?.sort) {
      f.WarehouseId = { sort: f.WarehouseId.sort };
    } else {
      delete f.WarehouseId;
    }
    return defaultActions.getList(module, { limit, offset, filters: f });
  },

  returnItems(module, p = {}) {
    const params = p;
    if (module.getters.WarehouseId) {
      params.WarehouseId = module.getters.WarehouseId;
    }
    return defaultActions.returnItems(module, params);
  },
};
