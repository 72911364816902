<template>
  <system-page class="y-overflow custom-bg">
    <v-col sm="12">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-col>
  </system-page>
</template>

<script>

import SystemPage from '@/components/SystemPage.vue';

export default {
  name: 'ViewsStorageResources',
  components: {
    SystemPage,
  },
};
</script>
