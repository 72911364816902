<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-card
      v-if="template"
      tile
      :loading="sectorLoading"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title class="card-title">
        <span class="card-title-caption text-truncate">
          {{ $t("label:sector", null, 1) }}
        </span>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="pb-0">
        <v-row
          justify="space-between"
          class="ma-0"
          dense
        >
          <v-col
            md="3"
            xl="2"
          >
            <v-autocomplete
              v-model="template.WarehouseId"
              :label="$t('label:warehouse', null, 1)"
              item-text="Name"
              item-value="ItemId"
              :items="warehouseItems"
              aria-autocomplete="off"
              hide-details
              :disabled="Boolean(template.WarehouseId && template.ItemId)"
              :rules="mnValidation.required"
              @input="onChangeWarehouseId"
            />
          </v-col>
        </v-row>
        <v-row
          justify="space-between"
          class="ma-0"
          dense
        >
          <v-col
            md="3"
            xl="2"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="template.Name"
                  :label="$t('label:name', null, 1)"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="template.Level"
                  :label="$t('label:level', null, 1)"
                />
                <v-autocomplete
                  v-model="template.Type"
                  :items="sectorTypes"
                  :label="$t('label:type', null, 1)"
                  hide-details="auto"
                  aria-autocomplete="off"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="2"
            xl="1"
          >
            <v-text-field
              v-model="template.PositionX"
              :label="$t('label:position:x')"
            />
            <v-text-field
              v-model="template.PositionY"
              :label="$t('label:position:y')"
            />
          </v-col>
          <v-col cols="auto">
            <v-row no-gutters>
              <v-col cols="auto">
                <c-number
                  v-model="template.Length"
                  :fixed="2"
                  :disabled="false"
                  :label="$t('label:length')"
                  class="width-10ch"
                />
              </v-col>
              <v-col
                cols="auto"
                class="pl-4"
              >
                <c-number
                  v-model="template.Width"
                  :fixed="2"
                  :disabled="false"
                  :label="$t('label:width')"
                  class="width-10ch"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <c-number
                  v-model="template.InnerLength"
                  :fixed="2"
                  :disabled="false"
                  :label="$t('label:inner_length')"
                  class="width-10ch"
                />
              </v-col>
              <v-col class="px-4">
                <c-number
                  v-model="template.InnerWidth"
                  :fixed="2"
                  :disabled="false"
                  :label="$t('label:inner_width')"
                  class="width-10ch"
                />
              </v-col>
              <v-col>
                <c-number
                  v-model="template.InnerHeight"
                  :fixed="2"
                  :disabled="false"
                  :label="$t('label:inner_height')"
                  class="width-10ch"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="2"
            xl="1"
          >
            <v-autocomplete
              v-model="template.ClusterId"
              :label="$t('label:parent_cluster', null, 1)"
              :items="clusters"
              :loading="clusterLoading"
              :search-input.sync="clusterSearch"
              :disabled="Boolean(template.SectorId)"
              item-value="ItemId"
              item-text="Name"
              flat
              hide-no-data
              clearable
              aria-autocomplete="off"
              @update:search-input="onClusterSearch"
              @input="onClusterInput"
            />
            <v-autocomplete
              v-model="template.SectorId"
              :label="$t('label:parent_sector', null, 1)"
              :items="cSectorItems"
              :loading="sectorLoading"
              :search-input.sync="sectorSearch"
              :disabled="Boolean(template.ClusterId)"
              item-value="ItemId"
              item-text="Name"
              cache-items
              flat
              hide-no-data
              clearable
              aria-autocomplete="off"
              @update:search-input="onSectorSearch"
              @input="onSectorInput"
            />
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <c-package-index
          v-if="template.ItemId"
          :static-filters="staticFilters"
          :disable="{ create: true, open: true, delete: true }"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          fixed
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          :loading="sectorLoading"
          :disabled="sectorLoading"
          @click="onSave"
        >
          {{ $t("btn:save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CPackageIndex from '@/components/package/index/storage.vue';

export default {
  name: 'SectorForm',
  components: {
    CPackageIndex,
  },

  data: () => ({
    sectorSearch: null,
    clusterSearch: null,
  }),

  computed: {
    ...mapGetters({
      template: 'sector/template',

      sectorItems: 'sector/items',
      clusterItems: 'cluster/items',

      sectorLoading: 'sector/loading',
      clusterLoading: 'cluster/loading',

      warehouseItems: 'warehouse/items',
      WarehouseId: 'warehouse/WarehouseId',
    }),

    sectorTypes() {
      return [
        { text: this.$t('label:rack'), value: 'Rack' },
        { text: this.$t('label:pallet'), value: 'Pallet' },
        { text: this.$t('label:ground'), value: 'Ground' },
      ];
    },

    cSectorItems() {
      return this.sectorItems.filter(
        ({ ItemId, deletedAt }) => !deletedAt || ItemId === this.template.SectorId,
      );
    },
    clusters() {
      return this.clusterItems.filter(
        (a) => (!a.deletedAt || a.ItemId === this.template.ClusterId)
        && a.WarehouseId === this.template.WarehouseId,
      );
    },
    staticFilters() {
      return { SectorId: { type: 'select', value: this.template.ItemId } };
    },
  },

  beforeMount() {
    this.initialize();
  },

  async beforeDestroy() {
    await this.resetTemplate();
  },

  methods: {
    ...mapActions({
      update: 'sector/update',
      create: 'sector/create',
      resetTemplate: 'sector/resetTemplate',
      getSector: 'sector/getItem',
      getSectorItems: 'sector/getItems',
      getClusterItems: 'cluster/getItems',
    }),
    async initialize() {
      await this.resetTemplate(false);
      if (this.$route.params.ItemId) {
        const { item } = await this.getSector(this.$route.params.ItemId);
        this.resetTemplate({
          ...item,
          ItemId: this.$route.name === 'storage-sector-clone' ? undefined : item.ItemId,
          Name: this.$route.name === 'storage-sector-clone' ? `${item.Name} - clone` : item.Name,
        });
      } else {
        this.resetTemplate({ WarehouseId: this.WarehouseId });
      }
      this.onClusterSearch();
    },
    onSectorInput() {
      this.resetTemplate({ ...this.template, ClusterId: null });
    },
    onClusterInput() {
      this.resetTemplate({ ...this.template, SectorId: null });
    },

    onSectorSearch() {
      clearTimeout(this.sectorTimeout);
      this.sectorTimeout = setTimeout(() => {
        const filter = {};
        if (this.sectorSearch && this.sectorSearch.length) {
          filter.Name = { value: this.sectorSearch, type: 'text' };
        }
        this.getSectorItems(filter);
      }, 2000);
    },
    onClusterSearch() {
      clearTimeout(this.clusterTimeout);
      this.clusterTimeout = setTimeout(() => {
        const filter = {};
        if (this.clusterSearch && this.clusterSearch.length) {
          filter.Name = { value: this.clusterSearch, type: 'text' };
        }
        this.getClusterItems(filter);
      }, 2000);
    },
    onChangeWarehouseId(WarehouseId) {
      this.resetTemplate({ ...this.template, WarehouseId, ClusterId: null });
      this.onClusterSearch();
    },
    onClose() {
      this.$router.push({ name: 'storage-sector-list' });
    },
    async onSave() {
      if (this.$route.name === 'storage-sector-clone' && await this.create(this.template)) {
        this.onClose();
      } else if (this.$route.params.ItemId) {
        if (await this.update(this.template)) {
          this.onClose();
        }
      } else if (await this.create(this.template)) {
        this.onClose();
      }
    },
    onUpdatePackageModel(value) {
      const index = this.template.Packages.findIndex(
        ({ ItemId }) => ItemId === value.ItemId,
      );
      const { Packages } = this.template;
      Packages[index] = value;
      this.resetTemplate({ ...this.template, Packages });
    },
  },
};
</script>

<style lang="scss">
.content-no-padding {
  &.v-expansion-panel-content {
    > .v-expansion-panel-content__wrap {
      padding: 0px;
    }
  }
}
</style>
