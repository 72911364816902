<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="highComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="template"
      tile
      :loading="loading"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title>
        <span>
          {{ $t("label:invoice", null, 1) }}
        </span>
        <v-chip class="ml-4">
          {{ $t(template.Status) }}
        </v-chip>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        style="height: 100%"
        class="pt-2"
      >
        <v-form
          ref="form"
          style="height: inherit"
        >
          <v-row
            style="height: inherit"
            class="ma-0"
          >
            <c-main
              :collapse="collapse"
              @collapse="(v) => collapse = v"
              @on-change-customer="onChangeCustomer()"
            />
            <v-col class="px-2">
              <v-row
                align="end"
                justify="end"
                class="ma-0"
              >
                <h3>
                  {{ $t('label:price_total', null ,1) }}:
                </h3>
                <h2 class="ml-2">
                  {{ CInvoicePrice }}
                </h2>
              </v-row>
              <v-row
                align="center"
                class="ma-0 mt-2"
                dense
              >
                <v-col>
                  <h5 class="font-weight-thin">
                    {{ $t('label:name', null, 1) }}
                  </h5>
                </v-col>
                <v-col
                  xl="2"
                  md="2"
                >
                  <h5 class="font-weight-thin">
                    {{ $t('label:type', null, 1) }}
                  </h5>
                </v-col>
                <v-col
                  xl="1"
                  md="1"
                >
                  <h5 class="font-weight-thin">
                    {{ $t('label:quantity', null, 1) }}
                  </h5>
                </v-col>
                <v-col
                  cols="auto"
                >
                  <h5
                    class="font-weight-thin"
                    style="width: 140px"
                  >
                    {{ $t('label:price_unit', null, 1) }}
                  </h5>
                </v-col>
                <v-col cols="auto">
                  <h5
                    class="font-weight-thin d-flex justify-end"
                    style="width: 140px"
                  >
                    {{ $t('label:price', null, 1) }}
                  </h5>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <c-invoiceline-main @add="onAdd" />
              </v-row>
              <v-row class="ma-0">
                <c-invoiceline
                  v-for="(Invoiceline, invoiceLineIndex) in template.Invoicelines"
                  :key="`invoiceline-${invoiceLineIndex}`"
                  :invoiceline="Invoiceline"
                  :loading="loading"
                  :line-price="CLinePrice(Invoiceline).toFixed(2)"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          :loading="loading"
          :disabled="loading || !template.CustomerId"
          @click="onSave"
        >
          {{ $t("btn:save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CMain from '@/components/invoice/main.vue';
import CInvoiceline from '@/components/invoiceline/invoiceline.vue';
import CInvoicelineMain from '@/components/invoiceline/main.vue';

export default {
  name: 'InvoiceForm',
  components: {
    CMain,
    CInvoiceline,
    CInvoicelineMain,
  },

  data() {
    return {
      collapse: false,
    };
  },

  computed: {
    ...mapGetters({
      customerItems: 'customer/items',
      loading: 'invoice/loading',
      template: 'invoice/template',

      warehouseLoading: 'warehouse/loading',
      warehouses: 'warehouse/items',

      WarehouseId: 'warehouse/WarehouseId',
      invoiceItems: 'invoice/items',
    }),

    customer() {
      return this.customerItems.filter(
        (c) => c.ItemId === this.template.CustomerId,
      );
    },

    CInvoicePrice() {
      return Number((this.template.Invoicelines || [])
        .reduce((t, i) => this.CLinePrice(i) + t, 0)).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
    },

  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      update: 'invoice/update',
      create: 'invoice/create',
      resetTemplate: 'invoice/resetTemplate',
      getTemplate: 'invoice/getItem',

      getContactItems: 'contact/getItems',
      setContactItems: 'contact/setItems',
    }),

    onAdd(invoiceline) {
      this.resetTemplate({
        ...this.template,
        Invoicelines: [
          invoiceline,
          ...(this.template.Invoicelines || []),
        ],
      });
    },

    CLinePrice: (Invoiceline) => {
      if (Invoiceline.Type !== 'SectorUsage') {
        return Invoiceline.Quantity * Invoiceline.PriceUnit * Invoiceline.Multiplier;
      }
      switch (Invoiceline.Unit) {
        case 'CarrierId':
          return Invoiceline.PriceUnit * Invoiceline.Multiplier;
        default:
          return Invoiceline.Quantity * Invoiceline.PriceUnit * Invoiceline.Multiplier;
      }
    },

    onClose() {
      this.$router.push({ name: 'finance-invoice-list' });
    },

    async initialize() {
      if (this.$route.params.ItemId) {
        const { item } = await this.getTemplate(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({
          WarehouseId: this.WarehouseId,
          Orderlines: [],
        });
      }
    },
    onChangeCustomer(keep = false) {
      if (!keep) {
        this.template.ContactId = null;
      }
      this.setContactItems([]);
      this.getContactItems({ CustomerId: this.template.CustomerId });
    },

    async onSave() {
      if (this.$route.params.ItemId) {
        if (await this.update(this.template)) {
          this.onClose();
        }
      } else if (await this.create(this.template)) {
        this.onClose();
      }
    },
  },
};
</script>
