import Docks from '@/components/views/system/Docks.vue';

import Index from '@/components/dock/index.vue';

import dockForm from '@/components/dock/form.vue';

export default [
  {
    path: '/system/dock/list',
    component: Docks,
    meta: {
      restriction: ['WMS.Admin'],
    },

    children: [
      {
        name: 'system-dock-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Admin'] },
        children: [
          {
            name: 'system-dock-form',
            path: '/system/dock/form/:ItemId?',
            component: dockForm,
            meta: { restriction: ['WMS.Admin'] },
          },
        ],
      },
    ],
  },
];
