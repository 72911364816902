<template>
  <v-dialog
    :retain-focus="false"
    :value="showModal"
    transition="slide-y-transition"
    max-width="500"
    @keydown.esc="$emit('close')"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <c-user-preview
          :user="$auth.user"
        />
      </span>
    </template>
    <v-list>
      <v-subheader>
        {{ $auth.user.sub }}
      </v-subheader>
      <v-divider />
      <v-list-item>
        <v-col cols="auto">
          <v-list-item-avatar>
            <img
              :src="s3($auth.user.picture)"
              :alt="$auth.user.email"
            >
          </v-list-item-avatar>
        </v-col>
        <v-col>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ $auth.user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-col>
      </v-list-item>

      <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $auth.user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
    <v-list
      tile
      class="pa-1"
    >
      <v-divider />
      <v-list-group
        :tile="true"
        no-action
        prepend-icon="mdi-translate"
        @click.stop
      >
        <template #activator>
          <v-list-item-title>{{ $t('label:language') }}</v-list-item-title>
          <v-list-item-avatar
            style="min-width: 60px"
          >
            <country-flag
              :country="languageISOFlag"
              class="app-flag"
            />
          </v-list-item-avatar>
        </template>

        <v-list-item
          v-for="language in languages"
          :key="language.name"
          link
          @click="onSelect(language.iso2)"
        >
          <v-list-item-title
            v-text="language.name"
          />
          <v-list-item-avatar
            style="min-width: 60px"
          >
            <country-flag
              :key="language.name"
              :country="language.iso2"
              size="normal"
              class="app-flag"
            />
          </v-list-item-avatar>
        </v-list-item>
      </v-list-group>

      <v-list-group
        :tile="true"
        prepend-icon="mdi-account"
        class="scrollable-list"
        @click.stop
      >
        <template #activator>
          <v-list-item-title>{{ $t('text:user_roles') }}</v-list-item-title>
        </template>

        <template v-for="(roleGroup, rIndex) in roleGroups">
          <v-list-group
            :key="`${rIndex}-group`"
            no-action
            sub-group
            prepend-icon=""
          >
            <template #activator>
              <v-list-item-content class="ml-12">
                <v-list-item-title>{{ roleGroup.value }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template #appendIcon>
              <v-icon>
                mdi-chevron-down
              </v-icon>
            </template>
            <template v-for="role in roles">
              <v-list-item
                v-if="role.startsWith(roleGroup.value)"
                :key="role"
              >
                <v-chip>
                  {{ $t(rolename(role)) }}
                </v-chip>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>

      <v-list-item-group
        :tile="true"
        color="primary"
      >
        <v-list-item :to="{ name: 'logout' }">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('label:logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-dialog>
</template>

<script>

import { getInstance } from '../auth/index';

export default {
  name: 'AppUser',
  data: () => ({
    showModal: false,
  }),
  computed: {
    items() {
      return [
        { text: this.$t('label:language', null, 1) },
        { divider: true },
        { text: this.$t('label:logout') },
      ];
    },
    languageISOFlag() {
      if (this.$auth.language === 'en') {
        return 'gb';
      }
      return this.$auth.language;
    },
    languages() {
      return [
        { name: 'English', iso2: 'gb' },
        { name: 'Nederlands', iso2: 'nl' },
      ].filter((l) => l.iso2 !== this.languageISOFlag);
    },
    roles() {
      return this.$auth.roles;
    },
    roleGroups() {
      const originArray = [];
      let groups = [];
      this.$auth.roles.forEach((role) => {
        if (role.search(/[.]/g) !== -1) {
          originArray.push(
            role.split('.')[0],
          );
        }
        groups = originArray.filter((v, i, a) => a.indexOf(v) === i).sort((b, c) => {
          const bName = b.toUpperCase();
          const cName = c.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return bName < cName ? -1 : bName > cName ? 1 : 0;
        });
        groups = groups.map((str, index) => ({ value: str, id: index + 1 }));
      });
      return groups;
    },
  },
  methods: {
    rolename(role) {
      return `role:${role}`;
    },
    countryISOLanguage(iso2) {
      if (iso2 === 'gb') {
        return 'en';
      }
      return iso2;
    },
    async onSelect(iso2) {
      this.loading = true;
      await getInstance().updateLanguage(this.countryISOLanguage(iso2));
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#app-avatar {
  height: 52px !important;
  width: 52px !important;
  min-width: 52px !important;
}
.v-dialog{
  position: absolute;
  top: 60px;
  right: 0;
}
.scrollable-list.v-list-group .v-list-group__items {
  @media (min-width: 600px) {
    max-height: 300px;
  }
  @media (min-width: 1280px) {
    max-height: 340px;
  }
  @media (min-width: 2000px) {
    max-height: 600px;
  }
  overflow-y: auto;
}
</style>
