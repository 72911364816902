import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  items: [],
  defaultTemplate: {

    CustomerId: null,
    DepartmentId: null,
    FunctionGroupId: null,
    LanguageId: null,

    ExternalId: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Mobile: '',
    Email1: '',
    Email2: '',
    LinkedIn: '',
    Note: '',
    Gender: 'Male',
    LetterAttention: '',
    LetterSalutation: '',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,

    IsSupplier: false,
    IsDepartment: false,
  },
  template: null,
  loading: false,
};
