export default {
  ApprovalChiefEngineer: 'At Approval by Chief Engineer',
  ApprovalManager: 'At Approval by Manager',
  ApprovalManagerMaintenance: 'At Approval by Maintenance Manager',
  ApprovalManagerWarehouse: 'At Approval by Warehouse Manager',
  Archived: 'Archived',
  Backlog: 'Backlog',
  Boolean: 'Boolean',
  BooleanString: 'BooleanString',
  Callback: 'Callback',
  Cancelled: 'Cancelled',
  'City/Government': 'City/Government',
  'Cleaning Company': 'Cleaning Company',
  'Client Visit': 'Client visit',
  Company: 'Company',
  Construction: 'Construction',
  Contact: 'Contact',
  Customer: 'Customer',
  Demo: 'Demo',
  Done: 'Done',
  Events: 'Events',
  ExternalInspection: 'External Inspection',
  ExternalMaintenance: 'External Maintenance',
  'Foreign company': 'Foreign company',
  Gardener: 'Gardener',
  Giveaway: 'Giveaway',
  High: 'High',
  Inbound: 'Inbound',
  InProgress: 'In Progress',
  Installation: 'Installation',
  InternalInspection: 'Internal Inspection',
  Lead: 'Lead',
  Loading: 'Loading',
  Low: 'Low',
  Maintenance: 'Maintenance',
  New: 'New',
  Normal: 'Normal',
  Number: 'Number',
  'Office Visit': 'Office visit',
  Ordered: 'Ordered',
  Outbound: 'Outbound',
  Painter: 'Painter',
  Partner: 'Partner',
  Pause: 'Pause',
  'Phone Call': 'Phone call',
  Pickup: 'Pickup',
  PickupCheck: 'Pickup Check',
  Planned: 'Planned',
  'Private Persoon': 'Private Persoon',
  Prospect: 'Prospect',
  Quotation: 'Quotation',
  'Rental Company': 'Rental Company',
  Rent: 'Rent',
  Rented: 'Rented',
  Repair: 'Repair',
  Returned: 'Returned',
  Sales: 'Sales',
  'Send Document': 'Send document',
  SimpleInspection: 'Simple Inspection',
  String: 'String',
  Supplier: 'Supplier',
  Suspect: 'Suspect',
  Underway: 'Underway',
  Urgent: 'Urgent',
  SectorUsage: 'Sector usage',
  ExecutorUsage: 'Executor usage',
  ConsumableUsage: 'Consumable usage',
  ResourceUsage: 'Resource usage',
  Custom: 'Custom',
  Surcharge: 'Surcharge',
  CubicMeters: 'Cubic meters',
  SquareMeters: 'Square meters',
  Duration: 'Duration',
  Quantity: 'Quantity',
  'Video Call': 'Video call',
  'Visit Client': 'Visit client',
  'Visit Office': 'Visit office',
  'action:get:error:address': 'Failed to get addresses from server: {error}',
  'action:get:error:conact': 'Failed to get contacts from server: {error}',
  'action:get:error:customer': 'Failed to get customers from server: {error}',
  'action:get:error:email': 'Failed to get email messages from server: {error}',
  'action:get:error:item': 'Failed to get data from server: {error}',
  'action:get:error:kvkOrganisation': 'Failed to get KvK Organisations from server: {error}',
  'action:get:error:users': 'Failed to get users from server: {error}',
  'action:save:error:address': 'Failed to save address: {error}',
  'action:save:error:contact': 'Failed to save contact: {error}',
  'action:save:error:customer': 'Failed to save customer: {error}',
  'action:save:error:email': 'Failed to send message to Mail Server: {error}',
  'action:save:error:item': 'Failed to save data: {error}',
  'action:save:error:kvkOrganisation': 'Failed to save KvK Organisation: {error}',
  'action:save:error:language': 'Failed to save language preference: {error}',
  'action:save:success:address': 'Address saved',
  'action:save:success:contact': 'Contact saved',
  'action:save:success:customer': 'Customer saved',
  'action:save:success:email': 'Message sent to Mail Server',
  'action:save:success:item': 'Data saved',
  'action:save:success:kvkOrganisation': 'KvK Organisation saved',
  'action:save:success:language': 'Language preference saved',
  'action:save:success:warehouse': 'Warehouse saved',
  'btn:hide': 'Hide',
  'btn:show_all': 'Show All',
  'btn:accessorie:add': 'Accessorie',
  'btn:accessories_lost': 'Accessories Lost',
  'btn:add': 'Add',
  'btn:address:add': 'Add Address',
  'btn:address:new': 'New Address',
  'btn:addresses': 'Addresses',
  'btn:administration': 'administration',
  'btn:approve': 'Approve',
  'btn:back': 'Back',
  'btn:back_to_tasks': 'Back to Tasks',
  'btn:base_checklist:add': 'Add Base Checklist',
  'btn:base_procedure:add': 'Add Base Procedure',
  'btn:basic_data': 'Basic Information',
  'btn:cancel': 'Cancel',
  'btn:chamber_of_commerce': 'Chamber Of Commerce',
  'btn:check_credit_device': 'Check via CreditDevice',
  'btn:check_credit_rating': 'Check via CreditRating',
  'btn:clear': 'Clear',
  'btn:close': 'Close',
  'btn:collection': 'Collection',
  'btn:company_customer:new': 'Company',
  'btn:complete': 'Complete',
  'btn:complete_and_approve': 'Complete & Approve Maintenance',
  'btn:complete_and_cancel': 'Complete & Cancel Maintenance',
  'btn:configuration:add': 'Add Configuration',
  'btn:configuration_line:add': 'Add Configuration Line',
  'btn:confirm': 'Confirm',
  'btn:confirm_loaded': 'Confirm Loaded',
  'btn:confirm_pickup': 'Confirm Pickup',
  'btn:confirm_replacement': 'Confirm replacement',
  'btn:confirm_transformation': 'Confirm transformation',
  'btn:contact:add': 'Add Contact',
  'btn:contact:new': 'New Contact',
  'btn:credit_rating': 'Credit Rating',
  'btn:customer:add': 'Add Customer',
  'btn:customer:new': 'New Customer',
  'btn:deactive': 'Deactivate',
  'btn:delivered_to_counter': 'Delivered To Counter',
  'btn:discount:add': 'Discount',
  'btn:end_delivery': 'End Delivery',
  'btn:execute': 'Execute',
  'btn:export': 'Export',
  'btn:finance_data': 'Finance Data',
  'btn:force_approve': 'Force Approve',
  'btn:force_save': 'Force Save',
  'btn:force_send': 'Force Send',
  'btn:general': 'general',
  'btn:get_order_items': 'Get Order Items',
  'btn:giveaway': 'Giveaway',
  'btn:invoice:create_custom': 'Create Custom Invoice',
  'btn:invoice:generate': 'Generate invoice',
  'btn:item:add': 'Item',
  'btn:kvk_data': 'KVK Data',
  'btn:make_all_free': 'Make All Free',
  'btn:merchandize:add': 'Merchandize',
  'btn:next': 'Next',
  'btn:ok': 'OK',
  'btn:order:rent': 'Rent Order',
  'btn:order:purchase': 'Purchase Order',
  'btn:order:sales': 'Sales Order',
  'btn:order:add': 'Order',
  'btn:order:new': 'New Order',
  'btn:order_ready': 'Order is ready',
  'btn:other_data': 'Other Data',
  'btn:pickup': 'Pickup',
  'btn:position': 'Position',
  'btn:prev': 'Prev',
  'btn:print': 'Print',
  'btn:private_customer:new': 'Individual',
  'btn:procedure_add': 'Add Procedure',
  'btn:procedure_new': 'New Procedure',
  'btn:product:add': 'Product',
  'btn:product_group:add': 'Product Subgroup',
  'btn:product_serie:add': 'Product Group',
  'btn:push_to_finish': 'Push to Finish',
  'btn:rating': 'Rating',
  'btn:re_export_selected': 'Re-export Selected',
  'btn:reassign_kvk': ' Re-assign KVK',
  'btn:refund': 'Refund',
  'btn:resend_pdf': '(Re)send PDF',
  'btn:reset': 'Reset Signature',
  'btn:reset_configuration': 'Reset Configuration',
  'btn:reset_items': 'Reset Items',
  'btn:save': 'Save',
  'btn:search': 'Search',
  'btn:send': 'Send',
  'btn:send_selected': 'Send Selected',
  'btn:update_and_resend': 'Update & Send',
  'btn:send_to_ordered': 'Send To Ordered',
  'btn:send_to_quotation': '(Re)send Quotation',
  'btn:send_to_tms': 'Send to TMS',
  'btn:serial:add': 'Serial',
  'btn:serial:possession': '3rd party Serial',
  'btn:show_completed': 'Show Completed',
  'btn:show_on_site': 'Show on Site',
  'btn:signature': 'Signature',
  'btn:start': 'Start',
  'btn:start_delivery': 'Start Delivery',
  'btn:start_loading': 'Start Loading',
  'btn:task:add': 'Add Task',
  'btn:to_backlog': 'To Backlog',
  'btn:to_maintenance_location': 'To maintenance location',
  'btn:to_pickup_list': 'To Pick Up List',
  'btn:to_pickup_location': 'To pickup location',
  'btn:to_planned': 'To Planned',
  'btn:to_plannable': 'To Plannable',
  'btn:to_check-in': 'To Check-in',
  'btn:to_handling': 'To Handling',
  'btn:to_done': 'To Done',
  'btn:transform_to_note': 'Transform to note',
  'btn:transform_to_task': 'Transform to task',
  'btn:unblock': 'Unblock',
  'btn:update': 'Update',
  'btn:update_end_date': 'Update End Date',
  'btn:update_note': 'Update notes & Back',
  'btn:use_configuration': 'Use Configuration',
  'btn:use_item': 'Use Item',
  'camera:auto': 'Auto-detect camera',
  'camera:front': 'Front camera',
  'camera:rear': 'Rear camera',
  'credit_save:check:error': 'CreditSafe check - fail: {error}',
  'error:qr:NotAllowedError': "Browser's camera access blocked by User",
  'error:qr:NotFoundError': 'Device has now suitable camera',
  'error:qr:NotReadableError': 'Camera already in use',
  'error:qr:NotSupportedError': 'Page served through insecure protocol',
  'error:qr:OverconstrainedError': "Can't find selected camera",
  'error:qr:StreamApiNotSupportedError': 'Camera not supported by browser',
  'error:qr:TypeError': "Browser doesn't support flashlight controls",
  'error:task:external_maintenance:paused:invalid_auth_sub': 'Current assigned Engineer is {engineer}',
  'error:task:giveaway:paused:invalid_vehicle_id': 'Task paused from different vehicle ({vehicle})',
  'error:task:giveaway:paused:no_auth_sub': 'Task has no assigned executor',
  'help:entity:in_maintenance': "Item's amount unavailable. Currently in acceptance or maintenance",
  'help:entity:in_rent': "Item's amount unavailable. Currently rented",
  'help:entity:in_stock': "Item's total amount available at warehouse at the moment",
  'help:orderline:lost': "Order's amount declared LOST by client, with the exception of SOLD",
  'help:orderline:quantity': 'Amount of items ordered',
  'help:orderline:rented': "Order's amount at client side, DOES NOT include LOST or SOLD",
  'help:orderline:returned': "Order's amount returned by client, DOES NOT include SOLD and DOES include FOUND ",
  'help:orderline:sold': "Order's amount of lost > invoiced > payed items",
  'help:orderline:underway': "Order's amount of currently transporting items, DOES include FOUND",
  'help:taskline:giveaway:loaded_quantity': 'Loaded amount confirmed by driver',
  'help:taskline:giveaway:planned_quantity_delivery': 'Amount of items Planned for Delivery',
  'help:taskline:giveaway:rentedout_quantity': 'Amount taken by client',
  'help:taskline:giveaway:undelivered_quantity': 'Amount of items not yet delivered',
  'help:taskline:pickup:found_quantity': 'Amount of found items, declared lost by client in previous Pickups',
  'help:taskline:pickup:loaded_quantity': 'Loaded amount, confirmed by driver',
  'help:taskline:pickup:lost_quantity': 'Amount declared lost by client',
  'help:taskline:pickup:order_total_unfound_quantity': 'Amount of items yet unfound',
  'help:taskline:pickup:order_total_unreturned_quantity': 'Amount of items not yet returned from client',
  'help:taskline:pickup:planned_found_quantity': 'Amount of items to be found, declared lost by client in previous Pickups',
  'help:taskline:pickup:planned_quantity_pickup': 'Amount of items Planned for Pick up',
  'help:taskline:pickup:rentedout_quantity': 'Amount of items rented by client',
  'help:taskline:replacement:planned_quantity_giveaway': 'Planned quantity of replacement items to deliver',
  'help:taskline:replacement:planned_quantity_pickup': 'Planned quantity of replacement items to pickup',
  'label:acceptance': ['Acceptance', 'Acceptances'],
  'label:accessorie': ['Accessorie', 'Accessories'],
  'label:accessorielose': 'Accessorie Lose',
  'label:action': ['Action', 'Actions'],
  'label:active': 'Active',
  'label:activity': ['Activity', 'Activities'],
  'label:approved': 'Approved',
  'label:add_document': 'Add Document',
  'label:add_file': 'Drag and drop to upload content!',
  'label:add_scheduled_service': 'Add Scheduled Service',
  'label:add_service_task': 'Add Service Task',
  'label:address': ['Address', 'Addresses'],
  'label:after': 'After',
  'label:allow_mailing': 'Allow Mailing',
  'label:answer': 'Answer',
  'label:answer_type': 'Answer Type',
  'label:archived': ['Archived'],
  'label:aspen': 'Aspen',
  'label:assign': 'Assign',
  'label:assigned_employee': 'Assigned To Employee',
  'label:association': ['Association', 'Associations'],
  'label:auto_discount': 'Discount Auto-Set',
  'label:auto_pickup': 'Auto-Pickup',
  'label:available_quantity': ['Available Quantity'],
  'label:awaiting_approval': ['Tasks pending approval'],
  'label:backlog': ['Backlog', 'Backlogs'],
  'label:backlog_filter': 'Date Filter',
  'label:backlog_search': 'Backlog Search',
  'label:base_checklist': ['Base Checklist', 'Base Checklists'],
  'label:base-package': 'Base package',
  'label:base_procedure': ['Base Procedure', 'Base Procedures'],
  'label:base_used_part': ['Base Used Part', 'Base Used Parts'],
  'label:batch': 'Batch',
  'label:batch:quantity': 'Batch quantity',
  'label:bcc': 'BCC',
  'label:before': 'Before',
  'label:billable': 'Billable',
  'label:blocked': ['Blocked'],
  'label:boolean': 'Boolean',
  'label:bought_circs': 'Bought Circumstances',
  'label:bought_date': 'Bought Date',
  'label:bought_documents': 'Bought Documents',
  'label:bought_from': 'Bought from',
  'label:bought_price': 'Bought price',
  'label:branch': 'Branch',
  'label:bundles': ['Bundle', 'Bundles'],
  'label:cancel': 'Cancel',
  'label:cancel_upload': 'Cancel Upload',
  'label:cancelled': ['Cancelled'],
  'label:capacity': 'Capacity(lifting/load/etc)',
  'label:carrier': ['Carrier', 'Carriers'],
  'label:carrier_add': 'Add carrier',
  'label:carrier_remove': 'Remove carrier',
  'label:cash': 'Cash',
  'label:cc': 'CC',
  'label:certification_period': 'Certification Period',
  'label:certification_provider': 'Certification Provider',
  'label:check_credit_rating': 'Check Credit Rating',
  'label:checklist': 'Checklist',
  'label:checklist_name': 'Checklist Name',
  'label:checklist_type': 'Checklist Type',
  'label:city': 'City',
  'label:clone': 'Clone',
  'label:cluster': 'Cluster',
  'label:code': 'Code',
  'label:collection': ['Collection', 'Collections'],
  'label:collection_name': 'Collection name',
  'label:collection_type': 'Collection type',
  'label:commentaries': 'Commentaries',
  'label:complete': 'Complete',
  'label:complete_acceptance': 'Complete Acceptance',
  'label:complete_giveaway': 'Complete Giveaway',
  'label:complete_maintenance': 'Complete Maintenance',
  'label:complete_pickup': 'Complete Pickup',
  'label:configuration': ['Configuration', 'Configurations'],
  'label:configuration_line': 'Configuration Line',
  'label:construction_year': ['Construction Year'],
  'label:contact': ['Contact', 'Contacts'],
  'label:contact_add': 'Add contact',
  'label:customer_add': 'Add customer',
  'label:contact_info': ['Contact Info'],
  'label:contract': 'Contract',
  'label:consumable': ['Consumable', 'Consumables'],
  'label:copy_phone': 'Copy phone number',
  'label:copy_serial': 'Copy Serial',
  'label:copy_url': 'Copy URL',
  'label:counter': ['Counter', 'Counters'],
  'label:counter_handling': ['Counter handling', 'Counter handlings'],
  'label:create': 'Create',
  'label:create_found_task': 'Create Return Task',
  'label:create_giveaway_task': 'Create Giveaway Task',
  'label:create_pickup_task': 'Create Pickup Task',
  'label:create_replacement_task': 'Create Replacement Task',
  'label:created': ['Created'],
  'label:created_at': 'Created at',
  'label:created_by': ['Created by'],
  'label:credit_check_alert': ['Credit Score lower than Order Total'],
  'label:credit_check_failed': 'Credit Check Failed',
  'label:credit_device_id': 'Credit Device Id',
  'label:credit_limit': 'Credit Limit',
  'label:credit_limit_history': 'Credit Limit History',
  'label:creditor_number': 'Creditor Number',
  'label:crm': 'CRM',
  'label:current_serial': ['Current Serial', 'Current Serials'],
  'label:price_day_current': 'Current day price',
  'label:price_day_static': 'Static day price',
  'label:price_sales_current': 'Current sales price',
  'label:price_sales_static': 'Static sales price',
  'label:price_week_current': 'Current week price',
  'label:price_week_static': 'Static week price',
  'label:custom': 'Custom',
  'label:custom_invoice_line': 'Custom invoice line',
  'label:customer': ['Customer', 'Customers'],
  'label:customer_discount': ['Customer Discounts', 'Customers Discounts'],
  'label:customer_mediator': ['Customer Mediator', 'Customer Mediators'],
  'label:customer_mediator_string': ['Mediator contact info'],
  'label:customer_overseer': ['Customer Overseer', 'Customer Overseers'],
  'label:customer_reference': ['Customer Reference'],
  'label:custom_tasks': 'Custom tasks',
  'label:customer_product_name': 'Customer product name',
  'label:date': 'Date',
  'label:date_of_birth': 'Date of Birth',
  'label:day_price': 'Day Price',
  'label:deactivated': 'Deactivated',
  'label:debtor': 'Debtor',
  'label:debtor_number': 'Debtor Number',
  'label:default_delivery_address': ['Default Delivery Address'],
  'label:default_driver': ['Default Driver'],
  'label:default_packaging': 'Default packaging',
  'label:delete': 'Delete',
  'label:deleted': ['Deleted'],
  'label:deleted_at': 'Deleted at',
  'label:deliver': 'Deliver',
  'label:delivery': ['Delivery'],
  'label:delivery_address': 'Delivery Address',
  'label:delivery_date': 'Delivery Date',
  'label:delivery_price': 'Delivery Price',
  'label:delivery_task': ['Delivery Task', 'Delivery Tasks'],
  'label:delivery_time': 'Delivery Time',
  'label:department': 'Department',
  'label:depreciation_bs': 'Depreciation BS',
  'label:depreciation_pl': 'Depreciation PL',
  'label:description': ['Description'],
  'label:diesel': ['Diesel'],
  'label:discount': ['Discount', 'Discounts'],
  'label:discount_type': 'Discount type',
  'label:divisions': 'Divisions',
  'label:docking_inbound': 'Inbound docking',
  'label:dock': 'Dock',
  'label:document': ['Document', 'Documents'],
  'label:document:add': 'Add Document',
  'label:document_type': ['Document Type', 'Document Types'],
  'label:done_custom': 'Completed custom task',
  'label:download': 'Download',
  'label:drag_drop_task': 'Drag & Drop task from menu',
  'label:driver': ['Driver', 'Drivers'],
  'label:driver_name': 'Driver name',
  'label:due_date': 'Due date',
  'label:duplicate': 'Duplicate',
  'label:duration': 'Duration',
  'label:dynamic_discount_percent': 'Dynamic %',
  'label:empty': 'Empty',
  'label:edit': 'Edit',
  'label:edit_task_note': 'Edit task notes',
  'label:email': 'Email',
  'label:email1': 'Email1',
  'label:email2': 'Email2',
  'label:email_for': 'Email for',
  'label:employee': ['Employee', 'Employees'],
  'label:end': ['End Date'],
  'label:end_before': 'End Before',
  'label:end_date': 'End Date',
  'label:end_price_day': 'End Price (Day)',
  'label:end_price_sales': 'End Price (Sales)',
  'label:end_price_week': 'End Price (Week)',
  'label:end_time': ['End Time'],
  'label:endeavor': ['Task & Note', 'Tasks & Notes'],
  'label:endeavor_action': 'Endeavor action',
  'label:endeavor_for': 'Endeavor for',
  'label:energy_source': 'Energy Source',
  'label:engineer': ['Engineer', 'Engineers'],
  'label:engineer_search': 'Engineer Search',
  'label:exact': 'Exact',
  'label:executed': 'Executed',
  'label:execution': 'Execution',
  'label:execution_date': ['Execution Date'],
  'label:execution_type': 'Execution Type',
  'label:executor': ['Executor', 'Executors'],
  'label:executor_handling': 'Executor handling',
  'label:expand': 'Expand',
  'label:exported': 'Exported',
  'label:external': ['External'],
  'label:external_Id': 'External Id',
  'label:external_inspection': 'External Inspection',
  'label:external_maintenance': 'External Maintenance',
  'label:factory_serial': ['Factory Serial'],
  'label:far': 'FAR',
  'label:fax': 'Fax',
  'label:female': 'Female',
  'label:fill': 'Fill',
  'label:filtered_orders': 'Filtered orders',
  'label:finance_data_not_synced': 'Finance data is not synchronised',
  'label:finance_data_synced': 'Finance data is synchronised',
  'label:finance_sync': 'Finance sync.',
  'label:financial_statements': 'Financial Statements',
  'label:financial_system_id': 'Financial System Id',
  'label:first_name': 'First Name',
  'label:flashlight': 'Flashlight',
  'label:found_quantity': ['Found Qty.'],
  'label:free': 'FREE',
  'label:free_search': 'Free Search',
  'label:from': 'From',
  'label:fuel_type': ['Fuel Type'],
  'label:functional_group': ['Functional Group', 'Functional Groups'],
  'label:gender': 'Gender',
  'label:geometry': 'Geometry',
  'label:giveaway': 'Giveaway',
  'label:giveaway_by': 'Giveaway By',
  'label:giveaway_state': 'Giveaway State',
  'label:giveaway_to': 'Giveaway To',
  'label:global': 'Global',
  'label:global_search': 'Global Search',
  'label:global_search_top_100': 'Global search (top 100)',
  'label:go_to_order': 'Go to order',
  'label:ground': 'Ground',
  'label:guarantee': ['Guarantee'],
  'label:handled:all': 'All handled',
  'label:handling_rule': ['Handling Rule', 'Handling Rules'],
  'label:has_discount': 'Has Discount',
  'label:height': 'Height, m',
  'label:high': ['High'],
  'label:house_number': 'House Number',
  'label:iban': 'IBAN',
  'label:id': 'ID',
  'label:inbound': 'Inbound',
  'label:initiator': 'Initiator',
  'label:outbound': 'Outbound',
  'label:inbound_batch': 'Inbound batch',
  'label:inbound_handling': 'Inbound handling',
  'label:inner_length': 'Inner length, m',
  'label:inner_width': 'Inner width, m',
  'label:inner_height': 'Inner height, m',
  'label:in_maintenance': 'In Maintenance',
  'label:in_possession_of': 'In Possession Of',
  'label:in_possession': 'In Possession',
  'label:inprogress_custom': 'In progress custom task',
  'label:inprogress_maintenance': 'Maintenance in progress',
  'label:invoice_pending_approval': ['Invoices pending approval'],
  'label:in_rent': 'In Rent',
  'label:in_repair': 'In Repair',
  'label:in_stock': 'In Stock',
  'label:inactive': 'Inactive',
  'label:include_individual': 'Include Individual',
  'label:individual': 'Individual',
  'label:info': 'Info',
  'label:inprogress_giveaway': 'InProgress Giveaway',
  'label:inprogress_pickup': 'InProgress Pickup',
  'label:insurance': 'Insurance',
  'label:internal_inspection': 'Internal Inspection',
  'label:internal_handling': 'Internal handling',
  'label:invoice': ['Invoice', 'Invoices'],
  'label:is_customer': 'Is Customer',
  'label:is_delivery_address': 'Is Delivery Address',
  'label:is_department': 'Is Department',
  'label:is_invoice_address': 'Is Invoice Address',
  'label:is_po_box': 'Is PO Box',
  'label:is_post_address': 'Is Post Address',
  'label:is_supplier': 'Is Supplier',
  'label:is_visit_address': 'Is Visit Address',
  'label:item': ['Item', 'Items'],
  'label:kg': 'kg',
  'label:kvk': 'Chamber of Commerce (CoC)',
  'label:language': 'Language',
  'label:last_certification': 'Last Certification',
  'label:last_name': 'Last Name',
  'label:ledger': 'Ledger',
  'label:length': 'Length, m',
  'label:lessor': ['Lessor', 'Lessors'],
  'label:ledger_rental': 'Rental ledger',
  'label:ledger_sales': 'Sales ledger',
  'label:level': 'Level',
  'label:license_plate': ['License Plate'],
  'label:limit_advice': 'Limit Advice',
  'label:linkedin': 'Go to LinkedIn page',
  'label:liter': ['liter', 'liters'],
  'label:loaded': ['Loaded'],
  'label:loaded_quantity': ['Loaded'],
  'label:loading_giveaway': 'Loading Giveaway',
  'label:loading_unloading': 'Loading/Unloading  Materieel',
  'label:loadmeters': 'Loadmeters',
  'label:loadmeters_short': 'ldm',
  'label:logout': 'Logout',
  'label:lost': ['Lost'],
  'label:lost_quantity': ['Lost'],
  'label:low': ['Low'],
  'label:lunchbreak': 'Lunchbreak',
  'label:machine_dim': 'Machine Dim',
  'label:mail_program': 'Mail program',
  'label:main_activity': 'Main Activity',
  'label:maintenance': 'Maintenance',
  'label:make': 'Make',
  'label:make_free': 'Make Free',
  'label:make_paid': 'Make Paid',
  'label:male': 'Male',
  'label:manage': 'Manage',
  'label:mechanic': ['Mechanic', 'Mechanics'],
  'label:merchandize': ['Merchandize', 'Merchandize'],
  'label:merchandize_price': 'Merchandize Price',
  'label:meter': ['meter', 'meters'],
  'label:middle_name': 'Middle Name',
  'label:minimum_photos': 'Min. Photos',
  'label:minimum_rent_period': 'Min. Rent Period (days)',
  'label:minimum_stock': 'Min. Stock',
  'label:minute': ['min', 'mins'],
  'label:mobile': 'Mobile',
  'label:modality_id': 'Modality ID',
  'label:model': 'Model',
  'label:month': ['month', 'months'],
  'label:monthly_depreciation_percent': 'Monthly Depreciation %',
  'label:movement_handling': 'Movement handling',
  'label:move_down': 'Move down',
  'label:move_up': 'Move up',
  'label:multiplier': 'Multiplier',
  'label:name': ['Name', 'Names'],
  'label:natural_gas': ['Natural Gas'],
  'label:net_worth': 'Net Worth',
  'label:netto_price': 'Netto Price',
  'label:new': ['New'],
  'label:next_certification': 'Next Certification',
  'label:next_day': 'Next day',
  'label:next_month': 'Next Month',
  'label:next_week': 'Next Week',
  'label:no': 'No',
  'label:no_notes_recorded': 'No notes recorded',
  'label:notes_section': 'Notes section',
  'label:no_consumables_spent': 'No consumables spent',
  'label:no_documents': 'No documents',
  'label:no_emails': 'No emails',
  'label:no_entries': 'No Entries',
  'label:no_entries_found': 'No Entries Found',
  'label:no_executor_involved': 'No executor involved',
  'label:no_external_Id': 'External Id not specified',
  'label:no_packages': 'No packages in this sector yet',
  'label:no_resource_used': 'No resource used',
  'label:no_photos': 'No Photos Available',
  'label:no_task': 'No tasks yet',
  'label:no_overseer': 'No Overseer yet',
  'label:no_orderlines_assigned': 'No orderlines assigned',
  'label:no_orders_filtered': 'No orders filtered',
  'label:none': 'None',
  'label:normal': ['Normal'],
  'label:not_synced': 'Not Synced',
  'label:note': ['Note', 'Notes'],
  'label:note_executor': 'Executor note',
  'label:note_planner': "Planner's note",
  'label:notes_not_filled': 'Notes not filled',
  'label:number': ['Number'],
  'label:open': 'Open',
  'label:open_in_tab': 'Open in new Tab',
  'label:order': ['Order', 'Orders'],
  'label:order_transformation': ['Transformation order', 'Transformation orders'],
  'label:order_inbound': ['Inbound order', 'Inbound orders'],
  'label:order_outbound': ['Outbound order', 'Outbound orders'],
  'label:order_id': 'Order ID',
  'label:order_line': ['OrderLine', 'OrderLines'],
  'label:order_note': 'Order note',
  'label:order_reference_mandatory': 'Order Reference Mandatory',
  'label:order_total_undelivered_quantity': ['Undelivered'],
  'label:order_total_unfound_quantity': ['Unfound Qty.'],
  'label:order_total_unreturned_quantity': ['Unreturned'],
  'label:order_type': 'Order Type',
  'label:ordered': ['Ordered'],
  'label:order_filters': 'Order filters',
  'label:organisation': 'Organisation',
  'label:origin': ['Origin', 'Origins'],
  'label:original': ['Original', 'Originals'],
  'label:other': 'Other',
  'label:outbound_batch': 'Outbound batch',
  'label:outbound_handling': 'Outbound handling',
  'label:overseer': 'Overseer',
  'label:own_weight': 'Own Weight',
  'label:package': ['Package', 'Packages'],
  'label:package_add': 'Add package',
  'label:package:create': 'Create package',
  'label:package:quantity': 'Package Quantity',
  'label:package:manual': 'Manual package',
  'label:package:result': ['Result package', 'Result packages'],
  'label:package:source': ['Source package', 'Source packages'],
  'label:package:transformation:add': 'Add package to current orderline for tranformation',
  'label:package:transformation:create': 'Create new order line for package transformation',
  'label:packing_number': 'Packing number',
  'label:paid': 'Paid',
  'label:pallet': 'Pallet',
  'label:pause': ['Pause', 'Pauses'],
  'label:paused_giveaway': 'Paused Giveaway',
  'label:payment': ['Payment', 'Payments'],
  'label:parent_cluster': 'Parent cluster',
  'label:parent_package': 'Parent package',
  'label:parent_sector': 'Parent sector',
  'label:part_of_configuration': 'Part of {name} configuration',
  'label:partially_sold': 'Partly sold',
  'label:pcs': 'pcs',
  'label:period': 'Period',
  'label:petrol': 'Petrol',
  'label:phone': 'Phone',
  'label:photo': ['Photo', 'Photos'],
  'label:picked_up': 'Picked up',
  'label:pickup': 'Pickup',
  'label:pickup_check': 'Pickup Check',
  'label:pickup_date': 'Pick up date',
  'label:pickup_state': 'Pickup State',
  'label:pickup_time': 'Pick up time',
  'label:pirce_sales': 'Sales Price',
  'label:placeholder_external_Id': 'Specify External Id',
  'label:plan': 'Plan.',
  'label:planned_custom': 'Planned custom task',
  'label:planboard_search': 'Planboard Search',
  'label:planner_note': 'Planner note',
  'label:planned_date': 'Planned Date',
  'label:planned_dockings': 'Planned Dockings',
  'label:planned_duration': 'Planned Duration',
  'label:planned_end': 'Planned End',
  'label:planned_found_quantity': 'Planned Finding Qty.',
  'label:planned_giveaway': 'Planned Giveaway',
  'label:planned_pickup': 'Planned Pickup',
  'label:planned_quantity': ['Planned'],
  'label:planned_start': 'Planned Start',
  'label:planned_time': 'Planned Time',
  'label:planning_giveaway': 'Planning Giveaway',
  'label:planning_pickup': 'Planning Pickup',
  'label:position:x': 'Position X',
  'label:position:y': 'Position Y',
  'label:postal_code': 'Postal Code',
  'label:possession': ['Possession', 'Possessions'],
  'label:possession:leased': 'Leased',
  'label:possession:all': 'Only 3rd party Serials',
  'label:possession:archived': 'Archived possessions',
  'label:possession:out': 'Out of possession',
  'label:possession:ordered': 'Ordered 3rd party Serials',
  'label:possession:surplus': 'Surplus 3rd party Serials',
  'label:possession:swappable': 'Swappable 3rd party Serials',
  'label:possession:filter': 'Filter by 3rd party attribute',
  'label:precise_execution_time': 'Set precise time',
  'label:previous_day': 'Previous day',
  'label:previous_month': 'Previous Month',
  'label:previous_week': 'Previous Week',
  'label:price': 'Price',
  'label:price_aspen': 'Aspen Price',
  'label:price_counter_hour': 'Counter Hour Rate',
  'label:price_day': ['Rent Price Per Day'],
  'label:price_diesel': 'Diesel Price',
  'label:price_engineer_call_hour': 'Engineer Hour Rate (on call)',
  'label:price_engineer_home_hour': 'Engineer Hour Rate (home)',
  'label:price_natural_gas': 'Naturale Gas Price',
  'label:price_per_day': 'Price Per Day',
  'label:price_per_week': 'Price Per Week',
  'label:price_petrol': 'Petrol Price',
  'label:price_sales': ['Sales Price'],
  'label:price_total': ['Total Price'],
  'label:price_unit': ['Unit Price'],
  'label:price_week': ['Rent Price Per Week'],
  'label:pricing': ['Pricing', 'Pricings'],
  'label:print_order': 'Print order',
  'label:print_packing_list': 'Print packing list',
  'label:priority': ['Priority'],
  'label:private_mailing': 'Private Mailing',
  'label:shard_size': 'Shard Size',
  'label:shard_process': 'Process As Shards',
  'label:procedure': ['Procedure', 'Procedures'],
  'label:procedure_name': 'Procedure Name',
  'label:product': ['Product', 'Products'],
  'label:product_bundle': 'Product bundle',
  'label:product_group': 'Product Subgroup',
  'label:product_group_name': 'Prod.Group',
  'label:product_serie': ['Product Group', 'Product Groups'],
  'label:productgroup': 'Product Subgroup',
  'label:productserie': 'Product Group',
  'label:project_name': 'Project Name',
  'label:purchase_account': 'Purchase Account',
  'label:purchase_price': 'Purchase Price',
  'label:qr_scanner': 'QR Scanner',
  'label:quantity': ['Quantity'],
  'label:quantity:bundle': 'Quantity bundle',
  'label:quantity:package': 'Package qty.',
  'label:quantity:container': 'Quantity container',
  'label:queued': 'Queued',
  'label:quotation': ['Quotation'],
  'label:rack': 'Rack',
  'label:ready_for_pickup': 'Ready for pick up',
  'label:refueled_quantity': ['Refueled'],
  'label:reg_number': 'Reg number',
  'label:registration': ['Registration', 'Registrations'],
  'label:related_task': ['Related Task', 'Related Tasks'],
  'label:relocate_package': 'Package relocation',
  'label:remove_file': 'Remove File',
  'label:rent': ['Rent', 'Rents'],
  'label:rent_calendar': 'Rent Calendar',
  'label:rent_end': ['Rent End'],
  'label:rent_start': ['Rent Start'],
  'label:rental_price_day': 'Rental Price Day',
  'label:rental_price_week': 'Rental Price Week',
  'label:rented': ['Rented'],
  'label:rentedout_quantity': ['Rented'],
  'label:repair': 'Repair',
  'label:replaced_accessorie': 'Replaced accessorie',
  'label:replaced_serial': 'Replaced serial',
  'label:replacement': 'Replacement',
  'label:replacement_accessorie': 'Replacement Accessorie',
  'label:replacement_add': 'Add replacement',
  'label:replacement_complete': 'Complete Replacement',
  'label:replacement_date': 'Replacement date',
  'label:replacement_inprogress': 'In progress Replacement',
  'label:replacement_loading': 'Loading Replacement',
  'label:replacement_planned': 'Planned Replacement',
  'label:replacement_planning': 'Planning Replacement',
  'label:replacement_product': 'Replacement Product',
  'label:replacement_serial': 'Replacement Serial',
  'label:replacement_task': 'Replacement Task',
  'label:replacement_time': 'Replacement time',
  'label:replacement_underway': 'Underway Replacement',
  'label:residual_value': 'Residual Value',
  'label:responsible': ['Responsible'],
  'label:resource': ['Resource', 'Resources'],
  'label:returned': ['Returned'],
  'label:review': ['Review', 'Reviews'],
  'label:rows_per_page': 'Per page',
  'label:rsin_kvk_branch': 'RSIN/CoC/Industry',
  'label:sales_price': 'Sales Price',
  'label:scan_qr': 'Scan QR',
  'label:scheduled_service_task': ['Scheduled Service Task', 'Scheduled Service Tasks'],
  'label:score': 'Score',
  'label:see_on_map': 'See on map',
  'label:sector': 'Sector',
  'label:sector_usage': 'Sector usage',
  'label:select_file': '...or click to select a file from device',
  'label:selected_date': 'Selected Date',
  'label:selected_order_lines': 'Selected order lines',
  'label:sellable': 'Sellable',
  'label:send_again': 'click to send again',
  'label:send_email': 'Send Email',
  'label:send_to_maintenance': ['Send To Maintenance'],
  'label:send_to_warehouse': ['Send To Warehouse'],
  'label:sent': ['Sent'],
  'label:sent_date': ['Send Date'],
  'label:sequence': ['Sequence', 'Sequences'],
  'label:serial': 'Serial',
  'label:serials_list': 'Serials List',
  'label:serials_with_gps': 'Serials with GPS data available',
  'label:serials_without_gps': 'Serials without GPS data',
  'label:service': ['Service', 'Services'],
  'label:service_task': ['Service Task', 'Service Tasks'],
  'label:setting': ['Setting', 'Settings'],
  'label:settings_history': ['Settings History'],
  'label:shareholders': 'Shareholders',
  'label:sidebar_collapse': 'Collapse sidebar',
  'label:sidebar_expand': 'Expand sidebar',
  'label:signing_in': 'Signing in...',
  'label:simple_inspection': 'Simple Inspection',
  'label:show_all_vehicles': 'Show all vehicles',
  'label:slim_id': 'TMS ID',
  'label:sold': ['Sold'],
  'label:specialization': ['Specialization', 'Specializations'],
  'label:specialty': ['Specialty', 'Specialties'],
  'label:specification': ['Specification', 'Specifications'],
  'label:stackable': 'Stackable',
  'label:start': ['Start Date'],
  'label:start_after': 'Start After',
  'label:start_date': 'Start Date',
  'label:start_time': ['Start Time'],
  'label:state': 'State',
  'label:status': 'Status',
  'label:status:planned': 'Planned',
  'label:status:planned:docking': 'Planned docking',
  'label:status:stored': 'Stored',
  'label:status:dissolved': 'Dissolved',
  'label:status:departed': 'Departed',
  'label:status:new': 'New',
  'label:status:plannable': 'Plannable Dockings',
  'label:status:plannable:order': 'Plannable order',
  'label:status:check-in': 'Check-in Dockings',
  'label:status:check-out:orderline': 'Check-out orderline',
  'label:status:handling': 'Handling Orderlines',
  'label:status:handling:orderline': 'Handling Orderlines',
  'label:status:done': 'Order Complete',
  'label:status_legend': 'Statuses color legend',
  'label:stock': ['Stock'],
  'label:stock_prediction': 'Stock Prediction',
  'label:storage_handling': 'Storage handling',
  'label:street': 'Street',
  'label:string': 'String',
  'label:subject': 'Subject',
  'label:suggested_price_day': 'Suggested Rent Price (per day)',
  'label:suggested_price_sales': 'Suggested Sales Price',
  'label:suggested_price_week': 'Suggested Rent Price (per week)',
  'label:supplier': 'Supplier',
  'label:synced': 'Synced',
  'label:sync:down': 'Sync Down',
  'label:sync:up': 'Sync Up',
  'label:tank_size': 'Tank Size',
  'label:task': ['Task', 'Tasks'],
  'label:task_awaiting_approval': ['Tasks pending approval'],
  'label:task_awaiting_approval_manager': ['Tasks pending approval by manager'],
  'label:task_awaiting_approval_engineer': ['Tasks pending approval by Chief engineer'],
  'label:task_id': 'Task ID',
  'label:task_note': 'Task/Note',
  'label:tax_percent': ['Tax Percent', 'Tax Percents'],
  'label:taxed': ['Taxed'],
  'label:template': ['Template', 'Templates'],
  'label:third_party_delivery': ['3rd Party Delivery', '3rd Party Deliveries'],
  'label:tms_id': 'TMS id',
  'label:tms': 'TMS',
  'label:to': 'To',
  'label:toggle': 'Toggle',
  'label:total': 'Total',
  'label:total_depreciation_time_months': 'Total Depreciation Time (Months)',
  'label:total_invoiced': 'Total Invoiced',
  'label:total_taxed': 'Total Taxed',
  'label:total_untaxed': 'Total Untaxed',
  'label:total_units': 'Units',
  'label:track_unit_id': ['Track-Unit ID'],
  'label:trans_date': 'Trans. Date',
  'label:transactions': ['Transaction', 'Transactions'],
  'label:transaction_type': ['Transaction Type'],
  'label:transformation_batch': 'Transformation batch',
  'label:transformation_handling': 'Transformation handling',
  'label:transportation': ['Transportation', 'Transportations'],
  'label:transportation_price': 'Transportation Price',
  'label:docking': ['Docking', 'Dockings'],
  'label:type': 'Type',
  'label:type_note': 'Type your notes',
  'label:underway': ['Underway'],
  'label:underway_pickup': ['Underway Pickup'],
  'label:unit': ['Unit'],
  'label:unique': ['Unique', 'Uniques'],
  'label:unloaded': 'Unloaded',
  'label:updated_at': 'Updated at',
  'label:urgent': ['Urgent'],
  'label:url': ['Url'],
  'label:usage_consumable': 'Consumable usage',
  'label:usage_executor': 'Executor usage',
  'label:usage_resource': 'Resource usage',
  'label:used_part': ['Used Part', 'Used Parts'],
  'label:usedpart': ['Used Part', 'Used Parts'],
  'label:vat_number': 'VAT Number',
  'label:vat_number_eu': 'VAT Number EU',
  'label:vatnr': 'VAT Number',
  'label:vehicle': ['Vehicle', 'Vehicles'],
  'label:vehicle_filter': 'Vehicle filter',
  'label:vehicle_on_map': 'Vehicle on map',
  'label:volume': 'Volume, m³',
  'label:volume_short': 'm³',
  'label:task_filter': 'Task filter',
  'label:vin': 'VIN',
  'label:warehouse': 'Warehouse',
  'label:warehouse_add': 'Add warehouse',
  'label:warehouse_select': 'Select warehouse',
  'label:web': 'Web',
  'label:website': ['Website', 'Websites'],
  'label:website_data': 'Website Data',
  'label:week_price': 'Week Price',
  'label:weeks_and_days': 'Weeks | Days',
  'label:weight_brutto': 'Gross weight, kg',
  'label:weight_netto': 'Net weight, kg',
  'label:weight': 'Weight, kg',
  'label:weight_short': 'kg',
  'label:width': 'Width, m',
  'label:working_capital': 'Working Capital',
  'label:working_height': 'Working Height',
  'label:year': 'Year',
  'label:yes': 'Yes',
  'nav:cms': 'CMS',
  'nav:qms': 'QMS',
  'nav:wms': 'WMS',
  'nav:tms': 'TMS',
  'nav:rms': 'RMS',
  'nav:wms:portal': 'WMS Portal',
  'nav:package': 'Packages',
  'nav:sector': 'Sectors',
  'nav:carrier': 'Carriers',
  'nav:dock': 'Docks',
  'nav:accessorie': 'Accessories',
  'nav:accounts': 'Accounts',
  'nav:address': 'Addresses',
  'nav:addresses': 'Addresses',
  'nav:availability': 'Availability',
  'nav:basechecklist': 'Base Checklists',
  'nav:baseprocedure': 'Base Procedures',
  'nav:client_orders': 'Client Orders',
  'nav:cluster': 'Clusters',
  'nav:configuration': 'Configurations',
  'nav:consumable': 'Consumables',
  'nav:core': 'Core',
  'nav:crm': 'CRM',
  'nav:customer': 'Customers',
  'nav:delivery_task': 'Delivery',
  'nav:discount': 'Discounts',
  'nav:endeavor': 'Task & Notes',
  'nav:entities': 'Entities',
  'nav:executed_lines': 'Executed lines',
  'nav:fms': 'FMS',
  'nav:general_costing': 'General costing',
  'nav:inbound_shipments': 'Inbound Shipments',
  'nav:internal_orders': 'Internal Orders',
  'nav:invoice': 'Invoices',
  'nav:logging': 'Logging',
  'nav:matrices': 'Matrixes',
  'nav:module_settings': 'Module Setting',
  'nav:order': 'Orders',
  'nav:order:transformation': 'Transformation orders',
  'nav:order:inbound': 'Inbound orders',
  'nav:order:outbound': 'Outbound orders',
  'nav:outsourced_shipments': 'Outsourced Shipments',
  'nav:planning': 'Planning',
  'nav:product': 'Products',
  'nav:quotations': 'Quotations',
  'nav:rates': 'Rates',
  'nav:resource': 'Resources',
  'nav:resources': 'Resources',
  'nav:scheduled_service_task': 'Scheduled Service',
  'nav:serial': 'Serials',
  'nav:service_task': 'Service',
  'nav:settings': 'Settings',
  'nav:surcharges': 'Surcharges',
  'nav:system': 'System',
  'nav:system_settings': 'System Settings',
  'nav:task': 'Tasks',
  'nav:docking': 'Dockings',
  'nav:vehicle': 'Vehicles',
  'nav:wages': 'Wages',
  'placeholder:bcc': 'Coma separated emails',
  'placeholder:cc': 'Coma separated emails',
  'placeholder:certification_period': 'Cert. Period',
  'placeholder:date_of_birth': 'DD-MM-YYYY',
  'placeholder:documents_input': 'For multiple files input use shift or ctrl',
  'placeholder:select_merchandize': 'Select Merchandize',
  'placeholder:subject': 'Subject',
  'placeholder:to': 'Recipient',
  'placeholder:whats_done?': 'what’s done?',
  'role:VDL:WMS:Manager': 'VDL Manager',
  'role:admin': 'Admin',
  'role:Betten': 'Betten',
  'role:CRM.Admin': 'CRM Admin',
  'role:CRM.Manager': 'CRM Manager',
  'role:TMS.Admin': 'TMS Admin',
  'role:TMS.Driver': 'TMS Driver',
  'role:TMS.Finance': 'TMS Finance',
  'role:TMS.Manager': 'TMS Manager',
  'role:TMS.Office': 'TMS Office',
  'role:TMS.Planner': 'TMS Planner',
  'role:TMS.Portaluser': 'TMS Portaluser',
  'role:TMS.Sales': 'TMS Sales',
  'role:WMS.Manager': 'WMS Manager',
  'role:WMS.Mover': 'WMS Mover',
  'role:WMS.Admin': 'WMS Admin',
  'role:WMS.ChiefEngineer': 'WMS Chief Engineer',
  'role:WMS.Counter': 'WMS Counter',
  'role:WMS.Driver': 'WMS Driver',
  'role:WMS.Engineer': 'WMS Engineer',
  'role:WMS.Finance': 'WMS Finance',
  'role:XTRA.Admin': 'XTRA Admin',
  'role:XTRA.ChiefEngineer': 'XTRA Chief Engineer',
  'role:XTRA.Counter': 'XTRA Counter',
  'role:XTRA.Driver': 'XTRA Driver',
  'role:XTRA.Engineer': 'XTRA Engineer',
  'role:XTRA.Finance': 'XTRA Finance',
  'role:XTRA.Manager': 'XTRA Manager',
  'role:Developer': 'Developer',
  'role:QMS.Admin': 'QMS Admin',
  'status:docking:new': 'New',
  'status:docking:planned': 'Planned',
  'status:docking:check-in': 'Check-In',
  'status:docking:handling': 'Handling',
  'status:docking:check-out': 'Check-out',
  'status:docking:done': 'Done',
  'text:all': 'All',
  'text:check_delivery_price:check': 'Check',
  'text:check_delivery_price:delivery_price': 'delivery price',
  'text:check_delivery_price:before_proceeding': 'before proceeding',
  'text:Admin': 'Admin',
  'text:CRM.Admin': 'CRM Admin',
  'text:CRM.Manager': 'CRM Manager',
  'text:TMS.Manager': 'TMS Manager',
  'text:WMS.Admin': 'WMS Admin',
  'text:WMS.ChiefEngineer': 'Chief Engineer',
  'text:WMS.Counter': 'Counter',
  'text:WMS.Driver': 'Driver',
  'text:WMS.Engineer': 'Engineer',
  'text:WMS.Finance': 'Finance',
  'text:WMS.Manager': 'WMS Manager',
  'text:VDL:WMS:Manager': 'Manager',
  'text:accessorie:delete:text': 'Permanently delete Accessorie?',
  'text:accessorie:delete:title': 'Are you sure?',
  'text:accesssorie:delete:text': 'The Accessorie will be deleted permanently.',
  'text:accesssorie:delete:title': 'Are you sure?',
  'text:accp_procedures_no_parts': 'Acceptance procedures can`t have used parts',
  'text:address': ['address', 'addresses'],
  'text:address:delete:text': 'The Address will be deleted permanently.',
  'text:address:delete:title': 'Are you sure?',
  'text:address:edit': 'Edit Address',
  'text:address:new': 'New Address',
  'text:addresses:length:invalid': 'At least one Address is required',
  'text:attachment': ['attachment', 'attachments'],
  'text:awaiting_approval': 'Awaiting Approval',
  'text:base_checklist:form:invalid': 'Base Checklist form invalid',
  'text:bought': 'Bought from {supplier} ({contact}), for {for}, at {at}',
  'text:branch': ['branch', 'branches'],
  'text:company:edit': 'Edit Company',
  'text:company:new': 'Company',
  'text:company_in_insolvent': 'Company in Insolvent!',
  'text:configuration': ['configuration', 'configurations'],
  'text:configuration:delete:length:text': "Configuration's Configuration Lines must be deleted first",
  'text:configuration:delete:length:title': 'Configuration Deletion',
  'text:configuration:delete:text': 'Permanently delete Configuration?',
  'text:configuration:delete:title': 'Are you sure?',
  'text:configuration_line:delete:text': 'Permanently delete Configuration Line?',
  'text:configuration_line:delete:title': 'Are you sure?',
  'text:configuration_line:form:invalid': 'Configuration Line form is invalid',
  'text:contact': ['Contact', 'Contacts'],
  'text:contact:delete:text': 'The Contact will be deleted permanently.',
  'text:contact:delete:title': 'Are you sure?',
  'text:contact:edit': 'Edit Contact',
  'text:contact:email:collision': 'Contact email(s) already used by {collisions}',
  'text:contact:new': 'New Contact',
  'text:contacts:length:invalid': 'At least one Contact is required',
  'text:country': ['country', 'countries'],
  'text:customer': ['customer', 'customers'],
  'text:customer:delete:text': 'The Customer, it`s Contacts and Addresses will be deleted permanently.',
  'text:customer:delete:title': 'Are you sure?',
  'text:customer:edit': 'Edit Customer',
  'text:customer:form:invalid': 'Customer form is invalid',
  'text:customer_info': 'Customer info',
  'text:customer:name:collision': 'Customer name already used by {collisions}',
  'text:customer:new': 'New Customer',
  'text:customer:select': 'Select customer, please',
  'text:task:different_overseer': 'Task supervised by another Manager',
  'text:task:status:invalid:title': 'Task status update',
  'text:task:status:invalid:text': 'Task status changed by another user. Redirecting..',
  'text:delivery': 'delivery',
  'text:department': ['department', 'departments'],
  'text:document': ['document', 'documents'],
  'text:documenttype': ['document type', 'document types'],
  'text:dynamic': 'Dynamic',
  'text:dynamic_discount': 'Dyn.',
  'text:email': ['Email', 'Emails'],
  'text:email:select': 'Select email, please',
  'text:email_to': ['Email to'],
  'text:endeavor:delete:text': 'Permanently delete Endeavor?',
  'text:endeavor:delete:title': 'Are you sure?',
  'text:export:page': 'Export Page',
  'text:force_update': 'Force update',
  'text:group': ['group', 'groups'],
  'text:hide_customer_data': 'Hide customer data',
  'text:high_credit_flag': 'high credit flag',
  'text:historicalrecord': ['historical record', 'historical records'],
  'text:historicalrecordtype': ['historical record type', 'historical record types'],
  'text:individual:edit': 'Edit Customer',
  'text:individual:new': 'New Customer',
  'text:info': 'Info',
  'text:invoice': 'invoice',
  'text:kvk:kvk_number:collision': 'KvK Number already used by {collisions}',
  'text:kvk:select:text': '(Re)assign {kvk} KVK to current Customer?',
  'text:language': ['language', 'languages'],
  'text:legal_since': 'Legal since',
  'text:low_credit_flag': 'low credit flag',
  'text:make_private': 'MAKE PRIVATE',
  'text:make_public': 'MAKE PUBLIC',
  'text:manual_discount': 'Man.',
  'text:medium_credit_flag': 'medium credit flag',
  'text:memorandum': 'Memorandum date',
  'text:merchandize:delete:text': 'The Merchandize will be deleted permanently.',
  'text:merchandize:delete:title': 'Are you sure?',
  'text:modification': 'Modification date',
  'text:note': ['note', 'notes'],
  'text:order': ['order', 'orders'],
  'text:order:close:unsaved:title': 'Are you really sure you want to close this order?',
  'text:order:close:unsaved:text': 'Order will not be saved or sent',
  'text:order:form:invalid': 'Order form is invalid',
  'text:order_line:length:invalid': 'At least one Order Item is required',
  'text:ordertype': ['order type', 'order types'],
  'text:organisation': ['organisation', 'organisations'],
  'text:phone:invalid': 'Phone is invalid or empty',
  'text:planning': 'planning',
  'text:planning_new': 'planning new',
  'text:private': 'PRIVATE',
  'text:product': ['product', 'products'],
  'text:product:delete:length:text': "Product's Serials must be deleted first",
  'text:product:delete:length:title': 'Product Deletion',
  'text:product:delete:text': 'Permanently delete Product?',
  'text:product:delete:title': 'Are you sure?',
  'text:product:form:invalid': 'Product form is invalid',
  'text:product_group:delete:length:text': "Product Subgroup's Products must be deleted first",
  'text:product_group:delete:length:title': 'Product Subroup Deletion',
  'text:product_group:delete:text': 'Permanently delete Product Subgroup?',
  'text:product_group:delete:title': 'Are you sure?',
  'text:product_group:form:invalid': 'Product Subgroup form is invalid',
  'text:product_serie:delete:length:text': "Product Group's Subgroups must be deleted first",
  'text:product_serie:delete:length:title': 'Product Group Deletion',
  'text:product_serie:delete:text': 'Permanently delete Product Group?',
  'text:product_serie:delete:title': 'Are you sure?',
  'text:product_serie:form:invalid': 'Product Group form is invalid',
  'text:public': 'PUBLIC',
  'text:purchase': 'puchase',
  'text:recevied': 'received',
  'text:registration': 'Registration date',
  'text:regular_driver': 'regular driver',
  'text:regular_driver_not_assigned': 'No regular driver assigned',
  'text:report_days_old': 'Report is {days} day(s) old',
  'text:search': 'Search..',
  'text:sent': 'sent',
  'text:serial': ['serial', 'serials'],
  'text:serial:delete:text': 'This will permanently delete Serial and all Transactions connected to it.',
  'text:serial:delete:title': 'Are you sure?',
  'text:serial:form:invalid': 'Serial form is invalid',
  'text:settlement': 'Settlement date',
  'text:show_customer_data': 'Show customer data',
  'text:signature:form:invalid': 'Signature form invalid',
  'text:static': 'Static',
  'text:static_price': 'Static',
  'text:status_inprogress': 'In Progress',
  'text:status_loading': 'Loading',
  'text:status_planned': 'Planned',
  'text:status_underway': 'Underway',
  'text:task:cancel:text': 'Cancel ongoing Task?',
  'text:task:cancel:title': 'Are you sure?',
  'text:task:delete:text': 'Permanently delete Task?',
  'text:task:delete:title': 'Are you sure?',
  'text:task:form:invalid': 'Task form invalid',
  'text:tradename': 'Name | Tradename',
  'text:turnover_code': 'Turnover Сode',
  'text:turnover_value': 'Turnover value',
  'text:unassigned': 'Unassigned',
  'text:unknown_credit_flag': 'unknown credit flag',
  'text:user_roles': 'Your Roles in App',
  'text:user_roles_in_app': 'Roles in App',
  'text:user_roles_not_assigned': "User don't have a single role in App",
  'text:yip_list': 'yip list',
  'validation:shard:multiple': 'Unshred quantity is not multiple of product\'s Shard Size',
  'validation:shard:empty': 'Product\'s Shard Size not defined',
  'validation:amount:required': 'The amount is required',
  'validation:bcc:invalid': 'BCC emails list is invalid',
  'validation:cc:invalid': 'CC emails list is invalid',
  'validation:checklist:checklist_lines:invalid': 'Checklist form incomplete',
  'validation:crm_mandatory_rule': 'Field set as mandatory for this Customer by CRM',
  'validation:currency:required': 'Financial value must be set',
  'validation:debtor_number:length': 'Debtor Number must be less than 15 characters',
  'validation:email:invalid': 'E-mail must be valid',
  'validation:filled:required': 'Field is required',
  'validation:in_stock:length': 'Product stock must be greater than or equal to minimum stock',
  'validation:in_stock:required': 'Product stock must be set',
  'validation:loaded_quantity:empty': 'At least one item must be loaded before departure',
  'validation:minimum_stock:length': 'Product minimum stock must be less than or equal to stock',
  'validation:minimum_stock:required': 'Product minimum stock must be set',
  'validation:name:required': 'Name is required',
  'validation:no_serial': 'Selected Serial required',
  'validation:phone:length': 'Phone must be less than 15 characters',
  'validation:planned_quantity:empty': 'At least one item must be planned to deliver',
  'validation:positive_number:required': 'Positive Number is required',
  'validation:procedures:procedure:invalid': 'Some Procedures incomplete',
  'validation:quantity:required': 'Quantity is required',
  'validation:rentedout_quantity:empty': 'At least one item must be unloaded before departure',
  'validation:required': 'Required',
  'validation:selected:required': 'Selected option is required',
  'validation:to:invalid': 'To is invalid email(s) (list)',
  'validation:to:required': 'To is required',
  'validation:vatnr:length': 'VATNR must be less than 15 characters',
  'validation:web:invalid': 'Website URL is not valid',
  'validation:uniques:count': 'Package\'s uniques count not equal package\'s total units',
};

// const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
// JSON.stringify(sortObject(a))
