import FinancePage from '@/layouts/Application.vue';

import Invoice from './invoice';

export default [
  {
    path: 'Finance',
    component: FinancePage,
    children: [
      ...Invoice,
    ],
  },
];
