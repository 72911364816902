var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2"},[_c('v-autocomplete',{attrs:{"label":_vm.$t('label:carrier', null, 1),"hide-details":"auto","aria-autocomplete":"off","item-value":"ItemId","item-text":"Name","disabled":_vm.disable.main || _vm.disable.quantity,"items":_vm.cCarrierItems,"value":_vm.value.CarrierId},on:{"input":function (v) { return _vm.onInput('CarrierId', v); }}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('c-number',{staticClass:"width-10ch",attrs:{"label":_vm.$t('label:quantity:container', null, 1),"hide-details":"auto","disabled":_vm.disable.main || _vm.disable.quantity,"value":_vm.value.Quantity},on:{"input":function (v) { return _vm.onInput('Quantity', v); }}})],1),_c('v-col',{staticClass:"ml-2",staticStyle:{"min-width":"84px"},attrs:{"cols":"auto"}},[(!_vm.disable.main && !_vm.disable.quantity)?[(_vm.decreaseControls)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"text","icon":"","small":""},on:{"click":function($event){return _vm.onMove(false)}}},on),[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)]}}],null,false,2098725063)},[_vm._v(" "+_vm._s(_vm.$t('label:move_up'))+" ")]):_vm._e(),(_vm.increaseControls)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"text","icon":"","small":""},on:{"click":function($event){return _vm.onMove(true)}}},on),[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)]}}],null,false,1236796347)},[_vm._v(" "+_vm._s(_vm.$t('label:move_down'))+" ")]):_vm._e(),(_vm.bundleControls)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"text","icon":"","small":""},on:{"click":function($event){return _vm.$emit('add')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3517864017)},[_vm._v(" "+_vm._s(_vm.$t('label:carrier_add'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"text","icon":"","small":""},on:{"click":function($event){return _vm.$emit('remove')}}},on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,false,3866159136)},[_vm._v(" "+_vm._s(_vm.$t('label:carrier_remove'))+" ")])]:_vm._e()]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }