<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="loading"
            :loading="loading"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              :headers="headers"
              :get-list="getList"
              :list="list"
              :loading="loading"
            >
              <template #footer-prepend>
                <v-col>
                  <v-btn
                    small
                    color="primary darken-1"
                    class="width-auto"
                    :disabled="loading"
                    :loading="loading"
                    @click.stop="onForm()"
                  >
                    {{ $t('label:invoice', null, 1) }}
                    <v-icon
                      small
                      right
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-row>
                    <v-col>
                      <v-menu
                        ref="from"
                        v-model="menu.from"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="mDate(from)"
                            :label="$t('label:from', null, 1)"
                            append-icon="mdi-calendar"
                            readonly
                            :tabindex="4"
                            :disabled="invoiceLoading"
                            :loading="invoiceLoading"
                            v-bind="attrs"
                            v-on="on"
                            @click:append="() => menu.from = true"
                          />
                        </template>
                        <v-date-picker
                          v-model="from"
                          no-title
                          color="blue"
                          locale="nl"
                          :first-day-of-week="1"
                          :max="$moment().format('YYYY-MM-DD')"
                          @input="v => onChange('from', v)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        ref="to"
                        v-model="menu.to"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :disabled="invoiceLoading"
                            :loading="invoiceLoading"
                            :value="mDate(to)"
                            :label="$t('label:to', null, 1)"
                            append-icon="mdi-calendar"
                            readonly
                            :tabindex="4"
                            v-bind="attrs"
                            v-on="on"
                            @click:append="() => menu.to = true"
                          />
                        </template>
                        <v-date-picker
                          v-model="to"
                          no-title
                          color="blue"
                          locale="nl"
                          :first-day-of-week="1"
                          :max="$moment().format('YYYY-MM-DD')"
                          @input="v => onChange('to', v)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-btn
                        class="width-auto mt-3"
                        :disabled="!from || !to || invoiceLoading"
                        :loading="invoiceLoading"
                        @click="onInvoice()"
                      >
                        {{ $t('btn:invoice:generate') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-btn
                      icon
                      @click.stop="onForm(item.ItemId)"
                    >
                      <v-icon>
                        mdi-open-in-app
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.ItemId }}</td>
                  <td>{{ mCustomer(item.CustomerId) }}</td>
                  <td>{{ $t(item.Status) }}</td>
                  <td>{{ item.SentDate ? $moment(item.SentDate).format("DD-MM-YYYY") : '-' }}</td>
                  <td>{{ item.Description }}</td>
                  <td>
                    <v-tooltip
                      v-if="['New'].includes(item.Status)"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          class="mr-2 float-right"
                          v-on="on"
                          @click="onDelete(item)"
                        >
                          <v-icon
                            class="delete-icon"
                          >
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t('label:delete') }}
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'InvoiceIndex',

  data: () => ({
    from: null,
    to: null,
    menu: {
      from: false,
      to: false,
    },
  }),
  computed: {
    ...mapGetters({
      list: 'invoice/list',

      loading: 'invoice/loadingList',
      invoiceLoading: 'invoice/loading',

      invoiceItems: 'invoice/items',
      customers: 'customer/items',
    }),
    headers() {
      return [
        {
          text: '', value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:customer', null, 1),
          value: 'CustomerId',
          clearable: true,
          sortable: false,
          type: 'select',
          options: {
            items: this.customers,
            itemText: 'Name',
            itemValue: 'ItemId',
          },
        },
        {
          text: this.$t('label:status', null, 1),
          value: 'Status',
          type: 'select',
          options: {
            items: [
              { text: this.$t('label:new', null, 1), value: 'New' },
              { text: this.$t('label:approved', null, 1), value: 'Approved' },
              { text: this.$t('label:synced', null, 1), value: 'Synced' },
            ],
          },
          clearable: true,
        },
        {
          text: this.$t('label:date', null, 1),
          value: 'SentDate',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        {
          text: this.$t('label:description', null, 1), value: 'Description', searchable: false, sortable: false,
        },
        {
          text: '', value: '', searchable: false, sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      invoiceInvoice: 'invoice/invoice',
      getList: 'invoice/getList',
      invoiceGetItems: 'invoice/getItems',
      deleteItem: 'invoice/destroy',
    }),
    async onForm(ItemId) {
      this.$router.push({ name: 'finance-invoice-form', params: { ItemId } });
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },

    mCustomer(CustomerId) {
      const customer = this.customers.find(
        ({ ItemId }) => ItemId === CustomerId,
      );
      return customer ? customer.Name : '-';
    },
    async onInvoice() {
      await this.invoiceInvoice({
        from: this.$moment(this.from).startOf('day').toISOString(),
        to: this.$moment(this.to).endOf('day').toISOString(),
      });
    },
    mDate(v) {
      if (!v) {
        return 'DD-MM-YYYY';
      }
      return this.$moment(v).format('DD-MM-YYYY');
    },
    onChange(p, v) {
      switch (p) {
        case 'from':
          // eslint-disable-next-line no-case-declarations
          const limit = this.$moment(v)
            .add(2, 'week')
            .isSameOrAfter(this.$moment())
            ? this.$moment() : this.$moment(v).add(2, 'week');

          if (!this.to || this.$moment(this.to).isSameOrAfter(limit)) {
            this.to = this.$moment(limit).format('YYYY-MM-DD');
          }
          if (this.$moment(this.from).isSameOrAfter(this.to)) {
            this.to = this.from;
          }
          break;
        case 'to':
          if (!this.from || this.$moment(v).subtract(2, 'week').isSameOrAfter(this.from)) {
            this.from = this.$moment(v).subtract(2, 'week').format('YYYY-MM-DD');
          }
          if (this.$moment(this.from).isSameOrAfter(this.to)) {
            this.from = this.to;
          }
          break;
        default:
          throw new Error('Invalid onChange param');
      }
    },
  },
};
</script>
