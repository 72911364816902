<template>
  <portal-page class="y-overflow custom-bg">
    <v-col sm="12">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-col>
  </portal-page>
</template>

<script>

import PortalPage from '@/components/PortalPage.vue';

export default {
  name: 'ViewsPortalInboundOrders',
  components: {
    PortalPage,
  },
};
</script>
