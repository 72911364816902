import Vue from 'vue';
// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import Toasted from 'vue-toasted';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuexI18n from 'vuex-i18n';
import CountryFlag from 'vue-country-flag';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// import VueCurrencyInput from 'vue-currency-input';
import VueSignaturePad from 'vue-signature-pad';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'jodit/build/jodit.min.css';
import JoditVue from 'jodit-vue';
import clipboard from 'vue-clipboards';

import authConfig from '@/configs/auth';

import warehouseWatcher from './store/_watchers/warehouse';

import tenantPlugin from './plugins/tenant';
import isodate from './plugins/isodate';
import eventBus from './plugins/eventBus';

import EN from './translations/EN';
import NL from './translations/NL';
// eslint-disable-next-line import/extensions
import { Auth0Plugin } from './auth';
import TenantTranslator from './tenant-translator';
import App from './App.vue';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './components';
import liveUpdateServer from './websocket/liveUpdate';

import authMixin from './mixins/authPass';
import s3Mixin from './mixins/s3';
import responsivenessMixin from './mixins/responsiveness';
import validationMixin from './mixins/validationMixin';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// if (process.env.VUE_APP_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://eebc7c944eaf4c7baa0c94aa66dd2d1e@o382846.ingest.sentry.io/5212376',
//     integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
//     environment: process.env.VUE_APP_ENV,
//   });
// }

// Vue.use(VueCurrencyInput, {
//   globalOptions: {
//     currency: 'EUR',
//     locale: 'nl',
//     autoDecimalMode: false,
//     precision: 2,
//     distractionFree: false,
//     // valueRange: { min: 0.00, max: 2000000 },
//   },
// });

Vue.use(clipboard);
Vue.use(JoditVue);
Vue.use(isodate);
Vue.use(eventBus);
Vue.use(VueTelInput);
Vue.use(VueSignaturePad);

Vue.component('CountryFlag', CountryFlag);

const config = authConfig(window.location.hostname);

Vue.use(Auth0Plugin, {
  domain: config.domain,
  clientId: config.clientId,
  audience: config.audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
    );
  },
});

Vue.use(VueMoment);
Vue.use(VueAxios, axios);
Vue.use(Toasted, {
  iconPack: 'mdi',
  className: 'toaster',
  duration: 3000,
});

Vue.use(tenantPlugin, axios);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.use(vuexI18n.plugin, store);
Vue.use(TenantTranslator);

Vue.i18n.add('en', EN);
Vue.i18n.add('nl', NL);

Vue.use(liveUpdateServer, { store });

Vue.mixin(authMixin);
Vue.mixin(s3Mixin);
Vue.mixin(responsivenessMixin);
Vue.mixin(validationMixin);

warehouseWatcher(store);

// eslint-disable-next-line no-unused-vars
const vm = new Vue({
  directives: { clipboard },
  router,
  store,
  vuetify,
  eventBus,
  render: (h) => h(App),
}).$mount('#app');
