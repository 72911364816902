export default {
  // template: {}, - should be defined separately at each modules state.
  // Is template for new item instance to be saved later.

  items: null,
  list: {
    items: [],
    total: null,
    limit: 10,
    offset: null,
    filters: { ItemId: { value: null, sort: { asc: false, order: 1 } } },
  },
  loading: null,
  loadingList: null,
};
