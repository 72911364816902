<template>
  <v-sheet
    v-if="value"
    color="border darken-1"
    rounded="lg"
    class="pa-4 pb-8 mt-n4 mb-9"
    style="height: 100%"
  >
    <v-badge
      color="info"
      :content="`Σ: ${total}`"
      class="w-100"
      overlap
      :value="Boolean(value.Bundles.length)"
      offset-x="75px"
      offset-y="12px"
    >
      <v-row dense>
        <v-col>
          <v-row
            no-gutters
            class="mx-n1"
          >
            <v-col class="pr-2">
              <v-autocomplete
                :value="value.ProductId"
                :label="$t('label:product', null, 1)"
                :items="products"
                :loading="productLoading"
                :search-input.sync="productSearch"
                :disabled="disable.main || disable.product || productLoading"
                item-value="ItemId"
                item-text="Name"
                cache-items
                flat
                hide-no-data
                clearable
                aria-autocomplete="off"
                :rules="mnValidation.required"
                @update:search-input="onProductSearch"
                @input="(v) => onInput('ProductId', v)"
              />
            </v-col>
            <v-col cols="auto">
              <c-number
                :label="$t('label:quantity:bundle', null, 1)"
                :value="value.Quantity"
                :disabled="disable.main || disable.quantity"
                class="width-10ch"
                hide-details="auto"
                :rules="mnValidation.required"
                @input="(v) => onInput('Quantity', v)"
              />
            </v-col>
            <v-col
              cols="auto"
              style="min-width: 84px"
              class="ml-2"
            >
              <v-tooltip
                v-if="!value.Bundles.length"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    color="text"
                    class="mt-4 mr-7"
                    icon
                    small
                    :disabled="disable.main || disable.quantity"
                    v-on="on"
                    @click="onAdd"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                {{ $t('label:carrier_add') }}
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            class="mt-n5"
            dense
          >
            <c-bundle-main
              v-for="(Bundle, bundleIndex) in value.Bundles"
              :key="`bundle-${bundleIndex}`"
              :path="path ? [...path, bundleIndex] : null"
              :bundle-controls="bundleIndex + 1 === value.Bundles.length"
              :increase-controls="bundleIndex + 1 !== value.Bundles.length"
              :decrease-controls="bundleIndex !== 0"
              :disable="disable"
              :template="template"
              class="w-100"
              @input="(v) => onUpdateBundle(bundleIndex, v)"
              @move="(v) => onMove(bundleIndex, v)"
              @add="onAdd"
              @remove="onRemove"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-badge>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CBundleMain from '@/components/bundle/main.vue';

export default {
  name: 'ProductBundleMain',

  components: {
    CBundleMain,
  },

  props: {
    template: {
      type: Object,
      required: true,
      description: 'vuex getter of ordertype',
    },
    reset: {
      type: Function,
      required: true,
      description: 'vuex reset template action of ordertype',
    },
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
        quantity: false,
      }),
    },
  },

  data: () => ({
    productSearch: null,
    products: [],
  }),

  computed: {
    ...mapGetters({
      productLoading: 'product/loading',

      bundleDefaultTemplate: 'bundle/defaultTemplate',
    }),

    value() {
      if (!this.path.length) {
        return this.template.ProductBundle;
      }
      return this.template
        .Orderlines[this.path[0]][this.path[1]][this.path[2]].ProductBundle;
    },

    total() {
      return this.value.Bundles.reduce(
        (total, { Quantity }) => total * Quantity, this.value.Quantity,
      );
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      async handler(n, o) {
        if (n?.ProductId && n.ProductId !== o?.ProductId) {
          this.products = await this.productReturnItems({ ItemId: n.ProductId });
        }
      },
    },
  },

  mounted() {
    this.sort();
  },

  methods: {
    ...mapActions({
      productReturnItems: 'product/returnItems',
    }),

    sort() {
      const { Bundles } = this.value;
      // eslint-disable-next-line no-nested-ternary
      Bundles.sort((a, b) => (a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : 0));
      this.onInput('Bundles', Bundles);
    },

    onInput(p, v) {
      if (!this.path.length) {
        this.reset({
          ...this.template,
          ProductBundle: {
            ...this.template.ProductBundle,
            [p]: v,
          },
        });
      } else {
        const { Orderlines } = this.template;

        Orderlines[this.path[0]][this.path[1]][this.path[2]].ProductBundle = ({
          ...this.value, [p]: v,
        });

        this.reset({
          ...this.template,
          Orderlines,
        });
      }
    },

    onProductSearch() {
      clearTimeout(this.productTimeout);
      this.productTimeout = setTimeout(async () => {
        const filter = {};
        if (this.productSearch?.length) {
          filter.Name = { value: this.productSearch, type: 'text' };
        }
        this.products = (await this.productReturnItems(filter))
          .filter(({ deletedAt, ItemId }) => !deletedAt || ItemId === this.value.ProductId);
      }, 500);
    },

    onAdd() {
      this.onInput('Bundles', [...this.value.Bundles, { ...this.bundleDefaultTemplate, Sequence: this.value.Bundles.length + 1 }]);
    },

    onRemove() {
      const { Bundles } = this.value;

      Bundles.pop();

      this.onInput('Bundles', Bundles);
    },

    onMove(bundleIndex, increase) {
      const { Bundles } = this.value;

      const Bundle = Bundles[bundleIndex];

      Bundles.splice(bundleIndex, 1);

      Bundles.splice(increase ? bundleIndex + 1 : bundleIndex - 1, 0, Bundle);

      this.onInput('Bundles', Bundles.map((b, Sequence) => ({ ...b, Sequence: Sequence + 1 })));
    },

    onUpdateBundle(bundleIndex, v) {
      const { Bundles } = this.value;
      Bundles[bundleIndex] = v;
      this.onInput('Bundles', Bundles);
    },
  },
};
</script>
