<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="loading"
            :loading="loading"
            :class="{'pt-2': !loading}"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              :headers="headers"
              :get-list="getList"
              :list="list"
              :loading="loading"
            >
              <template #footer-prepend>
                <v-btn
                  small
                  color="primary darken-1"
                  class="width-auto"
                  @click.stop="onForm()"
                >
                  {{ $t('label:resource', null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-btn
                      icon
                      @click="onForm(item.ItemId)"
                    >
                      <v-icon
                        :disabled="loading"
                        color="accent"
                      >
                        mdi-open-in-app
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    {{ item.Name }}
                  </td>
                  <td>
                    {{ item.WarehouseId }}
                  </td>
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          class="mr-2 float-right"
                          v-on="on"
                          @click="onDelete(item)"
                        >
                          <v-icon
                            class="delete-icon"
                          >
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t('label:delete') }}
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResourceIndex',
  computed: {
    ...mapGetters({
      loading: 'resource/loadingList',
      list: 'resource/list',
    }),
    headers() {
      return [
        {
          text: '', value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:name', null, 1), value: 'Name', type: 'text',
        },
        {
          text: this.$t('label:warehouse', null, 1), value: 'WarehouseId', searchable: false,
        },
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: '', value: '', searchable: false, sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getList: 'resource/getList',
      deleteItem: 'resource/destroy',
    }),

    onForm(ItemId) {
      this.$router.push({ name: 'system-resource-form', params: { ItemId } });
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
