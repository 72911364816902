import TransformationOrders from '@/components/views/movement/TransformationOrders.vue';

import Index from '@/components/transformation-order/index.vue';

import Form from '@/components/transformation-order/form.vue';

export default [
  {
    path: '/movement/transformation-order/list',
    component: TransformationOrders,
    meta: {
      restriction: ['WMS.Manager'],
    },

    children: [
      {
        name: 'movement-transformation-order-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Manager'] },
        children: [
          {
            name: 'movement-transformation-order-form',
            path: '/movement/transformation-order/form/:ItemId?',
            component: Form,
            meta: { restriction: ['WMS.Manager'] },
          },
        ],
      },
    ],
  },
];
