<template>
  <v-row class="ma-0">
    <app-navigation v-model="menuWide" />
    <v-main
      style="padding-top: 60px;"
      class="management"
      :class="menuWide ? 'menuWide' : ''"
    >
      <v-container fluid>
        <v-row no-gutters>
          <slot />
        </v-row>
      </v-container>
    </v-main>
  </v-row>
</template>

<script>

import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'StoragePage',
  components: {
    AppNavigation,
  },
  data: () => ({
    menuWide: false,
  }),
};
</script>
