<template>
  <v-card class="my-1">
    <v-row
      class="ma-0 flex-nowrap"
      align="center"
      dense
    >
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        height="46px"
        min-width="24px"
        class="px-1 rounded-tl-sm rounded-bl-sm rounded-tr-0 rounded-br-0"
        @click="$emit('select', value)"
      >
        <v-icon class="white--text">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-col
        cols="auto"
        class="pr-0"
      >
        <v-sheet class="width-10ch">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-chip
                x-small
                color="info"
                :loading="loading"
                :disabled="loading"
                v-on="on"
                @click="$emit('open')"
              >
                {{ $t('label:order', null, 1) }}: # {{ value.ItemId }}
              </v-chip>
            </template>
            {{ $t('label:go_to_order') }} #{{ value.ItemId }}
          </v-tooltip>
        </v-sheet>
      </v-col>
      <v-spacer />
      <v-col class="d-flex flex-column">
        <span class="clipped-line">
          {{ value.CustomerReference ? value.CustomerReference : '-' }}
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'OutboundOrderDocking',

  props: {
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
