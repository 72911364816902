<template>
  <div>
    <v-row
      no-gutters
    >
      <v-col class="pr-2">
        <v-autocomplete
          :label="$t('label:carrier', null, 1)"
          hide-details="auto"
          aria-autocomplete="off"
          item-value="ItemId"
          item-text="Name"
          :disabled="disabled"
          :items="cCarrierItems"
          :value="value.CarrierId"
          @input="(v) => onInput('CarrierId', v)"
        />
      </v-col>
      <v-col cols="auto">
        <c-number
          :label="$t('label:quantity:container', null, 1)"
          class="width-10ch"
          hide-details="auto"
          :disabled="disabled"
          :value="value.Quantity"
          @input="(v) => onInput('Quantity', v)"
        />
      </v-col>
      <v-col
        cols="auto"
        style="min-width: 84px"
        class="ml-2"
      >
        <template v-if="!disabled">
          <v-tooltip
            v-if="decreaseControls"
            top
          >
            <template #activator="{ on }">
              <v-btn
                color="text"
                class="mt-4"
                icon
                small
                v-on="on"
                @click="onMove(false)"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </template>
            {{ $t('label:move_up') }}
          </v-tooltip>
          <v-tooltip
            v-if="increaseControls"
            top
          >
            <template #activator="{ on }">
              <v-btn
                color="text"
                class="mt-4"
                icon
                small
                v-on="on"
                @click="onMove(true)"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </template>
            {{ $t('label:move_down') }}
          </v-tooltip>
          <template v-if="bundleControls">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  color="text"
                  class="mt-4"
                  icon
                  small
                  v-on="on"
                  @click="$emit('add')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              {{ $t('label:carrier_add') }}
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  color="text"
                  class="mt-4"
                  icon
                  small
                  v-on="on"
                  @click="$emit('remove')"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>
              {{ $t('label:carrier_remove') }}
            </v-tooltip>
          </template>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BundleMain',
  props: {
    value: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    bundleControls: {
      type: Boolean,
      default: false,
    },
    decreaseControls: {
      type: Boolean,
      default: false,
    },
    increaseControls: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    carrierSearch: null,
  }),

  computed: {
    ...mapGetters({
      carrierLoading: 'carrier/loading',

      carrierItems: 'carrier/items',

      bundleDefaultTemplate: 'bundle/defaultTemplate',
    }),

    cCarrierItems() {
      return this.carrierItems.filter(
        ({ deletedAt, ItemId }) => !deletedAt || ItemId === this.value.CarrierId,
      );
    },
  },

  methods: {
    ...mapActions({
      getCarrierItems: 'carrier/getItems',
      // bundleTmpID: 'bundle/tmpID',
    }),

    onMove(increase = false) {
      this.$emit('move', increase);
    },

    onInput(p, v) {
      if (this.disabled) {
        return;
      }
      this.$emit('input', { ...this.value, [p]: v });
    },
  },
};
</script>
