import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import CUserPreview from '@/components/user/preview.vue';
import CAuthSelect from './CAuthSelect.vue';
import ProgressBar from './ProgressBar.vue';
import CFile from './CFile.vue';
import CDropzone from './CDropzone.vue';
import CCurrencyField from './CCurrencyField.vue';
import CVuexList from './c-vuex-list/index.vue';
import CNumber from './CNumber.vue';

Vue.component('CAuthSelect', CAuthSelect);
Vue.component('CUserPreview', CUserPreview);
Vue.component('ProgressBar', ProgressBar);
Vue.component('CFile', CFile);
Vue.component('CDropzone', CDropzone);
Vue.component('CCurrencyField', CCurrencyField);
Vue.component('VueDropzone', vue2Dropzone);
Vue.component('CVuexList', CVuexList);
Vue.component('CNumber', CNumber);
