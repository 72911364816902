export default {
  ApprovalChiefEngineer: 'Op Goedkeuring door Chief Engineer',
  ApprovalManager: 'Op Goedkeuring door Manager',
  ApprovalManagerMaintenance: 'At Approval by Maintenance Manager',
  ApprovalManagerWarehouse: 'At Approval by Warehouse Manager',
  Archived: 'Gearchiveerd',
  Backlog: 'Achterstand',
  Boolean: 'Boolean',
  BooleanString: 'BooleanString',
  Callback: 'Terugbellen',
  Cancelled: 'Geannuleerd',
  'City/Government': 'Stad',
  'Cleaning Company': 'Schoonmaakbedrijf',
  'Client Visit': 'Klantbezoek',
  Company: 'Bedrijf',
  Construction: 'Bouw',
  Contact: 'Contact',
  Customer: 'Klant',
  Demo: 'Demo',
  Done: 'Gedaan',
  Events: 'Events',
  ExternalInspection: 'Externe Inspectie',
  ExternalMaintenance: 'Extern Onderhoud',
  'Foreign company': 'Buitenlands bedrijf',
  Gardener: 'Tuinman',
  Giveaway: 'Uitgifte',
  High: 'Hoog',
  Inbound: 'Inkomende',
  InProgress: 'In behandeling',
  Installation: 'Installatie',
  InternalInspection: 'Interne Inspectie',
  Lead: 'Lead',
  Loading: 'Laden',
  Low: 'Laag',
  Maintenance: 'Onderhoud',
  New: 'Nieuw',
  Normal: 'Normaal',
  Number: 'Nummer',
  'Office Visit': 'Kantoorbezoek',
  Ordered: 'Besteld',
  Outbound: 'Uitgaande',
  Painter: 'Schilder',
  Partner: 'Partner',
  Pause: 'Pauze',
  'Phone Call': 'Telefoongesprek',
  Pickup: 'Ophalen',
  PickupCheck: 'Ophaalcontrole',
  Planned: 'Gepland',
  'Private Persoon': 'Privé persoon',
  Prospect: 'Prospect',
  Quotation: 'Offerte',
  'Rental Company': 'Verhuurbedrijf',
  Rent: 'Huur',
  Rented: 'Verhuurd',
  Repair: 'Reparatie',
  Returned: 'Ingeleverd',
  Sales: 'Verkoop',
  'Send Document': 'Stuur document',
  SimpleInspection: 'Simpele Inspectie',
  String: 'String',
  Supplier: 'Leverancier',
  Suspect: 'Suspect',
  Underway: 'Onderweg',
  Urgent: 'Urgent',
  SectorUsage: 'Sector gebruikt',
  ExecutorUsage: 'Gebruik uitvoerder',
  ConsumableUsage: 'Verbruiksartikelen',
  ResourceUsage: 'Verpakkings verbruik',
  Custom: 'Custom',
  Surcharge: 'Toeslag',
  CubicMeters: 'Cubic meters',
  SquareMeters: 'Square meters',
  Duration: 'Duur',
  Quantity: 'Hoeveelheid',
  'Video Call': 'Video-oproep',
  'Visit Client': 'Bezoek klant',
  'Visit Office': 'Bezoek kantoor',
  'action:get:error:address': 'Adres ophalen van de server mislukt: {error}',
  'action:get:error:conact': ' Contact ophalen van de server mislukt: {error}',
  'action:get:error:customer': ' Klant ophalen van de server mislukt: {error}',
  'action:get:error:email': 'Email bericht ophalen van de server mislukt: {error}',
  'action:get:error:item': ' Gegevens ophalen van de server mislukt: {error}',
  'action:get:error:kvkOrganisation': 'KvK ophalen van de server mislukt: {error}',
  'action:get:error:users': 'Gebruikers ophalen van de server mislukt: {error}',
  'action:save:error:address': 'Adres opslaan mislukt: {error}',
  'action:save:error:contact': 'Contact opslaan mislukt: {error}',
  'action:save:error:customer': 'Klant opslaan mislukt: {error}',
  'action:save:error:email': 'Verzenden bericht naar Mail Server mislukt: {error}',
  'action:save:error:item': 'Gegevens opslaan mislukt: {error}',
  'action:save:error:kvkOrganisation': ' KvK opslaan mislukt: {error}',
  'action:save:error:language': 'Taalvoorkeur opslaan mislukt: {error}',
  'action:save:success:address': 'Adres opgeslagen',
  'action:save:success:contact': 'Contact opgeslagen',
  'action:save:success:customer': 'Klant opgeslagen',
  'action:save:success:email': 'Bericht verzonden naar Mail Server',
  'action:save:success:item': 'Gegevens opgeslagen',
  'action:save:success:kvkOrganisation': 'KvK opgeslagen',
  'action:save:success:language': 'Taalvoorkeur opgeslagen',
  'action:save:success:warehouse': 'Magazijn opgeslagen',
  'btn:hide': 'Verbergen',
  'btn:show_all': 'Toon alles',
  'btn:accessorie:add': 'Accessoire toevoegen',
  'btn:accessories_lost': 'Accessoire kwijtgeraakt',
  'btn:add': 'Toevoegen',
  'btn:address:add': 'Nieuw adres toevoegen',
  'btn:address:new': 'Nieuw adres toevoegen',
  'btn:addresses': 'Adressen',
  'btn:administration': 'Administratie',
  'btn:approve': 'Goedkeuren',
  'btn:back': 'Terug',
  'btn:back_to_tasks': 'Terug naar taken',
  'btn:base_checklist:add': 'Toevoegen basis controlelijst',
  'btn:base_procedure:add': 'Toevoegen basis procedure',
  'btn:basic_data': 'Basis gegevens',
  'btn:cancel': 'Annuleren',
  'btn:chamber_of_commerce': 'Kamer van Koophandel',
  'btn:check_credit_device': 'Check via CreditDevice',
  'btn:check_credit_rating': 'Controleer kredietwaardigheid',
  'btn:clear': 'Wissen',
  'btn:close': 'Sluiten',
  'btn:collection': ['Verzameling', 'Collections'],
  'btn:company_customer:new': 'Bedrijf',
  'btn:complete': 'Afgerond',
  'btn:complete_and_approve': 'Complete & Approve Maintenance',
  'btn:complete_and_cancel': 'Complete & Cancel Maintenance',
  'btn:configuration:add': 'Configuratie',
  'btn:configuration_line:add': 'CONFIGURATIE REGEL',
  'btn:confirm': 'Bevestigen',
  'btn:confirm_loaded': 'Bevestig Geladen',
  'btn:confirm_pickup': 'Bevestig Ophalen',
  'btn:confirm_replacement': 'Confirm replacement',
  'btn:confirm_transformation': 'Confirm transformation',
  'btn:contact:add': 'Contact',
  'btn:contact:new': 'Contact',
  'btn:credit_rating': 'Kredietwaardigheid',
  'btn:customer:add': 'Toevoegen',
  'btn:customer:new': 'Klant',
  'btn:deactive': 'Deactiveren',
  'btn:delivered_to_counter': 'Geleverd aan de Balie',
  'btn:discount:add': 'Korting',
  'btn:end_delivery': 'Eind Levering',
  'btn:execute': 'Uitvoeren',
  'btn:export': 'Export',
  'btn:finance_data': 'Financiële Data',
  'btn:force_approve': 'Forceer toestaan',
  'btn:force_save': 'Forceer Opslaan',
  'btn:force_send': 'Forceer verzenden',
  'btn:general': 'Algemeen',
  'btn:get_order_items': 'Haal order artikelen op',
  'btn:giveaway': 'Uitgifte',
  'btn:invoice:create_custom': 'Maak aangepaste factuur',
  'btn:invoice:generate': 'Factuur genereren',
  'btn:item:add': 'Artikel',
  'btn:kvk_data': 'KVK Data',
  'btn:make_all_free': 'Maak alles vrij',
  'btn:merchandize:add': 'Gebruiksartikel',
  'btn:next': 'Volgende',
  'btn:ok': 'OK',
  'btn:order:rent': 'Huurorder',
  'btn:order:purchase': 'Inkooporder',
  'btn:order:sales': 'Verkooporder',
  'btn:order:add': 'Order',
  'btn:order:new': 'Order',
  'btn:order_ready': 'Order is gereed',
  'btn:other_data': 'Overige gegevens',
  'btn:pickup': 'Ophalen',
  'btn:position': 'Positie',
  'btn:prev': 'Vorige',
  'btn:print': 'Print',
  'btn:private_customer:new': 'PARTICULIER',
  'btn:procedure_add': 'Toevoegen procedure',
  'btn:procedure_new': 'Nieuwe procedure',
  'btn:product:add': 'Product',
  'btn:product_group:add': 'Productsubgroep',
  'btn:product_serie:add': 'Productgroep',
  'btn:push_to_finish': 'Druk om te voltooien',
  'btn:rating': 'Beoordeling',
  'btn:re_export_selected': 'Opnieuw Exporteren Geselecteerd',
  'btn:reassign_kvk': 'Wijs KvK opnieuw toe',
  'btn:refund': 'Terugbetaling',
  'btn:resend_pdf': 'Verzend de PDF opnieuw',
  'btn:reset': 'Handtekening resetten',
  'btn:reset_configuration': 'Configuratie resetten',
  'btn:reset_items': 'Artikelen resetten',
  'btn:save': 'Opslaan',
  'btn:search': 'Zoeken',
  'btn:send': 'Versturen',
  'btn:send_selected': 'Verzenden geselecteerd',
  'btn:update_and_resend': 'Update & Send',
  'btn:send_to_ordered': 'Verzenden naar Besteld',
  'btn:send_to_quotation': 'Verzenden naar Offerte',
  'btn:send_to_tms': 'Verzenden naar TMS',
  'btn:serial:add': 'Serie',
  'btn:serial:possession': '3rd party Serial',
  'btn:show_completed': 'Toon voltooide taken',
  'btn:show_on_site': 'Toon op site',
  'btn:signature': 'Handtekening',
  'btn:start': 'Start',
  'btn:start_delivery': 'Start Levering',
  'btn:start_loading': 'Start Laden',
  'btn:task:add': 'Taak',
  'btn:to_backlog': 'Naar werkvoorraad',
  'btn:to_maintenance_location': 'Naar Onderhoudslocatie',
  'btn:to_pickup_list': 'Naar ophaallijst',
  'btn:to_pickup_location': 'Naar Ophalen locatie',
  'btn:to_planned': 'Naar gepland',
  'btn:to_plannable': 'Naar planbaar',
  'btn:to_check-in': 'Inchecken',
  'btn:to_handling': 'Naar afhandeling',
  'btn:to_done': 'Naar gedaan',
  'btn:transform_to_note': 'Transformeer naar notitie',
  'btn:transform_to_task': 'Transformeer naar taak',
  'btn:unblock': 'Deblokkeer',
  'btn:update': 'Bijwerken',
  'btn:update_end_date': 'Bijwerken eind datum',
  'btn:update_note': 'Update notities & Terug',
  'btn:use_configuration': 'Gebruik configuratie',
  'btn:use_item': 'TOEVOEGEN',
  'camera:auto': 'Van camera wisselen',
  'camera:front': 'Voor camera',
  'camera:rear': 'Achter camera',
  'credit_save:check:error': 'CreditSafe - controle - mislukt: {error}',
  'error:qr:NotAllowedError': 'Cameratoegang van browser geblokkeerd door gebruiker',
  'error:qr:NotFoundError': 'Apparaat heeft geen geschikte camera',
  'error:qr:NotReadableError': 'Camera is al in gebruik',
  'error:qr:NotSupportedError': 'Pagina geopend via een onveilig protocol',
  'error:qr:OverconstrainedError': 'Kan de geselecteerde camera niet vinden',
  'error:qr:StreamApiNotSupportedError': 'Camera wordt niet ondersteund door browser',
  'error:qr:TypeError': 'Browser ondersteunt geen flitsbediening',
  'error:task:external_maintenance:paused:invalid_auth_sub': 'De huidige toegewezen chauffeur is {engineer}',
  'error:task:giveaway:paused:invalid_vehicle_id': 'Taak gepauzeerd vanuit voertuig ({vehicle})',
  'error:task:giveaway:paused:no_auth_sub': 'Taak heeft geen toegewezen uitvoerder',
  'help:entity:in_maintenance': 'Stuks niet beschikbaar. Zijn momenteel gereserveerd of in onderhoud',
  'help:entity:in_rent': 'Stuks niet beschikbaar. Zijn momenteel verhuurd',
  'help:entity:in_stock': 'Stuks beschikbaar in magazijn',
  'help:orderline:lost': "Besteld aantal door klant als 'kwijt' gemeld, met uitzondering van VERKOCHT",
  'help:orderline:quantity': 'Aantal stuks besteld',
  'help:orderline:rented': 'Besteld door klant, DIT is exclusief VERLOREN of VERKOCHTE aantallen',
  'help:orderline:returned': 'Besteld aantallen teruggebracht door klant, DIT is exclusief VERKOCHTE aantallen, MAAR inclusief GEVONDEN aantallen',
  'help:orderline:sold': 'Besteld aantal kwijtgeraakte > gefactuurde > betaalde stuks',
  'help:orderline:underway': 'Besteld aantallen onderweg. DIT is inclusief TERUGGEVONDEN aantallen',
  'help:taskline:giveaway:loaded_quantity': 'Door chauffeur bevestigde geladen aantallen',
  'help:taskline:giveaway:planned_quantity_delivery': 'Aantal stuks gepland voor aflevering',
  'help:taskline:giveaway:rentedout_quantity': 'Door klant afgenomen aantal',
  'help:taskline:giveaway:undelivered_quantity': 'Aantal stuks nog niet afgeleverd',
  'help:taskline:pickup:found_quantity': "Aantal teruggevonden stuks (eerder als 'kwijt' gemeld door klant)",
  'help:taskline:pickup:loaded_quantity': 'Door chauffeur bevestigde geladen aantallen',
  'help:taskline:pickup:lost_quantity': 'Door klant als kwijt gemelde aantal stuks',
  'help:taskline:pickup:order_total_unfound_quantity': 'Aantal nog niet gevonden stuks',
  'help:taskline:pickup:order_total_unreturned_quantity': 'Nog niet van klant terugontvangen aantal',
  'help:taskline:pickup:planned_found_quantity': "Aantal stuks nog te retourneren (als 'kwijt' gemeld bij eerder ophaalactie)",
  'help:taskline:pickup:planned_quantity_pickup': 'Aantal op te halen stuks',
  'help:taskline:pickup:rentedout_quantity': 'Aantal stuks gehuurd door klant',
  'help:taskline:replacement:planned_quantity_giveaway': 'Planned quantity of replacement items to deliver',
  'help:taskline:replacement:planned_quantity_pickup': 'Planned quantity of replacement items to pickup',
  'label: previous_month': 'Vorige Maand',
  'label:acceptance': ['Acceptatie', 'Acceptaties'],
  'label:accessorie': ['Accessoire', 'Accessoires'],
  'label:accessorielose': 'Accessoire Verlies',
  'label:action': ['Actie', 'Acties'],
  'label:active': 'Actief',
  'label:activity': ['Activiteit', 'Activiteiten'],
  'label:approved': 'Goedgekeurd',
  'label:add_document': 'Document Toevoegen',
  'label:add_file': 'Bestand toevoegen',
  'label:add_scheduled_service': 'Toevoegen ingeplande servicebeurt',
  'label:add_service_task': 'Toevoegen servicebeurt taak',
  'label:address': ['Adres', 'Adressen'],
  'label:after': 'Volgend',
  'label:allow_mailing': 'Mailing toestaan',
  'label:answer': 'Antwoord',
  'label:answer_type': 'Antwoordtype',
  'label:archived': ['Gearchiveerd'],
  'label:aspen': 'Aspen',
  'label:assign': 'Assign',
  'label:assigned_employee': 'Toegewezen aan werknemer',
  'label:association': ['Vereniging', 'Verenigingen'],
  'label:auto_discount': 'Automatisch Ingestelde Korting',
  'label:auto_pickup': 'Automatisch ophalen',
  'label:available_quantity': ['Beschikbare hoeveelheid'],
  'label:awaiting_approval': ['Taken in afwachting van goedkeuring'],
  'label:backlog': ['Achterstand', 'Achterstanden'],
  'label:backlog_filter': 'Datumfilter',
  'label:backlog_search': 'Zoeken in werkvoorraad',
  'label:base_checklist': 'Basis Controlelijst',
  'label:base-package': 'Basis pakket',
  'label:base_procedure': 'Basis Procedure',
  'label:base_used_part': ['Basis gebruikte onderdeel', 'Basis gebruikte onderdelen'],
  'label:batch': 'Batch',
  'label:batch:quantity': 'Batch quantity',
  'label:bcc': 'BCC',
  'label:before': 'Voordat',
  'label:billable': 'Factureerbaar',
  'label:blocked': ['Geblokkeerd'],
  'label:boolean': 'Boolean',
  'label:bought_circs': 'Omstandigheden Aankoop',
  'label:bought_date': 'Aankoopdatum',
  'label:bought_documents': 'Documenten Aankoop',
  'label:bought_from': 'Aangekocht bij',
  'label:bought_price': 'Aankoopprijs',
  'label:branch': 'Afdeling',
  'label:bundles': ['Bundle', 'Bundles'],
  'label:cancel': 'Annuleren',
  'label:cancel_upload': 'Upload annuleren',
  'label:cancelled': ['Geannuleerd'],
  'label:capacity': 'Capaciteit (tillen/laden/etc)',
  'label:carrier': ['Drager', 'Dragers'],
  'label:carrier_add': 'Drager toevoegen',
  'label:carrier_remove': 'Drager verwijderen',
  'label:cash': 'Contact geld',
  'label:cc': 'CC',
  'label:certification_period': 'Certificering Periode',
  'label:certification_provider': 'Certificering',
  'label:check_credit_rating': 'Controleer Kredietwaardigheid',
  'label:checklist': 'Controlelijst',
  'label:checklist_name': 'Controlelijst Naam',
  'label:checklist_type': 'Controlelijst Type',
  'label:city': 'Plaats',
  'label:clone': 'Clone',
  'label:cluster': 'Cluster',
  'label:code': 'Postcode',
  'label:collection': 'Collectie',
  'label:collection_name': 'Collectienaam',
  'label:collection_type': 'Collectietype',
  'label:commentaries': 'Opmerkingen',
  'label:complete': 'Afgerond',
  'label:done_custom': 'Completed custom task',
  'label:complete_acceptance': 'Acceptatie Afgerond',
  'label:complete_giveaway': 'Uitgifte afgerond',
  'label:complete_maintenance': 'Onderhoud afgerond',
  'label:complete_pickup': 'Ophalen afgerond',
  'label:configuration': ['Configuratie', 'Configuraties'],
  'label:configuration_line': 'Configuratieregel',
  'label:construction_year': ['Bouwjaar'],
  'label:contact': ['Contact', 'Contacten'],
  'label:contact_add': 'Klantcontact toevoegen aan CRM',
  'label:customer_add': 'Klant toevoegen aan CRM',
  'label:contact_info': ['Contact Info'],
  'label:contract': 'Contract',
  'label:consumable': ['Verbruiksartikel', 'Verbruiksartikelen'],
  'label:copy_phone': 'Telefoonnummer kopiëren',
  'label:copy_serial': 'Kopieer het serienummer',
  'label:copy_url': 'Kopie URL',
  'label:counter': ['Balie', 'Balies'],
  'label:counter_handling': ['Baliebehandeling', 'Baliebehandeling'],
  'label:create': 'Aanmaken',
  'label:create_found_task': 'Maak een retourtaak aan',
  'label:create_giveaway_task': 'Uitgifte taak maken',
  'label:create_pickup_task': 'Ophalen Taak Maken',
  'label:create_replacement_task': 'Maak een vervangende taak',
  'label:created': ['Aangemaakt'],
  'label:created_at': 'Gemaakt bij',
  'label:created_by': ['Aangemaakt door'],
  'label:credit_check_alert': ['Kredietscore lager dan besteltotaal'],
  'label:credit_check_failed': 'Kredietcontrole mislukt',
  'label:credit_device_id': 'Credit Device Id',
  'label:credit_limit': 'Kredietlimiet',
  'label:credit_limit_history': 'Geschiedenis Kredietlimiet',
  'label:creditor_number': 'Crediteurnummer',
  'label:crm': 'CRM',
  'label:current_serial': ['Huidige Serie', 'Huidige Series'],
  'label:price_day_current': 'Current day price',
  'label:price_day_static': 'Static day price',
  'label:price_sales_current': 'Current sales price',
  'label:price_sales_static': 'Static sales price',
  'label:price_week_current': 'Current week price',
  'label:price_week_static': 'Static week price',
  'label:custom': 'Op maat',
  'label:custom_invoice_line': 'Aangepaste factuurregel',
  'label:customer': ['Klant ', 'Klanten'],
  'label:customer_discount': ['Klantenkorting'],
  'label:customer_mediator': ['Contactpersoon levering', 'Contactpersoonen levering'],
  'label:customer_mediator_string': ['Mediator contact info'],
  'label:customer_overseer': ['Contactpersoon klant', 'Contactpersonen klant'],
  'label:customer_reference': ['Klantreferentie'],
  'label:custom_tasks': 'Aangepaste taken',
  'label:customer_product_name': 'Klant product naam',
  'label:date': 'Datum',
  'label:date_of_birth': 'Geboortedatum',
  'label:day_price': 'Dagprijs',
  'label:deactivated': 'Gedeactiveerd',
  'label:debtor': 'Debiteur',
  'label:debtor_number': 'Debiteurennummer',
  'label:default_delivery_address': ['Default Delivery Address'],
  'label:default_driver': ['Standaard chauffeur'],
  'label:default_packaging': 'Default packaging',
  'label:delete': 'Verwijderen',
  'label:deleted': ['Verwijderd'],
  'label:deleted_at': 'Verwijderd bij',
  'label:deliver': 'Bezorgen',
  'label:delivery': ['Bezorging'],
  'label:delivery_address': 'Bezorgadres',
  'label:delivery_date': 'Bezorg Datum',
  'label:delivery_price': 'Bezorgkosten',
  'label:delivery_task': ['Leveringstaak', 'Leveringstaken'],
  'label:delivery_time': 'Aflevertijd',
  'label:department': 'Afdeling',
  'label:depreciation_bs': 'Afschrijving BS',
  'label:depreciation_pl': 'Afschrijving PL',
  'label:description': ['Omschrijving'],
  'label:diesel': ['Diesel'],
  'label:discount': ['Korting', 'Kortingen'],
  'label:discount_type': 'Discount type',
  'label:divisions': 'Afdelingen',
  'label:docking_inbound': 'Inkomende docking',
  'label:dock': 'Dock',
  'label:document': ['Document', 'Documenten'],
  'label:document:add': 'Document',
  'label:document_type': ['Document Type', 'Document Types'],
  'label:download': 'Download',
  'label:drag_drop_task': 'Sleep de taak uit het menu',
  'label:driver': ['Chauffeur', 'Chauffeurs'],
  'label:driver_name': 'Driver name',
  'label:due_date': 'Opleveringsdatum',
  'label:duplicate': 'Verveelvoudigen',
  'label:duration': 'Duur',
  'label:dynamic_discount_percent': 'Dynamic %',
  'label:empty': 'Empty',
  'label:edit': 'Bewerken',
  'label:edit_task_note': 'Bewerk taaknotities',
  'label:email': 'Email',
  'label:email1': 'Email1',
  'label:email2': 'Email2',
  'label:email_for': 'E-mail voor',
  'label:employee': ['Werknemer', 'Werknemers'],
  'label:end': ['Einddatum'],
  'label:end_before': 'Einde voor',
  'label:end_date': ['Einddatum'],
  'label:end_price_day': 'Eindprijs(Dag)',
  'label:end_price_sales': 'Eindprijs (Verkoop)',
  'label:end_price_week': 'EindPrijs (Week)',
  'label:end_time': ['Eindtijd'],
  'label:endeavor': ['Notitie', 'Notities'],
  'label:endeavor_action': 'Notitie actie',
  'label:endeavor_for': 'Streven naar',
  'label:energy_source': 'Energiebron',
  'label:engineer': ['Monteur', 'Monteurs'],
  'label:engineer_search': 'Monteur zoeken',
  'label:exact': 'Precies',
  'label:executed': 'Uitgevoerd',
  'label:execution': 'Uitvoering',
  'label:execution_date': ['Dag van uitvoering'],
  'label:execution_type': 'Uitvoeringstype',
  'label:executor': ['Uitvoerder', 'Uitvoerder'],
  'label:executor_handling': 'Uitvoerder afhandeling',
  'label:expand': 'Uitbreiden',
  'label:exported': 'Geëxporteerd',
  'label:external': ['External'],
  'label:external_Id': 'Externe ID',
  'label:external_inspection': 'Externe Inspectie',
  'label:external_maintenance': 'Extern onderhoud',
  'label:factory_serial': ['Fabrieksnummer'],
  'label:far': 'FAR',
  'label:fax': 'Fax',
  'label:female': 'Vrouw',
  'label:fill': 'Invullen',
  'label:filtered_orders': 'Filtered orders',
  'label:finance_data_not_synced': 'Financiële data wordt niet gesynchroniseerd',
  'label:finance_data_synced': 'Financiële data is gesynchronniseerd',
  'label:finance_sync': 'Finance sync.',
  'label:financial_statements': 'Financieel overzicht',
  'label:financial_system_id': 'Financieel systeem ID',
  'label:first_name': 'Voornaam',
  'label:flashlight': 'zaklamp',
  'label:found_quantity': ['Gevonden aantal'],
  'label:free': 'Onbetaald',
  'label:free_search': 'Overal zoeken',
  'label:from': 'Van',
  'label:fuel_type': ['Brandstof type '],
  'label:functional_group': 'Functiegroep',
  'label:gender': 'Geslacht',
  'label:geometry': 'Geometry',
  'label:giveaway_by': 'Uitgifte door',
  'label:giveaway_state': 'Afvoer Status',
  'label:giveaway_to': 'Uitgifte aan',
  'label:global': 'Globaal',
  'label:global_search': 'Algemeen zoeken',
  'label:global_search_top_100': 'Algemeen zoeken (top 100)',
  'label:go_to_order': 'Go to order',
  'label:ground': 'Ground',
  'label:guarantee': ['Garantie'],
  'label:handled:all': 'Alles behandeld',
  'label:handling_rule': ['Afhandelingsregel', 'Afhandelingsregels'],
  'label:has_discount': 'Krijgt korting',
  'label:height': 'Hoogte, m',
  'label:high': ['Hoog'],
  'label:house_number': 'Huisnummer',
  'label:iban': 'IBAN',
  'label:id': ['ID', 'IDS'],
  'label:inbound': 'Inkomende',
  'label:initiator': 'Initiator',
  'label:inbound_batch': 'Inkomende batch',
  'label:inbound_handling': 'Inkomende afhandeling',
  'label:inner_length': 'Inner length, m',
  'label:inner_width': 'Inner width, m',
  'label:inner_height': 'Inner height, m',
  'label:in_maintenance': 'In Onderhoud',
  'label:in_possession_of': 'In Bezit Van',
  'label:in_possession': 'In Bezit',
  'label:inprogress_custom': 'In progress custom task',
  'label:inprogress_maintenance': 'Onderhoud in uitvoering',
  'label:invoice_pending_approval': ['Facturen in afwachting van goedkeuring'],
  'label:in_rent': 'Verhuurd',
  'label:in_repair': 'In Reparatie',
  'label:in_stock': 'Op Voorraad',
  'label:inactive': 'Inactief',
  'label:include_individual': 'Inclusief individu',
  'label:individual': 'Individueel',
  'label:info': 'Informatie',
  'label:inprogress_giveaway': 'In behandeling Uitgifte',
  'label:inprogress_pickup': 'In behandeling Afhalen',
  'label:insurance': 'Verzekering',
  'label:internal_inspection': 'Interne Inspectie',
  'label:internal_handling': 'Internal handling',
  'label:invoice': ['Factuur', 'Facturen'],
  'label:is_customer': 'Is een klant',
  'label:is_delivery_address': 'Is een bezorgadres',
  'label:is_department': 'Is een afdeling/dienst',
  'label:is_invoice_address': 'Is een factuuradres',
  'label:is_po_box': 'Is een postbus',
  'label:is_post_address': 'Is een postadres',
  'label:is_supplier': 'Is een leverancier',
  'label:is_visit_address': 'Is een bezoekadres',
  'label:item': ['Artikel', 'Artikelen'],
  'label:kg': 'kg',
  'label:kvk': 'Kamer van Koophandel (KvK)',
  'label:language': 'Taal',
  'label:last_certification': 'Laatste Certificering',
  'label:last_name': 'Achternaam',
  'label:ledger': 'Grootboek',
  'label:lessor': ['Lessor', 'Lessors'],
  'label:ledger_rental': 'Verhuurgrootboek',
  'label:ledger_sales': 'Verkoopgrootboek',
  'label:length': 'Lengte, m',
  'label:level': 'Level',
  'label:license_plate': ['Kentekenplaat'],
  'label:limit_advice': 'Beperkt advies',
  'label:linkedin': 'Ga naar LinkedIn pagina',
  'label:liter': ['liter', 'liters'],
  'label:loaded': ['Geladen'],
  'label:loaded_quantity': ['Geladen Hoeveelheid'],
  'label:loading_giveaway': 'Geladen Uitgifte',
  'label:loading_unloading': 'Laden/Lossen VDL Materieel',
  'label:loadmeters': 'Loadmeters',
  'label:loadmeters_short': 'ldm',
  'label:logout': 'Log uit',
  'label:lost': ['Verloren'],
  'label:lost_quantity': 'Verloren hoeveelheid',
  'label:low': ['Laag'],
  'label:lunchbreak': 'Lunchbreak',
  'label:machine_dim': 'Machine Dim',
  'label:mail_program': 'E-mailprogramma',
  'label:main_activity': 'Hoofdactiviteit',
  'label:maintenance': 'Onderhoud',
  'label:make': 'Maak',
  'label:make_free': 'Maak onbetaald',
  'label:make_paid': 'Maak betaald',
  'label:male': 'Man',
  'label:manage': 'Beheer',
  'label:mechanic': ['Monteur', 'Monteurs'],
  'label:merchandize': ['gebruiksartikel', 'Gebruiksartikelen'],
  'label:merchandize_price': 'Prijs gebruiksartikel',
  'label:meter': ['meter', 'meters'],
  'label:middle_name': 'Tussenvoegsel',
  'label:minimum_photos': 'Min. aantal foto’s',
  'label:minimum_rent_period': 'Min. huurperiode (dagen)',
  'label:minimum_stock': 'Min. Voorraad',
  'label:minute': ['min', 'mins'],
  'label:mobile': 'Mobiel',
  'label:modality_id': 'Modiliteit-ID',
  'label:model': 'Model',
  'label:month': ['maand', 'maanden'],
  'label:monthly_depreciation_percent': '% Maandelijkse afschrijving',
  'label:movement_handling': 'Movement handling',
  'label:move_down': 'Move down',
  'label:move_up': 'Move up',
  'label:multiplier': 'Multiplier',
  'label:name': ['Naam', 'Namen'],
  'label:natural_gas': ['Gas'],
  'label:net_worth': 'Netto waarde',
  'label:netto_price': 'Netto Prijs',
  'label:new': ['Nieuw'],
  'label:next_certification': 'Volgende Certificering',
  'label:next_day': 'Volgende Dag',
  'label:next_month': 'Volgende Maand',
  'label:next_week': 'Volgende Week',
  'label:no': 'Nee',
  'label:no_notes_recorded': 'Geen notities opgenomen',
  'label:notes_section': 'Opmerkingen sectie',
  'label:no_consumables_spent': 'Geen accessoires gebruikt',
  'label:no_documents': 'Geen documenten',
  'label:no_emails': 'Geen e-mails',
  'label:no_entries': 'Geen vermelding',
  'label:no_entries_found': 'Geen vermeldingen gevonden',
  'label:no_executor_involved': 'Geen uitvoerder betrokken',
  'label:no_external_Id': 'External Id not specified',
  'label:no_packages': 'No packages in this sector yet',
  'label:no_resource_used': 'Geen middelen gebruikt',
  'label:no_photos': 'Geen foto’s beschikbaar',
  'label:no_task': 'Nog geen taken',
  'label:no_overseer': 'Nog geen relatiebeheerder',
  'label:no_orderlines_assigned': 'No orderlines assigned',
  'label:no_orders_filtered': 'No orders filtered',
  'label:none': 'Geen',
  'label:normal': ['Normaal'],
  'label:not_synced': 'Niet gesynchroniseerd',
  'label:note': ['Notitie', 'Notes'],
  'label:note_executor': 'Nota van de uitvoerder',
  'label:note_planner': 'Notitie van de planner',
  'label:notes_not_filled': 'Notities niet gevuld',
  'label:number': ['Nummer'],
  'label:open': 'Open',
  'label:open_in_tab': 'Openen in nieuw tabblad',
  'label:order': ['Order', 'Orders'],
  'label:order_inbound': ['Inkomende order', 'Inkomende orders'],
  'label:order_outbound': ['Uitgaande order', 'Uitgaande orders'],
  'label:order_transformation': ['Transformatie order', 'Transformatie orders'],
  'label:order_id': 'Order ID',
  'label:order_line': ['Bestelregel', 'Bestelregels'],
  'label:order_note': 'Order opmerking',
  'label:order_reference_mandatory': 'Orderreferentie verplicht',
  'label:order_total_undelivered_quantity': ['Niet bezorgd'],
  'label:order_total_unfound_quantity': ['Niet gevonden aantal'],
  'label:order_total_unreturned_quantity': ['Niet geretourneerd'],
  'label:order_type': 'Order Type',
  'label:ordered': ['Besteld'],
  'label:order_filters': 'Order filters',
  'label:organisation': 'Organisatie',
  'label:origin': ['Herkomst', 'Herkomst'],
  'label:original': ['Origineel', 'Originelen'],
  'label:other': 'Andere',
  'label:outbound': 'Uitgaande',
  'label:outbound_batch': 'Uitgaande batch',
  'label:outbound_handling': 'Uitgaande afhandeling',
  'label:overseer': 'Huidige gebruiker',
  'label:own_weight': 'Eigen Gewicht',
  'label:package': ['Pakket', 'Pakketten'],
  'label:package_add': 'Pakket toevoegen',
  'label:package:create': 'Maak een pakket',
  'label:package:quantity': 'Pakkethoeveelheid',
  'label:package:manual': 'Manual package',
  'label:package:result': ['Resultaat pakket', 'Resultaat pakketten'],
  'label:package:source': ['Bronpakket', 'Bronpakketten'],
  'label:package:transformation:add': 'Add package to current orderline for tranformation',
  'label:package:transformation:create': 'Create new order line for package transformation',
  'label:packing_number': 'Packing number',
  'label:paid': 'Betaald',
  'label:pallet': 'Pallet',
  'label:pause': ['Pauze', 'Pauzes'],
  'label:payment': ['Betaling', 'Betalingen'],
  'label:parent_cluster': 'Parent cluster',
  'label:parent_package': 'Parent package',
  'label:parent_sector': 'Hal',
  'label:part_of_configuration': 'Part of {name} configuration',
  'label:partially_sold': 'Deels verkocht',
  'label:pcs': 'pcs',
  'label:period': 'Periode',
  'label:petrol': 'Benzine',
  'label:phone': 'Telefoon',
  'label:photo': ['Foto', 'Foto’s'],
  'label:picked_up': 'Picked up',
  'label:pickup': 'Ophalen',
  'label:pickup_check': 'Ophaalcontrole',
  'label:pickup_date': 'Ophaaldatum',
  'label:pickup_state': 'Ophaal Status',
  'label:pickup_time': 'Ophaal tijd',
  'label:pirce_sales': 'Verkoopprijs',
  'label:placeholder_external_Id': 'Specificeer externe ID',
  'label:plan': 'Gepl.',
  'label:planboard_search': 'Planboard zoeken',
  'label:planner_note': 'Planner opmerking',
  'label:planned_custom': 'Planned custom task',
  'label:planned_date': 'Geplande datum',
  'label:planned_dockings': 'Geplande dockings',
  'label:planned_duration': 'Geplande duur',
  'label:planned_end': 'Gepland einde',
  'label:planned_found_quantity': 'Aantal gevonden',
  'label:planned_giveaway': 'Geplande Uitgifte',
  'label:planned_pickup': 'Geplande Ophalen',
  'label:planned_quantity': ['Geplande Hoeveelheid'],
  'label:planned_start': 'Geplande start',
  'label:planned_time': 'Geplande tijd',
  'label:planning_giveaway': 'Geplande Uitgifte',
  'label:planning_pickup': 'Geplande Ophalen',
  'label:position:x': 'Position X',
  'label:position:y': 'Position Y',
  'label:postal_code': 'Postcode',
  'label:possession': ['Possession', 'Possessions'],
  'label:possession:leased': 'Leased',
  'label:possession:all': 'Only 3rd party Serials',
  'label:possession:archived': 'Archived possessions',
  'label:possession:out': 'Out of possession',
  'label:possession:ordered': 'Ordered 3rd party Serials',
  'label:possession:surplus': 'Surplus 3rd party Serials',
  'label:possession:swappable': 'Swappable 3rd party Serials',
  'label:possession:filter': 'Filter by 3rd party attribute',
  'label:precise_execution_time': 'Set precieze tijd',
  'label:previous_day': 'Vorige Dag',
  'label:previous_month': 'Vorige maand',
  'label:previous_week': 'Vorige Week',
  'label:price': 'Prijs',
  'label:price_aspen': 'Aspen prijs',
  'label:price_counter_hour': 'Balie Uurtarief',
  'label:price_day': ['Huurprijs Per Dag'],
  'label:price_diesel': 'Diesel prijs',
  'label:price_engineer_call_hour': 'Uurtarief voor Monteur (op afroep)',
  'label:price_engineer_home_hour': 'Uurtarief voor Monteur (huis)',
  'label:price_natural_gas': 'Aardgasprijs',
  'label:price_per_day': 'Prijs per dag',
  'label:price_per_week': 'Prijs per week',
  'label:price_petrol': 'Benzine prijs',
  'label:price_sales': ['Verkoopprijs'],
  'label:price_total': ['Totale Prijs'],
  'label:price_unit': ['Eenheid Prijs'],
  'label:price_week': ['Huurprijs Per Week'],
  'label:pricing': ['Prijsstelling', 'Prijsstellingen'],
  'label:print_order': 'Printopdracht',
  'label:print_packing_list': 'Druk de paklijst af',
  'label:priority': ['Prioriteit'],
  'label:private_mailing': 'Privé mailing',
  'label:procedure': ['Procedure', 'Procedures'],
  'label:shard_size': 'Shard Size',
  'label:shard_process': 'Process As Shards',
  'label:procedure_name': 'Procedure Naam',
  'label:product': ['Product', 'Producten'],
  'label:product_bundle': 'Product bundle',
  'label:product_group': 'Productsubgroep',
  'label:product_group_name': 'Productgroep',
  'label:product_serie': ['Productgroep', 'Productgroepen'],
  'label:productgroup': 'Product Subgroep',
  'label:productserie': 'Product Groep',
  'label:project_name': 'Projectnaam',
  'label:purchase_account': 'Aankoopaccount',
  'label:purchase_price': 'Aankoopprijs',
  'label:qr_scanner': 'QR Scanner',
  'label:quantity': ['Hoeveelheid'],
  'label:quantity:bundle': 'Bundel hoeveelheid',
  'label:quantity:package': 'Pakkethoeveelheid',
  'label:quantity:container': 'Hoeveelheid container',
  'label:queued': 'In de wachtrij',
  'label:quotation': ['Offerte'],
  'label:rack': 'Rack',
  'label:ready_for_pickup': 'Ready for pick up',
  'label:refueled_quantity': ['Bijgetankt'],
  'label:reg_number': 'Reg nummer',
  'label:registration': ['Regristratie', 'Registraties'],
  'label:related_task': ['Gerelateerde taak', 'Gerelateerde taken'],
  'label:relocate_package': 'Package relocation',
  'label:remove_file': 'Bestand verwijderen',
  'label:rent': ['Verhuur', 'Huurprijzen'],
  'label:rent_calendar': 'Huur Kalender',
  'label:rent_end': ['Einde huur'],
  'label:rent_start': ['Start huur'],
  'label:rental_price_day': 'Huurprijs per dag',
  'label:rental_price_week': 'Huurprijs per week',
  'label:rented': ['Verhuurd'],
  'label:rentedout_quantity': ['Verhuurd'],
  'label:repair': 'Reparatie',
  'label:replaced_accessorie': 'Accessoire vervangen',
  'label:replaced_serial': 'Serie vervangen',
  'label:replacement': 'Vervangen',
  'label:replacement_accessorie': 'Vervangende accessoire',
  'label:replacement_add': 'Voeg vervanging toe',
  'label:replacement_complete': 'Complete vervanging',
  'label:replacement_date': 'Vervangingsdatum',
  'label:replacement_inprogress': 'Vervanging in uitvoering',
  'label:replacement_loading': 'Vervanging laden',
  'label:replacement_planned': 'Vervanging gepland',
  'label:replacement_planning': 'Vervanging plannen',
  'label:replacement_product': 'Vervangend product',
  'label:replacement_serial': 'Vervangende serie',
  'label:replacement_task': 'Vervangingstaak',
  'label:replacement_time': 'Vervangingstijd',
  'label:replacement_underway': 'Gestart met de vervanging',
  'label:residual_value': 'Restwaarde',
  'label:responsible': ['Verantwoordelijke'],
  'label:resource': ['Resource', 'Resources'],
  'label:returned': ['Ingeleverd'],
  'label:review': ['Review', 'Reviews'],
  'label:rows_per_page': 'Rijen per pagina',
  'label:rsin_kvk_branch': 'RSIN/CoC/Industry',
  'label:sales_price': 'Verkoopprijs',
  'label:scan_qr': 'Scan QR',
  'label:scheduled_service_task': ['Ingeplande Servicebeurt taak', 'Ingeplande Servicebeurt taken'],
  'label:score': 'Score',
  'label:see_on_map': 'Zie op kaart',
  'label:sector': 'Sector',
  'label:sector_usage': 'Sector gebruikt',
  'label:select_file': '... of klik om een bestand op uw computer te selecteren',
  'label:selected_date': 'Geselecteerde datum',
  'label:selected_order_lines': 'Selected order lines',
  'label:sellable': 'Verkoopbare',
  'label:send_again': 'Klik om opnieuw te sturen',
  'label:send_email': 'E-mail verzonden',
  'label:send_to_maintenance': ['Datum naar Onderhoud'],
  'label:send_to_warehouse': ['Datum naar Magazijn'],
  'label:sent': ['Verstuur'],
  'label:sent_date': ['Datum verstuurd'],
  'label:sequence': ['Volgorde', 'Sequences'],
  'label:serial': 'Serie',
  'label:serials_list': 'Series Lijst',
  'label:serials_with_gps': 'Series met GPS-gegevens beschikbaar',
  'label:serials_without_gps': 'Series zonder GPS-gegevens',
  'label:service': ['Service', 'Services'],
  'label:service_task': ['Servicebeurt taak', 'Servicebeurt taken'],
  'label:setting': ['Instelling', 'Instellingen'],
  'label:settings_history': ['Geschiedenis Instellingen'],
  'label:shareholders': 'Aandeelhouders',
  'label:sidebar_collapse': 'Collapse sidebar',
  'label:sidebar_expand': 'Expand sidebar',
  'label:signing_in': 'Zingen-in .....',
  'label:simple_inspection': 'Simpele inspectie',
  'label:show_all_vehicles': 'Toon alle voertuigen',
  'label:slim_id': 'TMS ID',
  'label:sold': ['Verkocht'],
  'label:specialization': ['Specialisatie', 'Specialisaties'],
  'label:specialty': 'Specialiteit',
  'label:specification': ['Specificatie', 'Specificaties'],
  'label:stackable': 'Stapelbaar',
  'label:start': ['Start Datum'],
  'label:start_after': 'Start After',
  'label:start_date': 'Start Datum',
  'label:start_time': ['Starttijd'],
  'label:state': 'Сonditie',
  'label:status': 'Status',
  'label:status:stored': 'Opgeslagen',
  'label:status:dissolved': 'Verdwenen',
  'label:status:departed': 'Vertrokken',
  'label:status:new': 'Nieuw',
  'label:status:plannable': 'Geplande dockings',
  'label:status:plannable:order': 'Geplande order',
  'label:status:planned': 'Gepland dockings',
  'label:status:planned:docking': 'Geplande docking',
  'label:status:check-in': 'Check-in Dockings',
  'label:status:check-out:orderline': 'Check-out orderregel',
  'label:status:handling': 'Afhandeling orderregels',
  'label:status:handling:orderline': 'Afhandeling orderregels',
  'label:status:done': 'Order voltooid',
  'label:status_legend': 'Status kleurlegenda',
  'label:stock': ['Op voorraad'],
  'label:stock_prediction': 'Voorraadvoorspelling',
  'label:storage_handling': 'Opslag',
  'label:street': 'Straat',
  'label:string': 'String',
  'label:subject': 'Onderwerp',
  'label:suggested_price_day': 'Voorgestelde Huurprijs (per dag)',
  'label:suggested_price_sales': 'Voorgestelde Verkoopprijs',
  'label:suggested_price_week': 'Voorgestelde Huurprijs (per week)',
  'label:supplier': 'Leverancier',
  'label:synced': 'Synchroniseerd',
  'label:sync:down': 'Sync Down',
  'label:sync:up': 'Sync Up',
  'label:tank_size': 'Groote Tank',
  'label:task': ['Taak', 'Taken'],
  'label:task_awaiting_approval': ['Taken in afwachting van goedkeuring'],
  'label:task_awaiting_approval_manager': ['Taken in afwachting van goedkeuring door manager'],
  'label:task_awaiting_approval_engineer': ['Taken in afwachting van goedkeuring door hoofdingenieur'],
  'label:task_id': 'Taak ID',
  'label:task_note': 'Task/Note',
  'label:tax_percent': ['Belastingspercentage', 'Belastingspercentages'],
  'label:taxed': ['Belast'],
  'label:template': ['Sjabloon', 'Templates'],
  'label:third_party_delivery': ['Levering door derden', 'Leveringen door derden'],
  'label:tms_id': 'TMS id',
  'label:tms': 'TMS',
  'label:to': 'Aan',
  'label:toggle': 'Toggle',
  'label:total': 'Totaal',
  'label:total_depreciation_time_months': 'Totale afschrijvingstijd (maanden)',
  'label:total_invoiced': 'Totaal Gefactureerd',
  'label:total_taxed': 'Totaal belast',
  'label:total_untaxed': 'Totaal onbelast',
  'label:total_units': 'Eenheden',
  'label:track_unit_id': ['Track-Unit ID'],
  'label:trans_date': 'Trans. datum',
  'label:transactions': ['Transaction', 'Transactions'],
  'label:transaction_type': ['Transactie Type'],
  'label:transformation_batch': 'Transformatie batch',
  'label:transformation_handling': 'Transformatie afhandeling',
  'label:transportation': ['Transportatie', 'Transportatie'],
  'label:transportation_price': 'Transportatie Prijs',
  'label:docking': ['Rit', 'Ritten'],
  'label:type': ['Type', 'Types'],
  'label:type_note': 'Typ uw notities',
  'label:underway': ['Onderweg'],
  'label:underway_pickup': ['Onderweg Afhalen'],
  'label:unit': ['Eenheid'],
  'label:unique': ['Uniek', 'Uniek'],
  'label:unloaded': 'Unloaded',
  'label:updated_at': 'Bijgewerkt op',
  'label:urgent': ['Urgent'],
  'label:url': ['Url'],
  'label:usage_consumable': 'Accessoires gebruikt',
  'label:usage_executor': 'Gebruik uitvoerder',
  'label:usage_resource': 'Verpakkings verbruik',
  'label:used_part': ['Gebruikte Onderdeel', 'Gebruikte Onderdelen'],
  'label:usedpart': ['Gebruikte Onderdeel', 'Gebruikte Onderdelen'],
  'label:vat_number': 'BTW-nummer',
  'label:vat_number_eu': 'BTW-nummer EU',
  'label:vatnr': 'BTW-nummer',
  'label:vehicle': ['Voertuig', 'Voertuigen'],
  'label:vehicle_filter': 'Voertuigfilter',
  'label:vehicle_on_map': 'Voertuig op kaart',
  'label:volume': 'Volume, m³',
  'label:volume_short': 'm³',
  'label:task_filter': 'Task filter',
  'label:vin': 'VIN',
  'label:warehouse': 'Magazijn',
  'label:warehouse_add': 'Magazijn toevoegen',
  'label:warehouse_select': 'Selecteer magazijn',
  'label:web': 'Web',
  'label:website': ['Website', 'Websites'],
  'label:website_data': 'Website gegevens',
  'label:week_price': 'Weekprijs',
  'label:weeks_and_days': 'Weken | Dagen',
  'label:weight_brutto': 'Bruto gewicht, kg',
  'label:weight_netto': 'Nettogewicht, kg',
  'label:weight': 'Gewicht, kg',
  'label:weight_short': 'kg',
  'label:width': 'Breedte, m',
  'label:working_capital': 'Werkkapitaal',
  'label:working_height': 'Werkhoogtes',
  'label:xtra_serial': ['VDL Serial'],
  'label:year': ['Jaar', 'Jaren'],
  'label:yes': 'Ja',
  'nav:cms': 'CMS',
  'nav:qms': 'QMS',
  'nav:wms': 'WMS',
  'nav:tms': 'TMS',
  'nav:rms': 'RMS',
  'nav:package': 'Pakketten',
  'nav:wms:portal': 'WMS Portal',
  'nav:sector': 'Sectoren',
  'nav:carrier': 'Dragers',
  'nav:dock': 'Docks',
  'nav:accessorie': 'Toebehoren',
  'nav:accounts': 'Accounts',
  'nav:address': 'Adressen',
  'nav:addresses': 'Adressen',
  'nav:availability': 'Beschikbaarheid',
  'nav:basechecklist': 'Basis Controlelijst',
  'nav:baseprocedure': 'Basis procedures',
  'nav:client_orders': 'Klant Orders',
  'nav:cluster': 'Clusters',
  'nav:configuration': 'Configuratie',
  'nav:consumable': 'Verbruiksartikelen',
  'nav:core': 'Core',
  'nav:crm': 'CRM',
  'nav:customer': 'Klanten',
  'nav:delivery_task': 'Leveringstaken',
  'nav:discount': 'Kortingen',
  'nav:endeavor': 'Notities',
  'nav:entities': 'Entiteiten',
  'nav:executed_lines': 'Uitgevoerde regels',
  'nav:fms': 'FMS',
  'nav:general_costing': 'General costing',
  'nav:inbound_shipments': 'Inkomende zendingen',
  'nav:internal_orders': 'Interne orders',
  'nav:invoice': 'Facturen',
  'nav:logging': 'Logboek',
  'nav:matrices': 'Matrixes',
  'nav:module_settings': 'Module-instellingen',
  'nav:order': 'Orders',
  'nav:order:transformation': 'Transformatie orders',
  'nav:order:inbound': 'Inkomende orders',
  'nav:order:outbound': 'Uitgaande orders',
  'nav:outsourced_shipments': 'Charters / Uitbestedingen',
  'nav:planning': 'Planning',
  'nav:product': 'Producten',
  'nav:quotations': 'Offerte',
  'nav:rates': 'Tarieven',
  'nav:resource': 'Middelen',
  'nav:resources': 'Middelen',
  'nav:scheduled_service_task': 'Ingeplande Servicebeurt',
  'nav:serial': 'Series',
  'nav:service_task': 'Servicebeurt taken',
  'nav:settings': 'Instellingen',
  'nav:surcharges': 'Toeslagen',
  'nav:system': 'Systeem',
  'nav:system_settings': 'Systeem Instellingen',
  'nav:task': 'Taken',
  'nav:docking': 'Ritten',
  'nav:vehicle': 'Voertuigen',
  'nav:wages': 'Wages',
  'placeholder:bcc': 'Door komma gescheiden emails',
  'placeholder:cc': 'Door komma gescheiden emails',
  'placeholder:certification_period': 'Cert. Periode',
  'placeholder:date_of_birth': 'DD/MM/JJJJ',
  'placeholder:documents_input': 'Gebruik Shift of CTRL om meerdere bestanden toe te voegen. ',
  'placeholder:select_merchandize': 'Selecteer Goederen',
  'placeholder:subject': 'Onderwerp',
  'placeholder:to': 'Ontvanger',
  'placeholder:whats_done?': 'Wat is er gedaan?',
  'role:VDL:WMS:Manager': 'VDL Manager',
  'role:admin': 'Admin',
  'role:Betten': 'Betten',
  'role:CRM.Admin': 'CRM Admin',
  'role:CRM.Manager': 'CRM-beheerder',
  'role:TMS.Admin': 'TMS Admin',
  'role:TMS.Driver': 'TMS Driver',
  'role:TMS.Finance': 'TMS Financieel',
  'role:TMS.Manager': 'TMS Manager',
  'role:TMS.Office': 'TMS Office',
  'role:TMS.Planner': 'TMS Planner',
  'role:TMS.Portaluser': 'TMS Portaluser',
  'role:TMS.Sales': 'TMS Sales',
  'role:WMS.Manager': 'WMS Manager',
  'role:WMS.Mover': 'WMS Mover',
  'role:WMS.Admin': 'VDL Admin',
  'role:WMS.ChiefEngineer': 'VDL Hoofd Ingenieur',
  'role:WMS.Counter': 'VDL Balie',
  'role:WMS.Driver': 'VDL Chauffeur',
  'role:WMS.Engineer': 'VDL Ingenieur',
  'role:WMS.Finance': 'VDL Financieel',
  'role:XTRA.Admin': 'XTRA Admin',
  'role:XTRA.ChiefEngineer': 'XTRA Hoofd Ingenieur',
  'role:XTRA.Counter': 'XTRA Balie',
  'role:XTRA.Driver': 'XTRA Chauffeur',
  'role:XTRA.Engineer': 'XTRA Ingenieur',
  'role:XTRA.Finance': 'XTRA Financieel',
  'role:XTRA.Manager': 'XTRA Manager',
  'role:Developer': 'Developer',
  'role:QMS.Admin': 'QMS Admin',
  'status:docking:new': 'Nieuw',
  'status:docking:planned': 'Gepland',
  'status:docking:check-in': 'Check-In',
  'status:docking:handling': 'Afhandeling',
  'status:docking:check-out': 'Check-out',
  'status:docking:done': 'Gedaan',
  'text:all': 'Alle',
  'text:check_delivery_price:check': 'Check',
  'text:check_delivery_price:delivery_price': 'delivery price',
  'text:check_delivery_price:before_proceeding': 'before proceeding',
  'text:Admin': 'Admin',
  'text:CRM.Admin': 'CRM Admin',
  'text:CRM.Manager': 'CRM-beheerder',
  'text:TMS.Manager': 'TMS Manager',
  'text:WMS.Admin': 'VDL Admin',
  'text:WMS.ChiefEngineer': 'Hoofd Ingenieur',
  'text:WMS.Counter': 'Balie',
  'text:WMS.Driver': 'Chauffeur',
  'text:WMS.Engineer': 'Monteur',
  'text:WMS.Finance': 'Financiën',
  'text:WMS.Manager': 'WMS Manager',
  'text:VDL:WMS:Manager': 'Manager',
  'text:accessorie:delete:text': 'Bijkomende zaken definitief verwijderen?',
  'text:accessorie:delete:title': 'Weet u het zeker?',
  'text:accesssorie:delete:text': 'Toebehoren wordt permanent verwijderd.',
  'text:accesssorie:delete:title': 'Weet u het zeker?',
  'text:accp_procedures_no_parts': 'Acceptatieprocedures kunnen geen gebruikte onderdelen hebben',
  'text:address': ['adres', 'adressen'],
  'text:address:delete:text': 'Het adres wordt permanent verwijderd.',
  'text:address:delete:title': 'Weet u het zeker?',
  'text:address:edit': 'Adres bewerken',
  'text:address:new': 'Adres',
  'text:addresses:length:invalid': 'Tenminste één adres vereist',
  'text:attachment': ['bijgevoegd', 'bijgevoegde'],
  'text:awaiting_approval': 'Wachten op goedkeuring',
  'text:base_checklist:form:invalid': 'Basischecklist ongedlig',
  'text:bought': 'Gekocht bij {supplier} ({contact}), voor {for}, op {at}',
  'text:branch': ['branch', 'branches'],
  'text:company:edit': 'Bedrijf bewerken',
  'text:company:new': 'Bedrijf',
  'text:company_in_insolvent': 'Bedrijf in insolvent!',
  'text:configuration': ['configuratie', 'configuraties'],
  'text:configuration:delete:length:text': 'Configuraties Configuratieregels moeten eerst worden verwijderd',
  'text:configuration:delete:length:title': 'Configuratie Verwijderen',
  'text:configuration:delete:text': 'Configuratie definitief verwijderen?',
  'text:configuration:delete:title': 'Weet u het zeker?',
  'text:configuration_line:delete:text': 'Configuratieregels permanent verwijderen?',
  'text:configuration_line:delete:title': 'Weet u het zeker?',
  'text:configuration_line:form:invalid': 'Configuratieregelformulier is ongeldig',
  'text:contact': ['contact', 'contacten'],
  'text:contact:delete:text': 'Het contact wordt permanent verwijderd.',
  'text:contact:delete:title': 'Weet u het zeker?',
  'text:contact:edit': 'Contact bewerken',
  'text:contact:email:collision': 'Contact email(s) al in gebruik door {collisions}',
  'text:contact:new': 'New Contact',
  'text:contacts:length:invalid': 'Tenminste één contact vereist',
  'text:country': ['Land', 'landen'],
  'text:customer': ['klant', 'klanten'],
  'text:customer:delete:text': 'De klant, zijn contactgegevens en adressen worden permanent verwijderd.',
  'text:customer:delete:title': 'Weet u het zeker?',
  'text:customer:edit': 'Klant bewerken',
  'text:customer:form:invalid': 'Klantformulier is ongeldig',
  'text:customer_info': 'Klant info',
  'text:customer:name:collision': 'Klantnaam al in gebruik door {collisions}',
  'text:customer:new': 'Klant',
  'text:customer:select': 'Selecteer klant, alstublieft',
  'text:task:different_overseer': 'Task supervised by another Manager',
  'text:task:status:invalid:title': 'Task status update',
  'text:task:status:invalid:text': 'Task status changed by another user. Redirecting..',
  'text:delivery': 'Bezorging',
  'text:department': ['afdeling', 'afdelingen'],
  'text:document': ['document', 'documenten'],
  'text:documenttype': ['document type', 'document types'],
  'text:dynamic': 'Dynamic',
  'text:dynamic_discount': 'Dyn.',
  'text:email': ['Email', 'Emails'],
  'text:email:select': 'Selecteer email, alstublieft',
  'text:email_to': ['Email naar'],
  'text:endeavor:delete:text': 'Notitie definitief verwijderen?',
  'text:endeavor:delete:title': 'Weet u het zeker?',
  'text:export:page': 'Export Page',
  'text:force_update': 'Update forceren',
  'text:group': ['groep', 'groepen'],
  'text:hide_customer_data': 'Klantgegevens verbergen',
  'text:high_credit_flag': 'Hoge kredietvlag',
  'text:historicalrecord': ['historische gegevens'],
  'text:historicalrecordtype': ['historische gegevens type', 'historische gegevens types'],
  'text:individual:edit': 'Klant bewerken',
  'text:individual:new': 'Nieuwe klant',
  'text:info': 'Info',
  'text:invoice': 'factuur',
  'text:kvk:kvk_number:collision': 'KvK nummer al in gebruik door {collisions}',
  'text:kvk:select:text': 'KvK (opnieuw) toewijzen aan huidige klant?',
  'text:language': ['taal', 'talen'],
  'text:legal_since': 'Legaal sinds',
  'text:low_credit_flag': 'Lage kredietvlag',
  'text:make_private': 'Maak privé',
  'text:make_public': 'Maak publiekelijk',
  'text:manual_discount': 'Hand.',
  'text:medium_credit_flag': 'Medium kredietvlag',
  'text:memorandum': 'Memorandum datum',
  'text:merchandize:delete:text': 'Het gebruiksartikel wordt permanent verwijderd',
  'text:merchandize:delete:title': 'Weet u het zeker?',
  'text:modification': 'Modificatie datum',
  'text:note': ['Notitie', 'Notities'],
  'text:order': ['Order', 'Orders'],
  'text:order:close:unsaved:title': 'Weet u zeker dat u de order wilt sluiten?',
  'text:order:close:unsaved:text': 'Niet opgeslagen gegevens gaan verloren',
  'text:order:form:invalid': 'Orderformulier is ongeldig',
  'text:order_line:length:invalid': 'Tenminste één order item vereist',
  'text:ordertype': ['Order type', 'Order types'],
  'text:organisation': ['Organisatie', 'Organisaties'],
  'text:phone:invalid': 'Opgegeven telefoonnummer is ongeldig of veld is leeg',
  'text:planning': 'Planning',
  'text:planning_new': 'Planning',
  'text:private': 'Privé',
  'text:product': ['product', 'producten'],
  'text:product:delete:length:text': 'Product Series moeten eerst verwijderd worden',
  'text:product:delete:length:title': 'Product verwijderen',
  'text:product:delete:text': 'Product definitief verwijderen?',
  'text:product:delete:title': 'Weet u het zeker?',
  'text:product:form:invalid': 'Product formulier is ongeldig',
  'text:product_group:delete:length:text': 'Productsubgroep Producten moeten eerst verwijderd worden',
  'text:product_group:delete:length:title': 'Productsubgroep verwijderen',
  'text:product_group:delete:text': 'Productsubgroep definitief verwijderen?',
  'text:product_group:delete:title': 'Weet u het zeker?',
  'text:product_group:form:invalid': 'Productsubgroep formulier is ongeldig',
  'text:product_serie:delete:length:text': 'Productgroep Subgroep moet eerst worden verwijderd',
  'text:product_serie:delete:length:title': 'Productgroep Verwijderen',
  'text:product_serie:delete:text': 'Productgroep definitief verwijderen?',
  'text:product_serie:delete:title': 'Weet u het zeker?',
  'text:product_serie:form:invalid': 'Productgroep formulier is ongeldig',
  'text:public': 'Publiek',
  'text:purchase': 'Aankoop',
  'text:recevied': 'Ontvangen',
  'text:registration': 'Registratiedatum',
  'text:regular_driver': 'gewone chauffeur',
  'text:regular_driver_not_assigned': 'Geen vaste chauffeur toegewezen',
  'text:report_days_old': 'Rapport is {days} dag(en) oud',
  'text:search': 'Zoeken..',
  'text:sent': 'Verzonden',
  'text:serial': ['serie', 'series'],
  'text:serial:delete:text': 'Hiermee worden Series en alle daarmee verbonden transacties definitief verwijderd.',
  'text:serial:delete:title': 'Weet u het zeker?',
  'text:serial:form:invalid': 'Serie formulier is ongeldig',
  'text:settlement': 'Afwikkelingsdatum',
  'text:show_customer_data': 'Toon klantgegevens',
  'text:signature:form:invalid': 'Handtekeningenformulier ongeldig',
  'text:static': 'Static',
  'text:static_price': 'Static',
  'text:status_inprogress': 'In behandeling',
  'text:status_loading': 'Laden',
  'text:status_planned': 'Gepland',
  'text:status_underway': 'Onderweg',
  'text:task:cancel:text': 'Lopende taak annuleren?',
  'text:task:cancel:title': 'Weet u het zeker?',
  'text:task:delete:text': 'Taak definitief verwijderen?',
  'text:task:delete:title': 'Weet u het zeker?',
  'text:task:form:invalid': 'Taakformulier ongeldig',
  'text:tradename': 'Handelsnaam',
  'text:turnover_code': 'Omzet code',
  'text:turnover_value': 'Omzet waarde',
  'text:unassigned': 'Niet Toegewezen',
  'text:unknown_credit_flag': 'Onbekende kredietvlag',
  'text:user_roles': 'Uw rollen in app',
  'text:user_roles_in_app': 'Rollen in app',
  'text:user_roles_not_assigned': 'De gebruiker heeft geen enkele rol in de app',
  'text:yip_list': 'yip list',
  'validation:shard:multiple': 'Unshred quantity is not multiple of product\'s Shard Size',
  'validation:shard:empty': 'Product\'s Shard Size not defined',
  'validation:amount:required': 'Het bedrag is vereist',
  'validation:bcc:invalid': 'Ongeldig(e) BBC e-mailadres(sen) (lijst) ',
  'validation:cc:invalid': 'Ongeldig(e) CC e-mailadres(sen) (lijst)',
  'validation:checklist:checklist_lines:invalid': 'Checklistformulier onvolledig',
  'validation:crm_mandatory_rule': 'Dit veld is door CRM als verplicht ingesteld voor deze klant',
  'validation:currency:required': 'Financiële waarde moet worden ingesteld',
  'validation:debtor_number:length': 'Debiteurennummer mag niet meer dan 15 tekens bevatten',
  'validation:email:invalid': 'E-mail moet geldig zijn',
  'validation:filled:required': 'Veld is vereist',
  'validation:in_stock:length': ' Productvoorraad moet groter zijn dan of gelijk zijn aan minimumvoorraad',
  'validation:in_stock:required': 'Productvoorraad moet worden ingesteld',
  'validation:loaded_quantity:empty': 'Voor vertrek moet er minimaal één item geladen zijn',
  'validation:minimum_stock:length': 'De minimumvoorraad van het product moet kleiner zijn dan of gelijk zijn aan de voorraad',
  'validation:minimum_stock:required': 'Minimum productvoorraad moet worden ingesteld',
  'validation:name:required': 'Naam vereist',
  'validation:no_serial': 'Geselecteerde serie vereist',
  'validation:phone:length': 'Telefoon mag niet meer dan 15 tekens bevatten',
  'validation:planned_quantity:empty': 'Er moet ten minste één item worden gepland om af te leveren',
  'validation:positive_number:required': 'Positief getal is vereist',
  'validation:procedures:procedure:invalid': 'Sommige procedures zijn onvolledig',
  'validation:quantity:required': 'Hoeveelheid is verplicht',
  'validation:rentedout_quantity:empty': 'Voor vertrek dient minimaal één item uitgeladen te zijn',
  'validation:required': 'Vereist',
  'validation:selected:required': 'Geselecteerde optie is vereist',
  'validation:to:invalid': ' Ongeldig(e) e-mailadres(sen) (lijst)',
  'validation:to:required': 'Naar is vereist',
  'validation:vatnr:length': 'BTWnummer mag niet meer dan 15 tekens bevatten',
  'validation:web:invalid': 'Website URL is niet geldig',
  'validation:uniques:count': 'Package\'s uniques count not equal package\'s total units',
};
