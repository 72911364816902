import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    OrderId: null,

    InitiatorId: null, // || AuthSub

    Name: '',
    Description: '',

    Date: null,

    Billable: true,
    InitiatorType: 'Customer',
    Type: 'Transformation',

    SourcePackages: [],
    ResultPackages: [],

    Status: 'Planned',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
