export default {
  methods: {
    ahref(url) {
      if (!url || url.startsWith('http')) {
        return url;
      }
      return `https://${url}`;
    },
    s3(Key, isAttachment = false) {
      if (!Key || Key.startsWith('http')) {
        return Key;
      }
      return `https://${window.location.hostname}:3000/s3/${Key}?${isAttachment ? 'attachment=true' : ''}`;
    },
  },
};
