<template>
  <v-sheet
    color="border darken-1"
    rounded="lg"
    class="pa-4 pb-8 mt-n4 mb-9"
    style="height: 100%"
  >
    <v-badge
      color="info"
      :content="`Σ: ${total}`"
      outlined
      class="w-100"
      overlap
      :value="Boolean(value.BaseBundles.length)"
      offset-x="75px"
      offset-y="12px"
    >
      <v-row dense>
        <v-col>
          <v-row
            no-gutters
            class="mx-n1"
          >
            <v-col class="pr-2">
              <v-text-field
                :value="productTemplate.Name"
                flat
                disabled
              />
            </v-col>
            <v-col cols="auto">
              <c-number
                :label="$t('label:quantity:bundle', null, 1)"
                class="width-10ch"
                hide-details="auto"
                :value="value.Quantity"
                :disabled="disabled"
                @input="(v) => onInput('Quantity', v)"
              />
            </v-col>
            <v-col
              cols="auto"
              style="min-width: 84px"
              class="ml-2"
            >
              <v-tooltip
                v-if="!value.BaseBundles.length"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    color="text"
                    class="mt-4 mr-7"
                    icon
                    small
                    :disabled="disabled"
                    v-on="on"
                    @click="onAdd"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                {{ $t('label:carrier_add') }}
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            class="mt-n5"
            dense
          >
            <c-base-bundle-main
              v-for="(Bundle, bundleIndex) in sequence"
              :key="`bundle-${bundleIndex}`"
              :value="value.BaseBundles[bundleIndex]"
              :bundle-controls="bundleIndex + 1 === value.BaseBundles.length"
              :increase-controls="bundleIndex + 1 !== value.BaseBundles.length"
              :decrease-controls="bundleIndex !== 0"
              class="w-100"
              @input="(v) => onUpdateBundle(bundleIndex, v)"
              @move="(v) => onMove(bundleIndex, v)"
              @add="onAdd"
              @remove="onRemove"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-badge>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

import CBaseBundleMain from '@/components/base-bundle/main.vue';

export default {
  name: 'BaseProductBundleMain',

  components: {
    CBaseBundleMain,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    productSearch: null,
    products: [],
  }),

  computed: {
    ...mapGetters({
      productTemplate: 'product/template',
      productLoading: 'product/loading',

      bundleDefaultTemplate: 'bundle/defaultTemplate',
    }),

    total() {
      return this.value.BaseBundles.reduce(
        (total, { Quantity }) => total * (Quantity || 0), this.value.Quantity,
      );
    },
    sequence() {
      const { BaseBundles } = this.value;
      // eslint-disable-next-line no-nested-ternary
      BaseBundles.sort((a, b) => (a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : 0));
      return BaseBundles;
    },
  },

  methods: {
    onAdd() {
      this.onInput('BaseBundles', [...this.value.BaseBundles, { ...this.baseBundleDefaultTemplate, Sequence: this.value.BaseBundles.length + 1 }]);
    },

    onRemove() {
      const BaseBundles = this.sequence;

      BaseBundles.pop();

      this.onInput('BaseBundles', BaseBundles);
    },

    onMove(bundleIndex, increase) {
      const BaseBundles = this.sequence;

      const Bundle = BaseBundles[bundleIndex];

      BaseBundles.splice(bundleIndex, 1);

      BaseBundles.splice(increase ? bundleIndex + 1 : bundleIndex - 1, 0, Bundle);

      this.onInput('BaseBundles', BaseBundles.map((b, Sequence) => ({ ...b, Sequence: Sequence + 1 })));
    },

    onUpdateBundle(bundleIndex, v) {
      const BaseBundles = this.sequence;
      BaseBundles[bundleIndex] = v;
      this.onInput('BaseBundles', BaseBundles);
    },

    onInput(p, v) {
      if (this.disabled) {
        return;
      }
      this.$emit('input', { ...this.value, [p]: v });
    },
  },
};
</script>
