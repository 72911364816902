<template>
  <v-data-table
    :disabled="loading"
    :loading="loading"
    :headers="headers"
    :no-data-text="$t('label:no_entries')"
    :no-results-text="$t('label:no_entries_found')"
    :items="list.items"
    :options="cOptions"
    :server-items-length="list.total"
    :footer-props="{
      itemsPerPageText: '',
      itemsPerPageOptions: [10, 50, 100],
      pageText: '{0}-{1} of {2}',
      showFirstLastPage: true,
      firstIcon: 'mdi-chevron-double-left',
      lastIcon: 'mdi-chevron-double-right',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      itemsPerPageText: $t('label:rows_per_page'),
    }"

    hide-default-header
    @update:options="onUpdateOptions"
  >
    <template #header>
      <thead>
        <tr>
          <c-header
            v-for="(header, index) in cHeaders"
            :key="`th-${index}`"
            :header="header"
            :options="header.options"
            :list="list"
            @input="(filter) => onUpdateFilter(header.value, filter)"
          />
        </tr>
      </thead>
    </template>
    <template #footer.prepend>
      <slot
        name="footer-prepend"
      />
      <v-spacer />
      <v-tooltip
        v-if="onExport"
        top
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            class="mr-2"
            :loading="loading"
            v-on="on"
            @click="onExport"
          >
            <v-icon small>
              mdi-export
            </v-icon>
          </v-btn>
        </template>
        {{ $t('text:export:page') }}
      </v-tooltip>
      <v-tooltip
        v-if="refreshable"
        top
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            class="mr-2"
            :loading="loading"
            v-on="on"
            @click="onRefresh"
          >
            <v-icon small>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>
        {{ $t('text:force_update') }}
      </v-tooltip>
    </template>
    <template #item="{ item }">
      <slot
        name="item"
        :item="item"
      />
    </template>
  </v-data-table>
</template>

<script>
import CHeader from './header.vue';

export default {
  name: 'CVuexListIndex',

  components: {
    CHeader,
  },

  props: {
    loading: {
      type: Boolean,
      default: null,
    },
    staticFilters: {
      type: Object,
      default: () => ({}),
    },
    onExport: {
      type: Function,
      default: null,
    },
    headers: {
      type: Array,
      required: true,
      description:
        '{ text: string, value: requiredUniqueString, searchable: true }',
      validator: (h) => {
        // if header value is not unique
        const filters = h
          .filter(({ value }) => value && value.length)
          .map(({ text }) => text);
        if (Array.from(new Set(filters)).length !== filters.length) {
          return false;
        }

        // no invalid headers found
        return true;
      },
    },
    list: {
      type: Object,
      required: true,
      description: 'Vuex Module`s list getter',
    },
    getList: {
      type: Function,
      required: true,
      description: 'Vuex Module`s getList action',
    },
    refreshable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    cOptions() {
      return {
        itemsPerPage: this.list.limit,
        page: Math.floor(this.list.offset / this.list.limit) + 1,
      };
    },
    cHeaders() {
      return this.headers.map(
        (header) => ({
          ...header,
          searchable: header.searchable !== false && !this.staticFilters[header.value],
        }),
      );
    },
  },
  watch: {
    staticFilters: {
      deep: true,
      handler(nV, oV) {
        if (JSON.stringify(nV) === JSON.stringify(oV)) {
          return;
        }

        const { filters } = this.list;

        const removed = Object.keys(oV)
          .filter((oK) => !Object.keys(nV).includes(oK));

        removed.forEach((key) => delete filters[key]);

        this.getList({
          ...this.list,
          offset: 0,
          filters: { ...filters, ...this.staticFilters },
        });
      },
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getList({
        ...this.list,
        offset: 0,
        limit: this.cOptions.itemsPerPage,
        filters: { ...this.staticFilters },
      });
    },
    onUpdateFilter(headerValue, filter) {
      const { filters } = this.list;
      if (headerValue) {
        filters[headerValue] = { ...filters[headerValue], ...filter };
      }

      this.getList({
        ...this.list,
        offset: 0,
        filters: { ...filters, ...this.staticFilters },
      });
    },

    onUpdateOptions({
      page,
      itemsPerPage,
    }) {
      if (page === this.cOptions.page && itemsPerPage === this.cOptions.itemsPerPage) {
        return;
      }
      const { filters } = this.list;

      this.getList({
        ...this.list,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        filters: { ...filters, ...this.staticFilters },
      });
    },

    onRefresh() {
      this.getList({
        ...this.list,
        offset: 0,
        limit: this.cOptions.itemsPerPage,
        filters: { ...this.staticFilters },
      });
    },
  },
};
</script>
