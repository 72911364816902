import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {
    WarehouseId: null,
    DockId: null,

    TmsId: '',

    Serial: '',
    DriverName: '',

    Type: 'Inbound',

    PlannedStart: null,
    PlannedEnd: null,
    Start: null,
    End: null,

    Status: 'New',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
