<template>
  <div>
    <v-hover
      v-slot="{ hover }"
      open-delay="300"
      close-delay="200"
    >
      <v-fade-transition mode="out-in">
        <v-navigation-drawer
          v-model="drawer"
          app
          clipped
          :permanent="$vuetify.breakpoint.mdAndUp"
          :mini-variant="!hover && mini"
          touchless
          :width="hover || !mini ? '270px' : ''"
        >
          <app-navigation-items />
        </v-navigation-drawer>
      </v-fade-transition>
    </v-hover>
    <v-app-bar
      app
      color="toolbar"
      dark
      clipped-left
      :class="envClass"
    >
      <v-app-bar-nav-icon
        class="hamburger primary-color"
        @click.stop="menu()"
      />
      <app-logo style="z-index:100" />
      <app-warehouse />
      <v-spacer />
      <app-user />
    </v-app-bar>
  </div>
</template>

<script>

import AppLogo from '@/components/AppLogo.vue';
import AppNavigationItems from '@/components/AppNavigationItems.vue';
import AppWarehouse from '@/components/AppWarehouse.vue';
import AppUser from '@/components/AppUser.vue';

export default {
  name: 'AppNavigation',
  components: {
    AppLogo,
    AppNavigationItems,
    AppWarehouse,
    AppUser,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    drawer: false,
    mini: true,
  }),
  computed: {
    envClass: () => (process.env.VUE_APP_ENV === 'staging' ? 'is-staging' : null),
    notProd: () => process.env.VUE_APP_ENV !== 'production',
  },

  methods: {
    menu() {
      this.mini = !this.mini;
      this.$emit('input', !this.mini);
    },
  },
};
</script>

<style lang="scss">
.v-application {
  .v-toolbar {
    &.is-staging {
      background-color: rgb(255, 174, 0) !important;
    }
  }
}

.hamburger {
  z-index: 5;
}

</style>
