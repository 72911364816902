<template>
  <v-dialog
    :retain-focus="false"
    :value="true"
    :persistent="true"
    :content-class="lowComplexityModal"
    scrollable
    no-click-animation
    transition="dialog-bottom-transition"
    @keydown.esc="onClose"
  >
    <v-card
      v-if="template"
      tile
      :loading="clusterLoading"
    >
      <template slot="progress">
        <progress-bar />
      </template>
      <v-card-title class="card-title">
        <span class="card-title-caption text-truncate">
          {{ $t("label:cluster", null, 1) }}
        </span>
        <v-spacer />
        <v-btn
          icon
          light
          color="text"
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-2">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="template.WarehouseId"
              :label="$t('label:warehouse', null, 1)"
              item-text="Name"
              item-value="ItemId"
              :items="warehouseItems"
              aria-autocomplete="off"
              hide-details
              :disabled="Boolean(template.WarehouseId && template.ItemId)"
              :rules="mnValidation.required"
            />
            <v-text-field
              v-model="template.Name"
              :label="$t('label:name', null, 1)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          fixed
          color="border darken-1"
          @click="onClose"
        >
          {{ $t("btn:close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          :loading="clusterLoading"
          :disabled="clusterLoading"
          @click="onSave"
        >
          {{ $t("btn:save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClusterForm',

  computed: {
    ...mapGetters({
      template: 'cluster/template',

      clusterLoading: 'cluster/loading',

      warehouseItems: 'warehouse/items',
      WarehouseId: 'warehouse/WarehouseId',
    }),
  },

  beforeMount() {
    this.initialize();
  },

  async beforeDestroy() {
    await this.resetTemplate();
  },

  methods: {
    ...mapActions({
      update: 'cluster/update',
      create: 'cluster/create',
      resetTemplate: 'cluster/resetTemplate',
      getCluster: 'cluster/getItem',
      getClusterItems: 'cluster/getItems',
    }),
    async initialize() {
      await this.resetTemplate(false);
      if (this.$route.params.ItemId) {
        const { item } = await this.getCluster(this.$route.params.ItemId);
        this.resetTemplate(item);
      } else {
        this.resetTemplate({ WarehouseId: this.WarehouseId });
      }
    },

    onClose() {
      this.$router.push({ name: 'storage-cluster-list' });
    },

    async onSave() {
      if (this.$route.params.ItemId) {
        if (await this.update(this.template)) {
          this.onClose();
        }
      } else if (await this.create(this.template)) {
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss">
.content-no-padding {
  &.v-expansion-panel-content {
    > .v-expansion-panel-content__wrap {
      padding: 0px;
    }
  }
}
</style>
