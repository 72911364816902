import Vue from 'vue';

export const api = `https://${window.location.hostname}:3000/`;

export default {

  /**
   *  Get single item (may contain extra data compared to getItems result)
   */
  async getItem({ commit, getters }, ItemId) {
    commit('SET_LOADING', true);
    try {
      const { data } = await Vue.axios.get(`${api}${getters.namespace}/${ItemId}`);
      commit('SET_LOADING', false);
      return data;
    } catch (e) {
      commit('SET_LOADING', false);
      return Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
    }
  },

  /**
   * Get items list from DB and store them in vuex store
   */
  async getItems({ commit, getters }, params) {
    commit('SET_LOADING', true);
    try {
      const { data } = await Vue.axios.get(`${api}${getters.namespace}`, { params });
      commit('SET_ITEMS', data.items);
    } catch (e) {
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
    }
    commit('SET_LOADING', false);
  },

  /**
   * Get items list from DB and store them in vuex store
   */
  async getList(
    { commit, getters },
    {
      limit = getters.list?.limit,
      offset = getters.list?.offset,
      filters = getters.list?.filters,
    },
  ) {
    commit('SET_LOADING_LIST', true);
    try {
      const { data } = await Vue.axios.post(`${api}${getters.namespace}/list`,
        {
          ...getters.list, limit, offset, filters, items: undefined, total: undefined,
        });
      commit('SET_LIST', {
        ...getters.list, offset, filters, limit, ...data,
      });
    } catch (e) {
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
    }
    commit('SET_LOADING_LIST', false);
  },

  /**
   * Get items list from DB and return them w/o vuex store involvement
   */
  async returnItems({ getters, commit }, params) {
    try {
      commit('SET_LOADING', true);
      const { data } = await Vue.axios.get(`${api}${getters.namespace}`, { params });
      commit('SET_LOADING', false);
      return data.items;
    } catch (e) {
      commit('SET_LOADING', false);
      Vue.toasted.error(Vue.i18n.translate('action:get:error:item', { error: e.message }));
      return [];
    }
  },

  /**
   * Used to (re)set items to custom value
   */
  setItems({ commit }, items) {
    commit('SET_ITEMS', items);
  },

  /**
   * Used by TASK module actions to optionally reset current template
   * if it was moved to other status
   * @param {*} param0
   * @param {*} template
   */
  resetObsoleteTemplate({ getters, commit }, template) {
    if (getters.template?.ItemId === template.ItemId) {
      commit('RESET_TEMPLATE', null);
    }
  },

  /**
   * Reset [edited] template to default [state]
   */
  resetTemplate({ commit, getters }, template) {
    if (template === false) { // if template must be explicit null
      commit('RESET_TEMPLATE', null);
    } else { // standard behavior: re-write with given columns, use default for not set
      const templateObject = template || {};
      commit('RESET_TEMPLATE', JSON.parse(JSON.stringify({ ...getters.defaultTemplate, ...templateObject })));
    }
  },

  /**
   * Create a new item in database, then add it to the store
   */
  async create({ commit, getters, dispatch }, data) {
    try {
      commit('SET_LOADING', true);
      const response = await Vue.axios.put(`${api}${getters.namespace}`, data); // No ID so create new
      const item = { ...data, ...response.data };
      commit('STORE', item);
      commit('SET_LOADING', false);
      dispatch('getList', {});
      return item;
    } catch (e) {
      commit('SET_LOADING', false);
      // e.response.data.forEach(
      //   (error) => Vue.toasted.error(Vue.i18n.translate('action:save:error:item', { error }))
      // );
      Vue.toasted.error(e.response.data);
      return false;
    }
  },

  /**
   * Update existing item in database, then update it in the store
   */
  async update({ commit, getters, dispatch }, data) {
    commit('SET_LOADING', true);
    try {
      const response = await Vue.axios.post(`${api}${getters.namespace}/${data.ItemId}`, data);
      commit('UPDATE_ITEM', { ...data, ...response.data });
      commit('SET_LOADING', false);
      dispatch('getList', {});
      return true;
    } catch (e) {
      // Vue.toasted.error(
      // Vue.i18n.translate('action:save:error:item', { error: e.response.data }));
      Vue.toasted.error(e.response.data);
      commit('SET_LOADING', false);
      return false;
    }
  },

  /**
   * Delete Item
   */
  async destroy({ commit, getters, dispatch }, id) {
    commit('SET_LOADING', true);
    try {
      const { data } = await Vue.axios.delete(`${api}${getters.namespace}/${id}`);
      if (data && data.ItemId) {
        commit('UPDATE_ITEM', data); // soft delete
      } else {
        commit('DESTROY', id);
      }
    } catch (e) {
      Vue.toasted.error(e.response.data);
    }
    commit('SET_LOADING', false);
    dispatch('getList', {});
  },

  /**
   * Websocket trigger action
   */
  wsEvent: ({ commit, dispatch }, data) => { commit(`WS_${data.action}`, data.payload.data); dispatch('getList', {}); },

  tmpID: ({ getters }) => `tmp:${getters.namespace}:${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`,
};
