import Invoices from '@/components/views/finance/Invoices.vue';

import Index from '@/components/invoice/index.vue';

import Form from '@/components/invoice/form.vue';

export default [
  {
    path: '/finance/invoice/list',
    component: Invoices,
    meta: {
      restriction: ['WMS.Finance'],
    },

    children: [
      {
        name: 'finance-invoice-list',
        path: '',
        component: Index,
        meta: { restriction: ['WMS.Finance'] },
        children: [
          {
            name: 'finance-invoice-form',
            path: '/finance/invoice/form/:ItemId?',
            component: Form,
            meta: { restriction: ['WMS.Finance'] },
          },
        ],
      },
    ],
  },
];
