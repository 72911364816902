<template>
  <transition name="slide-fade">
    <div>
      <v-row>
        <v-col>
          <v-card
            outlined
            :disabled="loading"
            :loading="loading"
            :class="{'pt-2': !loading}"
          >
            <template slot="progress">
              <progress-bar />
            </template>
            <c-vuex-list
              :headers="headers"
              :get-list="getList"
              :list="list"
              :loading="loading"
            >
              <template #footer-prepend>
                <v-btn
                  small
                  color="primary darken-1"
                  class="width-auto"
                  :disabled="loading"
                  :loading="loading"
                  @click.stop="onForm('Inbound')"
                >
                  {{ $t('label:inbound', null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  small
                  color="primary darken-1"
                  class="width-auto ml-2"
                  :disabled="loading"
                  :loading="loading"
                  @click.stop="onForm('Outbound')"
                >
                  {{ $t('label:outbound', null, 1) }}
                  <v-icon
                    small
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn-toggle
                  v-model="dStatus"
                  mandatory
                  @change="onUpdateStatusFilter"
                >
                  <v-btn
                    small
                    :value="null"
                  >
                    {{ $t('text:all') }}
                  </v-btn>
                  <v-btn
                    v-for="({text, value}, index) in statuses"
                    :key="`status-${index}`"
                    width="auto"
                    small
                    :value="value"
                  >
                    {{ text }}
                  </v-btn>
                </v-btn-toggle>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-btn
                      icon
                      @click.stop="onForm(item.Type, item.ItemId)"
                    >
                      <v-icon>
                        mdi-open-in-app
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    {{ item.ItemId }}
                  </td>
                  <td>{{ item.TmsId }}</td>
                  <td>{{ $t(item.Type) }}</td>
                  <td>{{ item.Dock.Name }}</td>
                  <td>{{ mQuantity(item) }}</td>
                  <td>{{ item.Serial }}</td>
                  <td>{{ item.DriverName }}</td>
                  <td>
                    {{ item.PlannedStart ? $moment(item.PlannedStart).format("DD-MM-YYYY") : '-' }}
                  </td>
                  <td>
                    <v-tooltip
                      v-if="['New', 'Planned'].includes(item.Status)"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          class="mr-2 float-right"
                          v-on="on"
                          @click="onDelete(item)"
                        >
                          <v-icon
                            class="delete-icon"
                          >
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t('label:delete') }}
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </c-vuex-list>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DockingIndex',

  data: () => ({
    dStatus: null,
  }),

  computed: {
    ...mapGetters({
      list: 'docking/list',

      loading: 'docking/loadingList',

      dockItems: 'dock/items',
    }),
    statuses() {
      return [
        {
          value: 'New',
          text: this.$t('status:docking:new', null, 1),
        },
        {
          value: 'Planned',
          text: this.$t('status:docking:planned', null, 1),
        },
        {
          value: 'Check-in',
          text: this.$t('status:docking:check-in', null, 1),
        },
        {
          value: 'Handling',
          text: this.$t('status:docking:handling', null, 1),
        },
        {
          value: 'Check-out',
          text: this.$t('status:docking:check-out', null, 1),
        },
        {
          value: 'Done',
          text: this.$t('status:docking:done', null, 1),
        },
      ];
    },
    headers() {
      return [
        {
          text: '', value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:id', null, 1),
          value: 'ItemId',
          type: 'number',
          fixed: 0,
          clearable: true,
          sortable: true,
        },
        {
          text: this.$t('label:slim_id', null, 1), value: 'TmsId', type: 'text',
        },
        {
          text: this.$t('label:type', null, 1),
          value: 'Type',
          type: 'select',
          options: {
            items: [
              { text: this.$t('label:inbound', null, 1), value: 'Inbound' },
              { text: this.$t('label:outbound', null, 1), value: 'Outbound' },
            ],
          },
          clearable: true,
        },

        {
          text: this.$t('label:dock', null, 1),
          value: 'DockId',
          type: 'select',
          options: {
            items: this.dockItems,
            itemText: 'Name',
            itemValue: 'ItemId',
            action: this.dockGetItems,
            loading: this.dockLoading,
          },
        },
        {
          text: this.$t('label:package:quantity', null, 1), value: '', searchable: false, sortable: false,
        },
        {
          text: this.$t('label:serial', null, 1), value: 'Serial', type: 'text', clearable: true,
        },
        {
          text: this.$t('label:driver', null, 1), value: 'DriverName', type: 'text',
        },
        {
          text: this.$t('label:planned_start', null, 1),
          value: 'PlannedStart',
          type: 'date',
          options: {
            start: { max: this.$moment().format('YYYY-MM-DD') },
            end: { min: this.$moment().format('YYYY-MM-DD') },
          },
        },
        {
          text: '', value: '', searchable: false, sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      getList: 'docking/getList',
      dockGetItems: 'dock/getItems',
      deleteItem: 'docking/destroy',
    }),

    mQuantity({ Orderlines }) {
      return Orderlines
        .flatMap((v) => [...v.InboundPackages || [], ...v.OutboundPackages || []])
        .reduce((total, { Quantity }) => total + Quantity, 0);
    },

    onUpdateStatusFilter(value) {
      const { filters } = this.list;
      delete filters.Status;
      if (value) {
        filters.Status = { type: 'select', value };
      }
      this.getList({
        ...this.list,
        offset: 0,
        filters,
      });
    },

    async onForm(Type, ItemId) {
      this.$router.push({ name: 'movement-docking-form', params: { ItemId, Type: Type.toLowerCase() } });
    },

    onDelete({ ItemId }) {
      this.deleteItem(ItemId);
    },
  },
};
</script>
