<template>
  <portal-page class="y-overflow custom-bg">
    <v-col>
      <v-row class="mt-0">
        <v-slide-x-transition>
          <v-col
            cols="6"
            class="pt-0"
          >
            <c-product-groups />
          </v-col>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-col
            cols="6"
            class="pt-0"
          >
            <c-products />
          </v-col>
        </v-slide-x-transition>
      </v-row>
      <v-row class="mt-2">
        <v-slide-y-transition>
          <v-col
            cols="12"
          >
            <c-packages />
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </v-col>
    <router-view />
  </portal-page>
</template>

<script>
import PortalPage from '@/components/PortalPage.vue';
import CProducts from '@/components/product/index/portal.vue';
import CProductGroups from '@/components/product-group/index/portal.vue';
import CPackages from '@/components/package/index/portal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ViewsStorageProducts',
  components: {
    PortalPage,
    CProducts,
    CProductGroups,
    CPackages,
  },

  computed: {
    ...mapGetters({
      productGroupList: 'productGroup/list',
      productList: 'product/list',
    }),
  },

};
</script>
