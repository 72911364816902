<template>
  <v-expansion-panel
    v-if="value"
    class="mb-2"
  >
    <v-expansion-panel-header>
      <template #default="{ open }">
        <v-row align="center">
          <v-col cols="auto">
            <h4
              class="text-uppercase font-weight-bold"
              :class="{ 'white--text': !open }"
            >
              {{ $t("label:order_line", null, 1) }} #{{ path[0] + 1 }}
            </h4>
          </v-col>
          <v-col
            xl="7"
            md="8"
          >
            <v-row>
              <v-col
                xl="4"
                md="4"
                class="d-flex flex-column"
              >
                <h5
                  v-if="value.Batch"
                  style="position: absolute; transform: translateY(-14px)"
                  :class="{ 'white--text': !open }"
                  class="font-weight-thin"
                >
                  {{ $t("label:batch", null, 1) }}
                </h5>
                <span :class="{ 'white--text': !open }">
                  {{ value.Batch }}
                </span>
              </v-col>
              <v-col
                xl="3"
                md="2"
                class="d-flex flex-column"
              >
                <h5
                  v-if="value.Quantity"
                  style="position: absolute; transform: translateY(-14px)"
                  :class="{ 'white--text': !open }"
                  class="font-weight-thin"
                >
                  {{ $t("label:batch:quantity", null, 1) }}
                </h5>
                <span :class="{ 'white--text': !open }">
                  {{ value.Quantity }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              v-if="!disable.delete"
              icon
              small
              class="mr-4"
              @click.stop="$emit('remove-orderline')"
            >
              <v-icon :class="{ 'white--text': !open }">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <v-row
        class="mt-1"
        dense
      >
        <v-col
          xl="4"
          md="5"
        >
          <v-text-field
            :value="value.Batch"
            :label="$t('label:batch', null, 1)"
            :disabled="disable.main"
            @input="(v) => onInput('Batch', v)"
          />
          <v-row
            no-gutters
            align="center"
          >
            <v-checkbox
              :input-value="value.Unique"
              disabled
              color="red"
              :label="$t('label:unique', null, 1)"
              left
              dense
            />
            <v-spacer />
            <v-alert
              v-if="!disabledStatus"
              text
              outlined
              dense
              color="info"
              class="mb-0"
            >
              <v-checkbox
                :input-value="value.Status"
                true-value="Check-out"
                false-value="Handling"
                class="float-left pt-0 mt-0"
                :color="'red'"
                :label="$t('label:ready_for_pickup', null, 1)"
                left
                dense
                hide-details
                @change="(v) => onInput('Status', v)"
                @click.stop
              />
            </v-alert>
          </v-row>
        </v-col>
        <v-col
          xl="3"
          md="4"
          class="pb-0"
        >
          <v-autocomplete
            :value="value.ProductId"
            :label="$t('label:product', null, 1)"
            :items="products"
            :loading="productLoading"
            :search-input.sync="productSearch"
            :disabled="
              disable.main ||
                productLoading ||
                !template.CustomerId ||
                Boolean(value.OutboundPackages.length)
            "
            item-value="ItemId"
            item-text="Name"
            cache-items
            flat
            hide-no-data
            clearable
            aria-autocomplete="off"
            @update:search-input="onProductSearch"
            @input="(v) => onInput('ProductId', v)"
          />
          <c-number
            :value="value.Quantity"
            class="float-right"
            :label="$t('label:batch:quantity', null, 1)"
            :disabled="disable.main"
            @input="(v) => onInput('Quantity', v)"
          />
        </v-col>
        <v-col class="pb-0">
          <v-textarea
            :value="value.Description"
            style="margin-top: 10px"
            rows="3"
            filled
            :label="$t('label:description', null, 1)"
            @input="(v) => onInput('Description', v)"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-2"
      >
        <div class="fadeline w-100" />
      </v-row>
      <v-row
        class="mt-2"
        no-gutters
      >
        <v-expansion-panels
          class="panels-styled"
          focusable
        >
          <slot name="packages" />
        </v-expansion-panels>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrderlineOutbound',

  props: {
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex]',
    },
    disable: {
      type: Object,
      default: () => ({
        main: false,
        unique: false,
        delete: false,
      }),
    },
  },

  data: () => ({
    productSearch: null,
    products: [],
    disabledStatus: null,
  }),

  computed: {
    ...mapGetters({
      template: 'outboundOrder/template',
      productLoading: 'product/loading',
    }),

    disableShred() {
      return (
        !this.value.Quantity
        || Number(this.value.Quantity) === 1
        || this.value.OutboundPackages.length > 0
      );
    },

    value() {
      return this.template.Orderlines[this.path[0]];
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      productReturnItems: 'product/returnItems',
      reset: 'outboundOrder/resetTemplate',
    }),
    initialize() {
      this.onProductSearch();
      this.disabledStatus = this.value.Status !== 'Handling';
    },
    mTotal(OutboundPackage) {
      return (
        OutboundPackage.ProductBundle.Bundles.reduce(
          (total, { Quantity }) => total * (Quantity || 0),
          OutboundPackage.ProductBundle.Quantity,
        ) * OutboundPackage.Quantity
      );
    },
    onInput(p, v) {
      const { Orderlines } = this.template;
      Orderlines[this.path[0]] = { ...this.value, [p]: v };
      this.reset({ ...this.template, Orderlines });
    },
    onProductSearch() {
      clearTimeout(this.productTimeout);
      this.productTimeout = setTimeout(async () => {
        const filter = {
          CustomerId: this.template.CustomerId,
        };
        if (!this.value) {
          return;
        }
        if (this.productSearch?.length) {
          filter.Name = { value: this.productSearch, type: 'text' };
        } else {
          filter.ItemId = { value: this.value.ProductId, type: 'select' };
        }
        this.products = (await this.productReturnItems(filter)).filter(
          ({ deletedAt, ItemId }) => !deletedAt || ItemId === this.value.ProductId,
        );
      }, 500);
    },
  },
};
</script>
