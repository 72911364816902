<template>
  <storage-page class="y-overflow custom-bg">
    <v-col sm="12">
      <c-cluster-index />
    </v-col>
    <router-view />
  </storage-page>
</template>

<script>

import StoragePage from '@/components/StoragePage.vue';
import CClusterIndex from '@/components/cluster/index.vue';

export default {
  name: 'ViewsStorageClusters',
  components: {
    StoragePage,
    CClusterIndex,
  },
};
</script>
