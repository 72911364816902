<template>
  <v-expansion-panel
    v-if="value"
    class="mb-2"
  >
    <v-expansion-panel-header>
      <template #default="{ open }">
        <v-row align="center">
          <v-col cols="auto">
            <h4
              class="text-uppercase font-weight-bold"
              :class="{ 'white--text': !open }"
            >
              {{ $t("label:order_line", null, 1) }} #{{ path[0] + 1 }}
            </h4>
          </v-col>
          <v-col
            xl="7"
            md="8"
          >
            <v-row>
              <v-col
                xl="3"
                md="2"
                class="d-flex flex-column"
              >
                <h5
                  v-if="value.Quantity"
                  style="position: absolute; transform: translateY(-14px)"
                  :class="{ 'white--text': !open }"
                  class="font-weight-thin"
                >
                  {{ $t("label:batch:quantity", null, 1) }}
                </h5>
                <span :class="{ 'white--text': !open }">
                  {{ value.Quantity }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              v-if="!disable.delete"
              icon
              small
              class="mr-4"
              @click.stop="$emit('remove-orderline')"
            >
              <v-icon :class="{ 'white--text': !open }">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <v-row
        class="mt-1"
        dense
      >
        <v-col
          xl="4"
          md="5"
          class="pb-0"
        >
          <v-autocomplete
            :value="value.InitiatorType"
            :label="$t('label:initiator', null, 1)"
            :disabled="disable.main"
            :items="cInitiators"
            :p-users="managers"
            @change="onChangeInitiatorType"
          />
          <v-autocomplete
            v-if="value.InitiatorType === 'Customer'"
            :value="value.InitiatorId"
            :label="$t('label:initiator', null, 1)"
            :disabled="disable.main"
            :items="cContacts"
            :item-text="(v) => `${v.FirstName} ${v.LastName}`"
            :item-value="(v) => `${v.FirstName} ${v.LastName}`"
            @change="(v) => onInput('InitiatorId', v)"
          />
          <c-auth-select
            v-else
            disabled
            :p-items="managers"
            :value="value.InitiatorId"
            :label="$t('label:initiator')"
          />
          <v-checkbox
            :input-value="value.Billable"
            :label="$t('label:billable')"
            hide-details
            class="mt-0 mb-2"
            @change="(v) => onInput('Billable', v)"
          />
        </v-col>
        <v-col class="pb-0">
          <v-textarea
            :value="value.Description"
            style="margin-top: 10px"
            rows="3"
            filled
            :label="$t('label:description', null, 1)"
            @input="(v) => onInput('Description', v)"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-0"
      >
        <div class="fadeline w-100" />
      </v-row>
      <v-row class="mt-0">
        <v-col
          md="6"
          xl="4"
          class="d-flex flex-column"
        >
          <v-row
            justify="space-between"
            no-gutters
            style="max-height: 28px"
          >
            <h4 style="height: 28px">
              {{ $t("label:package:source", null, 2) }}
            </h4>
          </v-row>
          <v-card
            flat
            outlined
            class="py-2 px-0 mt-1 flex-grow-1"
          >
            <v-col
              class="px-2 py-0"
            >
              <v-row no-gutters>
                <v-expansion-panels
                  class="panels-styled"
                  focusable
                >
                  <slot name="source-packages" />
                </v-expansion-panels>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
        <v-col class="pl-0 d-flex flex-column">
          <v-row
            justify="space-between"
            no-gutters
            style="max-height: 28px"
          >
            <h4 style="height: 28px">
              {{ $t("label:package:result", null, 2) }}
            </h4>
            <v-btn
              v-if="!disable.main"
              small
              color="primary"
              @click="$emit('add-result-package')"
            >
              {{ $t("label:package:result", null, 1) }}
              <v-icon right>
                fal fa-plus
              </v-icon>
            </v-btn>
          </v-row>
          <v-card
            flat
            outlined
            class="py-2 px-0 mt-1 flex-grow-1"
          >
            <v-col
              class="px-2 py-0"
            >
              <v-row no-gutters>
                <v-expansion-panels
                  class="panels-styled"
                  focusable
                >
                  <slot name="result-packages" />
                </v-expansion-panels>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { getInstance } from '@/auth/index';

export default {
  name: 'OrderlineTransformation',

  props: {
    path: {
      type: Array,
      required: true,
      description: '[OrderlineIndex][PackageType][PackageIndex]',
    },
    disable: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    managers: [],
  }),

  computed: {
    ...mapGetters({
      template: 'transformationOrder/template',
      contacts: 'contact/items',
    }),

    cContacts() {
      return this.contacts.filter(
        ({ deletedAt, ItemId }) => !deletedAt || ItemId === this.value.ContactId,
      );
    },

    value() {
      return this.template.Orderlines[this.path[0]];
    },

    cInitiators() {
      return [
        { text: this.$t('label:warehouse', null, 1), value: 'Warehouse' },
        { text: this.$t('label:customer', null, 1), value: 'Customer' },
      ];
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      productReturnItems: 'product/returnItems',
      reset: 'transformationOrder/resetTemplate',
    }),

    async initialize() {
      this.onProductSearch();
      const { items } = await getInstance().getUsers('WMS.Manager');
      this.managers = items;
    },

    onProductSearch() {
      clearTimeout(this.productTimeout);
      this.productTimeout = setTimeout(async () => {
        const filter = {
          CustomerId: this.template.CustomerId,
        };
        if (!this.value) {
          return;
        }
        if (this.productSearch?.length) {
          filter.Name = { value: this.productSearch, type: 'text' };
        } else {
          filter.ItemId = { value: this.value.ProductId, type: 'select' };
        }
        this.products = (await this.productReturnItems(filter)).filter(
          ({ deletedAt, ItemId }) => !deletedAt || ItemId === this.value.ProductId,
        );
      }, 500);
    },
    onChangeInitiatorType(v) {
      this.onInput('InitiatorType', v);
      if (v === 'Customer') {
        this.onInput('InitiatorId', null);
      } else {
        this.onInput('InitiatorId', this.$auth.user.sub);
      }
    },
    onInput(p, v) {
      const { Orderlines } = this.template;
      Orderlines[this.path[0]] = { ...this.value, [p]: v };
      this.reset({ ...this.template, Orderlines });
    },
  },
};
</script>
