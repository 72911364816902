import defaultState from '@/store/defaultState';

export default {
  ...defaultState,
  defaultTemplate: {

    ProductId: null,

    Batch: '',

    Quantity: null,

    DockingId: null,
    InboundOrderId: null,
    OutboundOrderId: null,

    Name: '',
    Description: '',

    InboundPackages: [],
    OutboundPackages: [],

    Unique: false,

    Status: 'Plannable',

    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  template: null,
  loading: false,
  items: [],
};
